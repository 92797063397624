/* eslint-disable  */
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields/prepared/checkboxes/IsDiffHeightMark.RF';
import { shallow } from 'enzyme';

import ManholesCard from './manholes';

jest.mock('redux-form', () => ({
  ...jest.requireActual('redux-form'),
  reduxForm: jest.fn(() => (Component) => {
    return Component;
  }),
}));

jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  connect: jest.fn(() => (b) => {
    return b;
  }),
}));

describe('ManholesJestSpec', function () {
  it('test', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const props = {
      card: { bord_begin: 1 },
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };
    // Act
    const wrapper = shallow(<ManholesCard {...props} />);

    // Assert
    expect(wrapper.find(IsDiffHeightMarkRF).props()).toStrictEqual({
      disabled: false,
    });
  });
});
