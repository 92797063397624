import './ChangeButton.scss';

import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { ContinueDialogHoc } from 'app/pages/cardsOgh/components/DIalogs/ContinueDialogHOC';
import { DialogEditButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/DialogEditButton';
import { ListDataType } from 'app/pages/cardsOgh/components/RightPanelCard/components/DialogEditButton/listDataType';
import { useEditCheckStatus } from 'app/pages/cardsOgh/hooks/useEditStatus';
import { useUser } from 'app/pages/providers';
import { Button } from 'core/uiKit/components/buttons/Button';
import React, { useState } from 'react';

import { checkPrivileges } from '../../helpers/checkPrivileges';
import { useCheckShowChangeBtn } from './useCheckShowChangeBtn';

interface ChangeButtonType {
  columnTree: ListDataType;

  /**
   * Flag active button.
   */
  activeButton: boolean;

  /**
   * Code type.
   */
  typeCode: number | string;
}

/**
 * Кнопка изменения состава паспортов дочерних реестров.
 *
 * @param {object} props - Пропсы.
 * @param {ListDataType} props.columnTree - Данные списка.
 * @param props.activeButton - Active Button.
 * @param props.typeCode - Code type.
 * @returns {JSX.Element} - Возвращает компонент кнопки изменить.
 */
export const ChangeButton = ({
  columnTree,
  activeButton,
  typeCode,
}: ChangeButtonType) => {
  const [openDialog, setOpenDialog] = useState(false);
  const editStatus = useEditCheckStatus();
  const { user } = useUser();
  const showChangeBtn = useCheckShowChangeBtn();
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();

  const isShow =
    !isCurrentObjectIsExternalSystem &&
    showChangeBtn &&
    activeButton &&
    !editStatus &&
    checkPrivileges(user.privileges ?? '', typeCode, 'editOgh');

  /**
   * Функция открывающая диалоговое окно.
   *
   * @returns {void} - Ничего.
   */
  const openDialogHandler = () => {
    setOpenDialog(true);
  };

  /**
   * Функция закрывающая диалоговое окно.
   *
   * @returns {void} - Ничего.
   */
  const closeDialogHandler = () => {
    setOpenDialog(false);
  };

  return isShow ? (
    <>
      <Button
        onClick={openDialogHandler}
        color={'success'}
        variant={'contained'}
        className={'card__right_panel edit_button_tree'}
      >
        Изменить
      </Button>
      {openDialog ? (
        <ContinueDialogHoc>
          <DialogEditButton
            isOpen={openDialog}
            closeDialog={closeDialogHandler}
            listData={columnTree}
          />
        </ContinueDialogHoc>
      ) : null}
    </>
  ) : null;
};
