import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import { Card } from 'core/uiKit/components/Card';
import { TableSimple } from 'core/uiKit/components/tables/TableSimple';
import React from 'react';

import { BrIdLinkCell } from './cells/BrIdLink.Cell';
import { BrStatusIdCell } from './cells/BrStatusId.Cell';
import { ScheduleStatusIdLinkCell } from './cells/ScheduleStatusIdLink.Cell';

const columns = [
  {
    Cell: BrIdLinkCell,
    Header: 'Id заявки на обследование ОГХ',
    accessor: 'br_id',
  },
  {
    Cell: BrStatusIdCell,
    Header: 'Статус заявки на обследование ОГХ',
    accessor: 'br_status_id',
  },
  {
    Cell: ScheduleStatusIdLinkCell,
    Header: 'Id графика паспортизации ОГХ',
    accessor: 'schedule_id',
  },
  {
    Header: 'Статус графика паспортизации ОГХ',
    accessor: 'schedule_status_name',
  },
  {
    Header: 'Год паспортизации',
    accessor: 'schedule_passportization_year',
  },
];

/**
 * Таб таблицы паспортизации.
 *
 * @returns {JSX.Element}
 */
export const PassportizationTab = () => {
  const { passportization } = useGetCurrentCard();

  return (
    <Card className={'p-3 mb-3'}>
      <CardContainer title={'Паспортизация'}>
        <TableSimple data={passportization || []} columns={columns} />
      </CardContainer>
    </Card>
  );
};
