import { elementFormGrid } from 'app/components/card/common/grid/index';
import { WithReduxFormSelect } from 'app/components/common/SelectField';

import { key, label } from './metadata';


/* eslint-disable */
export default function (props) {
  const cols = 2;
  const title = '';
  return elementFormGrid(getElements(props), cols, title, props.card.type_id);
}

function getElements(props) {
  return [
    {
      id: key,
      name: key,
      editable: false,
      formValue: true,
      component: WithReduxFormSelect,
      addition: {
        label,
        options: props.otherImprovementObjectType,
      },
    },
  ];
}
