import { YardOneTab } from 'app/components/card/ogh/ImprovementTerritoryCard/yard/YardOne.Tab/YardOne.Tab';
import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import { CreateType } from 'core/uiKit/preparedInputs';
import { GrbsId } from 'core/uiKit/preparedInputs/selects/simpleSelect/GrbsId';
import { shallow } from 'enzyme';
import React from 'react';

describe('spec YardOne.Tab', function () {
  it('should', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const attribute = { create_type: 3, grbs_id: { legal_person: 43 } };
    // Act
    const wrapper = shallow(<YardOneTab card={{ attribute }} />);
    // Assert
    expect(
      wrapper.contains(
        <CardContainer>
          <CreateType
            disabled={true}
            // @ts-ignore
            value={3}
          />
          <GrbsId
            // @ts-ignore
            disabled={true}
            value={43}
            label={'Учредитель/ГРБС'}
          />
        </CardContainer>,
      ),
    ).toBeTruthy();
  });

  it('нет свойств', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const attribute = {};
    // Act
    const wrapper = shallow(<YardOneTab card={{ attribute }} />);
    // Assert
    expect(
      wrapper.contains(
        <CardContainer>
          <CreateType
            disabled={true}
            // @ts-ignore
            value={undefined}
          />
          <GrbsId
            // @ts-ignore
            disabled={true}
            value={undefined}
            label={'Учредитель/ГРБС'}
          />
        </CardContainer>,
      ),
    ).toBeTruthy();
  });

  it('нет attribute', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    // Act
    const wrapper = shallow(<YardOneTab card={{}} />);
    // Assert
    expect(
      wrapper.contains(
        <CardContainer>
          <CreateType
            disabled={true}
            // @ts-ignore
            value={undefined}
          />
          <GrbsId
            // @ts-ignore
            disabled={true}
            value={undefined}
            label={'Учредитель/ГРБС'}
          />
        </CardContainer>,
      ),
    ).toBeTruthy();
  });
});
