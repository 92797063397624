import { cancelEditCard } from 'app/actions/odsObjectActions';
import { clearActionsTree } from 'app/actions/odsObjectActions/clearActionsTree';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { CancelButton } from 'app/pages/cardsOgh/components/buttonsCard/components/CanselButton';
import React from 'react';
import { useDispatch } from 'react-redux';

/**
 * CancelButtonHOC.
 *
 * @returns {*}
 */
export const CancelButtonHOC = () => {
  const dispatch = useDispatch();

  const { root_id, record_id } = useGetCurrentCard();

  /**
   * Функция сброса формы.
   *
   * @returns {void}
   */
  const resetForm = () => {
    dispatch(cancelEditCard(root_id, record_id));
    dispatch(clearActionsTree());
  };

  return <CancelButton resetForm={resetForm} />;
};
