import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';
import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { DISTRICT_LIST_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/DistrictList';

import { districtListAsyncValidate } from './asyncValidate.DistrictListField';

 
describe('title asyncValidateDistrictListField', () => {
  it.each`
    value              | description                    | expected
    ${[]}              | ${'Пустой массив'}             | ${{ [DISTRICT_LIST_NAME]: REQUIRED_FIELD_MESSAGE }}
    ${['']}            | ${'Массив с пустой строкой'}   | ${{ [DISTRICT_LIST_NAME]: REQUIRED_FIELD_MESSAGE }}
    ${[RANDOM_NUMBER]} | ${'Массив с рандомным числом'} | ${null}
  `('тест валидации $description', ({ value, expected }) => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const formValues = {
      [DISTRICT_LIST_NAME]: value,
    };
    // Act
    // Assert
    expect(districtListAsyncValidate(formValues)).toStrictEqual(expected);
  });
});
