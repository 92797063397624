import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid';
import { WithReduxFormCheckbox } from 'app/components/common/CheckboxField';
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import isMandatory from 'app/utils/card/isMandatory';
import { NumberFieldRF } from 'core/form/reduxForm/fields/default/NumberField.RF';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields/prepared/checkboxes/IsDiffHeightMark.RF';
import { CoatingGroupIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CoatingGroupId.RF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';

import { CoatingFaceTypeIdField } from '../../../components/selects/CoatingFaceTypeId.Field';
import { CoatingTypeIdFiled } from '../../../components/selects/CoatingTypeId.Field';
import { UnitIdField } from '../../../components/selects/UnitId.Field';

const checkboxStyle = {
  height: 50,
  paddingTop: 0,
};

/**
 * Таба Исходные данные __Элементы организации рельефа__.
 *
 * @param {*} params - Параметры.
 * @returns {JSX}
 */
export const CharacteristicsTabRelief = (params) => {
  const { editMode, isCurrentObjectIsExternalSystem, card, required } = params;

  return (
    <div id="characteristics">
      {elementFormGrid(
        [
          <div>
            <NumberFieldRF
              required={true}
              label={'Количество'}
              name={'quantity'}
              decimalScale={2}
              disabled={!(editMode && !isCurrentObjectIsExternalSystem)}
            />
          </div>,

          <div>
            <TotalAreaGeoRF disabled={true} decimalScale={2} />
          </div>,
          <div>
            <UnitIdField />
          </div>,
          {
            addition: {
              label: 'Материал',
              options: card.material,
              required: isMandatory(card.type_id, 'material_id', required),
            },
            component: WithReduxFormSelect,
            editable: editMode,
            formValue: true,
            id: 'material_id',
            name: 'material_id',
          },
          {
            component: renderCustomTextField,
            defaultValue: card.zone_name || '',
            editable: false,
            formValue: true,
            id: 'zone_name',
            label: 'Принадлежность элемента к зоне территории',
            name: 'zone_name',
          },

          <div>
            <CoatingGroupIdRF disabled={!editMode} />
          </div>,

          <div>
            <CoatingTypeIdFiled />
          </div>,
          <div>
            <CoatingFaceTypeIdField />
          </div>,
          <div>
            <NumberFieldRF
              label="Площадь облицовки, кв.м"
              name="face_area"
              decimalScale={2}
              disabled={!editMode}
            />
          </div>,
        ],
        2,
        '',
        card.type_id,
      )}

      {elementFormGrid(
        [
          {
            component: WithReduxFormCheckbox,
            editable: editMode,
            formValue: true,
            id: 'no_calc',
            label: 'Не учитывать',
            name: 'no_calc',
            style: checkboxStyle,
          },
          <div
            style={{
              height: 50,
              margin: '9px 24px',
              minWidth: 256,
              paddingTop: 0,
            }}
          >
            <IsDiffHeightMarkRF
              disabled={!editMode || isCurrentObjectIsExternalSystem}
              justifyContent={'space-between'}
              labelPlacement={'start'}
            />
          </div>,
        ],
        2,
        '',
        card.typeId,
      )}
    </div>
  );
};
