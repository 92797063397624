const tabs = [
  {
    id: 'squares-tab',
    name: 'Площади',
  },

  {
    id: 'plantation-tab',
    name: 'Озеленение',
  },
  {
    id: 'repairs-tab',
    name: 'Ремонты',
  },
  {
    id: 'repairs_plan-tab',
    name: 'Проекты',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
  {
    id: 'orders-tab',
    name: 'Ордера на работы',
  },
];
const oznTabs = [
  {
    id: 'squares-tab',
    name: 'Площади',
  },
  {
    id: 'plantation-tab',
    name: 'Озеленение',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
  {
    id: 'orders-tab',
    name: 'Ордера на работы',
  },
  {
    id: 'additional-information-tab',
    name: 'Дополнительная информация',
  },
];

 
export const yardCardTabs = tabs;
 
export const improvementObjectCardTabs = tabs;
 
export const oznCardTabs = oznTabs;
