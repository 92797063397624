import getCurrentObject from 'app/selectors/card/getCurrentObject';
/* eslint-disable  */
import getFormValues from 'app/selectors/form/getFormValues';
import { createSelector } from 'reselect';

/**
 *
 * @param state
 */
const getPlanarStructureTypeId = (state) =>
  getFormValues(state).planar_structure_type_id;

/**
 *
 * @param state
 */
const getPlanarStructureTypeRef = (state) =>
  getCurrentObject(state).planar_structure_type_ref;

/**
 *
 * @param currentPlanarTypeId
 * @param planarStructureTypeRef
 */
export const selectorResult = (currentPlanarTypeId, planarStructureTypeRef) => {
  if (Array.isArray(planarStructureTypeRef)) {
    return planarStructureTypeRef.filter(
      ({ typeId }) => typeId === currentPlanarTypeId,
    );
  }
  return [];
};

export default createSelector(
  [getPlanarStructureTypeId, getPlanarStructureTypeRef],
  (currentPlanarTypeId, planarStructureTypeRef) => {
    return selectorResult(currentPlanarTypeId, planarStructureTypeRef);
  },
);
