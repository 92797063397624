import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { FieldsContainer } from 'app/pages/cardsOgh/components/FieldsContainer';
import { OghCardHeaderFF } from 'app/pages/cardsOgh/components/headerFF/OghHeaderFF';
import { OtherDocumentsFf } from 'app/pages/cardsOgh/components/tables/Documents/FieldsFF/preparedFields/OtherDocuments.FF';
import { Versions } from 'app/pages/cardsOgh/components/tables/Versions';
import { TabsCard } from 'app/pages/cardsOgh/components/TabsCard';
import { TabsCardItem } from 'app/pages/cardsOgh/components/TabsCard/TabsCardItem';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { DatePickerFF } from 'core/form/finalForm/fields/default/datePickers/DatePickerFF';
import { TextFieldFF } from 'core/form/finalForm/fields/default/TextFieldFF';
import { IsDiffHeightMarkFf } from 'core/form/finalForm/fields/prepared/checkboxes/IsDiffHeightMark.FF';
import { IsFilledFf } from 'core/form/finalForm/fields/prepared/checkboxes/IsFilled.FF';
import { NoCalcFF } from 'core/form/finalForm/fields/prepared/checkboxes/NoCalc.FF';
import { PlacesAreaFF } from 'core/form/finalForm/fields/prepared/numberField/PlacesArea.FF';
import { PlacesQuantityFF } from 'core/form/finalForm/fields/prepared/numberField/PlacesQuantity.FF';
import { RelatedElementsFlowersGardenIdFF } from 'core/form/finalForm/fields/prepared/numberField/RelatedElementsFlowersGardenId.FF';
import { RelatedElementsTreesShrubsIdFF } from 'core/form/finalForm/fields/prepared/numberField/RelatedElementsTreesShrubsId.FF';
import { ErzStateIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/ErzStateId.FF';
import { MaterialIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/MaterialId.FF/MaterialId.FF';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import React from 'react';

import { GuranteePeriodDate } from './components/GuranteePeriodDate';
import { InstallationDate } from './components/InstallationNameDate';
import { LifeTimeDate } from './components/LifeTimeDate';
import { QuantityField } from './components/QuantityField';
import { UnitIdField } from './components/UnitIdField';
import { VerticalLandscapingClassTypeIdField } from './components/VerticalLandscapingClassTypeIdField';
import { VerticalLandscapingRefTypeIdField } from './components/VerticalLandscapingRefTypeIdField';
import { VerticalLandscapingTypeIdField } from './components/VerticalLandscapingTypeIdField';

/**
 * LeftPanel Инженерные сооружения.
 *
 * @returns {JSX.Element}
 */
export const LeftPanelVerticalLandscaping = () => {
  const { rootId = 0 } = useGetCurrentCard();
  const { editMode } = useEditMode();

  const tabsTopHeader = [
    { label: 'Свойства', value: 'features' },
    { label: 'Версии', show: Boolean(rootId), value: 'versions' },
  ];

  const tabsEnd = [
    { label: 'Исходные данные', value: 'initialData' },
    { label: 'Документы', value: 'documents' },
  ];

  return (
    <>
      <OghCardHeaderFF />
      <TabsCard tabs={tabsTopHeader}>
        <TabsCardItem tabValue={'features'}>
          <FieldsContainer>
            <VerticalLandscapingTypeIdField />
            <VerticalLandscapingClassTypeIdField />
            <VerticalLandscapingRefTypeIdField />
            <PlacesQuantityFF disabled={!editMode} required={true} />
            <ParentInfo />
            <PlacesAreaFF disabled={!editMode} required={true} />
            <RelatedElementsFlowersGardenIdFF disabled={true} />
            <RelatedElementsTreesShrubsIdFF disabled={true} />
            <NoCalcFF disabled={!editMode} />
            <IsDiffHeightMarkFf disabled={true} />
          </FieldsContainer>
          <TabsCard tabs={tabsEnd}>
            <TabsCardItem tabValue={'initialData'}>
              <FieldsContainer>
                <UnitIdField />
                <QuantityField />
                <MaterialIdFF disabled={!editMode} required={true} />
                <TextFieldFF
                  name={'zone_name'}
                  label={'Принадлежность элемента к зоне территории'}
                  disabled={true}
                />
                <TextFieldFF
                  name={'id_rfid'}
                  label={'ID RFID метки'}
                  disabled={!editMode}
                />
                <InstallationDate />
                <LifeTimeDate />
                <GuranteePeriodDate />
                <TextFieldFF
                  name={'eais_id'}
                  label={'ИД УАИС БУ'}
                  disabled={true}
                />
                <IsFilledFf disabled={!editMode} />
                <ErzStateIdFF disabled={true} />
                <DatePickerFF
                  name={'erz_date'}
                  label={'Дата состояния (из ЕРЗ)'}
                  disabled={true}
                />
              </FieldsContainer>
            </TabsCardItem>
            <TabsCardItem tabValue={'documents'}>
              <OtherDocumentsFf />
            </TabsCardItem>
          </TabsCard>
        </TabsCardItem>
        <TabsCardItem tabValue={'versions'}>
          <FieldsContainer>
            <Versions />
          </FieldsContainer>
        </TabsCardItem>
      </TabsCard>
    </>
  );
};
