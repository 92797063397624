// 33 Элементы благоустройства для маломобильных групп населения
/* eslint-disable  */

import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import CardAbstract from 'app/components/card/common/CardAbstract';
import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import DocumentsTab, {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import { WithReduxFormCheckbox } from 'app/components/common/CheckboxField';
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import { column } from 'app/constants/tables';
import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import getQuantityDigits from 'app/selectors/card/getQuantityDigits';
import hasQuantityLock from 'app/selectors/card/hasQuantityLock';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import isMandatory, { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import { ArrangeElementTypeIdField } from './components/selects/ArrangeElementTypeId.Field';
import { UnitIdField } from './components/selects/UnitId.Field';
import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['ppi'];
const Versions = VersionsTemplate(types);

const tabs = [
  {
    id: 'characteristics-tab',
    name: 'Исходные данные',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

const propTypes = {
  card: PropTypes.object,
  changeFieldValue: PropTypes.func,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  formValues: PropTypes.object,
  mode: PropTypes.object,
  quantityLock: PropTypes.bool,
  required: PropTypes.bool,
};

const checkboxStyle = {
  height: 50,
  paddingTop: 0,
};

/**
 * Классовый компонет PpiCard.
 */
class PpiCard extends React.Component {
  /**
   * Компонент жизненого цикла componentDidUpdate.
   *
   * @param {object} prevProps - Предыдущие пропсы.
   * @returns {void} - Nothing.
   * @example --------
   */
  componentDidUpdate(prevProps) {
    const {
      formValues: { unit_id },
      mode,
    } = this.props;
    if (prevProps.formValues.unit_id !== unit_id && !mode.viewMode) {
      this.setDefaultQuantity();
    }
  }

  /**
   * Функция setDefaultQuantity.
   *
   * @returns {void} - Nothing.
   * @example ----
   */
  setDefaultQuantity() {
    const { changeFieldValue, quantityLock } = this.props;
    if (quantityLock) {
      changeFieldValue('quantity', 1);
    } else {
      changeFieldValue('quantity', '');
    }
  }

  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   * @example ----
   */
  render() {
    const {
      card,
      createMode,
      documents,
      editMode,
      isCurrentObjectIsExternalSystem,
      mode,
      quantityDigits,
      quantityLock,
      required,
    } = this.props;

    return (
      <CardAbstract>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(card.type_id)}

          <div id="props">
            <CardContainer>
              <ArrangeElementTypeIdField />
              <ParentInfo />
            </CardContainer>

            {elementFormGrid(
              [
                {
                  component: WithReduxFormCheckbox,
                  editable: editMode,
                  formValue: true,
                  id: 'no_calc',
                  label: 'Не учитывать',
                  name: 'no_calc',
                  style: checkboxStyle,
                },
                <div
                  style={{
                    height: 50,
                    margin: '9px 24px',
                    minWidth: 256,
                    paddingTop: 0,
                  }}
                >
                  <IsDiffHeightMarkRF
                    disabled={!mode.editMode || isCurrentObjectIsExternalSystem}
                    justifyContent={'space-between'}
                    labelPlacement={'start'}
                  />
                </div>,
              ],
              2,
              '',
              card.typeId,
            )}

            <Tabs tabs={tabs} />

            <div id="characteristics">
              {elementFormGrid(
                [
                  {
                    addition: {
                      digits: quantityDigits,
                      positive: true,
                      required: isMandatory(card.type_id, 'quantity', required),
                      type: 'integer',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    editable:
                      editMode &&
                      !quantityLock &&
                      !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'quantity',
                    label: 'Количество',
                    name: 'quantity',
                  },
                  <div>
                    <TotalAreaGeoRF disabled={true} decimalScale={2} />
                  </div>,

                  <div>
                    <UnitIdField />
                  </div>,
                  {
                    addition: {
                      label: 'Материал',
                      options: card.material,
                      required: isMandatory(
                        card.type_id,
                        'material_id',
                        required,
                      ),
                    },

                    component: WithReduxFormSelect,
                    editable: editMode,
                    formValue: true,
                    id: 'material_id',
                    name: 'material_id',
                  },
                  {
                    component: renderCustomTextField,
                    defaultValue: card.zone_name || '',
                    editable: false,
                    formValue: true,
                    id: 'zone_name',
                    label: 'Принадлежность элемента к зоне территории',
                    name: 'zone_name',
                  },
                ],
                column.TWO,
                '',
                card.type_id,
              )}
            </div>

            {DocumentsTab()({
              documents: documents,
              editable: editMode,
              typeId: card.type_id,
            })}
          </div>
          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={createMode}
              key={`versions-${card.root_id}`}
            />
          </div>
        </div>
      </CardAbstract>
    );
  }
}

PpiCard.propTypes = propTypes;

const dictFields = ['arrange_element_type_id', 'unit_id', 'material_id'];

const numericFields = [
  {
    name: 'quantity',
    positive: true,
    type: 'decimal',
    zero: false,
  },
];

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object} - Отвалидированные поля.
 * @example ----
 */
const validate = (values) => validateNumeric(values, numericFields);

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
const createAttribute = (formValues) => ({
  ...createDocumentsAttribute(formValues),
});

/**
 * Функция сабмита.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {void} - Жопа.
 * @example ----
 */
function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

const improvementElementDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);

  const result = {
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),
    enableReinitialize: true,
    formValues: getFormValues(state),

    initialValues: {
      arrange_element_type_id: parse(
        'arrange_element_type_id.arrange_element_type',
      ),
      endDate: parseDate(props.card.end_date),
      is_diff_height_mark: parse('is_diff_height_mark'),
      material_id: parse('material_id.material'),
      no_calc: parse('no_calc'),
      parent_name: props.card.parent_info,
      quantity: parse('quantity'),
      startDate: parseDate(props.card.start_date),
      totalAreaGeo: parse('total_area_geo'),
      unit_id: parse('unit_id.unit'),
    },

    onSubmit: onSubmit.bind({ props }),
    quantityDigits: getQuantityDigits(state),
    quantityLock: hasQuantityLock(state),
    required: isRequired(state, props),
    validate,
  };

  return improvementElementDocumentsReducer(result, state, props);
};

/**
 * Функция получения экшенов.
 *
 * @param {Function} dispatch - Функция диспатч.
 * @returns {{changeFieldValue: mapDispatchToProps.changeFieldValue}} - Экшен changeFieldValue.
 * @example ----
 */
const mapDispatchToProps = (dispatch) => ({
  /**
   *
   * @param field
   * @param value
   */
  changeFieldValue: (field, value) => {
    dispatch(change('editorCard', field, value));
  },
});

/**
 * Обертка для использования хуков.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - Реакт Компонент.
 * @example -----
 */
const PpiCardContainer = (props) => {
  const { typeId } = useGetParentCard();
  return <PpiCard parentTypeId={typeId} {...props} />;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(PpiCardContainer));
