import './Draggable.scss';

import {
  fetchNeighboursGeometry,
  resetNeighboursGeometry,
} from 'app/actions/geometryActions';
import { WithMapContext } from 'app/components/map/withMap';
import { NEIGHBOURS } from 'app/constants/layers';
import hasNeighbours from 'app/selectors/card/hasNeighbours';
import { onGetNeighboursButtonPress } from 'app/utils/card/checkGeometry';
import { InfoPanel } from 'core/uiKit/components';
import { Button } from 'core/uiKit/components/buttons/Button';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';

import toggleContent from '../content/toggle';
import ToggleButton from './ToggleButton';

class NeighboursButton extends React.Component {
  static propTypes = {
    hasNeighbours: PropTypes.bool.isRequired,
    resetNeighboursGeometry: PropTypes.func.isRequired,
    fetchNeighboursGeometry: PropTypes.func.isRequired,
    showAlert: PropTypes.func.isRequired,
    rootId: PropTypes.number,
    currentCard: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      toggleData: '',
      toggleHeader: '',
    };
  }
  static contextType = WithMapContext;
  componentWillUnmount() {
    this.props.resetNeighboursGeometry();
  }

  getNeighboursGeometriesByButton = async () => {
    const {
      rootId,
      currentCard,
      hasNeighbours,
      resetNeighboursGeometry,
      fetchNeighboursGeometry,
      showAlert,
    } = this.props;

    const data = {
      rootId,
      geometry: this.context.getGeometry(currentCard.record_id),
      startDate: currentCard.start_date,
      endDate: currentCard.end_date,
    };

    this.context.clearLayer(NEIGHBOURS);
    const { bodyData, headerData } = await onGetNeighboursButtonPress({
      data,
      hasNeighbours,
      resetNeighboursGeometry,
      fetchNeighboursGeometry,
      showAlert,
      draw: this.context.drawNeighboursGeometry,
    });

    return { bodyData, headerData };
  };

  render() {
    const { isOpen, toggleData, toggleHeader } = this.state;

    const handleClickOpen = async () => {
      const { bodyData, headerData } =
        await this.getNeighboursGeometriesByButton();
      if (bodyData) {
        this.setState({ isOpen: true });
        this.setState({ toggleHeader: headerData });
        this.setState({ toggleData: bodyData });
      }
    };
    const closeDialog = () => {
      this.setState({
        isOpen: false,
        className: 'draggable__popup draggable__center',
      });
    };
    return (
      <>
        <InfoPanel
          isOpen={isOpen}
          textHeader={toggleHeader}
          Body={toggleData}
          ButtonsActions={
            <Button variant={'contained'} onClick={closeDialog} color={'info'}>
              Закрыть
            </Button>
          }
        />
        <ToggleButton
          active={this.props.hasNeighbours}
          content={toggleContent.neighbours}
          onClick={handleClickOpen}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hasNeighbours: hasNeighbours(state),
  };
};

const mapDispatchToProps = {
  resetNeighboursGeometry,
  fetchNeighboursGeometry,
};

 
export default connect(mapStateToProps, mapDispatchToProps)(NeighboursButton);
