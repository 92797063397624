import { CUSTOMER_HINT } from 'app/constants/messages';
import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import getHint from 'app/utils/getHint';
import getTypeCodeById from 'app/utils/getTypeCodeById';
import { CustomerPlaceRF } from 'core/form/reduxForm/fields/prepared/selects/autocompleteAsync/CustomerPlace.RF';
import { OwnerIdPlaceRF } from 'core/form/reduxForm/fields/prepared/selects/autocompleteAsync/OwnerIdPlace.RF/OwnerIdPlace.RF';

/**
 * Поля Балансодержатель и заказчик.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
function OwnerAndCustomerFields(props) {
  const {
    isOrphanObject,
    mode,
    editable,
    required,
    card,
    isCurrentObjectIsExternalSystem,
  } = props;

  const typeId = card.type_id;
  const place = getTypeCodeById(typeId);

  return (
    <CardContainer>
      <OwnerIdPlaceRF
        place={place}
        helpTooltip={getHint('owner_id')}
        disabled={
          !(
            !isOrphanObject &&
            mode &&
            mode.editMode &&
            !isCurrentObjectIsExternalSystem
          )
        }
        required={required}
      />

      <CustomerPlaceRF
        place={place}
        helpTooltip={CUSTOMER_HINT}
        disabled={!(editable && !isCurrentObjectIsExternalSystem)}
        required={required}
      />
    </CardContainer>
  );
}

export default OwnerAndCustomerFields;
