import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import { CreateType } from 'core/uiKit/preparedInputs';
import { GrbsId } from 'core/uiKit/preparedInputs/selects/simpleSelect/GrbsId';
import React from 'react';

/**
 * YardOneTab.
 *
 * @param params - Parameters.
 * @param params.card - Card.
 * @param params.card.attribute - Attribute.
 * @param params.card.attribute.create_type - Create type.
 * @param params.card.attribute.grbs_id - GrbsId.
 * @param params.card.attribute.grbs_id.legal_person - GrbsId.
 * @returns {JSX}
 */
export const YardOneTab = ({
  card: { attribute },
}: {
  card: {
    attribute?: { create_type?: number; grbs_id?: { legal_person: number } };
  };
}) => {
  return (
    <CardContainer>
      <CreateType
        disabled={true}
        // @ts-ignore
        value={attribute?.create_type}
      />
      <GrbsId
        // @ts-ignore
        disabled={true}
        value={attribute?.grbs_id?.legal_person}
        label={'Учредитель/ГРБС'}
      />
    </CardContainer>
  );
};
