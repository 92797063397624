/* eslint-disable */

import { cardToggleStyles } from 'app/components/card/ogh/index/card/styles';
import { ArrowHeader } from 'app/pages/cardsOgh/components/RightPanelCard/components/icons/ArrowHeader';
import IconButton from 'core/newComponents/IconButton';
import { shallow } from 'enzyme';
import $ from 'jquery';
import { useState } from 'react';

import RightPanel from './RightPanel';

jest.mock('jquery');
jest.mock('react', () => ({
  ...jest.requireActual('react'),
  useState: jest.fn(),
}));

/**
 * Компонент для теста.
 *
 * @returns {JSX.Element}
 */
const PanelMap = () => {
  return <div>Панель управления</div>;
};

describe('RightPanel', function () {
  it('test click and render ArrowHeader', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const setStateSpy = jest.fn();
    useState.mockReturnValue([false, setStateSpy]);

    const toggleClassSpy = jest.fn();
    $.mockReturnValue({ toggleClass: toggleClassSpy });

    // Act
    const wrapper = shallow(<RightPanel panel={PanelMap} />);

    const buttonArrowHeader = wrapper.find(ArrowHeader).props();

    buttonArrowHeader.onClick();

    // Assert
    expect($).toBeCalledWith('#right-panel');
    expect(toggleClassSpy).toBeCalledWith('bottom-auto');
    expect(setStateSpy).toBeCalledTimes(1);
    expect(buttonArrowHeader).toStrictEqual({
      active: false,
      onClick: expect.any(Function),
    });
  });
  it('test click and render IconButton', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const setStateSpy = jest.fn();
    useState.mockReturnValue([false, setStateSpy]);

    const toggleSpy = jest.fn();
    const resizeSpy = jest.fn();
    $.mockReturnValue({ resize: resizeSpy, toggle: toggleSpy });

    // Act
    const wrapper = shallow(<RightPanel panel={PanelMap} />);

    const buttonIcon = wrapper.find(IconButton).props();

    buttonIcon.onClick();

    // Assert
    expect($).toBeCalledWith('.ods-core-left-panel');
    expect(toggleSpy).toBeCalledTimes(1);
    expect(resizeSpy).toBeCalledTimes(1);
    expect(buttonIcon).toStrictEqual({
      className:
        'right-panel__card-toggle card-toggle fa fa-angle-double-right boxArea',
      edge: false,
      onClick: buttonIcon.onClick,
      style: cardToggleStyles,
    });
  });
});
