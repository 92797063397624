/* eslint-disable  */
import './Panel.scss';

import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import * as B from 'app/components/card/common/MapEditPanel/buttons';
import { useMapContext } from 'app/components/map/useMapContext';
import { HOLE, LINESTRING, POINT, POLYGON } from 'app/constants/geometryTypes';
import { CheckGeometryButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/CheckGeometryButton';
import { NeighboursButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/NeighboursButton';
import { ReonButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/ReonButton';
import { ShowChildrenButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/ShowChildrenButton';
import { ShowDistrictBoundaries } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/ShowDistrictBoundaries/ShowDistrictBoundaries';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import React, { useState } from 'react';

/**
 *Компонент PanelRightCard.
 *
 * @param {{allowedGeometryTypes: object}} props - Жопа.
 * @returns {JSX.Element} - JSX.
 * @example
 * const MapEditPanelWrapper = (props) => {
 *   const allowedGeometryTypes = useGetAllowedGeometryTypes();
 *
 *   return <PanelRightCard allowedGeometryTypes={allowedGeometryTypes} />;
 * };
 */
export const PanelRightCard = ({ allowedGeometryTypes }) => {
  const [activeGeometry, setActiveGeometry] = useState(null);
  const { editMode } = useEditMode();
  const context = useMapContext();
  const currentCard = useGetCurrentCard();

  const polygonAllowed = allowedGeometryTypes.includes(POLYGON);
  const pointAllowed = allowedGeometryTypes.includes(POINT);
  const lineAllowed = allowedGeometryTypes.includes(LINESTRING);

  /**
   * Функция onDrawClick.
   *
   * @param {boolean} active - Флаг активности.
   * @param {*} geometryType - Тип геометрии.
   * @param {Function} drawFunction - Функция отрисовки.
   * @returns {void} - Nothing.
   * @example  <B.DrawButton
   *         active={activeGeometry === POINT}
   *         disabled={!editMode || !pointAllowed}
   *         geometryType={POINT}
   *         onClick={onDrawClick}
   */
  const onDrawClick = (active, geometryType, drawFunction) => {
    if (active) {
      stopDrawing();
    } else {
      context.callMapFunction(drawFunction, currentCard);
      changeActiveGeometry(geometryType);
    }
  };

  /**
   * Функция changeActiveGeometry.
   *
   * @param {*} activeGeometry - Геометрия.
   * @returns {void} - Nothing.
   */
  const changeActiveGeometry = (activeGeometry) => {
    setActiveGeometry(activeGeometry);
  };

  /**
   * Функция stopDrawing.
   *
   * @returns {void} - Nothing.
   */
  const stopDrawing = () => {
    context.callMapFunction('stopDrawing', currentCard);
    changeActiveGeometry(null);
  };

  /**
   * Функция получения текущей геометрии.
   *
   * @returns {*} Геометрия.
   */
  const getGeometry = () => {
    return context.getGeometry(currentCard.recordId);
  };

  return (
    <div className="map-edit__card">
      <ShowDistrictBoundaries />
      <B.DownloadGeometryButton
        currentCard={currentCard}
        getGeometry={getGeometry}
        isMapLoaded={context.isMapLoaded}
      />
      <NeighboursButton />
      <ReonButton />
      <ShowChildrenButton />
      <CheckGeometryButton />
      <B.DrawButton
        active={activeGeometry === POINT}
        disabled={!editMode || !pointAllowed}
        geometryType={POINT}
        onClick={onDrawClick}
      />
      <B.DrawButton
        active={activeGeometry === LINESTRING}
        disabled={!editMode || !lineAllowed}
        geometryType={LINESTRING}
        onClick={onDrawClick}
      />
      <B.DrawButton
        active={activeGeometry === POLYGON}
        disabled={!editMode || !polygonAllowed}
        geometryType={POLYGON}
        onClick={onDrawClick}
      />
      <B.DrawButton
        active={activeGeometry === HOLE}
        disabled={!editMode || !polygonAllowed}
        geometryType={HOLE}
        onClick={onDrawClick}
      />
      <B.DeleteSelectedButton
        callMapFunction={context.callMapFunction}
        currentCard={currentCard}
        disabled={!(editMode && context.isMapLoaded)}
      />
    </div>
  );
};
