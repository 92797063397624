import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { FieldsContainer } from 'app/pages/cardsOgh/components/FieldsContainer';
import { getNameFile } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getNameFile';
import React from 'react';
import { Field } from 'redux-form';

import { DocumentWithReduxForm } from '../DocumentWithReduxForm';

const ID = 15;
const NAME = getNameFile(ID);
const TITLE = 'Акт приемки выполненных работ';

/**
 * Таблица документов __Акт приемки выполненных работ__.
 *
 * @returns {JSX.Element}
 * @example
 * <ActPerfomWorksRF />
 */
export const ActPerfomWorksRF = () => {
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();

  return (
    <FieldsContainer>
      <Field
        title={TITLE}
        id={ID}
        name={NAME}
        component={DocumentWithReduxForm}
        disabled={isCurrentObjectIsExternalSystem}
      />
    </FieldsContainer>
  );
};
