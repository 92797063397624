import CardAbstract from 'app/components/card/common/CardAbstract';
/* eslint-disable  */
import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import { PlacementIdRFWrapper } from 'app/components/card/ogh/components/selects/PlacementIdRFWrapper';
import DocumentsTab from 'app/components/card/ogh/DocumentsTab/DocumentsTab';
import printOghCardHeader from 'app/components/card/ogh/header/oghHeader';
import { IsFilled } from 'app/components/card/ogh/ImprovementTerritoryCard/components/IsFilled';
import VersionsTemplate from 'app/components/card/ogh/versions/VersionsTemplate';
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import { ODH_AXIS_TOOLTIP } from 'app/constants/messages';
import { column } from 'app/constants/tables';
import filterDictBySprNumber from 'app/utils/card/filterDictBySprNumber';
import isMandatory from 'app/utils/card/isMandatory';
import { CheckboxRF } from 'core/form/reduxForm/fields/default/CheckboxRF';
import { KeyboardDatePickerRF } from 'core/form/reduxForm/fields/default/datePickers/KeyboardDatePickerRF';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields/prepared/checkboxes/IsDiffHeightMark.RF';
import { ErzStateIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/ErzStateId.RF';
import { EaisIdRF } from 'core/form/reduxForm/fields/prepared/textField/EaisId.RF';
import { IdRfidRF } from 'core/form/reduxForm/fields/prepared/textField/IdRfid.RF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import React from 'react';

const convElementTypeFieldStyles = {
  width: 'calc(100% - 48px)',
};

const types = ['maf'];
const Versions = VersionsTemplate(types);

const tabs = [
  {
    id: 'characteristics-tab',
    name: 'Исходные данные',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

/**
 * Компонент MafComponent.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - Реакт компонент.
 * @example ----
 */
const MafComponent = (props) => {
  /**
   * Компонент getConvElementTypeOptions.
   *
   * @returns {*} - Жопа.
   * @example ----
   */
  function getConvElementTypeOptions() {
    const { type_id, conv_element_type, viewing } = props.card;
    if (viewing) {
      return conv_element_type;
    }
    return filterDictBySprNumber(conv_element_type, type_id);
  }

  const {
    mode,
    card,
    createMode,
    editMode,
    required,
    documents,
    formValues,
    isCurrentObjectIsExternalSystem,
  } = props;

  return (
    <CardAbstract>
      <div id="editor-card">
        {printOghCardHeader(card, mode)}
        {card && objectTabs(card.type_id)}

        <div id="props">
          {elementFormGrid(
            [
              {
                addition: {
                  label: 'Тип',
                  options: getConvElementTypeOptions(),
                  required: isMandatory(
                    card.type_id,
                    'conv_element_type_id',
                    required,
                  ),
                },
                component: WithReduxFormSelect,
                editable: editMode && !isCurrentObjectIsExternalSystem,
                formValue: true,
                id: 'conv_element_type_id',
                name: 'conv_element_type_id',
                style: convElementTypeFieldStyles,
              },
              <div>
                <ParentInfo />
              </div>,
            ],
            column.TWO,
            '',
            card.type_id,
          )}

          {elementFormGrid(
            [
              <div>
                <IsDiffHeightMarkRF disabled={!editMode} />
              </div>,
            ],
            column.TWO,
            '',
            card.type_id,
          )}

          <Tabs tabs={tabs} />

          <div id="characteristics">
            {elementFormGrid(
              [
                <div>
                  <PlacementIdRFWrapper disabled={true} withAll={false} />
                </div>,
                {
                  addition: {
                    9: '[а-яА-Я0-9a-zA-Z]',
                    formatChars: {
                      mask: '99',
                      tooltipText: ODH_AXIS_TOOLTIP,
                      type: 'text',
                    },
                  },
                  component: renderCustomTextField,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'odh_axis',
                  label: 'Ось',
                  name: 'odh_axis',
                },
                {
                  addition: {
                    label: 'Сторона',
                    options: card.odh_side,
                    required: isMandatory(
                      card.type_id,
                      'odh_side_id',
                      required,
                    ),
                  },
                  component: WithReduxFormSelect,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'odh_side_id',
                  name: 'odh_side_id',
                },
                {
                  addition: {
                    digits: 2,
                    positive: true,
                    required: isMandatory(card.type_id, 'bord_begin', required),
                    type: 'decimal',
                    zero: false,
                  },
                  // костыль что бы 0 отобразился
                  component: renderCustomTextField,
                  defaultValue: formValues.bord_begin,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'bord_begin',
                  label: 'Начало, м',
                  name: 'bord_begin',
                },
                {
                  addition: {
                    digits: 2,
                    positive: true,
                    required: isMandatory(card.type_id, 'bord_end', required),
                    type: 'decimal',
                    zero: false,
                  },
                  // костыль что бы 0 отобразился
                  component: renderCustomTextField,
                  defaultValue: formValues.bord_end,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'bord_end',
                  label: 'Конец, м',
                  name: 'bord_end',
                },
              ],
              column.TWO,
              'Местоположение',
              card.type_id,
            )}

            {elementFormGrid(
              [
                {
                  addition: { type: 'text' },
                  component: renderCustomTextField,
                  editable: editMode,
                  formValue: true,
                  id: 'property',
                  label: 'Краткая характеристика',
                  name: 'property',
                },
                {
                  addition: {
                    digits: 2,
                    positive: true,
                    required: isMandatory(card.type_id, 'area', required),
                    type: 'decimal',
                    zero: false,
                  },
                  component: renderCustomTextField,
                  defaultValue: formValues.area,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'area',
                  label: 'Площадь, кв.м',
                  name: 'area',
                },
                <div>
                  <TotalAreaGeoRF disabled={true} decimalScale={2} />
                </div>,
                <div>
                  <IdRfidRF disabled={!editMode} />
                </div>,
                <div>
                  <KeyboardDatePickerRF
                    label={'Дата установки'}
                    name={'installation_date'}
                    disabled={!editMode}
                  />
                </div>,
                <div>
                  <KeyboardDatePickerRF
                    label={'Срок эксплуатации'}
                    name={'lifetime'}
                    disabled={!editMode}
                  />
                </div>,
                <div>
                  <KeyboardDatePickerRF
                    label={'Гарантийный срок'}
                    name={'guarantee_period'}
                    disabled={!editMode}
                    minDate={formValues?.installation_date || ''}
                  />
                </div>,
                <div>
                  <EaisIdRF disabled={true} />
                </div>,
                <div>
                  <IsFilled />
                </div>,
                <div>
                  <ErzStateIdRF disabled={true} />
                </div>,
                <div>
                  <KeyboardDatePickerRF
                    label={'Дата состояния (из ЕРЗ)'}
                    name={'erz_date'}
                    disabled={true}
                  />
                </div>,
              ],
              column.TWO,
              'Характеристики',
              card.type_id,
            )}

            {elementFormGrid(
              [
                <div>
                  <CheckboxRF
                    label={'Включать в общую площадь ОДХ'}
                    name={'is_object_area'}
                    disabled={true}
                  />
                </div>,
              ],
              column.ONE,
              '',
              card.type_id,
            )}

            {elementFormGrid(
              [
                {
                  addition: { type: 'text' },
                  component: renderCustomTextField,
                  editable: editMode,
                  formValue: true,
                  id: 'description',
                  label: 'Примечание',
                  name: 'description',
                },
              ],
              column.ONE,
              '',
              card.type_id,
            )}
          </div>
          {DocumentsTab()({
            documents,
            editable: editMode,
            typeId: card.type_id,
          })}
        </div>
        <div hidden={true} id="versions">
          <Versions
            card={card}
            disabled={createMode}
            key={`versions-${card.root_id}`}
          />
        </div>
      </div>
    </CardAbstract>
  );
};

export default MafComponent;
