import { mapDispatchToPropsReducer as ordersDispatchReducer } from 'app/components/card/ogh/OrdersTab';

import { OznCard } from './OznCard';
jest.mock('./ImprovementTerritoryCardTemplate', () =>
  jest.fn((param) => param),
);

//
jest.mock('./components/RepairsPlanTable');
describe('spec OznCard', function () {
  it('ImprovementTerritoryCardTemplate', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    // Act

    // Assert
    expect(OznCard).toStrictEqual({
      mapDispatchToPropsReducers: [ordersDispatchReducer],
      mapStateToPropsReducers: [
        expect.any(Function),
        expect.any(Function),
        expect.any(Function),
        expect.any(Function),
        expect.any(Function),
        expect.any(Function),
        expect.any(Function),
        expect.any(Function),
        expect.any(Function),
        expect.any(Function),
        expect.any(Function),
        expect.any(Function),
        expect.any(Function),
        expect.any(Function),
        expect.any(Function),
      ],
      propsTabElementRenders: expect.any(Array),
    });
  });
});
