/* eslint-disable  */
import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import PropTypes from 'prop-types';
import React from 'react';

export const CONTAINER_TYPE_ID_NAME = 'container_type_id';

/**
 * ## Тип МСО.
 *
 * `type = Select`
 * `name = container_type_id`
 * `dict = containerType`.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - JSX.
 * @example ---
 */
export const ContainerTypeId = (props) => {
  const dict = 'containerType';

  const { data = [] } = useGetDictionary(dict);

  const options = data.map((option) => ({
    id: option.id,
    name: option.attribute.name,
  }));

  return (
    <Select
      {...props}
      label={'Тип МНО'}
      name={CONTAINER_TYPE_ID_NAME}
      options={options}
    />
  );
};

ContainerTypeId.propTypes = {
  /**
   * Выбор темной темы.
   */
  dark: PropTypes.bool,

  /**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,

  /**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,

  /**
   * Вывод вспомогательного текста.
   */
  helperText: PropTypes.string,

  /**
   * Функция вызова когда выберется итем.
   */
  onChange: PropTypes.func,

  /**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,

  /**
   * Значение.
   */
  value: PropTypes.any,
};
