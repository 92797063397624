import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
/* eslint-disable  */
import CardAbstract from 'app/components/card/common/CardAbstract';
import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import DocumentsTab from 'app/components/card/ogh/DocumentsTab/DocumentsTab';
import printOghCardHeader from 'app/components/card/ogh/header/oghHeader';
import { IsFilled } from 'app/components/card/ogh/ImprovementTerritoryCard/components/IsFilled';
import MafType from 'app/components/card/ogh/LittleForm/components/MafType';
import VersionsTemplate from 'app/components/card/ogh/versions/VersionsTemplate';
import { WithReduxFormCheckbox } from 'app/components/common/CheckboxField';
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import {
  IdRfidRF,
  IsDiffHeightMarkRF,
  KeyboardDatePickerRF,
} from 'core/form/reduxForm/fields';
import { ErzStateIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/ErzStateId.RF';
import { EaisIdRF } from 'core/form/reduxForm/fields/prepared/textField/EaisId.RF';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import React from 'react';

const types = ['little_form'];
const Versions = VersionsTemplate(types);

const tabs = [
  {
    id: 'characteristics-tab',
    name: 'Исходные данные',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

const checkboxStyle = {
  height: 50,
  paddingTop: 0,
};

/**
 *
 * @param props
 */
const LittleFormComponent = (props) => {
  const {
    card,
    changeFieldValue,
    createMode,
    documents,
    editMode,
    formValues,
    isCurrentObjectIsExternalSystem,
    isEditableMaterial,
    mafTypeLevel1,
    mafTypeLevel2,
    mafTypeLevel3,
    mode,
    onChangeMafTypeLevel2Id,
    parentTypeId,
    required,
    units,
  } = props;

  const { type_id: typeId } = card;

  // Обеспечить выполнение следующего алгоритма ограничения (ФЛК):
  // "Ед.изм. - шт." - "Количество" -  целое число (не может быть отрицательным)
  // "Ед.изм. - п.м./кв.м - "Количество" - целое и дробное число (не может быть отрицательным)
  const unit = units.find((item) => item.id === formValues.unit_id);
  const quantityDigits = ['square_meters', 'running_meters'].includes(
    unit?.code,
  )
    ? 2
    : 0;

  return (
    <CardAbstract>
      <div id="editor-card">
        {printOghCardHeader(card, mode)}
        {card && objectTabs(typeId)}
        <div id="props">
          <MafType
            changeFieldValue={changeFieldValue}
            parentTypeId={parentTypeId}
            editable={editMode}
            isCurrentObjectIsExternalSystem={isCurrentObjectIsExternalSystem}
            mafTypeLevel1={mafTypeLevel1}
            mafTypeLevel1Id={formValues.maf_type_level1_id}
            mafTypeLevel2={mafTypeLevel2}
            mafTypeLevel3={mafTypeLevel3}
            required={required}
            typeId={typeId}
            onChangeMafTypeLevel2Id={onChangeMafTypeLevel2Id}
          />

          {elementFormGrid(
            [
              <div>
                <ParentInfo />
              </div>,
              {
                component: WithReduxFormCheckbox,
                editable: editMode,
                formValue: true,
                id: 'no_calc',
                label: 'Не учитывать',
                name: 'no_calc',
                style: checkboxStyle,
              },
              <div
                style={{
                  height: 50,
                  margin: '9px 24px',
                  minWidth: 256,
                  paddingTop: 0,
                }}
              >
                <IsDiffHeightMarkRF
                  disabled={!mode.editMode || isCurrentObjectIsExternalSystem}
                  justifyContent={'space-between'}
                  labelPlacement={'start'}
                />
              </div>,
            ],
            2,
            '',
            typeId,
          )}

          <Tabs tabs={tabs} />

          <div id="characteristics">
            {elementFormGrid(
              [
                {
                  addition: {
                    autoSelectForOne: editMode,
                    label: 'Ед. изм.',
                    labelStyle: {
                      height: 33,
                    },
                    options: units,
                    required,
                  },
                  component: WithReduxFormSelect,
                  editable:
                    !isCurrentObjectIsExternalSystem &&
                    formValues.maf_type_level1_id &&
                    units.length > 0
                      ? editMode
                      : false,
                  formValue: true,
                  id: 'unit_id',
                  name: 'unit_id',
                },
                {
                  addition: {
                    digits: quantityDigits,
                    positive: true,
                    required,
                    type: 'integer',
                    zero: false,
                  },
                  component: renderCustomTextField,
                  editable: !isCurrentObjectIsExternalSystem && editMode,
                  formValue: true,
                  id: 'quantity',
                  label: 'Количество МАФ',
                  name: 'quantity',
                },
                {
                  addition: {
                    label: 'Материал',
                    options: card.material,
                  },
                  component: WithReduxFormSelect,
                  editable: editMode && isEditableMaterial(),
                  formValue: true,
                  id: 'material_id',
                  name: 'material_id',
                },
                {
                  component: renderCustomTextField,
                  defaultValue: card.zone_name || '',
                  editable: false,
                  formValue: true,
                  id: 'zone_name',
                  label: 'Принадлежность элемента к зоне территории',
                  name: 'zone_name',
                },
                <div>
                  <IdRfidRF disabled={!editMode} />
                </div>,
                <div>
                  <KeyboardDatePickerRF
                    label={'Дата установки'}
                    name={'installation_date'}
                    disabled={!editMode}
                  />
                </div>,
                <div>
                  <KeyboardDatePickerRF
                    label={'Срок эксплуатации'}
                    name={'lifetime'}
                    disabled={!editMode}
                  />
                </div>,
                <div>
                  <KeyboardDatePickerRF
                    label={'Гарантийный срок'}
                    name={'guarantee_period'}
                    disabled={!editMode}
                    minDate={formValues?.installation_date || ''}
                  />
                </div>,
                <div>
                  <EaisIdRF disabled={true} />
                </div>,
                <div>
                  <IsFilled />
                </div>,
                <div>
                  <ErzStateIdRF disabled={true} />
                </div>,
                <div>
                  <KeyboardDatePickerRF
                    label={'Дата состояния (из ЕРЗ)'}
                    name={'erz_date'}
                    disabled={true}
                  />
                </div>,
              ],
              2,
              '',
              typeId,
            )}
          </div>

          {DocumentsTab()({
            documents,
            editable: editMode,
            typeId,
          })}
        </div>
        <div hidden={true} id="versions">
          <Versions
            card={card}
            disabled={createMode}
            key={`versions-${card.root_id}`}
          />
        </div>
      </div>
    </CardAbstract>
  );
};

/**
 *
 * @param props
 */
const LittleForm = (props) => {
  const { typeId } = useGetParentCard();
  return <LittleFormComponent parentTypeId={typeId} {...props} />;
};

export default LittleForm;
