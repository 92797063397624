import * as geometryTypes from 'app/constants/geometryTypes';
import getOghTypeId from 'app/selectors/card/getOghTypeId';
import getFormValues from 'app/selectors/form/getFormValues';
import getDict from 'app/selectors/getDict';
import { isNaN, uniq } from 'lodash';
import { createSelector } from 'reselect';

/* eslint-disable */
export default createSelector(
  [getOghTypeId, getFormValues, getDict('geometryTypes')],
  (typeId, attribute, geometryTypesDict) => {
    let result;

    if (typeId) {
      const geometryList = geometryTypesDict[typeId];
      const geometryEntries =
        geometryList &&
        geometryList.filter((item) =>
          checkByTemplate(attribute, item.template),
        );
      result = geometryEntries
        ? uniq(geometryEntries.map((item) => geometryTypes[item.geometry_type]))
        : [];
    } else {
      result = [];
    }

    return result;
  },
);

const isNumber = (value) => !isNaN(Number(value));

function checkByTemplate(attribute, template) {
  let geometryTypeFound = true;

  for (let key in template) {
    if (
      attribute[key] === template[key] ||
      (isNumber(template[key]) &&
        Number(attribute[key]) === Number(template[key]))
    ) {
      continue;
    } else {
      geometryTypeFound = false;
      break;
    }
  }

  return geometryTypeFound;
}
