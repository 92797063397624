import { elementFormGrid } from 'app/components/card/common/grid';
/* eslint-disable */
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import mafTypeService from 'app/services/mafTypeService';
import { NumberFieldRF } from 'core/form/reduxForm/fields/default/NumberField.RF';
import PropTypes from 'prop-types';
import React from 'react';

import { MafTypeLevel1RFWrapper } from './MafTypeLevel1RFWrapper';

/**
 * MafType.
 *
 * @param {object} props - Property.
 * @returns {JSX}
 */
const MafType = (props) => {
  /**
   * Get Element.
   *
   * @returns {JSX}
   */
  const getElements = () => {
    const {
      changeFieldValue,
      editable,
      isCurrentObjectIsExternalSystem,
      mafTypeLevel1Id,
      mafTypeLevel1,
      mafTypeLevel2,
      mafTypeLevel3,
      onChangeMafTypeLevel2Id,
      parentTypeId,
      required,
    } = props;

    const {
      label: labelMafQuantityCharacteristics,
      disabled: disabledMafQuantityCharacteristics,
    } = getQuantityCharacteristicsProps(mafTypeLevel1Id);

    const editableMafQuantityCharacteristics =
      editable && !disabledMafQuantityCharacteristics;

    return [
      <div>
        <MafTypeLevel1RFWrapper
          disabled={!(editable && !isCurrentObjectIsExternalSystem)}
          required={required}
          mafTypeLevel1={mafTypeLevel1}
          getQuantityCharacteristicsProps={getQuantityCharacteristicsProps}
          changeFieldValue={changeFieldValue}
          parentTypeId={parentTypeId}
        />
      </div>,
      // На всякий случай, чтобы можно было посмотреть, как было до правки ODS-12571
      // {
      //   addition: {
      //     label: 'Вид МАФ',
      //     options: parentTypeId
      //       ? mafTypeLevel1?.filter(({ parent_ogh_object_type_list = [] }) =>
      //           parent_ogh_object_type_list?.includes(parentTypeId),
      //         )
      //       : mafTypeLevel1,
      //     required,
      //     withChange: (mafTypeLevel1Id) => {
      //       const mafTypeObject = (mafTypeLevel1 || []).find(
      //         (item) => item.id === mafTypeLevel1Id,
      //       );
      //       const unitId = mafTypeObject ? mafTypeObject.unit_id : null;
      //       const { disabled } =
      //         getQuantityCharacteristicsProps(mafTypeLevel1Id);
      //       changeFieldValue('maf_type_level2_id', null);
      //       changeFieldValue('maf_type_level3_id', null);
      //       changeFieldValue('unit_id', unitId);
      //       if (disabled) {
      //         changeFieldValue('maf_quantity_characteristics', null);
      //       }
      //     },
      //   },
      //   component: SelectRF,
      //   editable: editable && !isCurrentObjectIsExternalSystem,
      //   formValue: true,
      //   id: 'maf_type_level1_id',
      //   name: 'maf_type_level1_id',
      // },
      {
        addition: {
          label: 'Уточнение',
          options: mafTypeLevel2,
          withChange: onChangeMafTypeLevel2Id,
        },
        component: WithReduxFormSelect,
        editable: editable && mafTypeLevel2 && mafTypeLevel2.length,
        formValue: true,
        id: 'maf_type_level2_id',
        name: 'maf_type_level2_id',
      },
      {
        addition: {
          label: 'Детализация',
          options: parentTypeId
            ? mafTypeLevel3?.filter(({ parent_ogh_object_type_list = [] }) =>
                parent_ogh_object_type_list?.includes(parentTypeId),
              )
            : mafTypeLevel3,

          /**
           *
           * @param mafTypeLevel3Id
           */
          withChange: (mafTypeLevel3Id) => {
            const mafTypeObject = (mafTypeLevel3 || []).find(
              (item) => item.id === mafTypeLevel3Id,
            );
            const unitId = mafTypeObject ? mafTypeObject.unit_id : null;

            changeFieldValue('unit_id', unitId);
          },
        },
        component: WithReduxFormSelect,
        editable: editable && mafTypeLevel3 && mafTypeLevel3.length,
        formValue: true,
        id: 'maf_type_level3_id',
        name: 'maf_type_level3_id',
      },
      <div>
        <NumberFieldRF
          {...{
            disabled: !editableMafQuantityCharacteristics,
            id: 'maf_quantity_characteristics',
            label: labelMafQuantityCharacteristics,
            name: 'maf_quantity_characteristics',
            required: editableMafQuantityCharacteristics,
          }}
        />
      </div>,
      // this.getMafQuantityCharacteristics(),
    ];
  };

  // getMafQuantityCharacteristics() {
  //   const { props } = this;
  //   const { label, disabled } = this.getQuantityCharacteristicsProps(
  //     props.mafTypeLevel1Id,
  //   );
  //
  //   return {
  //     id: 'maf_quantity_characteristics',
  //     name: 'maf_quantity_characteristics',
  //     label,
  //     editable,
  //     formValue: true,
  //     component: NumberFieldRF,
  //     addition: {
  //       type: 'integer',
  //       positive: true,
  //       zero: true,
  //       digits: 0,
  //       required: editable,
  //     },
  //   };
  // }

  /**
   * Function.
   *
   * @param {*} mafTypeLevel1Id - A.
   * @returns {*}
   */
  const getQuantityCharacteristicsProps = (mafTypeLevel1Id) => {
    const { mafTypeLevel1 } = props;
    return mafTypeService.getQuantityCharacteristicsProps(
      mafTypeLevel1,
      mafTypeLevel1Id,
    );
  };

  return elementFormGrid(getElements(), 2);
};

MafType.propTypes = {
  changeFieldValue: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  mafTypeLevel1: PropTypes.array,
  mafTypeLevel1Id: PropTypes.number,
  mafTypeLevel2: PropTypes.array,
  mafTypeLevel3: PropTypes.array,
  onChangeMafTypeLevel2Id: PropTypes.func.isRequired,
  required: PropTypes.bool,
  typeId: PropTypes.number,
};

export default MafType;
