import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import { DepartmentRF } from 'core/form/reduxForm/fields/prepared/selects/simple/Department.RF';
import React from 'react';

/**
 * Two tab.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const OznTwoTab = (props) => (
  <CardContainer>
    <DepartmentRF
      required={true}
      disabled={!props.editable || props.isCurrentObjectIsExternalSystem}
      withAll={false}
    />
  </CardContainer>
);
