import { shallow } from 'enzyme';
import React from 'react';

import { LandscapingTab } from './Landscaping.Tab';
import { LandscapingTable } from './Landscaping.Table/Landscaping.Table';

describe('LandscapingTabSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    const wrapper = shallow(<LandscapingTab />);
    // ❓ Assert
    expect(wrapper.text()).toBe('<Card />');
  });
  it('props', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    const wrapper = shallow(<LandscapingTab />);
    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      children: <LandscapingTable />,
      className: 'p-3 mb-3',
      hidden: true,
      id: 'ods-landscaping',
    });
  });
});
