/* eslint-disable */
import { shallow } from 'enzyme';

import { useState } from 'react';

import { RANDOM_ID } from 'core/forTesting/constants';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';

import { DialogCloseElectronicSignature } from './Dialog.CloseElectronicSignature';
import { ButtonCloseElectronicSignature } from './Button.CloseElectronicSignature';
import { CloseElectronicSignature } from './CloseElectronicSignature';

jest.mock('core/utils/hooks/useGetObjectId');
jest.mock('./Dialog.CloseElectronicSignature');
jest.mock('./Button.CloseElectronicSignature');

jest.mock('react', () => ({
  ...jest.requireActual('react'),
  useState: jest.fn(),
}));

/* eslint-disable */
describe('file ApproveElectronicSignature', () => {
  it('simple test', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const spySet = jest.fn();
    useState.mockImplementation((v) => [v, spySet]);
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    // Act
    const wrapper = shallow(<CloseElectronicSignature />);
    // Assert
    expect(wrapper.text()).toBe('<ButtonCloseElectronicSignature />');
  });
  it('simple test isOpen', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const spySet = jest.fn();
    useState.mockImplementation((v) => [true, spySet]);
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    // Act
    const wrapper = shallow(<CloseElectronicSignature />);
    // Assert
    expect(wrapper.text()).toBe(
      '<ButtonCloseElectronicSignature /><DialogCloseElectronicSignature />',
    );
  });

  it('props ButtonCloseElectronicSignature', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const spySet = jest.fn();
    useState.mockImplementation((v) => [v, spySet]);
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    // Act
    const wrapper = shallow(<CloseElectronicSignature />);
    const Button = wrapper.find(ButtonCloseElectronicSignature);
    // Assert

    expect(Button.props()).toStrictEqual({
      objectId: RANDOM_ID,
      onClick: expect.any(Function),
    });

    // Act
    Button.prop('onClick')();
    // Assert
    expect(spySet).toHaveBeenCalledWith(true);
  });

  it('props is Open', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const spySet = jest.fn();
    useState.mockImplementation((v) => [true, spySet]);
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    // Act
    const wrapper = shallow(<CloseElectronicSignature />);
    const Button = wrapper.find(ButtonCloseElectronicSignature);
    const Dialog = wrapper.find(DialogCloseElectronicSignature);

    // Assert
    expect(Button.props()).toStrictEqual({
      objectId: RANDOM_ID,
      onClick: expect.any(Function),
    });
    expect(Dialog.props()).toStrictEqual({
      isOpen: true,
      onClose: expect.any(Function),
    });

    // Act
    Button.prop('onClick')();

    // Assert
    expect(spySet).toHaveBeenCalledWith(true);

    // Act
    Dialog.prop('onClose')();

    // Assert
    expect(spySet).toHaveBeenCalledWith(false);
  });
});
