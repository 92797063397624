import React from 'react';

/**
 * Item Таба.
 *
 * @param {React.Children} children - Дети.
 * @returns {JSX.Element}
 */
export const TabsCardItem = ({ children }) => {
  return <div style={{ margin: '25px 0' }}>{children}</div>;
};
