import 'assets/styles/card.nestedTable.scss';
import './renderAddressList2.scss';

import { DistrictAlRAL2 } from 'app/components/card/common/grid/render/renderAddressList2.Fields/DistrictAl.RAL2';
import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';
import classnames from 'classnames';
import TextField from 'core/newComponents/TextField';
import { Button } from 'core/uiKit/components/buttons/Button';
import React, { useState } from 'react';

import { AddressAlRAL2 } from './renderAddressList2.Fields/AddressAl.RAL2';
import { OkrugAlRAL2 } from './renderAddressList2.Fields/OkrugAl.RAL2';
import { StreetAlRAL2 } from './renderAddressList2.Fields/StreetAl.RAL2';
import AddressListTemplate from './renderAddressListTemplate';

/**
 * АДРЕС БТИ.
 *
 * @param {object} props - Properties.
 * @returns {JSX}
 */
const AddressList = (props) => {
  return (
    <div style={{ margin: '0 1rem', width: '100%' }}>
      <AddressListTemplate
        disabled={props.disabled}
        hintText={props.hintText}
        input={props.input}
        meta={props.meta}
        onlyOne={props.onlyOne}
        renderAddressForm={renderAddressForm(props)}
        onDelete={props.onDelete}
        onChange={props.onChange}
      />
    </div>
  );
};

/**
 * Обертка для AddressListTemplate. Для поддержки старого функционала.
 *
 * @param {{}} props - Properties.
 * @returns {function(*, *): *}
 */
const renderAddressForm = (props) => {
  return (value, row) => <AddressForm {...props} {...{ row, value }} />;
};

/**
 * RenderAddressForm АДРЕС БТИ.
 *
 * @param {object} props - Properties.
 * @param {object} props.value - The value to be rendered.
 * @param {*} props.input - The input to be rendered.
 * @returns {JSX.Element}
 */
const AddressForm = ({ value, input }) => {
  const [address, setAddress] = useState({});
  const [errors, setErrors] = useState({});

  // const addressRequired = !!address_required;

  return (
    <div className={'edit_form'}>
      <div className={'address-form__content'}>
        <OkrugAlRAL2 {...{ address, errors, setAddress }} />
        <DistrictAlRAL2 {...{ address, errors, setAddress }} />
        <StreetAlRAL2 {...{ address, errors, setAddress }} />
        <AddressAlRAL2 {...{ address, setAddress }} />

        <TextField disabled={true} label="UNOM" value={address.unom || ''} />
        <TextField disabled={true} label="UNAD" value={address.unad || ''} />
        <TextField
          disabled={true}
          label="Статус"
          value={address.is_actual ? 'актуален' : 'не актуален'}
        />
        <TextField
          disabled={true}
          label="Класс строения"
          name="kl"
          value={address.kl || ''}
        />
      </div>
      <div className={classnames('address-form__footer')}>
        <Button
          id="add"
          variant="contained"
          color={'primary'}
          onClick={() => {
            let errorMessage = {};

            const newRow = Object.entries(address).reduce(
              (acc, [key, values]) => {
                acc[key.replace('al', 'id')] = values?.id;
                acc[key.replace('al', 'name')] = values?.name;
                return acc;
              },
              {},
            );

            //  костыли так как значения для других инпутов приходить в этом options.
            newRow.is_actual = address.is_actual;
            newRow.kl = address.kl;
            newRow.unad = address.unad;
            newRow.unom = address.unom;

            if (!newRow?.okrug_id) {
              errorMessage.okrug_al = REQUIRED_FIELD_MESSAGE;
            }
            if (!newRow?.district_id) {
              errorMessage.district_al = REQUIRED_FIELD_MESSAGE;
            }
            if (!newRow?.street_id) {
              errorMessage.street_al = REQUIRED_FIELD_MESSAGE;
            }
            // if (!newRow?.address_al) {
            //   err = true;
            //   errorMessage.address_error = REQUIRED_FIELD_MESSAGE;
            // }

            if (Object.keys(errorMessage)?.length > 0) {
              setErrors(errorMessage);
              return;
            }

            // input.onChange(value.map((row) => _.omit(newRow, 'editable')));
            input.onChange([newRow]);
          }}
        >
          Готово
        </Button>
        <Button
          id={'cancel'}
          variant={'outlined'}
          onClick={() => {
            input.onChange(value.filter((row) => !row.editable));
          }}
        >
          Отмена
        </Button>
      </div>
    </div>
  );
};

export default AddressList;
export const AddressList2 = AddressList;
