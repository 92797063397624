import { RANDOM_BOOL, RANDOM_NUMBER } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { useField } from 'react-final-form';

import {
  DOCUMENT_CLOSE_FILE_NAME,
  DocumentCloseFileField,
} from './DocumentCloseFileField';
import { getRequiredDocumentCloseField } from './getRequiredDocumentCloseField';

jest.mock('./getRequiredDocumentCloseField');
jest.mock('react-final-form');

describe('DocumentCloseFileField.jestSpec', () => {
  it('props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useField.mockReturnValue({ input: { value: [] } });
    getRequiredDocumentCloseField.mockReturnValue(RANDOM_BOOL);
    // Act
    const wrapper = shallow(<DocumentCloseFileField />);

    // Assert
    expect(wrapper.props()).toStrictEqual({
      disabled: !RANDOM_BOOL,
      name: DOCUMENT_CLOSE_FILE_NAME,
      required: RANDOM_BOOL,
      text: 'Требуется документ-основание закрытия паспорта ОГХ. Документ-основание должен быть заверен уполномоченными сотрудниками соответствующих организаций. Допустимые форматы - .JPEG, .PNG, .PDF, .JPG',
    });
  });

  it('getRequiredDocumentCloseField to called with', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useField.mockReturnValue({ input: { value: [RANDOM_NUMBER] } });

    // Act
    shallow(<DocumentCloseFileField />);

    // Assert
    expect(getRequiredDocumentCloseField).toHaveBeenCalledWith([RANDOM_NUMBER]);
  });

  it('text', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<DocumentCloseFileField />);

    // Assert
    expect(wrapper.text()).toBe('<DndFileUploadFF />');
  });
});
