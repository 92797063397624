/* eslint-disable */
import { useGetFilesForSignature } from 'app/api/hooks/signs/useGetFilesForSignature';
import { CLOSE } from 'app/api/requests/signs/signOghSave';
import {
  CERTIFICATE_NAME,
  SIGNATURES_NAME,
} from 'core/form/finalForm/fields/prepared/FileSignatureCryptoProFF';
import { SEND_REASON_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/SendReason';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useEffect, useState } from 'react';
import { useField } from 'react-final-form';

/**
 * UseSetup ое Диалог Формы подписания файлов.
 *
 * @returns {object}
 */
const useSetup = (): {
  callbackError: (e: Error) => void;
  certificate: unknown;
  error: string;
  files: File[];
  filesForSignature: File[];
  /** Если пользователь выбрал причину закрытия паспорта ОГХ "Объединение ОГХ". */
  isUnion: boolean;
  onSignaturesChange: ((event: unknown) => void) | undefined;
  signatures: string;
} => {
  const {
    input: { value: filesForm = [] },
  } = useField('files');

  const { input: { value: certificate } = {} } = useField(CERTIFICATE_NAME);
  const { input: { value: signatures, onChange: onSignaturesChange } = {} } =
    useField(SIGNATURES_NAME);
  const [error, setError] = useState<string>('');
  const { objectId } = useGetObjectId();
  const { data } = useGetFilesForSignature(objectId, CLOSE);

  /**
   * Fn.
   *
   * @param e - Error.
   * @returns {void}
   */
  const callbackError = (e: Error) =>
    setError(e?.toString().replace(/Error: /g, ''));

  const files = data?.map((item) => item.file) || [];
  const filesForSignature = [...files, ...filesForm];

  const {
    input: { value: reason },
  } = useField(SEND_REASON_NAME);

  const {
    input: { onChange: onChangeIdOghUni },
  } = useField('id_ogh_uni');

  // Если пользователь выбрал причину закрытия паспорта ОГХ "Объединение ОГХ".
  const isUnion = reason?.includes(46);
  useEffect(() => {
    if (!isUnion) {
      onChangeIdOghUni(undefined);
    }
  }, [isUnion, onChangeIdOghUni]);

  return {
    callbackError,
    certificate,
    error,
    files,
    filesForSignature,
    isUnion,
    onSignaturesChange,
    signatures,
  };
};

export default useSetup;
