// 13 Проезжая часть
/* eslint-disable  */

import CardAbstract from 'app/components/card/common/CardAbstract';
import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import DocumentsTab, {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import {
  AUTO_CLEANING_TYPE,
  cleaningTypesWithNoCleaning,
  MANUAL_CLEANING_TYPE,
} from 'app/constants/cleaningTypes';
import { ODH_AXIS_TOOLTIP } from 'app/constants/messages';
import { column } from 'app/constants/tables';
import getCleaningType from 'app/selectors/card/getCleaningType';
import getCoatingTypes from 'app/selectors/card/getCoatingTypes';
import getDefaultFieldValues from 'app/selectors/card/getDefaultFieldValues';
import getNoCleanArea from 'app/selectors/card/getNoCleanArea';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import isMandatory, { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['carriageway'];
const Versions = VersionsTemplate(types);

const tabs = [
  { id: 'characteristics-tab', name: 'Исходные данные' },
  { id: 'docs-tab', name: 'Документы' },
];

const propTypes = {
  card: PropTypes.object,
  changeFieldValue: PropTypes.func,
  coatingTypes: PropTypes.array,
  createMode: PropTypes.bool,
  documents: PropTypes.object,
  editMode: PropTypes.bool,
  formValues: PropTypes.object,
  mode: PropTypes.object,
  required: PropTypes.bool,
};

/**
 * Классовый компонет CarriagewayCard.
 */
class CarriagewayCard extends React.Component {
  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   * @example ----
   */
  render() {
    const {
      card,
      changeFieldValue,
      coatingTypes,
      createMode,
      documents,
      editMode,
      formValues,
      isCurrentObjectIsExternalSystem,
      mode,
      required,
    } = this.props;

    return (
      <CardAbstract>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(card.type_id)}

          <div id="props">
            {elementFormGrid(
              [
                {
                  addition: {
                    label: 'Тип',
                    options: card.flat_element_type,
                    required: isMandatory(
                      card.type_id,
                      'flat_element_type_id',
                      required,
                    ),
                  },
                  component: WithReduxFormSelect,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'flat_element_type_id',
                  name: 'flat_element_type_id',
                },
                <div
                  style={{
                    height: 50,
                    margin: '9px 24px',
                    minWidth: 256,
                    paddingTop: 0,
                  }}
                >
                  <IsDiffHeightMarkRF
                    disabled={!mode.editMode || isCurrentObjectIsExternalSystem}
                    justifyContent={'space-between'}
                    labelPlacement={'start'}
                  />
                </div>,
                <div>
                  <ParentInfo />
                </div>,
              ],
              column.TWO,
              '',
              card.type_id,
            )}

            <Tabs tabs={tabs} />

            <div id="characteristics">
              {elementFormGrid(
                [
                  {
                    addition: {
                      formatChars: {
                        9: '[а-яА-Я0-9a-zA-Z]',
                      },
                      mask: '99',
                      tooltipText: ODH_AXIS_TOOLTIP,
                      type: 'text',
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'odh_axis',
                    label: 'Ось',
                    name: 'odh_axis',
                  },
                  {
                    addition: {
                      label: 'Сторона',
                      options: card.odh_side,
                      required: isMandatory(
                        card.type_id,
                        'odh_side_id',
                        required,
                      ),
                    },
                    component: WithReduxFormSelect,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'odh_side_id',
                    name: 'odh_side_id',
                  },
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      required: isMandatory(
                        card.type_id,
                        'bord_begin',
                        required,
                      ),
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    // костыль что бы 0 отобразился
                    defaultValue: formValues.bord_begin,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'bord_begin',
                    label: 'Начало, м',
                    name: 'bord_begin',
                  },
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      required: isMandatory(card.type_id, 'bord_end', required),
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    // костыль что бы 0 отобразился
                    defaultValue: formValues.bord_end,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'bord_end',
                    label: 'Конец, м',
                    name: 'bord_end',
                  },
                ],
                column.TWO,
                'Местоположение',
                card.type_id,
              )}

              {elementFormGrid(
                [
                  {
                    addition: {
                      label: 'Вид покрытия',
                      options: card.coating_group,
                      required: isMandatory(
                        card.type_id,
                        'coating_group_id',
                        required,
                      ),

                      /**
                       *
                       */
                      withChange: () => {
                        changeFieldValue('coating_type_id', null);
                      },
                    },
                    component: WithReduxFormSelect,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'coating_group_id',
                    name: 'coating_group_id',
                  },
                  {
                    addition: {
                      label: 'Вид покрытия (уточнение)',
                      options: coatingTypes,
                      required: isMandatory(
                        card.type_id,
                        'coating_type_id',
                        required,
                      ),
                    },
                    component: WithReduxFormSelect,
                    editable:
                      editMode &&
                      get(formValues, 'coating_group_id') &&
                      !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'coating_type_id',
                    name: 'coating_type_id',
                  },
                  {
                    addition: {
                      digits: 1,
                      positive: true,
                      required: isMandatory(card.type_id, 'distance', required),
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'distance',
                    label: 'Длина, п.м',
                    name: 'distance',
                  },
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      required: isMandatory(card.type_id, 'area', required),
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'area',
                    label: 'Площадь, кв.м',
                    name: 'area',
                  },
                  <div>
                    <TotalAreaGeoRF disabled={true} decimalScale={2} />
                  </div>,
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'width_begin',
                    label: 'Ширина в начале, м',
                    name: 'width_begin',
                  },
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'width_end',
                    label: 'Ширина в конце, м',
                    name: 'width_end',
                  },
                ],
                column.TWO,
                'Характеристики',
                card.type_id,
              )}

              {elementFormGrid(
                [
                  {
                    addition: {
                      label: 'Способ уборки',
                      options: cleaningTypesWithNoCleaning,
                      required: isMandatory(
                        card.type_id,
                        'cleaning_type',
                        required,
                      ),
                    },
                    component: WithReduxFormSelect,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'cleaning_type',
                    name: 'cleaning_type',
                  },
                ],
                column.TWO,
                'УБОРОЧНЫЕ ДАННЫЕ',
                card.type_id,
              )}

              {elementFormGrid(
                [
                  {
                    addition: { type: 'text' },
                    component: renderCustomTextField,
                    editable: editMode,
                    formValue: true,
                    id: 'description',
                    label: 'Примечание',
                    name: 'description',
                  },
                ],
                column.ONE,
                '',
                card.type_id,
              )}
            </div>
            {DocumentsTab()({
              documents,
              editable: editMode,
              type_id: card.type_id,
            })}
          </div>
          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={createMode}
              key={`versions-${card.root_id}`}
            />
          </div>
        </div>
      </CardAbstract>
    );
  }
}

CarriagewayCard.propTypes = propTypes;

const carriagewayDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);
  const defaultFieldValues = getDefaultFieldValues(state, props);

  const result = {
    coatingTypes: getCoatingTypes(state, props),

    createMode: isCreateMode(state, props),

    editMode: isEditMode(state, props),
    enableReinitialize: true,
    formValues: getFormValues(state),

    initialValues: {
      area: parse('area'),
      bord_begin: parse('bord_begin'),
      bord_end: parse('bord_end'),
      cleaning_type: getCleaningType(state, props),
      coating_group_id: parse('coating_group_id.coating_group'),
      coating_type_id: parse('coating_type_id.coating_type'),
      customer_id: parse('customer_id'),
      description: parse('description'),
      distance: parse('distance'),
      endDate: parseDate(props.card.end_date),
      flat_element_type_id:
        defaultFieldValues.flat_element_type_id ||
        parse('flat_element_type_id.flat_element_type'),
      is_diff_height_mark: parse('is_diff_height_mark'),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      owner_id: parse('owner_id'),
      reagent_area: parse('reagent_area'),
      snow_area: parse('snow_area'),
      startDate: parseDate(props.card.start_date),
      totalAreaGeo: parse('total_area_geo'),
      width_begin: parse('width_begin'),
      width_end: parse('width_end'),
    },
    noCleanArea: getNoCleanArea(state, props),
    onSubmit: onSubmit.bind({ props }),

    required: isRequired(state, props),
    validate,
  };

  return carriagewayDocumentsReducer(result, state, props);
};

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

const numericFields = [
  { name: 'bord_begin', type: 'decimal', zero: true },
  { name: 'bord_end', type: 'decimal', zero: true },
  {
    name: 'area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'distance',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'width_begin',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'width_end',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'auto_clean_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'manual_clean_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'no_clean_area',
    positive: true,
    type: 'decimal',
    zero: true,
  },
  {
    name: 'snow_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'rotor_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'reagent_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
];

const dictFields = [
  'odh_side_id',
  'flat_element_type_id',
  'coating_group_id',
  'coating_type_id',
];

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object} - Отвалидированные поля.
 * @example ----
 */
const validate = (values) => validateNumeric(values, numericFields);

/**
 * Функция сабмита.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {void} - Жопа.
 * @example ----
 */
function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );
  formValuesWithAttribute.is_diff_height_mark = Boolean(
    formValuesWithAttribute.is_diff_height_mark,
  );

  formValuesWithAttribute.manual_clean_area = 0;
  formValuesWithAttribute.auto_clean_area = 0;
  formValuesWithAttribute.no_clean_area = 0;

  if (formValuesWithAttribute.cleaning_type === MANUAL_CLEANING_TYPE) {
    formValuesWithAttribute.manual_clean_area = formValuesWithAttribute.area;
  } else if (formValuesWithAttribute.cleaning_type === AUTO_CLEANING_TYPE) {
    formValuesWithAttribute.auto_clean_area = formValuesWithAttribute.area;
  } else {
    formValuesWithAttribute.no_clean_area = formValuesWithAttribute.area;
  }
  delete formValuesWithAttribute.cleaning_type;

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

/**
 * Функция получения экшенов.
 *
 * @param {Function} dispatch - Функция диспатч.
 * @returns {{changeFieldValue: mapDispatchToProps.changeFieldValue}} - Экшен changeFieldValue.
 * @example ----
 */
const mapDispatchToProps = (dispatch) => ({
  /**
   *
   * @param field
   * @param value
   */
  changeFieldValue: (field, value) => {
    dispatch(change('editorCard', field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(CarriagewayCard));
