/* eslint-disable */

import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { G } from 'app/components/map/useMapContext';
import { useGetGeometryLoad } from 'app/pages/cardsOgh/components/Map/api/geometryLoad';

/**
 * Хук получения текущей геометрии.
 *
 * @returns {{initMapCurrent: Function, currentSuccess: boolean, dataMapCurrent: Array<G> }} - Возвращает текущуие данные карты, флаг успешновыполненого запроса и функцию перезапроса.
 * @example - useGeometryMapCurrent()
 */
export const useGeometryMapCurrent = () => {
  const { recordId } = useGetCurrentCard();

  const { data, isSuccess, remove } = useGetGeometryLoad({
    ids: [recordId],
  });

  return {
    currentSuccess: isSuccess,
    dataMapCurrent: data,
    initMapCurrent: remove,
  };
};
