// 32 Цветники
/* eslint-disable  */

import CardAbstract from 'app/components/card/common/CardAbstract';
import { getParseAttribute } from 'app/components/card/common/getParseAttribute';
import { elementFormGrid } from 'app/components/card/common/grid';
import { parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import buildMandatoriesMessage from 'app/components/card/common/validation/buildMandatoriesMessage';
import DocumentsTab, {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import { WithReduxFormCheckbox } from 'app/components/common/CheckboxField';
import { APOTHECARY_GARDEN, CARPET_PLANT_AREA } from 'app/constants/fieldNames';
import { FLOWERS_GARDEN } from 'app/constants/oghTypes';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import { transformValues } from 'app/utils/form/transformation';
import { isEmpty } from 'app/utils/validation';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm, SubmissionError } from 'redux-form';

import { CharacteristicsTab } from './FlowersGarden/components/CharacteristicsTab';
import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['flowers_garden'];
const Versions = VersionsTemplate(types);

const tabs = [
  {
    id: 'characteristics-tab',
    name: 'Исходные данные',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

const checkboxStyle = {
  height: 50,
  paddingTop: 0,
};

/**
 * Классовый компонет FlowersGardenCard.
 */
class FlowersGardenCard extends React.Component {
  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   * @example ----
   */
  render() {
    const { props } = this;
    const {
      card,
      changeFieldValue,
      mode,
      editMode,
      required,
      isCurrentObjectIsExternalSystem,
    } = props;
    const { type_id: typeId } = card;

    return (
      <CardAbstract>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(typeId)}
          <div id="props">
            {elementFormGrid(
              [
                <div>
                  <ParentInfo />
                </div>,
                {
                  component: WithReduxFormCheckbox,
                  editable: editMode,
                  formValue: true,
                  id: 'no_calc',
                  label: 'Не учитывать',
                  name: 'no_calc',
                  style: checkboxStyle,
                },
                <div
                  style={{
                    height: 50,
                    margin: '9px 24px',
                    minWidth: 256,
                    paddingTop: 0,
                  }}
                >
                  <IsDiffHeightMarkRF
                    disabled={!mode.editMode || isCurrentObjectIsExternalSystem}
                    justifyContent={'space-between'}
                    labelPlacement={'start'}
                  />
                </div>,
              ],
              2,
              '',
              typeId,
            )}

            <Tabs tabs={tabs} />

            <CharacteristicsTab
              changeFieldValue={changeFieldValue}
              editable={editMode}
              isCurrentObjectIsExternalSystem={isCurrentObjectIsExternalSystem}
              id="characteristics"
              required={required}
              typeId={typeId}
              values={props.currentValues}
            />

            {DocumentsTab()({
              documents: props.documents,
              editable: editMode,
              typeId,
            })}
          </div>
          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={props.createMode}
              key={`versions-${card.root_id}`}
            />
          </div>
        </div>
      </CardAbstract>
    );
  }
}

const flowersGardenDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция получения экшенов.
 *
 * @param {Function} dispatch - Функция диспатч.
 * @returns {{changeFieldValue: mapDispatchToProps.changeFieldValue}} - Экшен changeFieldValue.
 * @example ----
 */
const mapDispatchToProps = (dispatch) => ({
  /**
   *
   * @param field
   * @param value
   */
  changeFieldValue: (field, value) => {
    dispatch(change('editorCard', field, value));
  },
});

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {*} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);
  const result = {
    createMode: isCreateMode(state, props),

    currentValues: getFormValues(state),

    editMode: isEditMode(state, props),

    enableReinitialize: true,
    initialValues: {
      ...props.card.attribute,
      annuals_area: parse('annuals_area'),
      biennials_area: parse('biennials_area'),
      bulbous_area: parse('bulbous_area'),
      endDate: parseDate(props.card.end_date),
      flinders_area: parse('flinders_area'),
      floor_vertical_design_area: parse('floor_vertical_design_area'),
      floor_vertical_design_qty: parse('floor_vertical_design_qty'),
      flowers_vertical_design_area: parse('flowers_vertical_design_area'),
      flowers_vertical_design_qty: parse('flowers_vertical_design_qty'),
      green_num: parse('green_num'),
      inert_area: parse('inert_area'),
      inert_other_area: parse('inert_other_area'),
      inert_stone_chips_area: parse('inert_stone_chips_area'),
      inert_tree_bark_area: parse('inert_tree_bark_area'),
      is_diff_height_mark: parse('is_diff_height_mark'),
      lawn_area: parse('lawn_area'),
      no_calc: parse('no_calc'),
      perennials_area: parse('perennials_area'),
      rockery_area: parse('rockery_area'),
      season_shift_area: parse('season_shift_area'),
      section_num: parse('section_num'),
      shrubs_area: parse('shrubs_area'),
      startDate: parseDate(props.card.start_date),
      suspend_vertical_design_area: parse('suspend_vertical_design_area'),
      suspend_vertical_design_qty: parse('suspend_vertical_design_qty'),
      total_area: parse('total_area'),
      totalAreaGeo: parse('total_area_geo'),
      tulip_area: parse('tulip_area'),
      vase_qty: parse('vase_qty'),
    },
    onSubmit: onSubmit.bind({ props }),

    required: isRequired(state, props),
    validate,
  };

  return flowersGardenDocumentsReducer(result, state, props);
};

/**
 * Функция валидации.
 *
 * @param {object} formValues - Значения формы.
 * @returns {object} - Отвалидированные поля.
 * @example ----
 */
function validate(formValues) {
  return {
    ...validateNumeric(formValues, numericFields),
  };
}

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

const numericFields = [
  {
    name: 'vase_qty',
    positive: true,
    type: 'integer',
    zero: true,
  },
  {
    name: 'floor_vertical_design_qty',
    positive: true,
    type: 'integer',
    zero: true,
  },
  {
    name: 'floor_vertical_design_area',
    positive: true,
    type: 'decimal',
    zero: true,
  },
  {
    name: 'suspend_vertical_design_qty',
    positive: true,
    type: 'integer',
    zero: true,
  },
  {
    name: 'inert_stone_chips_area',
    positive: true,
    type: 'decimal',
    zero: true,
  },
  {
    name: 'inert_other_area',
    positive: true,
    type: 'decimal',
    zero: true,
  },
  {
    name: 'inert_tree_bark_area',
    positive: true,
    type: 'decimal',
    zero: true,
  },
  {
    name: 'rockery_area',
    positive: true,
    type: 'decimal',
    zero: true,
  },
  {
    name: 'lawn_area',
    positive: true,
    type: 'decimal',
    zero: true,
  },
  {
    name: 'suspend_vertical_design_area',
    positive: true,
    type: 'decimal',
    zero: true,
  },
  {
    name: 'flowers_vertical_design_qty',
    positive: true,
    type: 'integer',
    zero: true,
  },
  {
    name: 'flowers_vertical_design_area',
    positive: true,
    type: 'decimal',
    zero: true,
  },
  {
    name: 'annuals_area',
    positive: true,
    type: 'decimal',
  },
  {
    name: 'perennials_area',
    positive: true,
    type: 'decimal',
  },
  {
    name: 'inert_area',
    positive: true,
    type: 'decimal',
  },
  {
    name: 'shrubs_area',
    positive: true,
    type: 'decimal',
  },
  {
    name: 'season_shift_area',
    positive: true,
    type: 'decimal',
  },
  {
    name: 'bulbous_area',
    positive: true,
    type: 'decimal',
  },
  {
    name: 'tulip_area',
    positive: true,
    type: 'decimal',
  },
  {
    name: 'biennials_area',
    positive: true,
    type: 'decimal',
  },
  {
    name: 'flinders_area',
    positive: true,
    type: 'decimal',
  },
  {
    name: 'green_num',
    positive: true,
    type: 'decimal',
    zero: true,
  },
  {
    name: 'section_num',
    positive: true,
    type: 'decimal',
    zero: true,
  },
  {
    name: 'total_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
];

const mandatoryFields = {
  [FLOWERS_GARDEN]: ['section_num', 'green_num'],
};

/**
 * Функция сабмита.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {void} - Жопа.
 * @example ----
 */
function onSubmit(formValues) {
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  // форматирование в число - пока так
  ['annual_vase_area', APOTHECARY_GARDEN, CARPET_PLANT_AREA].forEach(
    (property) => {
      if (formValuesWithAttribute[property]) {
        formValuesWithAttribute[property] = Number(
          formValuesWithAttribute[property],
        );
      }
    },
  );

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[this.props.card.type_id],
  );
  areaValidation(formValuesWithAttribute, this.props.alert);

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      numericFields,
    }),
  );
}

const mandatoryAreaFieldNames = {
  [APOTHECARY_GARDEN]: 'Аптекарский огород, кв.м',
  [CARPET_PLANT_AREA]: 'Ковровые растения, кв.м',
  annuals_area: 'Однолетники, кв.м',
  inert_area: 'Кустовые и корневищные многолетники, кв.м',
  perennials_area: 'Многолетники и розы, кв.м',
};

const tabToFields = {
  'Исходные данные': Object.keys(mandatoryAreaFieldNames),
};

/**
 * Функция areaValidation.
 *
 * @param {object} formValues - Значения из формы.
 * @param {Function} alert - Функция сообщения.
 * @returns {void} - Жопа.
 * @example ----
 */
function areaValidation(formValues, alert) {
  const mandatoryKeys = Object.keys(mandatoryAreaFieldNames);
  const result = mandatoryKeys.some((key) => !isEmpty(_.get(formValues, key)));

  if (!result) {
    const title = 'Необходимо заполнить хотя бы одно из полей:';
    const noErrors = {};
    alert(
      buildMandatoriesMessage(
        mandatoryAreaFieldNames,
        tabToFields,
        mandatoryAreaFieldNames,
        title,
      ),
    );
    throw new SubmissionError(noErrors);
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(FlowersGardenCard));
