import { preparation } from 'core/utils/hooks/usePreparation/preparation';
/* eslint-disable */

interface PreparationObject {
  [key: string]: unknown;
}

/**
 * Функция преобразования значений формы в заранее подготовленные изменения.
 *
 * @param {object} values - Значения формы.
 * @returns {*}
 */
export function preparationValues<T extends Record<string, unknown>>(
  values: T,
) {
  const formValues: PreparationObject | T = {};

  for (const [key, value] of Object.entries(values)) {
    if (value !== undefined && value !== null) {
      const { value: newValue, key: newKey } = preparation(key, value);
      formValues[newKey] = newValue;
    }
  }
  return formValues;
}
