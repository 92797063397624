import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { PlantationTypeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/PlantationTypeId.RF';
import React, { FC } from 'react';

/**
 * Тип насаждения.
 *
 * @param props - Property.
 * @param props.changeFieldValue - Функция изменения полей формы.
 * @param props.editMode - Флаг изменения.
 * @returns {JSX}
 */
export const PlantationTypeIdRFTreesShrubs: FC<{
  changeFieldValue: (nameField: string, value: unknown) => void;
  editMode?: boolean;
}> = ({ changeFieldValue, editMode }) => {
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();

  return (
    // @ts-ignore
    <PlantationTypeIdRF
      disabled={!editMode || isCurrentObjectIsExternalSystem}
      required={true}
      withChange={() => {
        changeFieldValue('plant_type_id', null);
        changeFieldValue('life_form_type_id', null);
        changeFieldValue('age', null);
        changeFieldValue('diameter', null);
        changeFieldValue('area', '');
      }}
    />
  );
};
