import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer'; // Dependencies
import { DepartmentRF } from 'core/form/reduxForm/fields/prepared/selects/simple/Department.RF';
import { shallow } from 'enzyme';
import React from 'react';

import { OznTwoTab } from './OznTwo.Tab'; // The code we want to test

describe('OznTwoTab', () => {
  let props;

  beforeEach(() => {
    props = {
      editable: true,
      isCurrentObjectIsExternalSystem: false,
    };
  });

  it('should render a CardContainer and DepartmentRF fields when rendered', () => {
    const wrapper = shallow(<OznTwoTab {...props} />);

    expect(wrapper.find(CardContainer)).toHaveLength(1);
    expect(wrapper.find(DepartmentRF)).toHaveLength(1);
  });

  it('should disable DepartmentRF field if not editable', () => {
    props.editable = false;
    const wrapper = shallow(<OznTwoTab {...props} />);
    const departmentField = wrapper.find(DepartmentRF);

    expect(departmentField.prop('disabled')).toBe(true);
  });

  it('should disable DepartmentRF field if current object is external system', () => {
    props.isCurrentObjectIsExternalSystem = true;
    const wrapper = shallow(<OznTwoTab {...props} />);
    const departmentField = wrapper.find(DepartmentRF);

    expect(departmentField.prop('disabled')).toBe(true);
  });

  it('should require DepartmentRF field when rendered', () => {
    const wrapper = shallow(<OznTwoTab {...props} />);
    const departmentField = wrapper.find(DepartmentRF);

    expect(departmentField.prop('required')).toBe(true);
  });

  it('should not show an "All" option in the DepartmentRF field', () => {
    const wrapper = shallow(<OznTwoTab {...props} />);
    const departmentField = wrapper.find(DepartmentRF);

    expect(departmentField.prop('withAll')).toBe(false);
  });
});
