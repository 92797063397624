import { mapStateToPropsReducer as documentsReducer } from 'app/components/card/ogh/DocumentsTab';
import { IsDiffHeightMarkReducer } from 'app/components/card/ogh/ImprovementTerritoryCard/components/IsDiffHeightMark';
import OrdersTab, {
  mapDispatchToPropsReducer as ordersDispatchReducer,
  mapStateToPropsReducer as ordersReducer,
} from 'app/components/card/ogh/OrdersTab';
import React from 'react';

import AddressListField, {
  mapStateToPropsReducer as addressListFieldReducer,
} from './components/AddressListField';
import { mapStateToPropsReducer as aquaAreaReducer } from './components/AquaAreaTab';
import DepartmentField, {
  mapStateToPropsReducer as DepartmentFieldReducer,
} from './components/DepartmentTypeField';
import { mapStateToPropsReducer as equipmentIndexReducer } from './components/EquipmentIndex';
import ImprovementCategories, {
  mapStateToPropsReducer as improvementCategoriesReducer,
} from './components/ImprovementCategories';
import MainParamsTab from './components/MainParamsTab';
import { mapStateToPropsReducer as nameFieldReducer } from './components/NameField';
import OwnerAndCustomerFields, {
  mapStateToPropsReducer as ownerAndCustomerReducer,
} from './components/OwnerAndCustomerFields';
import PlantationTab from './components/PlantationTab';
import PropsTabs from './components/PropsTabs';
import RepairsPlanTab, {
  mapStateToPropsReducer as repairsPlanReducer,
} from './components/RepairsPlanTab';
import RepairsPlanTable from './components/RepairsPlanTable';
import RepairsTab, {
  mapStateToPropsReducer as repairsReducer,
} from './components/RepairsTab';
import { mapStateToPropsReducer as snowAreaReducer } from './components/SnowAreaTab';
import ImprovementTerritoryCardTemplate from './ImprovementTerritoryCardTemplate';
import mapStateToPropsReducer from './mapStateToPropsReducer';
import { yardCardTabs } from './tabs';
import { asyncValidate } from './yard/asyncValidate';
import { createAttribute } from './yard/createAttribute';
import { validate } from './yard/validate';
import { YardDocsTab } from './yard/YardDocs.Tab';
import { YardOneTab } from './yard/YardOne.Tab';
import { YardTwoTab } from './yard/YardTwo.Tab';

const ids = {
  address: 're_calc_address_list',
  name: 're_calc_name',
};

const mandatoryFieldNames = {
  customer_id: 'Заказчик',
  owner_id: 'Балансодержатель',
  snow_clean_area: 'Площадь вывоза снега',
};

const tabToFields = {
  Свойства: ['owner_id', 'customer_id', 'snow_clean_area'],
};

/**
 * Карточка Дворовые Территории. Yard.
 */
export const YardCard = ImprovementTerritoryCardTemplate({
  mapDispatchToPropsReducers: [ordersDispatchReducer],
  mapStateToPropsReducers: [
    mapStateToPropsReducer({
      asyncValidate,
      createAttribute,
      mandatoryFieldNames,
      tabToFields,
      validate,
    }),
    improvementCategoriesReducer({
      improvement: {
        defaultOption: {
          id: -2,
          name: 'Не определена (уборочная площадь = 0)',
        },
        defaultValue: -2,
        landscaping: {
          defaultValue: 3,
        },
      },
    }),
    ownerAndCustomerReducer(),
    nameFieldReducer({
      id: ids.name,
      path: ids.name,
    }),
    equipmentIndexReducer(),
    addressListFieldReducer({
      id: ids.address,
    }),
    snowAreaReducer,
    aquaAreaReducer,
    repairsReducer,
    repairsPlanReducer,
    DepartmentFieldReducer({
      department_id: 10216105,
    }),
    IsDiffHeightMarkReducer(),
    documentsReducer({
      oghGroupCode: 'yard_object',
    }),
    ordersReducer,
  ],
  propsTabElementRenders: [
    YardOneTab,
    DepartmentField({ editable: false }),
    OwnerAndCustomerFields,
    ImprovementCategories({
      improvement: {
        editable: false,
      },
      landscaping: {
        editable: false,
      },
    }),
    YardTwoTab,
    AddressListField({
      editable: false,
      id: ids.address,
      name: ids.address,
    }),
    PropsTabs(yardCardTabs),
    MainParamsTab(),
    <PlantationTab />,
    RepairsTab(),
    RepairsPlanTab({
      component: RepairsPlanTable,
    }),
    // Таб документов
    YardDocsTab,
    OrdersTab(),
  ],
});
