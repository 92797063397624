import './Legend.Cell.scss';

import cn from 'classnames';
import React, { useMemo } from 'react';

/**
 * Компонент легенды.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const LegendCell = (props) => {
  const {
    row: {
      original: { operation },
    },
  } = props;

  const classRow = useMemo(() => {
    return cn('legend-table-radio', {
      'legend-table-radio__add': operation === 'add',
      'legend-table-radio__delete': operation === 'delete',
      'legend-table-radio__move': operation === 'move',
      'legend-table-radio__update': operation === 'update',
    });
  }, [operation]);

  return <div className={classRow} />;
};
