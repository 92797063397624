/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { v4 } from 'uuid';
import { isEmpty, isEqual } from 'lodash';
import { connect } from 'react-redux';

import { setLastMapEvent } from 'app/actions/mapActions';
import getAllowedGeometryTypes from 'app/selectors/card/getAllowedGeometryTypes';
import geometryService from 'app/services/geometryService';

import mapService from 'app/services/mapService';
import { FOREGROUND } from 'app/constants/layers';
import { EGKO } from 'app/constants/tileLayers';

import TileLayerRadioButton from './TileLayerRadioButton';

import './MapTip.scss';

const propTypes = {
  editable: PropTypes.bool.isRequired,
  allowedGeometryTypes: PropTypes.array.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  tileLayerRadioButtonClassName: PropTypes.string,
};

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: v4(),
      tileLayer: EGKO,
    };
  }

  componentDidMount() {
    if (document.readyState === 'complete') {
      this.initMapEditor();
    } else {
      window.addEventListener('load', () => {
        this.initMapEditor();
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { editable, allowedGeometryTypes } = this.props;
    const { id, tileLayer } = this.state;
    return (
      editable !== nextProps.editable ||
      (!isEmpty(nextProps.allowedGeometryTypes) &&
        !isEqual(allowedGeometryTypes, nextProps.allowedGeometryTypes)) ||
      id !== nextState.id ||
      tileLayer !== nextState.tileLayer
    );
  }

  componentDidUpdate(prevProps) {
    const { editable, allowedGeometryTypes } = this.props;

    // case when map loads earlier than editable changes
    if (prevProps.editable !== editable) {
      if (this.mapEditor) {
        this.mapEditor.setEditable(editable);
      }
    }

    if (editable) {
      if (
        !isEmpty(allowedGeometryTypes) &&
        !isEqual(prevProps.allowedGeometryTypes, allowedGeometryTypes)
      ) {
        this.clearInappropriateGeometry(allowedGeometryTypes);
      }
    }
  }

  getGeometry = (oghObjectId) => {
    const geometry = this.mapEditor && this.mapEditor.getGeometry(FOREGROUND);

    const filterCondition = (item) =>
      oghObjectId ? item.oghObjectId === oghObjectId : true;

    return {
      points: geometryService.getPointsObject(geometry, filterCondition),
      lines: geometryService.getLinesObject(geometry, filterCondition),
      polygons: geometryService.getPolygonsObject(geometry, filterCondition),
    };
  };

  clearInappropriateGeometry = (allowedGeometryTypes) => {
    if (this.mapEditor) {
      this.mapEditor.clearInappropriateGeometry(allowedGeometryTypes);
    }
  };

  clearLayer = (layerId) => {
    if (this.mapEditor) {
      this.mapEditor.clearLayer(layerId);
    }
  };

  deleteSelected = () => this.mapEditor.deleteSelected();

  drawToLayer = (layerId, objects, autoZoom) => {
    if (this.mapEditor) {
      const features = objects
        .map((object) => geometryService.toFeatures(object))
        .reduce((result, features) => {
          result.push(...features);
          return result;
        }, []);

      this.mapEditor.drawFeatures(layerId, features);
      autoZoom && this.zoomToFeatures([layerId]);
    }
  };

  zoomToFeatures = (layerIds) =>
    this.mapEditor && this.mapEditor.zoomToFeatures(layerIds);

  hole = (oghObjectId, oghTypeId) =>
    this.mapEditor.startDrawingHole(oghObjectId, oghTypeId);

  initMapEditor = async () => {
    const { setIsMapLoaded } = this.props;
    const { id } = this.state;

    this.mapEditor = await mapService.createMapEditor(id);

    // case when map loads later than editable changes
    // it's important to get "this.props.editable" value after map creation
    this.mapEditor.setEditable(this.props.editable);

    setIsMapLoaded();

    // handle geometry changes
    this.mapEditor.onFeaturesChange(this.mapGeometryChangeHandle.bind(this));
  };

  mapGeometryChangeHandle = (event) => {
    const { setMapEvent } = this.props;

    if (event.feature) {
      const geometry = event.feature.getGeometry();

      setMapEvent({
        eventType: event.type,
        data: {
          type: geometry.getType(),
          coordinates: JSON.stringify(geometry.getCoordinates()),
        },
      });
    }
  };

  line = (oghObjectId, oghTypeId) =>
    this.mapEditor.startDrawingLine(oghObjectId, oghTypeId);

  point = (oghObjectId, oghTypeId) =>
    this.mapEditor.startDrawingPoint(oghObjectId, oghTypeId);

  polygon = (oghObjectId, oghTypeId) =>
    this.mapEditor.startDrawingPolygon(oghObjectId, oghTypeId);

  stopDrawing = () => this.mapEditor.stopDrawing();

  handleTileLayerChange = (event) => {
    const tileLayer = event.currentTarget.value;
    this.setState({
      tileLayer,
    });
    this.mapEditor.switchTilesTo(tileLayer);
  };

  render() {
    const { style, className, tileLayerRadioButtonClassName } = this.props;
    const { id, tileLayer } = this.state;
    return (
      <div>
        <div
          className={cn(className)}
          onClick={() => {
            if (document.activeElement !== document.body) {
              document.activeElement.blur();
            }
          }}
        >
          <div id={id} style={style} />
          <TileLayerRadioButton
            className={tileLayerRadioButtonClassName}
            mapId={id}
            value={tileLayer}
            onChange={this.handleTileLayerChange}
          />
        </div>
      </div>
    );
  }
}

Map.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    allowedGeometryTypes: getAllowedGeometryTypes(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setMapEvent: (event) => {
    dispatch(setLastMapEvent(event));
  },
});

 
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Map);
