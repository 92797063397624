// 9 Прочие инженерные сооружения

/* eslint-disable  */
import CardAbstract from 'app/components/card/common/CardAbstract';
import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid/index';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import { PlacementIdRFWrapper } from 'app/components/card/ogh/components/selects/PlacementIdRFWrapper';
import DocumentsTab, {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import { WithReduxFormCheckbox } from 'app/components/common/CheckboxField';
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import { ODH_AXIS_TOOLTIP } from 'app/constants/messages';
import { column } from 'app/constants/tables';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import { isThisViewing } from 'app/selectors/card/isThisViewing';
import filterDictBySprNumber from 'app/utils/card/filterDictBySprNumber';
import isMandatory, { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['other_engin_construct'];
const Versions = VersionsTemplate(types);

const tabs = [
  { id: 'characteristics-tab', name: 'Исходные данные' },
  { id: 'docs-tab', name: 'Документы' },
];

const propTypes = {
  card: PropTypes.object,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  mode: PropTypes.object,
  required: PropTypes.bool,
};

/**
 * Классовый компонет OtherEnginConstructCard.
 */
class OtherEnginConstructCard extends React.Component {
  /**
   * Функция getEnginStructTypeOptions.
   *
   * @returns {*} - Жопа.
   * @example ----
   */
  getEnginStructTypeOptions() {
    const { type_id, engin_struct_type, viewing } = this.props.card;
    if (viewing) {
      return engin_struct_type || [];
    }

    return filterDictBySprNumber(engin_struct_type, type_id);
  }

  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   * @example ----
   */
  render() {
    const {
      card,
      mode,
      createMode,
      editMode,
      required,
      documents,
      formValues = {},
      isCurrentObjectIsExternalSystem,
    } = this.props;

    return (
      <CardAbstract>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(card.type_id)}

          <div id="props">
            {elementFormGrid(
              [
                {
                  addition: {
                    label: 'Тип',
                    options: this.getEnginStructTypeOptions(),
                    required: isMandatory(
                      card.type_id,
                      'engin_struct_type_id',
                      required,
                    ),
                  },
                  component: WithReduxFormSelect,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'engin_struct_type_id',
                  name: 'engin_struct_type_id',
                },
                <div>
                  <ParentInfo />
                </div>,
              ],
              column.TWO,
              '',
              card.type_id,
            )}

            <Tabs tabs={tabs} />

            <div id="characteristics">
              {elementFormGrid(
                [
                  <div>
                    <PlacementIdRFWrapper
                      disabled={true}
                      required={true}
                      withAll={false}
                    />
                  </div>,
                  {
                    addition: {
                      formatChars: {
                        9: '[а-яА-Я0-9a-zA-Z]',
                      },
                      mask: '99',
                      tooltipText: ODH_AXIS_TOOLTIP,
                      type: 'text',
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'odh_axis',
                    label: 'Ось',
                    name: 'odh_axis',
                  },
                  {
                    addition: {
                      label: 'Сторона',
                      options: card.odh_side,
                      required: isMandatory(
                        card.type_id,
                        'odh_side_id',
                        required,
                      ),
                    },
                    component: WithReduxFormSelect,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'odh_side_id',
                    name: 'odh_side_id',
                  },
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      required: isMandatory(
                        card.type_id,
                        'bord_begin',
                        required,
                      ),
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    // костыль чтобы 0 отобразился
                    defaultValue: formValues.bord_begin,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'bord_begin',
                    label: 'Начало, м',
                    name: 'bord_begin',
                  },
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      required: isMandatory(card.type_id, 'bord_end', required),
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    // костыль чтобы 0 отобразился
                    defaultValue: formValues.bord_end,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'bord_end',
                    label: 'Конец, м',
                    name: 'bord_end',
                  },
                ],
                column.TWO,
                'Местоположение',
                card.type_id,
              )}

              {elementFormGrid(
                [
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'area',
                    label: 'Площадь (в плане), кв.м',
                    name: 'area',
                  },
                  <div>
                    <TotalAreaGeoRF disabled={true} decimalScale={2} />
                  </div>,
                  {
                    addition: {
                      label: 'Входит в общую площадь ОДХ',
                    },
                    component: WithReduxFormCheckbox,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'is_object_area',
                    name: 'is_object_area',
                  },
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'gutters_length',
                    label: 'Двухметровая прилотковая зона, п.м.',
                    name: 'gutters_length',
                  },
                ],
                column.TWO,
                'Характеристики',
                card.type_id,
              )}

              {elementFormGrid(
                [
                  {
                    addition: { type: 'text' },
                    component: renderCustomTextField,
                    editable: editMode,
                    formValue: true,
                    id: 'description',
                    label: 'Примечание',
                    name: 'description',
                  },
                ],
                column.ONE,
                '',
                card.type_id,
              )}
            </div>
            {DocumentsTab()({
              documents,
              editable: editMode,
              typeId: card.type_id,
            })}
          </div>
          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={createMode}
              key={`versions-${card.root_id}`}
            />
          </div>
        </div>
      </CardAbstract>
    );
  }
}

OtherEnginConstructCard.propTypes = propTypes;

const otherEnginConstructDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

const dictFields = ['placement_id', 'engin_struct_type_id', 'odh_side_id'];

const numericFields = [
  { name: 'bord_begin', type: 'decimal', zero: true },
  { name: 'bord_end', type: 'decimal', zero: true },
  { name: 'gutters_length', type: 'decimal' },
  {
    name: 'area',
    positive: true,
    type: 'decimal',
    zero: true,
  },
];

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object} - Отвалидированные поля.
 * @example ----
 */
const validate = (values) => validateNumeric(values, numericFields);

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

/**
 * Функция сабмита.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {void}
 * @example ----
 */
function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);
  const formValues = getFormValues('editorCard')(state);

  const result = {
    createMode: isCreateMode(state, props),

    editMode: isEditMode(state, props),

    enableReinitialize: true,
    engin_struct_type: get(formValues, 'engin_struct_type_id'),
    initialValues: {
      area: parse('area'),
      bord_begin: parse('bord_begin') || 0,
      bord_end: parse('bord_end') || 0,
      customer_id: parse('customer_id'),
      description: parse('description'),
      endDate: parseDate(props.card.end_date),
      engin_struct_type_id: parse('engin_struct_type_id.engin_struct_type'),
      gutters_length: parse('gutters_length'),
      is_object_area: parse('is_object_area'),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      owner_id: parse('owner_id'),
      parent_name: props.card.parent_info,
      placement_id: parse('placement_id.placement'),
      startDate: parseDate(props.card.start_date),
      totalAreaGeo: parse('total_area_geo'),
    },
    onSubmit: onSubmit.bind({ props }),

    required: isRequired(state, props),

    validate,
    viewing: isThisViewing(state),
  };

  return otherEnginConstructDocumentsReducer(result, state, props);
};

export default connect(mapStateToProps)(
  reduxForm({ form: 'editorCard' })(OtherEnginConstructCard),
);
