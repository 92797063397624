import { ContinueFFDialog } from 'core/form/finalForm/components/dialogs/ContinueFF.Dialog';

import { BodyDialogRepairsInfoListPlan } from './BodyDialog.RepairsInfoListPlan';
import { useSendRepairsInfoListPlan } from './useSendRepairsInfoListPlan';
import { validateRepairsInfoListPlan } from './validate.RepairsInfoListPlan';

/**
 * Диалоговое окно __Добавить сведения о проектах__.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} props.isOpen - Флаг отображения диалогового окна.
 * @param {Function} props.closeDialog - Функция выхода из диалогового окна.
 * @param {Function} props.onChange - Функция добавления нового проекта.
 * @param {object} props.value - Значения таблицы.
 * @returns {JSX.Element}
 */
export const DialogRepairsInfoListPlan = ({
  isOpen,
  closeDialog,
  onChange,
  value,
}) => {
  const { sendSubmit, isLoading } = useSendRepairsInfoListPlan({
    closeDialog,
    onChange,
    value,
  });

  /**
   * Функция onSubmit.
   *
   * @param {object} values - Занчения формы.
   */
  const onSubmit = (values) => {
    sendSubmit(values);
  };

  return (
    <ContinueFFDialog
      initialValues={{}}
      validate={validateRepairsInfoListPlan}
      onSubmit={onSubmit}
      maxWidth={'sm'}
      isOpen={isOpen}
      closeDialog={closeDialog}
      textHeader={'Добавить сведения о проектах'}
      Body={<BodyDialogRepairsInfoListPlan />}
      isLoading={isLoading}
    />
  );
};
