import { shallow } from 'enzyme';

import { OznOneTab } from './OznOne.Tab';

describe('OznOneTab', () => {
  const card = {
    attribute: {
      create_type: 'some-create-type',
      grbs_id: {
        legal_person: 'some-legal-person',
      },
    },
  };

  it('a should render without crashing', () => {
    expect.hasAssertions();

    //🔥 Act
    const wrapper = shallow(<OznOneTab card={card} />);

    //❓ Assert
    expect(wrapper.text()).toBe('<CardContainer />');
  });

  it('should use CardContainer component', () => {
    expect.hasAssertions();

    //🔥 Act
    const wrapper = shallow(<OznOneTab card={card} />);

    //❓ Assert
    expect(wrapper.find('CardContainer')).toHaveLength(1);
  });

  it('should use CreateType component with correct props', () => {
    expect.hasAssertions();

    //🔥 Act
    const wrapper = shallow(<OznOneTab card={card} />);

    //❓ Assert
    expect(wrapper.find('CreateType')).toHaveLength(1);
    expect(wrapper.find('CreateType').prop('disabled')).toBe(true);
    expect(wrapper.find('CreateType').prop('value')).toBe('some-create-type');
  });

  it('should use GrbsId component with correct props', () => {
    expect.hasAssertions();

    //🔥 Act
    const wrapper = shallow(<OznOneTab card={card} />);

    //❓ Assert
    expect(wrapper.find('GrbsId')).toHaveLength(1);
    expect(wrapper.find('GrbsId').prop('disabled')).toBe(true);
    expect(wrapper.find('GrbsId').prop('value')).toBe('some-legal-person');
    expect(wrapper.find('GrbsId').prop('label')).toBe('Учредитель/ГРБС');
  });

  it('should render empty CreateType when card.attribute.create_type is not defined', () => {
    expect.hasAssertions();

    //🔥 Act
    const wrapper = shallow(<OznOneTab card={{ attribute: {} }} />);

    //❓ Assert
    expect(wrapper.find('CreateType')).toHaveLength(1);
    expect(wrapper.find('CreateType').prop('value')).toBeUndefined();
  });

  it('should render empty GrbsId when card.attribute.grbs_id.legal_person is not defined', () => {
    expect.hasAssertions();

    //🔥 Act
    const wrapper = shallow(
      <OznOneTab card={{ attribute: { grbs_id: {} } }} />,
    );

    //❓ Assert
    expect(wrapper.find('GrbsId')).toHaveLength(1);
    expect(wrapper.find('GrbsId').prop('value')).toBeUndefined();
  });
});
