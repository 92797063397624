import { EquipmentIndex } from 'app/components/card/ogh/ImprovementTerritoryCard/components/EquipmentIndex';
import { YardTwoTab } from 'app/components/card/ogh/ImprovementTerritoryCard/yard/YardTwo.Tab/YardTwo.Tab';
import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields/prepared/checkboxes/IsDiffHeightMark.RF';
import { ReCalcNameRF } from 'core/form/reduxForm/fields/prepared/textField/ReCalcName.RF';
import { shallow } from 'enzyme';
import React from 'react';
describe('spec YardTwo.Tab', function () {
  it('all true', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    // Act
    const wrapper = shallow(
      <YardTwoTab editable={true} isCurrentObjectIsExternalSystem={true} />,
    );
    // Assert
    expect(
      wrapper.contains(
        <CardContainer>
          {/*// @ts-ignore*/}
          <ReCalcNameRF />
          <IsDiffHeightMarkRF label={'Разновысотный ОГХ'} disabled={true} />
          {/*// @ts-ignore*/}
          <EquipmentIndex />
        </CardContainer>,
      ),
    ).toBeTruthy();
  });
  it('editable true', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    // Act
    const wrapper = shallow(<YardTwoTab editable={true} />);
    // Assert
    expect(
      wrapper.contains(
        <CardContainer>
          {/*// @ts-ignore*/}
          <ReCalcNameRF />
          <IsDiffHeightMarkRF
            label={'Разновысотный ОГХ'}
            disabled={undefined}
          />
          {/*// @ts-ignore*/}
          <EquipmentIndex />
        </CardContainer>,
      ),
    ).toBeTruthy();
  });
  it('isCurrentObjectIsExternalSystem true', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    // Act
    const wrapper = shallow(
      <YardTwoTab isCurrentObjectIsExternalSystem={true} />,
    );
    // Assert
    expect(
      wrapper.contains(
        <CardContainer>
          {/*// @ts-ignore*/}
          <ReCalcNameRF />
          <IsDiffHeightMarkRF label={'Разновысотный ОГХ'} disabled={true} />
          {/*// @ts-ignore*/}
          <EquipmentIndex />
        </CardContainer>,
      ),
    ).toBeTruthy();
  });

  it('all false', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    // Act
    const wrapper = shallow(<YardTwoTab />);
    // Assert
    expect(
      wrapper.contains(
        <CardContainer>
          {/*// @ts-ignore*/}
          <ReCalcNameRF />
          <IsDiffHeightMarkRF label={'Разновысотный ОГХ'} disabled={true} />
          {/*// @ts-ignore*/}
          <EquipmentIndex />
        </CardContainer>,
      ),
    ).toBeTruthy();
  });
});
