import { useMode } from 'app/pages/cardsOgh/hooks/useMode';

import { useQueryKnowledgeBase } from './api/useQuery.KnowledgeBase';
import { useUpdateKnowledgeBaseMutation } from './api/useUpdate.KnowledgeBaseMutation';
import { pathKnowledgeBase } from './path.KnowledgeBase';

/**
 * Setup for Card Методические материалы.
 *
 * @returns Методы и данные.
 */
export const useSetupKnowledgeBase = () => {
  const { editMode } = useMode();

  const [onSubmit, { isLoading: isLoadingSave }] =
    useUpdateKnowledgeBaseMutation();

  const { data, isFetching } = useQueryKnowledgeBase();

  const files = data?.attribute?.file_list?.map((file) => ({
    file_id: file.id,
    file_name: file?.file_name || file?.id,
  }));

  const breadcrumbs = [
    {
      name: pathKnowledgeBase.name,
      url: `./`,
    },
    {
      name: data?.name,
      url: `./`,
    },
  ];

  return {
    breadcrumbs,
    files,
    id: data?.id,
    isEdit: editMode && !isFetching && !isLoadingSave,
    isLoading: isFetching || isLoadingSave,
    onSubmit,
  };
};
