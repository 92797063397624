/* eslint-disable  */

import { asyncValidate as ownerAndCustomerFieldsAsyncValidate } from 'app/components/card/ogh/ImprovementTerritoryCard/components/OwnerAndCustomerFields';
import { asyncValidate as snowAreaAsyncValidate } from 'app/components/card/ogh/ImprovementTerritoryCard/components/SnowAreaTab';

import { asyncValidate } from './asyncValidate';
jest.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/components/OwnerAndCustomerFields',
);
jest.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/components/SnowAreaTab',
);

describe('asyncValidate.jestSpec', () => {
  it('should', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    (ownerAndCustomerFieldsAsyncValidate as jest.Mock).mockReturnValue({
      b: 4,
    });
    (snowAreaAsyncValidate as jest.Mock).mockReturnValue({
      c: 3,
    });
    // Act
    const res = asyncValidate({ a: 23 });
    // Assert
    expect(res).toStrictEqual({ b: 4, c: 3 });
    expect(ownerAndCustomerFieldsAsyncValidate).toBeCalledWith({ a: 23 });
    expect(snowAreaAsyncValidate).toBeCalledWith({ a: 23 });
  });
});
