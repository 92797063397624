/* eslint-disable  */
// 41 Иные некапитальные объекты

import CardAbstract from 'app/components/card/common/CardAbstract';
import {
  elementFormGrid,
  renderAddressList2,
} from 'app/components/card/common/grid';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import DocumentsTab, {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import printOghCardHeader from 'app/components/card/ogh/header/oghHeader';
import submitCard from 'app/components/card/ogh/submit';
import VersionsTemplate from 'app/components/card/ogh/versions/VersionsTemplate';
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import { UNTENANTABLE } from 'app/constants/addressKlKods';
import { column } from 'app/constants/tables';
import getBuildingsTypeSpecByTypeId from 'app/selectors/card/getBuildingsTypeSpecByTypeId';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import getDict from 'app/selectors/getDict';
import { getInitialBuildingsTypeId } from 'app/utils/card/getInitialBuildingsTypeId';
import isMandatory, { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import getHint from 'app/utils/getAddressListHints';
// import { WithReduxFormCheckbox } from 'app/components/common/CheckboxField';
import { IsDiffHeightMarkRF, NumberFieldRF } from 'core/form/reduxForm/fields';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import { get, mapValues, pick } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { BuildingsTypeSpecCard } from './BuildingsTypeSpecCard';

const types = ['other_tech_place'];
const Versions = VersionsTemplate(types);

const tabs = [
  {
    id: 'characteristics-tab',
    name: 'Исходные данные',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

const fieldLabelStyles = {
  height: 33,
};

/**
 * Компонент OtherTechPlaceCard.
 */
class OtherTechPlaceCard extends React.Component {
  /**
   * Метод жизненго цикла render.
   *
   * @returns {JSX.Element} - Компонент карточки.
   * @example render().
   */
  render() {
    const { props } = this;
    const {
      card,
      mode,
      required,
      editMode,
      // buildingsTypeSpec,
      isCurrentObjectIsExternalSystem,
    } = props;
    const { type_id: typeId } = card;

    return (
      <CardAbstract>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(typeId)}
          <div id="props">
            {elementFormGrid(
              [
                <div>
                  <ParentInfo />
                </div>,
                {
                  addition: {
                    label: 'Назначение',
                    options: card.buildings_type,
                    required: isMandatory(
                      card.type_id,
                      'buildings_type_id',
                      required,
                    ),
                  },
                  component: WithReduxFormSelect,
                  editable: false,
                  formValue: true,
                  id: 'buildings_type_id',
                  name: 'buildings_type_id',
                },
                <div>
                  <BuildingsTypeSpecCard />
                </div>,
              ],
              column.TWO,
              '',
              typeId,
            )}

            {elementFormGrid(
              [
                // Закоментировано в рамках ODS-9626
                // {
                //   id: 'no_calc',
                //   name: 'no_calc',
                //   label: 'Не учитывать',
                //   editable: editMode,
                //   formValue: true,
                //   component: WithReduxFormCheckbox,
                //   style: checkboxStyle,
                // },
                <div
                  style={{
                    height: 50,
                    margin: '9px 24px',
                    minWidth: 256,
                    paddingTop: 0,
                  }}
                >
                  <IsDiffHeightMarkRF
                    disabled={!mode.editMode || isCurrentObjectIsExternalSystem}
                    justifyContent={'space-between'}
                    labelPlacement={'start'}
                  />
                </div>,
              ],
              2,
              '',
              typeId,
            )}

            {elementFormGrid(
              [
                {
                  addition: {
                    address_required: true,
                    data: {
                      address: {
                        kl_kod: UNTENANTABLE,
                      },
                    },
                    hintText: editMode ? getHint('near_address') : null,
                    noWrapper: true,
                    onlyOne: true,
                  },
                  component: renderAddressList2,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'address_list',
                  label: null,
                  name: 'address_list',
                },
              ],
              column.ONE,
              'Адрес БТИ',
              typeId,
            )}

            <Tabs tabs={tabs} />

            <div id="characteristics">
              {elementFormGrid(
                [
                  {
                    addition: {
                      label: 'Материал',
                      labelStyle: fieldLabelStyles,
                      options: card.material,
                      required: isMandatory(
                        card.type_id,
                        'material_id',
                        required,
                      ),
                    },
                    component: WithReduxFormSelect,
                    editable: editMode,
                    formValue: true,
                    id: 'material_id',
                    name: 'material_id',
                  },
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      required: isMandatory(card.type_id, 'area', required),
                      type: 'decimal',
                      zero: false,
                    },
                    component: NumberFieldRF,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'area',
                    label: 'Площадь, кв.м',
                    name: 'area',
                  },
                  <div>
                    <TotalAreaGeoRF disabled={true} decimalScale={2} />
                  </div>,
                ],
                column.TWO,
                '',
                typeId,
              )}
            </div>
            {DocumentsTab()({
              documents: props.documents,
              editable: editMode,
              typeId,
            })}
          </div>
          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={props.createMode}
              key={`versions-${card.root_id}`}
            />
          </div>
        </div>
      </CardAbstract>
    );
  }
}

const getAbutmentTypes = getDict('abutmentTypes');

const otherTechPlaceDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

const numericFields = [
  {
    name: 'area',
    positive: true,
    type: 'decimal',
  },
];

const dictFields = [
  'buildings_type_id',
  'buildingsTypeSpecId',
  'material_id',
  'abutment_type_id',
];

/**
 * Валидация числовых значений.
 *
 * @param {object} values - Заполненые значения формы.
 * @returns {object} - Возвращает объект с ошибками по полям.
 * @example validate(values)
 */
function validate(values) {
  return validateNumeric(values, numericFields);
}

/**
 * Добавляет значения формы в папку attributes.
 *
 * @param {object} formValues - Заполненые значения формы.
 * @returns {object} - Объект с attributes.
 * @example const formValuesWithAttribute = {
 *     ...values,
 *     ...createAttribute(values),
 *   };
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

/**
 * Функций submit.
 *
 * @param {object} formValues - Заполненые значения формы.
 * @returns {void} - Жопа.
 * @example
 * onSubmit(formValues)
 */
function onSubmit(formValues) {
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[this.props.card.type_id],
  );

  if (get(formValuesWithAttribute, 'abutment_type_list.table[0]')) {
    formValuesWithAttribute.abutment_type_list =
      formValuesWithAttribute.abutment_type_list.table;
  } else {
    delete formValuesWithAttribute.abutment_type_list;
  }

  if (get(formValuesWithAttribute, 'flowers_module_list.table[0]')) {
    formValuesWithAttribute.flowers_module_list =
      formValuesWithAttribute.flowers_module_list.table;
  } else {
    delete formValuesWithAttribute.flowers_module_list;
  }

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      custom: {
        address_list: {
          /**
           *
           * @param value
           */
          transformer: (value) => {
            return value == null || value.length === 0
              ? null
              : value.map((item) => {
                  return mapValues(
                    pick(item, [
                      'okrug_id',
                      'address_id',
                      'street_id',
                      'district_id',
                      'description',
                    ]),
                    (id) => ({ bti_object: id }),
                  );
                });
          },
        },
      },
      dictFields,
      numericFields,
    }),
  );
}

/**
 * Получение значений из state.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {*} - Передает в пропсы выбранные значения.
 * @example export default connect(mapStateToProps)(
 *   reduxForm({ form: 'editorCard' })(OtherTechPlaceCard),
 * );
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);
  const getBuildingsTypeSpec = getBuildingsTypeSpecByTypeId(props.card.type_id);
  const result = {
    abutmentTypes: getAbutmentTypes(state),
    buildingsTypeSpec: getBuildingsTypeSpec(state),
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),
    enableReinitialize: true,

    formValues: getFormValues(state),
    initialValues: {
      abutment_type_list: {
        showDialog: false,
        table: parse('abutment_type_list'),
      },
      address_list:
        props.card.address_list &&
        props.card.address_list.map(
          (item) =>
            ({
              ...item,
              ...item.attributes,
              attributes: null,
            } || null),
        ),
      area: parse('area'),
      buildings_type_id: getInitialBuildingsTypeId(props, parse),
      buildingsTypeSpecId: parse('buildings_type_spec_id.buildings_type_spec'),
      endDate: parseDate(props.card.end_date),
      flowers_module_list: {
        showDialog: false,
        table: props.card.flowers_module_list || null,
      },
      is_diff_height_mark: parse('is_diff_height_mark'),
      material_id: parse('material_id.material'),
      parent_name: props.card.parent_info,
      startDate: parseDate(props.card.start_date),
      totalAreaGeo: parse('total_area_geo'),
    },
    onSubmit: onSubmit.bind({ props }),
    required: isRequired(state, props),

    validate,
  };

  return otherTechPlaceDocumentsReducer(result, state, props);
};

export default connect(mapStateToProps)(
  reduxForm({ form: 'editorCard' })(OtherTechPlaceCard),
);
