import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import { createCategorySpaAttribute } from 'app/components/card/ogh/ImprovementTerritoryCard/components/CategorySpa/createCategorySpaAttribute';
import { IsDiffHeightMarkReducer } from 'app/components/card/ogh/ImprovementTerritoryCard/components/IsDiffHeightMark';
import OrdersTab, {
  mapDispatchToPropsReducer as ordersDispatchReducer,
  mapStateToPropsReducer as ordersReducer,
} from 'app/components/card/ogh/OrdersTab';
import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import getHint from 'app/utils/getHint';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields';
import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import { CustomerOznRF } from 'core/form/reduxForm/fields/prepared/selects/autocompleteAsync/CustomerOzn.RF';
import { OwnerIdRF } from 'core/form/reduxForm/fields/prepared/selects/autocompleteAsync/OwnerId.RF';
import { CategorySpaIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CategorySpaId.RF';
import { DepartmentRF } from 'core/form/reduxForm/fields/prepared/selects/simple/Department.RF';
import { CreateType } from 'core/uiKit/preparedInputs';
import { CATEGORY_SPA_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CategorySpaId';
import { GrbsId } from 'core/uiKit/preparedInputs/selects/simpleSelect/GrbsId';
import React from 'react';

import AddressListField, {
  createAttribute as createAddressListFieldAttribute,
  mapStateToPropsReducer as addressListFieldReducer,
} from './components/AddressListField';
import {
  createAttribute as createAquaAreaAttribute,
  mapStateToPropsReducer as aquaAreaReducer,
  validate as validateAquaArea,
} from './components/AquaAreaTab';
import { asyncValidate as categorySpaAsyncValidate } from './components/CategorySpa';
import {
  asyncValidate as departmentTypeFieldAsyncValidate,
  mapStateToPropsReducer as departmentTypeFieldReducer,
} from './components/DepartmentTypeField';
import {
  createAttribute as improvementCategoriesCreateAttribute,
  mapStateToPropsReducer as improvementCategoriesReducer,
} from './components/ImprovementCategories';
import MainParamsTab from './components/MainParamsTab';
import {
  asyncValidate as nameFieldAsyncValidate,
  mapStateToPropsReducer as nameFieldReducer,
  NameField,
} from './components/NameField';
import {
  asyncValidate as ownerAndCustomerFieldsAsyncValidate,
  createAttribute as createOwnerAndCustomerFieldsAttribute,
  mapStateToPropsReducer as ownerAndCustomerReducer,
} from './components/OwnerAndCustomerFields';
import PlantationTab from './components/PlantationTab';
import PropsTabs from './components/PropsTabs';
import {
  asyncValidate as snowAreaAsyncValidate,
  mapStateToPropsReducer as snowAreaReducer,
  validate as validateSnowArea,
} from './components/SnowAreaTab';
import ImprovementTerritoryCardTemplate from './ImprovementTerritoryCardTemplate';
import mapStateToPropsReducer from './mapStateToPropsReducer';
import { DocumentsTabSpa } from './spa/DocumentsTab.Spa/DocumentsTab.Spa';

const mandatoryFieldNames = {
  categorySpaId: 'Категория',
  customer_id: 'Заказчик',
  department_id: 'Отраслевой ОИВ',
  name: 'Наименование',
  owner_id: 'Балансодержатель',
  snow_clean_area: 'Площадь вывоза снега',
};

const tabToFields = {
  Свойства: [
    'owner_id',
    'name',
    'customer_id',
    'department_id',
    'department_id',
    'snow_clean_area',
    'categorySpaId',
  ],
};

/**
 * Получение initialValues.
 *
 * @param {object} result - Хопа.
 * @param {object} state - State.
 * @param {object} props - Пропсы.
 * @returns {void} - Жопа.
 */
const initialValues = (result, state, props) => {
  const { card } = props;
  return {
    ...result,
    initialValues: {
      ...card?.attribute,
      ...card?.calc_attribute,
      ...result.initialValues,
      [CATEGORY_SPA_ID_NAME]:
        card?.attribute?.category_spa_id?.category_spa || null,
    },
  };
};

/**
 * Карта - Особо охраняемые природные территории.
 */
export const SpaCard = ImprovementTerritoryCardTemplate({
  mapDispatchToPropsReducers: [ordersDispatchReducer],
  mapStateToPropsReducers: [
    mapStateToPropsReducer({
      asyncValidate,
      createAttribute,
      mandatoryFieldNames,
      tabToFields,
      validate,
    }),
    improvementCategoriesReducer({
      improvement: {
        defaultOption: {
          id: -1,
          name: '',
        },
        defaultValue: -1,
        isAlwaysDefault: true,
      },
    }),
    initialValues,
    ownerAndCustomerReducer(),
    departmentTypeFieldReducer({
      id: 'department_id',
      path: 'attribute.department_id.legal_person',
    }),
    IsDiffHeightMarkReducer(),
    nameFieldReducer(),
    addressListFieldReducer(),
    snowAreaReducer,
    aquaAreaReducer,
    documentsReducer({
      oghGroupCode: 'ozn_object',
    }),
    ordersReducer,
  ],
  propsTabElementRenders: [
    (props) => (
      <CardContainer>
        <CreateType
          disabled={true}
          value={props?.card?.attribute?.create_type}
        />
        <GrbsId
          disabled={true}
          value={props?.card?.attribute?.grbs_id?.legal_person}
          label={'Учредитель/ГРБС'}
        />
      </CardContainer>
    ),
    (props) => (
      <CardContainer>
        <DepartmentRF
          required={true}
          disabled={!props.editable}
          withAll={false}
        />
      </CardContainer>
    ),
    (props) => (
      <CardContainer>
        <OwnerIdRF
          helpTooltip={getHint('owner_id')}
          disabled={!props.editable}
          required={true}
        />
        <CustomerOznRF
          helpTooltip={getHint('customer_id_OZN')}
          disabled={!props.editable}
          required={true}
        />
        <CategorySpaIdRF disabled={!props.editable} required />
        <NameField {...props} />
        <IsDiffHeightMarkRF
          label={'Разновысотный ОГХ'}
          disabled={!props.editable}
        />
        <TextFieldRF
          disabled={!props.editable}
          label={'Исполнитель работ по инвентаризации'}
          name={'inventory_contractor'}
        />
      </CardContainer>
    ),
    AddressListField({
      required: false,
    }),
    PropsTabs([
      {
        id: 'squares-tab',
        name: 'Площади',
      },
      {
        id: 'plantation-tab',
        name: 'Озеленение',
      },
      {
        id: 'docs-tab',
        name: 'Документы',
      },
      {
        id: 'orders-tab',
        name: 'Ордера на работы',
      },
    ]),
    MainParamsTab(),
    <PlantationTab />,
    // Таб документов
    <DocumentsTabSpa />,
    OrdersTab(),
  ],
});

/**
 * Валидация.
 *
 * @param {object} values - Values to validate.
 * @returns {object|null} Объект с ошибками.
 * @example ---
 */
function validate(values) {
  return {
    ...validateSnowArea(values),
    ...validateAquaArea(values),
  };
}

/**
 * CreateAttribute.
 *
 * @param {object} formValues - The form values.
 * @returns {object} - Объект атрибутов.
 * @example ---
 */
function createAttribute(formValues) {
  return {
    ...createAddressListFieldAttribute(formValues),
    ...createOwnerAndCustomerFieldsAttribute(formValues),
    ...improvementCategoriesCreateAttribute(formValues),
    ...createDocumentsAttribute(formValues),
    ...createAquaAreaAttribute(formValues),
    ...createCategorySpaAttribute(formValues),
  };
}
// Закомментировано в рамках задачи ODS-9224
// const cowParsnipAreaAsyncValidate = (formValues) => {
//   const { cow_parsnip_area } = formValues;
//   let result = {};
//   if (!cow_parsnip_area) {
//     result['cow_parsnip_area'] = REQUIRED_FIELD_MESSAGE;
//   }
//
//   return Object.keys(result).length > 0 ? result : null;
// };

/**
 * AsyncValidate.
 *
 * @param {object} formValues - The form values.
 * @returns {object} - Объект.
 * @example ---
 */
function asyncValidate(formValues) {
  return {
    ...snowAreaAsyncValidate(formValues),
    ...departmentTypeFieldAsyncValidate(formValues),
    ...ownerAndCustomerFieldsAsyncValidate(formValues),
    ...nameFieldAsyncValidate(formValues),
    ...categorySpaAsyncValidate(formValues),
  };
}
