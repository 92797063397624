import { LegendNestedObjectsDiff } from 'app/pages/cardsOgh/components/tables/Versions/DialogComparison/NestedObjectsDiff/Legend.NestedObjectsDiff';
import { useGetNestedObjectsDiffTable } from 'app/pages/cardsOgh/components/tables/Versions/DialogComparison/NestedObjectsDiff/useGetNestedObjectsDiffTable';
import formatRootId from 'app/utils/formatRootId';
import { IndexCell } from 'core/uiKit/components/cells/Index.Cell/Index.Cell';
import { LegendCell } from 'core/uiKit/components/cells/Legend.Cell/Legend.Cell';
import Table from 'core/uiKit/components/tables/Table/Table';
import React from 'react';

 
/**
 * Таблица "Вложенные объекты" с легендой.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.etalon - Объект сравниваемый.
 * @param {object} props.copy - C чем сравниваем.
 * @returns {JSX.Element} - JSX.
 * @example <NestedObjectsDiff etalon={etalon} copy={copy}/>
 */
export const NestedObjectsDiff = ({ etalon, copy }) => {
  /* eslint-disable */
  const columns = [
    {
      Header: '№ п/п',
      accessor: 'index',
      align: 'center',
      minWidth: 60,
      Cell: IndexCell,
    },
    {
      Header: 'Объект',
      accessor: 'type_name',
    },
    {
      Header: `${formatRootId(copy.id)} (${copy.object_status_name})`,
      accessor: 'copy_object',
      align: 'center',
    },
    {
      Header: `${formatRootId(etalon.id)} (${etalon.object_status_name})`,
      accessor: 'original_object',
      align: 'center',
    },
    {
      Header: 'Легенда',
      accessor: 'prop_operation',
      align: 'center',
      Cell: LegendCell,
    },
  ];

  const { table, isLoading } = useGetNestedObjectsDiffTable(etalon, copy);

  return (
    <>
      <div className={'dialog-comparison-version-card__dialog-table'}>
        <Table
          columns={columns}
          data={table}
          pagination={false}
          isLoading={isLoading}
        />
      </div>
      <LegendNestedObjectsDiff />
    </>
  );
};
