// 43 Деревья/Кустарники
/* eslint-disable  */
// 43 Деревья/Кустарники

import CardAbstract from 'app/components/card/common/CardAbstract';
import { elementFormGrid } from 'app/components/card/common/grid';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import buildMandatoriesMessage from 'app/components/card/common/validation/buildMandatoriesMessage';
import {
  getRequiredValidateDiameter,
  validateDiameterMultipleTwo,
} from 'app/components/card/common/validation/validateDiameterMultipleTwo';
import { LifeFormTypeIdRfWrapper } from 'app/components/card/ogh/components/selects/LifeFormTypeIdRFWrapper';
import DocumentsTab, {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import printOghCardHeader from 'app/components/card/ogh/header/oghHeader';
import submitCard from 'app/components/card/ogh/submit';
import {
  getFieldsMandatoryMessage,
  mandatoryFieldNamesTreesShrubs,
} from 'app/components/card/ogh/trees_shrubs/getFieldsMandatoryMessage';
import {
  asyncValidateTreesShrubs,
  distanceRequired,
} from 'app/components/card/ogh/TreesShrubs/asyncValidate.TreesShrubs';
import { CharacteristicStateGardeningIdRFTreesShrubs } from 'app/components/card/ogh/TreesShrubs/fields/CharacteristicStateGardeningIdRF.TreesShrubs';
import { PlantationTypeIdRFTreesShrubs } from 'app/components/card/ogh/TreesShrubs/fields/PlantationTypeId.RF.TreesShrubs';
import { PlantServiceRecomendationsIdRfTreesShrubs } from 'app/components/card/ogh/TreesShrubs/fields/PlantServiceRecomendationsIdRF.TreesShrubs/PlantServiceRecomendationsIdRF.TreesShrubs';
import VersionsTemplate from 'app/components/card/ogh/versions/VersionsTemplate';
import { WithReduxFormCheckbox } from 'app/components/common/CheckboxField';
import getLifeFormTypes from 'app/selectors/card/getLifeFormTypes/getLifeFormTypes';
import getLockedFields from 'app/selectors/card/getLockedFields';
import getMandatoryFields from 'app/selectors/card/getMandatoryFields';
import getTreesShrubsArea from 'app/selectors/card/getTreesShrubsArea';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import { transformValues } from 'app/utils/form/transformation';
import { IsDiffHeightMarkRF, NumberFieldRF } from 'core/form/reduxForm/fields';
import { DetailedStateGardeningIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/DetailedStateGardeningId.RF';
import { PlantTypeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/PlantTypeId.RF';
import { StateGardeningIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/StateGardeningId.RF';
import { ValuablePlantsIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/ValuablePlantsId.RF';
import { VALUABLE_PLANTS_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/ValuablePlantsId';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import { preparationValues } from 'core/utils/helpers/preparationValues';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

const types = ['trees_shrubs', 'trees', 'shrubs', 'lawns'];
const Versions = VersionsTemplate(types);
const tabs = [
  {
    id: 'characteristics-tab',
    name: 'Исходные данные',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

const checkboxStyle = {
  height: 50,
  paddingTop: 0,
};

/**
 * 43 Деревья/Кустарники.
 *
 */
class TreesAndShrubsCard extends React.Component {
  /**
   * Метод изменения инпута площади.
   *
   * @returns {undefined}
   */
  setAreaValue() {
    setTimeout(() => {
      if (
        this.props.editMode &&
        this.props.treesShrubsArea &&
        this.props.changeFieldValue
      ) {
        this.props.changeFieldValue('area', this.props.treesShrubsArea);
      }
    }, 0);
  }

  /**
   * Метод проверки заблокированности.
   *
   * @param {string} fieldName - Имя.
   * @returns {boolean}
   */
  isLocked(fieldName) {
    return (
      this.props.editMode &&
      this.props.lockedFields &&
      !this.props.lockedFields.includes(fieldName)
    );
  }

  /**
   * Метод проверки является ли обязательным поле.
   *
   * @param {string} fieldName - Имя.
   * @returns {boolean}
   */
  isMandatory(fieldName) {
    return (
      this.props.required &&
      this.props.mandatoryFields &&
      this.props.mandatoryFields.includes(fieldName)
    );
  }

  /**
   * Метод отрисовки React.
   *
   * @returns {JSX.Element} - JSX.
   * @example
   * ----
   */
  render() {
    const { props } = this;
    const {
      card,
      mode,
      editMode,
      changeFieldValue,
      currentValues,
      isCurrentObjectIsExternalSystem,
    } = props;
    const { type_id: typeId } = card;

    // const { card, mode, currentValues, changeFieldValue } = this.props;
    // const editable = mode && mode.editMode || false;
    return (
      <CardAbstract>
        <div>
          <div id="editor-card">
            {printOghCardHeader(card, mode)}
            {card && objectTabs(typeId)}
            <div id="props">
              {elementFormGrid(
                [
                  <div>
                    <ParentInfo />
                  </div>,
                  <div
                    style={{
                      height: 50,
                      margin: '9px 24px',
                      minWidth: 256,
                      paddingTop: 0,
                    }}
                  >
                    <IsDiffHeightMarkRF
                      disabled={
                        !mode.editMode || isCurrentObjectIsExternalSystem
                      }
                      justifyContent={'space-between'}
                      labelPlacement={'start'}
                    />
                  </div>,
                ],
                2,
                '',
              )}

              <Tabs tabs={tabs} />

              <div id="characteristics">
                {elementFormGrid(
                  [
                    {
                      addition: {
                        digits: 0,
                        fullWidth: true,
                        required: this.isMandatory('section_num'),
                        type: 'integer',
                      },
                      component: NumberFieldRF,
                      editable: editMode && !isCurrentObjectIsExternalSystem,
                      formValue: true,
                      id: 'section_num',
                      label: 'Номер участка',
                      name: 'section_num',
                    },
                    {
                      addition: {
                        digits: 0,
                        required: true,
                        type: 'integer',
                      },
                      component: NumberFieldRF,
                      editable: editMode && !isCurrentObjectIsExternalSystem,
                      formValue: true,
                      id: 'bio_group_num',
                      label: 'Номер биогруппы',
                      name: 'bio_group_num',
                    },
                    {
                      addition: {
                        digits: 0,
                        required: this.isMandatory('green_num'),
                        type: 'integer',
                      },
                      component: NumberFieldRF,
                      editable: editMode && !isCurrentObjectIsExternalSystem,
                      formValue: true,
                      id: 'green_num',
                      label: 'Номер растения',
                      name: 'green_num',
                    },
                    <div>
                      <PlantationTypeIdRFTreesShrubs
                        editMode={editMode}
                        changeFieldValue={changeFieldValue}
                      />
                    </div>,
                    <div>
                      <LifeFormTypeIdRfWrapper
                        disabled={
                          !(editMode && currentValues?.plantation_type_id) ||
                          isCurrentObjectIsExternalSystem
                        }
                        withChange={() => {
                          changeFieldValue('plant_type_id', null);
                          changeFieldValue('age', null);
                          changeFieldValue('diameter', null);
                          changeFieldValue('area', '');
                          changeFieldValue('quantity', '');
                        }}
                        required={true}
                      />
                    </div>,
                    <div>
                      <PlantTypeIdRF
                        disabled={
                          !(editMode && currentValues?.life_form_type_id) ||
                          isCurrentObjectIsExternalSystem
                        }
                        required={true}
                        filter={(dict) =>
                          dict.filter(({ life_form_type_ids }) =>
                            life_form_type_ids.includes(
                              currentValues?.life_form_type_id,
                            ),
                          )
                        }
                      />
                    </div>,
                    <div>
                      <ValuablePlantsIdRF
                        disabled={!editMode || isCurrentObjectIsExternalSystem}
                      />
                    </div>,
                    {
                      addition: {
                        required: true,

                        /**
                         *
                         */
                        withChange: () => {
                          this.setAreaValue();
                        },
                        zero: false,
                      },
                      component: NumberFieldRF,
                      editable: editMode && !isCurrentObjectIsExternalSystem,
                      formValue: true,
                      id: 'quantity',
                      label: 'Количество, шт.',
                      name: 'quantity',
                    },
                    {
                      addition: {
                        decimalScale: 2,
                        required: this.isMandatory('area'),
                      },
                      component: NumberFieldRF,
                      editable:
                        this.isLocked('area') &&
                        !isCurrentObjectIsExternalSystem,
                      formValue: true,
                      id: 'area',
                      label: 'Площадь, кв.м',
                      name: 'area',
                      // defaultValue: this.props.treesShrubsArea,
                    },
                    {
                      addition: {
                        decimalScale: 1,
                        required: distanceRequired(currentValues),
                      },
                      component: NumberFieldRF,
                      editable: editMode && !isCurrentObjectIsExternalSystem,
                      formValue: true,
                      id: 'distance',
                      label: 'Количество, п.м.',
                      name: 'distance',
                    },
                    {
                      addition: {
                        positive: true,
                        required: getRequiredValidateDiameter(currentValues),
                      },
                      component: NumberFieldRF,
                      editable:
                        this.isLocked('diameter') &&
                        !isCurrentObjectIsExternalSystem,
                      formValue: true,
                      id: 'diameter',
                      label: 'Диаметр на высоте 1,3 м, см.',
                      name: 'diameter',
                    },
                    {
                      addition: {
                        digits: 2,
                        positive: true,
                        required: true,
                        type: 'decimal',
                        zero: false,
                      },
                      component: NumberFieldRF,
                      editable: editMode && !isCurrentObjectIsExternalSystem,
                      formValue: true,
                      id: 'height',
                      label: 'Высота, м',
                      name: 'height',
                    },
                    {
                      addition: {
                        digits: 1,
                        positive: true,
                        required: this.isMandatory('age'),
                        type: 'decimal',
                        zero: false,
                      },
                      component: NumberFieldRF,
                      editable: editMode && !isCurrentObjectIsExternalSystem,
                      formValue: true,
                      id: 'age',
                      label: 'Возраст, лет',
                      name: 'age',
                    },
                    <div>
                      <StateGardeningIdRF
                        required={true}
                        disabled={!editMode || isCurrentObjectIsExternalSystem}
                        withChange={() => {
                          changeFieldValue('detailed_state_gardening_id', null);
                          changeFieldValue(
                            'characteristic_state_gardening_id',
                            [],
                          );
                          changeFieldValue(
                            'plant_service_recomendations_id',
                            null,
                          );
                        }}
                      />
                    </div>,
                    <div>
                      <DetailedStateGardeningIdRF
                        required={true}
                        withChange={() => {
                          changeFieldValue(
                            'characteristic_state_gardening_id',
                            [],
                          );
                          changeFieldValue(
                            'plant_service_recomendations_id',
                            null,
                          );
                        }}
                        disabled={
                          !(editMode && currentValues?.state_gardening_id) ||
                          isCurrentObjectIsExternalSystem
                        }
                        filter={(dict) =>
                          dict.filter(
                            (item) =>
                              item.state_gardening_id ===
                              currentValues?.state_gardening_id,
                          )
                        }
                      />
                    </div>,
                    <div>
                      <CharacteristicStateGardeningIdRFTreesShrubs
                        {...{
                          changeFieldValue,
                          currentValues,
                          editMode,
                        }}
                      />
                    </div>,
                    <div>
                      <PlantServiceRecomendationsIdRfTreesShrubs
                        {...{ currentValues, editMode }}
                      />
                    </div>,
                    {
                      addition: {
                        label: 'Акция "Миллион деревьев"',
                      },
                      component: WithReduxFormCheckbox,
                      editable: editMode,
                      formValue: true,
                      id: 'million_trees',
                      name: 'million_trees',
                    },
                  ],
                  2,
                  'Характеристики',
                  typeId,
                )}

                {elementFormGrid(
                  [
                    {
                      component: WithReduxFormCheckbox,
                      editable: editMode,
                      formValue: true,
                      id: 'no_calc',
                      label: 'Не учитывать',
                      name: 'no_calc',
                      style: checkboxStyle,
                    },
                  ],
                  2,
                  '',
                  card.typeId,
                )}
              </div>

              {DocumentsTab()({
                documents: props.documents,
                editable: editMode && !isCurrentObjectIsExternalSystem,
                typeId,
              })}
            </div>
            <div hidden={true} id="versions">
              <Versions
                card={card}
                disabled={props.createMode}
                key={`versions-${card.root_id}`}
              />
            </div>
          </div>
        </div>
      </CardAbstract>
    );
  }
}

const treesShrubsDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция mapStateToProps.
 *
 * @param {object} state - State redux.
 * @param {object} props - Props component.
 * @returns {object} - Жопа.
 * @example
 * export default connect(
 *   mapStateToProps,
 *   mapDispatchToProps,
 * )(reduxForm({ form: 'editorCard' })(TreesAndShrubsCard));
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);
  const result = {
    createMode: isCreateMode(state, props),
    currentValues: state?.form?.editorCard?.values,
    editMode: isEditMode(state, props),
    enableReinitialize: true,
    initialValues: {
      [VALUABLE_PLANTS_ID_NAME]:
        parse('valuable_plants_id.valuable_plants') || 4,
      age: parse('age'),
      area: parse('area'),
      bio_group_num: parse('bio_group_num'),
      characteristic_state_gardening_id:
        parse(
          'characteristic_state_gardening_id.characteristic_state_gardening',
        ) || [],
      detailed_state_gardening_id: parse(
        'detailed_state_gardening_id.detailed_state_gardening',
      ),
      diameter: parse('diameter'),
      distance: parse('distance'),
      endDate: parseDate(props.card.end_date),
      green_num: parse('green_num'),
      height: parse('height'),
      is_diff_height_mark: parse('is_diff_height_mark'),
      life_form_type_id: parse('life_form_type_id.life_form_type'),
      million_trees: parse('million_trees'),
      no_calc: parse('no_calc'),
      plant_service_recomendations_id: parse(
        'plant_service_recomendations_id.plant_service_recomendations',
      ),
      plant_type_id: parse('plant_type_id.plant_type'),
      plantation_type_id: parse('plantation_type_id.plantation_type'),
      quantity: parse('quantity'),
      section_num: parse('section_num'),
      startDate: parseDate(props.card.start_date),
      state_gardening_id: parse('state_gardening_id.state_gardening'),
    },
    lifeFormTypes: getLifeFormTypes(state),
    lockedFields: getLockedFields(state, props),
    mandatoryFields: getMandatoryFields(state, props),
    onSubmit: onSubmit.bind({ props }),
    plantationTypes: state?.odsDicts?.plantationTypes,
    required: isRequired(state, props),
    treesShrubsArea: getTreesShrubsArea(state),
    validate,
  };

  return treesShrubsDocumentsReducer(result, state, props);
};

/**
 * Функция валидации формы.
 *
 * @param {object} values - Значения формы.
 * @returns {object}
 */
function validate(values) {
  return {
    ...validateNumeric(values, numericFields),
    ...validateDiameterMultipleTwo(values),
  };
}

/**
 * Функция создания атрибутов (наверное).
 *
 * @param {object} formValues - Значения формы.
 * @returns {{file_list: *}}
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

const numericFields = [
  {
    name: 'age',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'diameter',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'height',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'quantity',
    positive: true,
    type: 'integer',
    zero: false,
  },
  {
    name: 'area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'distance',
    positive: true,
    type: 'decimal',
    zero: true,
  },
  {
    name: 'bio_group_num',
    positive: true,
    type: 'decimal',
    zero: true,
  },
  {
    name: 'green_num',
    positive: true,
    type: 'decimal',
    zero: true,
  },
  {
    name: 'section_num',
    positive: true,
    type: 'decimal',
    zero: true,
  },
];

const dictFields = [
  'plantation_type_id',
  'plant_type_id',
  'life_form_type_id',
  'state_gardening_id',
  'detailed_state_gardening_id',
  'characteristic_state_gardening_id',
  'plant_service_recomendations_id',
];

/**
 * Функция Submit.
 *
 * @param {object} formValues - Значения формы.
 * @param {Function} fn - Функция (вроде не используется тут).
 * @param {object} props - Пропсы.
 * @returns {void}
 */
export function onSubmit(formValues, fn, props) {
  const values = { ...formValues };
  delete values.parent_name;

  const formValuesWithAttribute = preparationValues({
    ...values,
    ...createAttribute(values),
  });

  formValuesWithAttribute.million_trees =
    !!formValuesWithAttribute.million_trees;

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    [...props.mandatoryFields],
    null,
    asyncValidateTreesShrubs,
    {
      /**
       *
       * @param errors
       */
      alertErrors: (errors) => {
        props.alert(
          buildMandatoriesMessage(
            errors,
            getFieldsMandatoryMessage(values),
            mandatoryFieldNamesTreesShrubs,
          ),
        );
      },
    },
  );

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

/**
 * MapDispatchToProps от Деревья/Кустарники.
 *
 * @param {Function} dispatch - Функция dispatch.
 * @returns {{changeFieldValue: Function}}
 */
function mapDispatchToProps(dispatch) {
  return {
    /**
     *
     * @param field
     * @param value
     */
    changeFieldValue: (field, value) => {
      dispatch(change('editorCard', field, value));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(TreesAndShrubsCard));
