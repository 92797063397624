import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { OZN, YARD } from 'app/constants/oghTypes';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { CheckboxRF } from 'core/form/reduxForm/fields';
import React, { FC } from 'react';

interface CheckboxGreeningComponentProps {}

/**
 * Checkbox от хедера __Требует дополнения по зелёным насаждениям__.
 *
 * @returns {JSX}
 */
export const CheckboxGreeningComponent: FC<
  CheckboxGreeningComponentProps
> = () => {
  const { typeId } = useGetCurrentCard();
  const { editMode } = useEditMode();
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();

  return [OZN, YARD].includes(typeId) ? (
    <CheckboxRF
      label={'Требует дополнения по зелёным насаждениям'}
      name={'greeningAddition'}
      disabled={!editMode || isCurrentObjectIsExternalSystem}
    />
  ) : null;
};
