// 12 Светофорные объекты
/* eslint-disable  */

import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import CardAbstract from 'app/components/card/common/CardAbstract';
import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid/index';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import { PlacementIdRFWrapper } from 'app/components/card/ogh/components/selects/PlacementIdRFWrapper';
import DocumentsTab, {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import { ODH_AXIS_TOOLTIP } from 'app/constants/messages';
import { column } from 'app/constants/tables';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import { isThisViewing } from 'app/selectors/card/isThisViewing';
import filterDictBySprNumber from 'app/utils/card/filterDictBySprNumber';
import isMandatory, { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['road_signs', 'fencing', 'traffic_light'];
const Versions = VersionsTemplate(types);

const tabs = [
  { id: 'characteristics-tab', name: 'Исходные данные' },
  { id: 'docs-tab', name: 'Документы' },
];

const labelStyles = {
  height: 33,
};

const fieldFullWidthStyles = {
  width: 'calc(100% - 48px)',
};

const propTypes = {
  card: PropTypes.object,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  mode: PropTypes.object,
  required: PropTypes.bool,
};

/**
 *
 * @param props
 */
const TrafficLightCard = (props) => {
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();

  /**
   *
   */
  const getMountingModeOptions = () => {
    const { type_id, mounting_mode, viewing } = props.card;
    if (viewing) {
      return mounting_mode;
    }

    return filterDictBySprNumber(mounting_mode, type_id);
  };

  const { mode, card, createMode, editMode, required, documents } = props;

  return (
    <CardAbstract>
      <div id="editor-card">
        {printOghCardHeader(card, mode)}
        {card && objectTabs(card.type_id)}

        <div id="props">
          {elementFormGrid(
            [
              {
                addition: {
                  label: 'Тип',
                  options: card.equipment_type,
                  required: isMandatory(
                    card.type_id,
                    'equipment_type_id',
                    required,
                  ),
                },
                component: WithReduxFormSelect,
                editable: editMode && !isCurrentObjectIsExternalSystem,
                formValue: true,
                id: 'equipment_type_id',
                name: 'equipment_type_id',
              },
              <div>
                <ParentInfo />
              </div>,
            ],
            column.TWO,
            '',
            card.type_id,
          )}

          <Tabs tabs={tabs} />

          <div id="characteristics">
            {elementFormGrid(
              [
                <div>
                  <PlacementIdRFWrapper
                    disabled={!editMode || isCurrentObjectIsExternalSystem}
                    required={true}
                    withAll={false}
                  />
                </div>,
                {
                  addition: {
                    formatChars: {
                      9: '[а-яА-Я0-9a-zA-Z]',
                    },
                    mask: '99',
                    tooltipText: ODH_AXIS_TOOLTIP,
                    type: 'text',
                  },
                  component: renderCustomTextField,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'odh_axis',
                  label: 'Ось',
                  name: 'odh_axis',
                },
                {
                  addition: {
                    label: 'Сторона',
                    options: card.odh_side,
                    required: isMandatory(
                      card.type_id,
                      'odh_side_id',
                      required,
                    ),
                  },
                  component: WithReduxFormSelect,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'odh_side_id',
                  name: 'odh_side_id',
                },
                {
                  addition: {
                    digits: 2,
                    positive: true,
                    required: isMandatory(card.type_id, 'endwise', required),
                    type: 'decimal',
                    zero: false,
                  },
                  component: renderCustomTextField,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'endwise',
                  label: 'По оси, м',
                  name: 'endwise',
                },
              ],
              column.TWO,
              'Местоположение',
              card.type_id,
            )}

            {elementFormGrid(
              [
                {
                  addition: {
                    label: 'Тип установки',
                    labelStyle: labelStyles,
                    options: getMountingModeOptions(),
                    required: isMandatory(
                      card.type_id,
                      'mounting_mode_id',
                      required,
                    ),
                  },
                  component: WithReduxFormSelect,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'mounting_mode_id',
                  name: 'mounting_mode_id',
                },
                {
                  addition: {
                    digits: 0,
                    positive: true,
                    type: 'integer',
                    zero: false,
                  },
                  component: renderCustomTextField,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'traff_light_car',
                  label: 'Состав оборудования. Светофор транспортный, шт.',
                  name: 'traff_light_car',
                },
                {
                  addition: {
                    digits: 0,
                    positive: true,
                    type: 'integer',
                    zero: false,
                  },
                  component: renderCustomTextField,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'traff_light_men',
                  label: 'Состав оборудования. Светофор пешеходный, шт.',
                  name: 'traff_light_men',
                },
                {
                  addition: {
                    digits: 0,
                    positive: true,
                    type: 'integer',
                    zero: false,
                  },
                  component: renderCustomTextField,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'section',
                  label: 'Состав оборудования. Секция поворотная, шт.',
                  name: 'section',
                },
                {
                  addition: { type: 'text' },
                  component: renderCustomTextField,
                  editable: editMode,
                  formValue: true,
                  id: 'description',
                  label: 'Примечание',
                  name: 'description',
                  style: fieldFullWidthStyles,
                },
              ],
              column.TWO,
              'Характеристики',
              card.type_id,
            )}
          </div>
          {DocumentsTab()({
            documents,
            editable: editMode,
            typeId: card.type_id,
          })}
        </div>
        <div hidden={true} id="versions">
          <Versions
            card={card}
            disabled={createMode}
            key={`versions-${card.root_id}`}
          />
        </div>
      </div>
    </CardAbstract>
  );
};

TrafficLightCard.propTypes = propTypes;

const dictFields = [
  'odh_side_id',
  'equipment_type_id',
  'mounting_mode_id',
  'placement_id',
];

const numericFields = [
  { name: 'endwise', type: 'decimal', zero: true },
  { name: 'traff_light_car', positive: true, type: 'integer' },
  { name: 'traff_light_men', positive: true, type: 'integer' },
  { name: 'section', positive: true, type: 'integer' },
];

/**
 *
 * @param values
 */
const validate = (values) => validateNumeric(values, numericFields);

const trafficLightCardDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Create attribute from form.
 *
 * @param {*} formValues - Form values.
 * @returns {*}
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

/**
 * Submit handler.
 *
 * @param {*} formValues - Form values.
 * @returns {void}
 */
function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };
  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );
  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, { dictFields, numericFields }),
  );
}

/**
 *
 * @param state
 * @param props
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);

  const result = {
    createMode: isCreateMode(state, props),

    editMode: isEditMode(state, props),

    enableReinitialize: true,
    initialValues: {
      customer_id: parse('customer_id'),
      description: parse('description'),
      endDate: parseDate(props.card.end_date),
      endwise: parse('endwise'),
      equipment_type_id: parse('equipment_type_id.equipment_type'),
      mounting_mode_id: parse('mounting_mode_id.mounting_mode'),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      owner_id: parse('owner_id'),
      parent_name: props.card.parent_info,
      placement_id: parse('placement_id.placement'),
      section: parse('section'),
      startDate: parseDate(props.card.start_date),
      traff_light_car: parse('traff_light_car'),
      traff_light_men: parse('traff_light_men'),
    },
    onSubmit: onSubmit.bind({ props }),
    required: isRequired(state, props),

    validate,
    viewing: isThisViewing(state),
  };

  return trafficLightCardDocumentsReducer(result, state, props);
};

/**
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  /**
   *
   * @param field
   * @param value
   */
  changeFieldValue: (field, value) => {
    dispatch(change('editorCard', field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(TrafficLightCard));
