/* eslint-disable */
import { shallow } from 'enzyme';
import { AdditionalInformationTab } from './AdditionalInformation.Tab';
import { OznTypeField } from 'app/components/card/ogh/ImprovementTerritoryCard/components/OznTypeField';

jest.mock('core/form/reduxForm/fields/default/PhoneField.RF', () => ({
  PhoneFieldRF: (props) => <div data-testid={props.name} {...props} />,
}));

jest.mock('core/form/reduxForm/fields/default/TextFieldRF', () => ({
  TextFieldRF: (props) => <div data-testid={props.name} {...props} />,
}));

jest.mock('app/pages/cardsOgh/hooks/useEditMode', () => ({
  useEditMode: () => ({
    editMode: true,
  }),
}));

jest.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/components/OznTypeField',
  () => ({
    OznTypeField: (props) => <div {...props} />,
  }),
);

 
describe('file AdditionalInformationTab', () => {
  it('snap', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<AdditionalInformationTab />);
    // Assert
    expect(wrapper.html()).toMatchSnapshot();
  });
});
