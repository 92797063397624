import { DistrictListField } from 'app/components/card/ogh/ImprovementTerritoryCard/components/DistrictListField';
import { ImprovementObjectCategoryIdWrapper } from 'app/components/card/ogh/ImprovementTerritoryCard/components/ImprovementCategories/ImprovementObjectCategoryIdWrapper';
import { NameField } from 'app/components/card/ogh/ImprovementTerritoryCard/components/NameField';
import { OkrugListField } from 'app/components/card/ogh/ImprovementTerritoryCard/components/OkrugListField';
import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import getHint from 'app/utils/getHint';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields';
import { CustomerOznRF } from 'core/form/reduxForm/fields/prepared/selects/autocompleteAsync/CustomerOzn.RF';
import { OwnerIdRF } from 'core/form/reduxForm/fields/prepared/selects/autocompleteAsync/OwnerId.RF';
import { ImprovementCategoryIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/ImprovementCategoryId.RF';
import React from 'react';

/**
 * Therr tab.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const OznThreeTab = (props) => (
  <CardContainer>
    <OwnerIdRF
      helpTooltip={getHint('owner_id')}
      disabled={!props.editable || props.isCurrentObjectIsExternalSystem}
      required={true}
    />
    <CustomerOznRF
      helpTooltip={getHint('customer_id_OZN')}
      disabled={!props.editable || props.isCurrentObjectIsExternalSystem}
      required={true}
    />
    <ImprovementCategoryIdRF disabled={true} />
    <ImprovementObjectCategoryIdWrapper {...props} />
    <NameField {...props} />
    <OkrugListField {...props} />
    <DistrictListField {...props} />
    <IsDiffHeightMarkRF
      label={'Разновысотный ОГХ'}
      disabled={!props.editable || props.isCurrentObjectIsExternalSystem}
    />
  </CardContainer>
);
