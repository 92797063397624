import { post } from 'app/api';
/* eslint-disable  */
import { WithMapContext } from 'app/components/map/withMap';
import { ContextDialog } from 'app/pages/cardsOgh/components/DIalogs/Dialogs';
import { ContextMapEdit } from 'app/pages/cardsOgh/components/Map/EditMapContext';
import { message } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/helpers/dialogMessage';
import GeometryCSVLink from 'app/utils/card/checkGeometry/GeometryCSVLink';
import IntersectionsMessage from 'app/utils/card/checkGeometry/IntersectionsMessage';
import toArray from 'app/utils/toArray';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { log } from 'core/utils/log';
import React, { useContext } from 'react';

/**
 *
 * @param data
 */
const postReonIntersection = (data) =>
  post('/ogh/geometry/reon/intersection', data);

/* eslint-disable */
export const useGetReonIntersections = () => {
  const { drawReonGeometry } = useContext(WithMapContext);
  const { showAlert } = useContext(ContextDialog);
  const { setHasReonIntersections } = useContext(ContextMapEdit);

  const [getReonIntersections] = useMutationAdaptor(postReonIntersection, {
    onSuccess: (data) => {
      const intersections = toArray(data);
      if (intersections.length > 0) {
        showAlert(createMessage(intersections, message.reonIntersections));
        const items = intersections.map((item) => createObjectForMap(item));
        drawReonGeometry(items);
        setHasReonIntersections(true);
      } else {
        showAlert(message.success);
      }
    },
    onError: () => {
      log.info('reonintesection не прошел');
    },
  });

  return { getReonIntersections };
};

function createMessage(array, message) {
  const intersections = array.map((item) => formatMessage(item));
  const GeometryCSV = () => (
    <GeometryCSVLink fileName={'ReonGeometry.csv'} objects={array} />
  );

  return (
    <IntersectionsMessage
      GeometryCSVLink={GeometryCSV()}
      intersections={intersections}
      message={message}
      orderedList={true}
    />
  );
}

function formatMessage(data) {
  const descr = data['descr'];
  const prizn_sobstv = data['prizn_sobstv'];
  const prizn_prava = data['prizn_prava'];
  const address = data['address'];
  return `Кадастровый номер: ${descr};
          Признак собственности: ${prizn_sobstv};
          Признак оформленного права: ${prizn_prava};
          Адрес: ${address}`;
}

function createObjectForMap(item) {
  const hintFields = [
    {
      key: 'title',
      rawValue: 'Объект РЕОН',
      value: 'Объект РЕОН',
    },
    {
      key: 'descr',
      rawValue: item.descr,
      value: `Кадастровый номер: ${item.descr}`,
    },
    {
      key: 'prizn_sobstv',
      rawValue: item.prizn_sobstv,
      value: `Признак собственности: ${item.prizn_sobstv}`,
    },
    {
      key: 'prizn_prava',
      rawValue: item.prizn_prava,
      value: `Признак оформленного права: ${item.prizn_prava}`,
    },
    {
      key: 'Адрес',
      rawValue: item.address,
      value: `Адрес: ${item.address}`,
    },
  ];

  const hint = hintFields.filter((item) => item.rawValue);

  return {
    hint: {
      object_attribute_hint: hint,
      object_attribute_hint_short: [
        {
          key: 'name',
          value: item.descr,
        },
      ],
    },
    ...item.geometry,
  };
}
