/* eslint-disable  */

import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import OtherFlatBuildingCard from './other_flat_building';

vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});

vi.mock('react-redux', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    connect: vi.fn(() => (b) => {
      return b;
    }),
  };
});

describe('OtherFlatBuildingSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      card: { bord_begin: 1, flat_element_type: [], type_id: RANDOM_NUMBER },
      card_type: 'OTHER_FLAT_BUILDING',
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,

      mode: {},
    };
    // 🔥 Act

    const wrapper = shallow(<OtherFlatBuildingCard {...props} />);
    // ❓ Assert
    expect(wrapper.text()).toBe('<CardAbstract />');
  });
});
