/* eslint-disable */
export async function onShowChildrenButtonPress(params) {
  const { childrenOnMap, toggleGeometryOfChildren } = params;

  if (childrenOnMap) {
    toggleGeometryOfChildren();
  } else {
    await getChildrenGeometries(params);
  }
}

async function getChildrenGeometries(params) {
  const { children, toggleGeometryOfChildren, showAlert, loadAndDrawChildren } =
    params;

  if (children && children.length > 0) {
    await loadAndDrawChildren();
    toggleGeometryOfChildren();
  } else {
    showAlert('ОГХ не имеет элементов благоустройства.');
  }
}
