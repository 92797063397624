// 26 Места сбора отходов
/* eslint-disable  */

import CardAbstract from 'app/components/card/common/CardAbstract';
import {
  elementFormGrid,
  renderAbutmentList,
  renderAddressList2,
  renderMafMsoList,
} from 'app/components/card/common/grid';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import { Characteristics } from 'app/components/card/ogh/Container/tabs/Characteristics';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import { WithReduxFormCheckbox } from 'app/components/common/CheckboxField';
import { POINT } from 'app/constants/geometryTypes';
import { REQUIRED_ADDRESS_BTI } from 'app/constants/messages';
import { CONTAINER } from 'app/constants/oghTypes';
import getAllowedGeometryTypes from 'app/selectors/card/getAllowedGeometryTypes';
import getCoatingTypes from 'app/selectors/card/getCoatingTypes';
import hasSeparateGarbageCollection from 'app/selectors/card/hasSeparateGarbageCollection';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import getDict from 'app/selectors/getDict';
import { transformValues } from 'app/utils/form/transformation';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields';
import { ContainerTypeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/ContainerTypeId.RF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { TabsCard } from 'core/uiKit/components/TabsCard';
import { TabsCardItem } from 'core/uiKit/components/TabsCard/TabsCardItem';
import { Checkbox } from 'core/uiKit/inputs';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import { get, has, mapValues, pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['container'];
const Versions = VersionsTemplate(types);

const getMafTypeLevel1 = getDict('mafTypeLevel1');
const getMafTypeLevel2 = getDict('mafTypeLevel2');

const propTypes = {
  abutmentTypes: PropTypes.array,
  card: PropTypes.object,
  changeFieldValue: PropTypes.func,
  coatingTypes: PropTypes.array,
  containerTypes: PropTypes.array,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  formValues: PropTypes.object,
  hasSeparateGarbageCollection: PropTypes.bool,
  isPointGeometry: PropTypes.bool,
  mafTypeLevel1: PropTypes.array,
  mafTypeLevel2: PropTypes.array,
  mode: PropTypes.object,
  required: PropTypes.bool,
};

const checkboxStyle = {
  height: 50,
  paddingTop: 0,
};

/**
 * Классовый компонет ContainerCard.
 */
class ContainerCard extends React.Component {
  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   * @example ----
   */
  render() {
    const { props } = this;

    const {
      card,
      changeFieldValue,
      editMode,
      formValues,
      inYard,
      isPointGeometry,
      mode,
      required,
      isCurrentObjectIsExternalSystem,
    } = props;

    const tabsEnd = [
      {
        label: 'Исходные данные',
        value: 'characteristics',
      },
      {
        label: 'Элемент сопряжения',
        show: !editMode,
        value: 'abutments',
      },
      {
        label: 'МАФ',
        value: 'mafs',
      },
      {
        label: 'Документы',
        value: 'documents',
      },
    ];

    const isCreate = !card.root_id;

    const { type_id: typeId } = card;
    const msoType = this.props.containerTypes.find(
      (item) => item.id === formValues.container_type_id,
    );
    const mafListLengthIsZero = !get(formValues, 'maf_type_list.table[0]');
    return (
      <CardAbstract>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(typeId)}
          <div id="props">
            {elementFormGrid(
              [
                <div>
                  <ContainerTypeIdRF
                    required={required}
                    disabled={
                      !(
                        editMode &&
                        isCreate &&
                        !isCurrentObjectIsExternalSystem
                      )
                    }
                  />
                </div>,
                <div>
                  <ParentInfo />
                </div>,

                <div
                  style={{
                    height: 50,
                    margin: '9px 24px',
                    minWidth: 256,
                    paddingTop: 0,
                  }}
                >
                  <IsDiffHeightMarkRF
                    disabled={!mode.editMode || isCurrentObjectIsExternalSystem}
                    justifyContent={'space-between'}
                    labelPlacement={'start'}
                  />
                </div>,
              ],
              2,
              '',
              typeId,
            )}

            {elementFormGrid(
              [
                {
                  component: WithReduxFormCheckbox,
                  editable: editMode,
                  formValue: true,
                  id: 'no_calc',
                  label: 'Не учитывать',
                  name: 'no_calc',
                  style: checkboxStyle,
                },
                <div
                  style={{
                    height: 50,
                    margin: '9px 24px',
                    minWidth: 256,
                    paddingTop: 0,
                  }}
                >
                  <Checkbox
                    disabled={true}
                    id="in_yard"
                    justifyContent={'space-between'}
                    label="Внутридворовое"
                    labelPlacement="start"
                    name="inYard"
                    value={inYard}
                  />
                </div>,
              ],
              2,
              '',
              typeId,
            )}

            {elementFormGrid(
              [
                {
                  addition: {
                    address_required: true,
                    noWrapper: true,
                    onlyOne: true,
                  },
                  component: renderAddressList2,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'address_list',
                  label: null,
                  name: 'address_list',
                },
              ],
              1,
              'Адрес БТИ',
              typeId,
              {
                required,
              },
            )}

            <TabsCard tabs={tabsEnd}>
              <TabsCardItem tabValue={'characteristics'}>
                <Characteristics
                  {...{
                    card,
                    changeFieldValue,
                    editMode,
                    isCurrentObjectIsExternalSystem,
                    isPointGeometry,
                    required,
                    typeId,
                  }}
                />
              </TabsCardItem>
              <TabsCardItem tabValue={'abutments'}>
                {elementFormGrid(
                  [
                    {
                      addition: {
                        abutmentTypes: this.props.abutmentTypes,
                      },
                      component: renderAbutmentList,
                      editable: false,
                      formValue: true,
                      id: 'abutment_type_list',
                      name: 'abutment_type_list',
                      style: {
                        overflow: 'auto',
                      },
                    },
                  ],
                  1,
                  '',
                  typeId,
                )}
              </TabsCardItem>
              <TabsCardItem tabValue={'mafs'}>
                {elementFormGrid(
                  [
                    {
                      addition: {
                        mafListLengthIsZero: mafListLengthIsZero,
                        mafTypeLevel1: this.props.mafTypeLevel1,
                        mafTypeLevel2: this.props.mafTypeLevel2,
                        msoType: msoType,
                      },
                      component: renderMafMsoList,
                      editable: editMode && !isCurrentObjectIsExternalSystem,
                      formValue: true,
                      id: 'maf_type_list',
                      name: 'maf_type_list',
                    },
                  ],
                  1,
                  '',
                  typeId,
                )}
              </TabsCardItem>
              <TabsCardItem tabValue={'documents'}>
                <OtherDocumentsRF
                  disabled={!editMode && isCurrentObjectIsExternalSystem}
                />
              </TabsCardItem>
            </TabsCard>
          </div>
          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={props.createMode}
              key={`versions-${card.root_id}`}
            />
          </div>
        </div>
      </CardAbstract>
    );
  }
}

ContainerCard.propTypes = propTypes;

const getAbutmentTypes = getDict('abutmentTypes');
const getContainerTypes = getDict('containerTypes');

const containerDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const { card } = props;

  const allowedGeometryTypes = getAllowedGeometryTypes(state);
  const isPointGeometry = allowedGeometryTypes.includes(POINT);

  const numericFields = [
    {
      name: 'area',
      positive: !isPointGeometry,
      type: 'decimal',
      zero: isPointGeometry,
    },
  ];

  const parse = getParseAttribute(props);

  const address_list = card.address_list
    ? card.address_list.map(({ attributes, ...item }) => ({
        ...item,
        ...attributes,
      }))
    : null;

  const result = {
    abutmentTypes: getAbutmentTypes(state),
    calcAttributes: state.calcAttributes,
    coatingTypes: getCoatingTypes(state, props),
    containerTypes: getContainerTypes(state),
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),
    enableReinitialize: true,
    formValues: getFormValues(state),
    hasSeparateGarbageCollection: hasSeparateGarbageCollection(state),
    inYard: !!parse('in_yard'),
    initialValues: {
      abutment_type_list: {
        showDialog: false,
        table: parse('abutment_type_list'),
      },
      address_list,
      area: parse('area'),
      coating_group_id: getInitialCoatingGroupId(props, parse),
      coating_type_id: parse('coating_type_id.coating_type'),
      container_type_id: parse('container_type_id.container_type'),
      endDate: parseDate(props.card.end_date),
      hasAddressBti: card.address_list,
      is_diff_height_mark: parse('is_diff_height_mark'),
      maf_type_list: {
        showDialog: false,
        table: parse('maf_type_list'),
      },
      no_calc: parse('no_calc'),
      startDate: parseDate(props.card.start_date),
      totalAreaGeo: parse('total_area_geo'),
    },
    isPointGeometry,
    mafTypeLevel1: getMafTypeLevel1(state),
    mafTypeLevel2: getMafTypeLevel2(state),
    numericFields,
    onSubmit: onSubmit(props, numericFields),
    required: isRequired(state, props),

    /**
     *
     * @param values
     * @param props
     */
    validate: (values, props) => validateNumeric(values, props.numericFields),
  };

  return containerDocumentsReducer(result, state, props);
};

/**
 * Функция getInitialCoatingGroupId.
 *
 * @param {object} props - Пропсы.
 * @param {Function} parse - Функция.
 * @returns {*} - Жопа.
 * @example ----
 */
function getInitialCoatingGroupId(props, parse) {
  const improved = 1; // TODO: use code instead of id and relocate to getDefaultFieldValues.js
  return has(props.card.attribute, 'coating_group_id.coating_group')
    ? parse('coating_group_id.coating_group')
    : improved;
}

// function getInitialCoatingTypeId(props, parse) {
//   const asphaltic = 35; // TODO: use code instead of id and relocate to getDefaultFieldValues.js
//   return has(props.card.attribute, 'coating_type_id.coating_type')
//     ? parse('coating_type_id.coating_type')
//     : asphaltic;
// }

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

const dictFields = [
  'container_type_id',
  'location_type_id',
  'coating_type_id',
  'coating_group_id',
  'abutment_type_id',
  'maf_type_id',
];

const ignoreField = [
  're_calc_trees_shrubs_list',
  're_calc_name',
  're_calc_address_list',
  're_calc_flowers_garden_list',
  're_calc_lawn_list',
];

const mandatoryFields = {
  [CONTAINER]: [
    'container_type_id',
    'coating_type_id',
    'coating_group_id',
    'area',
    'address_list',
  ],
};

/**
 * Функция сабмита.
 *
 * @param {object} props - Значения из формы.
 * @param {Array} numericFields - Цифровые поля.
 * @returns {*} - Жопа.
 * @example ----
 */
const onSubmit = (props, numericFields) => (formValues) => {
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[props.card.type_id],
    () => {
      const errors = {};
      if (formValuesWithAttribute?.address_list?.length < 1) {
        errors.address_list = REQUIRED_ADDRESS_BTI;
      }
      return errors;
    },
  );

  let valuesWithoutFields = {};
  Object.entries(formValuesWithAttribute).forEach(([key, formValue]) => {
    if (formValue !== null && ignoreField.indexOf(key) === -1) {
      valuesWithoutFields[key] = formValue;
    }
  });
  if (get(formValuesWithAttribute, 'maf_type_list.table[0]')) {
    formValuesWithAttribute.maf_type_list =
      formValuesWithAttribute.maf_type_list.table;
  } else {
    delete formValuesWithAttribute.maf_type_list;
  }

  if (get(formValuesWithAttribute, 'abutment_type_list.table[0]')) {
    formValuesWithAttribute.abutment_type_list =
      formValuesWithAttribute.abutment_type_list.table;
  } else {
    delete formValuesWithAttribute.abutment_type_list;
  }

  const transformed = transformValues(formValuesWithAttribute, {
    custom: {
      address_list: {
        /**
         *
         * @param value
         */
        transformer: (value) => {
          return value
            ? value.map((item) => {
                return mapValues(
                  pick(item, [
                    'okrug_id',
                    'address_id',
                    'street_id',
                    'district_id',
                    'description',
                  ]),
                  (id) => {
                    return { bti_object: id };
                  },
                );
              })
            : null;
        },
      },
    },
    dictFields,
    numericFields,
  });

  submitCard(props, transformed);
};

/**
 * Функция получения экшенов.
 *
 * @param {Function} dispatch - Функция диспатч.
 * @returns {{changeFieldValue: mapDispatchToProps.changeFieldValue}} - Экшен changeFieldValue.
 * @example ----
 */
function mapDispatchToProps(dispatch) {
  return {
    /**
     *
     * @param field
     * @param value
     */
    changeFieldValue: (field, value) => {
      dispatch(change('editorCard', field, value));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(ContainerCard));
