import { EquipmentIndex } from 'app/components/card/ogh/ImprovementTerritoryCard/components/EquipmentIndex';
import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields';
import { ReCalcNameRF } from 'core/form/reduxForm/fields/prepared/textField/ReCalcName.RF';
import React from 'react';

/**
 * YardTwoTab.
 *
 * @param params - Parameters.
 * @param params.isCurrentObjectIsExternalSystem - Is current object is external system.
 * @param params.editable - Editable.
 * @returns {JSX}
 */
export const YardTwoTab = ({
  editable,
  isCurrentObjectIsExternalSystem,
}: {
  editable?: boolean;
  isCurrentObjectIsExternalSystem?: boolean;
}) => {
  return (
    <CardContainer>
      {/*// @ts-ignore*/}
      <ReCalcNameRF />
      <IsDiffHeightMarkRF
        label={'Разновысотный ОГХ'}
        disabled={!editable || isCurrentObjectIsExternalSystem}
      />
      {/*// @ts-ignore*/}
      <EquipmentIndex />
    </CardContainer>
  );
};
