import { RANDOM_BOOL, RANDOM_WORD } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { BodyDialogRepairsInfoListPlan } from './BodyDialog.RepairsInfoListPlan';
import { DialogRepairsInfoListPlan } from './Dialog.RepairsInfoListPlan';
import { useSendRepairsInfoListPlan } from './useSendRepairsInfoListPlan';
import { validateRepairsInfoListPlan } from './validate.RepairsInfoListPlan';

vi.mock('./useSendRepairsInfoListPlan');

describe('DialogRepairsInfoListPlanSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const sendSubmit = vi.fn();
    const onChange = vi.fn();
    const closeDialog = vi.fn();
    const isLoading = RANDOM_BOOL;
    const props = {
      closeDialog,
      isOpen: RANDOM_BOOL,
      onChange,
      value: {},
    };
    useSendRepairsInfoListPlan.mockReturnValue({ isLoading, sendSubmit });
    // 🔥 Act

    const wrapper = shallow(<DialogRepairsInfoListPlan {...props} />);
    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      Body: <BodyDialogRepairsInfoListPlan />,
      closeDialog,
      initialValues: {},
      isLoading: RANDOM_BOOL,
      isOpen: RANDOM_BOOL,
      maxWidth: 'sm',
      onSubmit: expect.any(Function),
      textHeader: 'Добавить сведения о проектах',
      validate: validateRepairsInfoListPlan,
    });
    expect(wrapper.text()).toBe('<ContinueFFDialog />');
  });
  it('useSendRepairsInfoListPlan', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const sendSubmit = vi.fn();
    const onChange = vi.fn();
    const closeDialog = vi.fn();
    const isLoading = RANDOM_BOOL;
    const props = {
      closeDialog,
      isOpen: RANDOM_BOOL,
      onChange,
      value: {},
    };
    useSendRepairsInfoListPlan.mockReturnValue({ isLoading, sendSubmit });
    // 🔥 Act

    shallow(<DialogRepairsInfoListPlan {...props} />);
    // ❓ Assert
    expect(useSendRepairsInfoListPlan).toHaveBeenCalledWith({
      closeDialog,
      onChange,
      value: {},
    });
  });
  it('onSubmit', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const sendSubmit = vi.fn();
    const onChange = vi.fn();
    const closeDialog = vi.fn();
    const isLoading = RANDOM_BOOL;
    const props = {
      closeDialog,
      isOpen: RANDOM_BOOL,
      onChange,
      value: {},
    };
    useSendRepairsInfoListPlan.mockReturnValue({ isLoading, sendSubmit });
    // 🔥 Act

    const wrapper = shallow(<DialogRepairsInfoListPlan {...props} />);
    const { onSubmit } = wrapper.props();
    onSubmit({ foo: RANDOM_WORD });
    // ❓ Assert
    expect(sendSubmit).toHaveBeenCalledWith({
      foo: RANDOM_WORD,
    });
  });
});
