import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import PropTypes from 'prop-types';

 
/**
 *
 * @typedef {'planar_structure_type_ref'} PlanarStructureTypeRefName
 */
 
/**
 *
 * @type {PlanarStructureTypeRefName}
 */
export const PLANAR_STRUCTURE_TYPE_REF_NAME =
  'planar_structure_type_ref';

 
/**
 * Селект Уточнение.
 *
 * `type = Select`.
 * `name = planar_structure_type_ref`
 * `name = PLANAR_STRUCTURE_TYPE_REF_NAME`
 * `dict = 'planar_structure_type_ref'`.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - JSX.
 * @example ---
 */
export const PlanarStructureTypeRefId = (props) => {
  const dict = 'planar_structure_type_ref';
  const { data = [] } = useGetDictionary(dict);

  return (
    <Select
      {...props}
      options={data}
      label={props.label || 'Уточнение'}
      name={PLANAR_STRUCTURE_TYPE_REF_NAME}
    />
  );
};

PlanarStructureTypeRefId.propTypes = {
   
/**
 * Очищаем инпут с сохранением значения.
 */
  clear: PropTypes.bool,
   
/**
 * Выбор темной темы.
 */
  dark: PropTypes.bool,
   
/**
 * Заблокировать на редактирование.
 */
  disabled: PropTypes.bool,
   
/**
 * Вывод ошибки.
 */
  errorText: PropTypes.string,
   
/**
 * Вывод вспомогательного текста.
 */
  helperText: PropTypes.string,
   
/**
 * Функция вызова когда выберется итем.
 */
  onChange: PropTypes.func,
   
/**
 * Вывод информации об обязательном поле.
 */
  required: PropTypes.bool,
   
/**
 * Значение.
 */
  value: PropTypes.any,
};
