import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const IMPROVEMENT_OBJECT_CATEGORY_ID = 'improvement_object_category_id';

/**
 * ## Категория озеленения.
 *
 * @param {*} props - The props.
 * @returns {JSX.Element}
 */
export const ImprovementObjectCategoryId = (props) => {
  const { improvementObjectCategory = [] } = useGetCurrentCard();
  return (
    <Select
      {...props}
      options={improvementObjectCategory}
      label={'Категория озеленения'}
      name={IMPROVEMENT_OBJECT_CATEGORY_ID}
    />
  );
};
