import { get } from 'lodash';


/* eslint-disable */
export default function ({ repairs_info_list_plan: attribute }, options = {}) {
  const { mapper } = options;
  let result;
  if (get(attribute, 'table.length')) {
    result = attribute.table.map(({ checked, ...item }) =>
      mapper ? mapper(item) : item,
    );
  } else {
    result = null;
  }
  return {
    repairs_info_list_plan: result,
  };
}
