/* eslint-disable  */
import {
  IMPROVEMENT_OBJECT_CATEGORY_ID,
  ImprovementObjectCategoryId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/ImprovementObjectCategoryId';
import { Field } from 'redux-form';

/**
 *
 * @param root0
 * @param root0.input
 */
const ImprovementObjectCategoryIdRFWithReduxForm = ({ input, ...props }) => {
  return <ImprovementObjectCategoryId {...input} {...props} />;
};

/* eslint-disable */
export const ImprovementObjectCategoryIdRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={IMPROVEMENT_OBJECT_CATEGORY_ID}
      component={ImprovementObjectCategoryIdRFWithReduxForm}
    />
  );
};
