import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid/index';
import { CleanCoverArea } from 'app/components/card/ogh/ImprovementTerritoryCard/components/CleanCoverArea';
import { DataPassport } from 'app/components/card/ogh/ImprovementTerritoryCard/components/MainParamsTab/DataPassport';
import { getHintAutoCleanArea } from 'app/components/card/ogh/ImprovementTerritoryCard/components/MainParamsTab/hints/hint.autoCleanArea';
import { getHintManualCleanArea } from 'app/components/card/ogh/ImprovementTerritoryCard/components/MainParamsTab/hints/hint.manualСleanArea';
import { getHintTotalArea } from 'app/components/card/ogh/ImprovementTerritoryCard/components/MainParamsTab/hints/hint.totalArea';
import { getHintTotalCleanArea } from 'app/components/card/ogh/ImprovementTerritoryCard/components/MainParamsTab/hints/hint.totalCleanArea';
import { OZN, SPA, YARD } from 'app/constants/oghTypes';
import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import PropTypes from 'prop-types';
import React from 'react';

import { getAquaElements } from '../AquaAreaTab';
import { getGreenElements } from '../GreenAreaTab';
import { getSnowElements } from '../SnowAreaTab';

/* eslint-disable  */
export class MainParamsTab extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    typeId: PropTypes.number.isRequired,
    calcAttributes: PropTypes.object,
    editable: PropTypes.bool,
  };

  static defaultProps = {
    id: 'squares',
  };

  getAquaAreaElements() {
    const { editable, isCurrentObjectIsExternalSystem } = this.props;

    return getAquaElements({
      editable: editable && !isCurrentObjectIsExternalSystem,
    });
  }

  getSnowAreaElements() {
    const { editable } = this.props;

    return getSnowElements({ editable });
  }

  getGreenAreaElements() {
    const { calcAttributes = {}, editable } = this.props;

    return getGreenElements({ calcAttributes, editable });
  }

  getElement() {
    const { calcAttributes = {}, typeId } = this.props;
    return [
      {
        id: 'total_area',
        name: 'total_area',
        label: 'Общая площадь объекта, кв.м',
        editable: false,
        formValue: true,
        defaultValue: this.parseDefaultValue(calcAttributes?.total_area),
        component: renderCustomTextField,
        addition: {
          type: 'decimal',
          positive: true,
          zero: false,
          digits: 2,
          helpTitle: getHintTotalArea(typeId),
        },
      },
    ];
  }

  getElements() {
    const { calcAttributes = {}, typeId } = this.props;

    return [
      {
        id: 'manual_clean_area',
        name: 'manual_clean_area',
        label: 'Площадь ручной уборки, кв. м',
        editable: false,
        formValue: true,
        defaultValue: this.parseDefaultValue(calcAttributes.manual_clean_area),
        component: renderCustomTextField,
        addition: {
          type: 'decimal',
          positive: true,
          zero: false,
          digits: 2,
          helpTitle: getHintManualCleanArea(typeId),
        },
      },
      {
        id: 'auto_clean_area',
        name: 'auto_clean_area',
        label: 'Площадь механизированной уборки, кв. м',
        editable: false,
        formValue: true,
        defaultValue: this.parseDefaultValue(calcAttributes.auto_clean_area),
        component: renderCustomTextField,
        addition: {
          type: 'decimal',
          positive: true,
          zero: false,
          digits: 2,
          helpTitle: getHintAutoCleanArea(typeId),
        },
      },
      {
        id: 'total_clean_area',
        name: 'total_clean_area',
        label: 'Общая уборочная площадь, кв.м',
        editable: false,
        formValue: true,
        defaultValue: this.parseDefaultValue(calcAttributes.total_clean_area),
        component: renderCustomTextField,
        addition: {
          type: 'decimal',
          positive: true,
          zero: false,
          digits: 2,
          helpTitle: getHintTotalCleanArea(typeId),
        },
      },
    ];
  }

  parseDefaultValue(value) {
    return value || 0;
  }

  render() {
    const { id, typeId, calcAttributes } = this.props;
    const twoCols = 2;
    const oneCol = 1;
    const title = '';
    const titleCleanArea = 'Уборочная площадь';
    const titleAquaArea = 'Водоемы';
    const titleSnowArea = 'Площадь вывоза снега';
    const titleGreenArea = 'Площадь газонов';

    return (
      <div id={id}>
        {elementFormGrid(this.getElement(), oneCol, title, typeId)}
        {[YARD, OZN].includes(typeId) ? (
          <CardContainer>
            <TotalAreaGeoRF
              disabled={true}
              decimalScale={2}
              fixedDecimalScale
            />
          </CardContainer>
        ) : null}
        {elementFormGrid(this.getElements(), twoCols, titleCleanArea, typeId)}
        <CleanCoverArea typeId={typeId} calcAttributes={calcAttributes} />
        {elementFormGrid(
          this.getGreenAreaElements(),
          twoCols,
          titleGreenArea,
          typeId,
        )}
        <DataPassport />
        {elementFormGrid(
          this.getSnowAreaElements(),
          oneCol,
          titleSnowArea,
          typeId,
        )}
        {typeId === SPA ? (
          <CardContainer title={'Участки, занятые борщевиком'}>
            <NumberFieldRF
              decimalScale={2}
              label={'Участки, занятые борщевиком, кв.м'}
              name={'cow_parsnip_area'}
              disabled={!this.props.editable}
            />
          </CardContainer>
        ) : null}
        {typeId === SPA ? (
          <CardContainer title={'Луга и низины'}>
            <NumberFieldRF
              decimalScale={2}
              label={'Луга и низины, кв.м'}
              name={'meadow_lowland_area'}
              disabled={!this.props.editable}
            />
          </CardContainer>
        ) : null}
        {elementFormGrid(
          this.getAquaAreaElements(),
          oneCol,
          titleAquaArea,
          typeId,
        )}
      </div>
    );
  }
}

export default function (params) {
  return (props) => <MainParamsTab {...props} {...params} />;
}
