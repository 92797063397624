import { get } from 'lodash';


/* eslint-disable */
export default function () {
  return (result, state, props) => {
    const { card } = props;
    return {
      ...result,
      initialValues: {
        ...result.initialValues,
        name_description: get(card, 'attribute.name_description', null),
        re_calc_name: get(card, 'attribute.name', null),
      },
    };
  };
}
