import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useMemo, useState } from 'react';

/**
 * Setup Таблица с диалоговым окном для таблицы __Проекты__.
 *
 * @param {object} params - Параметры.
 * @param {object} params.value - Значения таблицы.
 * @param {Function} params.onChange - Функция изменения.
 * @returns {{isOpen: boolean, closeDialog: Function, selectRow: Function, editMode: boolean, openDialog: Function,  removeRow: Function, dataTableRepairsPlanTab: object}}
 */
export const useSetupRepairsInfoListPlanWithReduxFrom = ({
  value,
  onChange,
}) => {
  const [selectedRow, setSelectedRow] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const { editMode } = useMode();

  /**
   * Закрыть диалоговое окно.
   *
   */
  const closeDialog = () => {
    setOpen(false);
  };

  /**
   * Открыть диалоговое окно.
   */
  const openDialog = () => {
    setOpen(true);
  };

  /**
   * Select.
   *
   * @param {object} props - Property.
   * @param {Array} props.selectedFlatRows - Selected row.
   * @returns {void}
   */
  const selectRow = ({ selectedFlatRows }) => {
    const selectRowId = selectedFlatRows.map(({ original: { id } = {} }) => id);
    setSelectedRow(selectRowId);
  };

  const dataTableRepairsPlanTab = useMemo(() => {
    return (value?.table || []).map((item) => {
      return {
        ...item,
        file_list: (item.file_list || []).map(({ files }) => {
          return files;
        }),
      };
    });
  }, [value]);

  /**
   * Удаление колонки.
   *
   */
  const removeRow = () => {
    onChange({
      ...value,
      table: value.table.filter(({ id }) => !selectedRow.includes(id)),
    });
  };

  const disabledDeleteButton = !(editMode && selectedRow.length);

  return {
    closeDialog,
    dataTableRepairsPlanTab,
    disabledDeleteButton,
    editMode,
    isOpen,
    openDialog,
    removeRow,
    selectRow,
  };
};
