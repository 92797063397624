/* eslint-disable */
import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { elementFormGrid } from 'app/components/card/common/grid';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { useEditorCardSelector } from 'app/selectors/useSelectors/useEditorCard.Selector';
import { useGetCardCalcAttributeSelector } from 'app/selectors/useSelectors/useGetCardCalcAttribute.Selector';
import { shallow } from 'enzyme';
import React from 'react';

import { BasicParameters } from './BasicParameters';

jest.mock('app/selectors/useSelectors/useEditorCard.Selector');
jest.mock('app/api/hooks/useIsCurrentObjectIsExternalSystem');
jest.mock('app/pages/cardsOgh/hooks/useEditMode');
jest.mock('app/selectors/useSelectors/useGetCardCalcAttribute.Selector');
jest.mock('app/components/card/common/grid');

/**
 * Default Mock.
 *
 * @returns {void}
 */
const defaultMock = () => {
  (useEditMode as jest.Mock).mockReturnValue({});
  (useIsCurrentObjectIsExternalSystem as jest.Mock).mockReturnValue({});
  (useEditorCardSelector as jest.Mock).mockReturnValue({});
  (useGetCardCalcAttributeSelector as jest.Mock).mockReturnValue({});
  (elementFormGrid as jest.Mock).mockImplementation((_) => _);
};
describe('spec BasicParameters', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    defaultMock();
    // 🧹 clear mock

    // 🔥 Act
    const wrapper = shallow(<BasicParameters propertyTab={''} />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<TabPanel />');
  });

  it('если есть округления то они все 20', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    defaultMock();

    let digits: number[] = [];
    (elementFormGrid as jest.Mock).mockImplementation((v) => {
      digits = v
        .map(
          (item: { addition?: { digits?: number } }) => item?.addition?.digits,
        )
        .filter(Boolean);
    });

    // 🔥 Act
    shallow(<BasicParameters propertyTab={''} />);

    // ❓ Assert
    expect(digits.every((item) => item === 20)).toBeTruthy();
  });
});
