/* eslint-disable */
import React, { useMemo } from 'react';
import { ImprovementObjectCategoryIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/ImprovementObjectCategoryId.RF/ImprovementObjectCategoryId.RF';
import { OZN, SPA } from 'app/constants/oghTypes';

const mapImprovementObjectCategory = {
  [OZN]: [1, 2, 4],
  [SPA]: [1, 2, 4],
};

 
export const ImprovementObjectCategoryIdWrapper = (props) => {
  const { typeId, editMode, isCurrentObjectIsExternalSystem } = props;

  // const filter = (_) => _;
  const filter = useMemo(() => {
    return (dict) => {
      return (
        dict.filter(({ id }) =>
          mapImprovementObjectCategory[typeId].includes(id),
        ) || []
      );
    };
  }, [typeId]);
  return (
    <ImprovementObjectCategoryIdRF
      filter={filter}
      required={true}
      withAll={false}
      disabled={!editMode || isCurrentObjectIsExternalSystem}
    />
  );
};
