import { Department, DEPARTMENT_NAME } from 'core/uiKit/preparedInputs';
import { Field } from 'redux-form';
/* eslint-disable */

const DepartmentRFWithReduxForm = ({ input, ...props }) => {
  return <Department {...input} {...props} />;
};

export const DepartmentRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={DEPARTMENT_NAME}
      component={DepartmentRFWithReduxForm}
    />
  );
};
