/* eslint-disable */
import { shallow } from 'enzyme';

import { useState } from 'react';

import { RANDOM_ID } from 'core/forTesting/constants';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';

import { DialogApproveElectronicSignature } from './Dialog.ApproveElectronicSignature';
import { ApproveElectronicSignatureButton } from './ApproveElectronicSignature.Button';
import { ApproveElectronicSignature } from './ApproveElectronicSignature';

jest.mock('core/utils/hooks/useGetObjectId');
jest.mock('./Dialog.ApproveElectronicSignature');
jest.mock('./ApproveElectronicSignature.Button');

jest.mock('react', () => ({
  ...jest.requireActual('react'),
  useState: jest.fn(),
}));

/* eslint-disable */
describe('file ApproveElectronicSignature', () => {
  it('simple test', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const spySet = jest.fn();
    useState.mockImplementation((v) => [v, spySet]);
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    // Act
    const wrapper = shallow(<ApproveElectronicSignature />);
    // Assert
    expect(wrapper.text()).toBe('<ApproveElectronicSignatureButton />');
  });
  it('simple test isOpen', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const spySet = jest.fn();
    useState.mockImplementation((v) => [true, spySet]);
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    // Act
    const wrapper = shallow(<ApproveElectronicSignature />);
    // Assert
    expect(wrapper.text()).toBe(
      '<ApproveElectronicSignatureButton /><DialogApproveElectronicSignature />',
    );
  });

  it('props ApproveElectronicSignatureButton', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const spySet = jest.fn();
    useState.mockImplementation((v) => [v, spySet]);
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    // Act
    const wrapper = shallow(<ApproveElectronicSignature />);
    const Button = wrapper.find(ApproveElectronicSignatureButton);
    // Assert

    expect(Button.props()).toStrictEqual({
      objectId: RANDOM_ID,
      onClick: expect.any(Function),
    });

    // Act
    Button.prop('onClick')();
    // Assert
    expect(spySet).toHaveBeenCalledWith(true);
  });

  it('props is Open', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const spySet = jest.fn();
    useState.mockImplementation((v) => [true, spySet]);
    useGetObjectId.mockReturnValue({ objectId: RANDOM_ID });
    // Act
    const wrapper = shallow(<ApproveElectronicSignature />);
    const Button = wrapper.find(ApproveElectronicSignatureButton);
    const Dialog = wrapper.find(DialogApproveElectronicSignature);

    // Assert
    expect(Button.props()).toStrictEqual({
      objectId: RANDOM_ID,
      onClick: expect.any(Function),
    });
    expect(Dialog.props()).toStrictEqual({
      onClose: expect.any(Function),
    });

    // Act
    Button.prop('onClick')();

    // Assert
    expect(spySet).toHaveBeenCalledWith(true);

    // Act
    Dialog.prop('onClose')();

    // Assert
    expect(spySet).toHaveBeenCalledWith(false);
  });
});
