import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { elementFormGrid } from 'app/components/card/common/grid';
import { BarnewJerseyHints } from 'app/components/card/ogh/Odh/components/hints/BarnewJersey.Hints/BarnewJersey.Hints';
import { ODH } from 'app/constants/oghTypes';
import { column } from 'app/constants/tables';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { useEditorCardSelector } from 'app/selectors/useSelectors/useEditorCard.Selector';
import { useGetCardCalcAttributeSelector } from 'app/selectors/useSelectors/useGetCardCalcAttribute.Selector';
import { createNumberedList } from 'app/utils/createNumberedList';
import getHint from 'app/utils/getHint';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import { TabPanel } from 'core/uiKit/components/TabPanel';
import React from 'react';

/**
 * Уборочные данные.
 *
 * @param {object} params - Parameters.
 * @param {string} params.propertyTab - Активная вкладка.
 * @returns {JSX}
 */
export const Cleaning = ({ propertyTab }: { propertyTab: string }) => {
  const { editMode } = useEditMode();
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();
  const { is_orphan_object } = useEditorCardSelector();
  const calcAttributes = useGetCardCalcAttributeSelector();

  const isOrphanObject = Boolean(is_orphan_object);

  const required = false;

  /**
   * Fn.
   *
   * @param {object} obj - Жопа.
   * @returns {null|*} - Жопа.
   * @example ----
   */
  const showIfIsNotOrphanObject = (obj: Record<string, unknown>) =>
    isOrphanObject ? null : obj;

  /**
   * Fn.
   *
   * @param hint - Хинт.
   * @param hintIsOrphanObject - Ф.
   * @returns - Хинт.
   */
  const hintIfIsNotOrphanObject = (
    hint: string,
    hintIsOrphanObject:
      | string
      | Element
      | undefined = 'Заполняется пользователем вручную',
  ) => (isOrphanObject ? hintIsOrphanObject : getHint(hint));

  return (
    <TabPanel show={propertyTab === 'cleaning'}>
      {elementFormGrid(
        [
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject(
                'cleaning_area',
                // @ts-ignore
                createNumberedList({
                  header: 'Сумма значений полей',
                  list: [
                    'Площадь ручной уборки проезжей части (без парковочного пространства), кв.м',
                    'Площадь механизированной уборки проезжей части (без парковочного пространства), кв.м',
                    'Площадь ручной уборки парковочного пространства, кв.м',
                    'Площадь мехнизированной уборки парковочного пространства, кв.м',
                    'Площадь уборки обочин, мех., кв.м',
                    'Площадь уборки обочин, руч., кв.м',
                    'Площадь уборки тротуаров мех., кв.м',
                    'Площадь уборки тротуаров ручн., кв.м',
                    'Площадь убираемых остановок, кв.м',
                  ],
                }),
              ),
              required: isOrphanObject,
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.cleaning_area,
            editable: false,
            formValue: true,
            id: 'cleaning_area',
            label: 'Площадь уборки, кв.м',
            name: 'cleaning_area',
          },

          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('snow_area'),
              required,
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            editable: editMode,
            formValue: true,
            id: 'snow_area',
            label: 'Площадь вывоза снега, кв.м',
            name: 'snow_area',
          },
          showIfIsNotOrphanObject({
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('manual_edit'),
              required,
              zero: true,
            },
            component: NumberFieldRF,
            editable: editMode,
            formValue: true,
            id: 'reagent_area',
            label: 'Площадь обработки реагентами, кв.м',
            name: 'reagent_area',
          }),
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('rotor_area'),
              required,
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            editable: editMode,
            formValue: true,
            id: 'rotor_area',
            label: 'Площадь роторной перекидки, кв.м',
            name: 'rotor_area',
          },
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject(
                'roadway_noprkg_manual_clean_area',
              ),
              required: isOrphanObject,
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.roadway_noprkg_manual_clean_area,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'roadway_noprkg_manual_clean_area',
            label:
              'Площадь ручной уборки проезжей части (без парковочного пространства), кв.м',
            name: 'roadway_noprkg_manual_clean_area',
          },
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject(
                'roadway_noprkg_auto_clean_area',
              ),
              required: isOrphanObject,
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.roadway_noprkg_auto_clean_area,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'roadway_noprkg_auto_clean_area',
            label:
              'Площадь механизированной уборки проезжей части (без парковочного пространства), кв.м',
            name: 'roadway_noprkg_auto_clean_area',
          },
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject(
                'roadway_prkg_manual_clean_area',
              ),
              required: isOrphanObject,
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.roadway_prkg_manual_clean_area,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'roadway_prkg_manual_clean_area',
            label: 'Площадь ручной уборки парковочного пространства, кв.м',
            name: 'roadway_prkg_manual_clean_area',
          },
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject(
                'roadway_prkg_auto_clean_area',
              ),
              required: isOrphanObject,
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.roadway_prkg_auto_clean_area,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'roadway_prkg_auto_clean_area',
            label:
              'Площадь механизированной уборки парковочного пространства, кв.м',
            name: 'roadway_prkg_auto_clean_area',
          },
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('auto_footway_area'),
              required: isOrphanObject,
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.auto_footway_area,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'auto_footway_area',
            label: 'Площадь уборки тротуаров мех., кв.м',
            name: 'auto_footway_area',
          },
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('manual_footway_area'),
              required: isOrphanObject,
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.manual_footway_area,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'manual_footway_area',
            label: 'Площадь уборки тротуаров ручн., кв.м',
            name: 'manual_footway_area',
          },

          {
            addition: {
              helpTitle: hintIfIsNotOrphanObject('station_number'),
              required: isOrphanObject,
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.station_number,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'station_number',
            label: 'Количество убираемых остановок, ед.',
            name: 'station_number',
          },
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('station_clean_area'),
              required: isOrphanObject,
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.station_clean_area,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'station_clean_area',
            label: 'Площадь убираемых остановок, кв.м',
            name: 'station_clean_area',
          },
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('margin_auto_clean_area'),
              positive: true,
              required: isOrphanObject,
              type: 'decimal',
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.margin_auto_clean_area,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'margin_auto_clean_area',
            label: 'Площадь уборки обочин, мех., кв.м',
            name: 'margin_auto_clean_area',
          },
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('margin_manual_clean_area'),
              positive: true,
              required: isOrphanObject,
              type: 'decimal',
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.margin_manual_clean_area,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'margin_manual_clean_area',
            label: 'Площадь уборки обочин, руч., кв.м',
            name: 'margin_manual_clean_area',
          },
          {
            addition: {
              digits: 20,
              helpTitle: <BarnewJerseyHints />,
              required: isOrphanObject,
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.bar_new_jersey,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'bar_new_jersey',
            label: 'Стенка Нью-Джерси, п.м',
            name: 'bar_new_jersey',
          },
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('bar_wave'),
              required: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.bar_wave,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'bar_wave',
            label: 'Металлические барьерные ограждения Волна, п.м',
            name: 'bar_wave',
          },
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('bar_trans'),
              required: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.bar_trans,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'bar_trans',
            label: 'Металлические барьерные ограждения Трансэкострой, п.м',
            name: 'bar_trans',
          },
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('bar_pipe'),
              required: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.bar_pipe,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'bar_pipe',
            label: 'Металлические барьерные ограждения Труба, п.м',
            name: 'bar_pipe',
          },
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('bar_foot'),
              required: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.bar_foot,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'bar_foot',
            label: 'Пешеходные ограждения, п.м',
            name: 'bar_foot',
          },
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('footway_pole'),
              required: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.footway_pole,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'footway_pole',
            label: 'Тротуарные столбики, п.м',
            name: 'footway_pole',
          },
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('bar_antinoise'),
              required: isOrphanObject,
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.bar_antinoise,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'bar_antinoise',
            label: 'Защитная стенка, п.м',
            name: 'bar_antinoise',
          },
          {
            addition: {
              helpTitle: hintIfIsNotOrphanObject('asperity'),
              required: isOrphanObject,
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.asperity,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'asperity',
            label: 'ИДН, ед.',
            name: 'asperity',
          },

          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('bar_fracasso'),
              required: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.bar_fracasso,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'bar_fracasso',
            label: 'Металлическое барьерное ограждение Фракассо, п.м',
            name: 'bar_fracasso',
          },
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('bar_concrete'),
              required: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.bar_concrete,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'bar_concrete',
            label: 'Бетонный парапет, п.м',
            name: 'bar_concrete',
          },
          {
            addition: {
              helpTitle: hintIfIsNotOrphanObject('buffer'),
              required: isOrphanObject,
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.buffer,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'buffer',
            label: 'Буфер безопасности, ед.',
            name: 'buffer',
          },
        ],
        column.TWO,
        '',
        ODH,
        {},
      )}
    </TabPanel>
  );
};
