import { OwnerIdPlace } from 'core/uiKit/preparedInputs/selects/autocompleteAsync/OwnerIdPlace';

/**
 * Обертка РФ для балансодержателя.
 *
 * @param {object} params - Пропсы.
 * @param {object} params.input - Значения из формы РФ.
 * @returns {JSX.Element}
 */
export const OwnerIdPlaceWithReduxForm = ({ input, ...props }) => {
  return <OwnerIdPlace {...input} {...props} />;
};
