import { OznTypeField } from 'app/components/card/ogh/ImprovementTerritoryCard/components/OznTypeField';
import { FieldsContainer } from 'app/pages/cardsOgh/components/FieldsContainer';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { PhoneFieldRF } from 'core/form/reduxForm/fields/default/PhoneField.RF';
import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import React from 'react';

/**
 * Таб Дополнительная информация.
 *
 * @returns {JSX.Element} - Возвращает таб с полями.
 * @example  <AdditionalInformationTab />
 */
export const AdditionalInformationTab = () => {
  const { editMode } = useEditMode();
  return (
    <div hidden={true} id={'additional-information'} className={'p-3 mb-3'}>
      <FieldsContainer>
        <OznTypeField disabled={!editMode} />
        <TextFieldRF
          label={'Описание месторасположения'}
          name={'place_descr'}
          disabled={!editMode}
        />
        <TextFieldRF
          label={'ИНН балансодержателя'}
          name={'owner_inn'}
          disabled
        />
        <TextFieldRF
          label={'КПП балансодержателя'}
          name={'owner_kpp'}
          disabled
        />
        <TextFieldRF
          label={'ОГРН балансодержателя'}
          name={'owner_ogrn'}
          disabled
        />
        <PhoneFieldRF
          label={'Телефон балансодержателя'}
          name={'owner_phone'}
          disabled={!editMode}
        />
        <TextFieldRF
          label={'Сайт балансодержателя'}
          name={'owner_link'}
          disabled={!editMode}
        />
        <TextFieldRF
          label={'Парк по месту жительства'}
          name={'park_descr'}
          disabled={!editMode}
        />
        <TextFieldRF
          label={'Сайт парковой территории'}
          name={'park_link'}
          disabled={!editMode}
        />
        <PhoneFieldRF
          label={'Телефон парковой территории'}
          name={'park_phone'}
          disabled={!editMode}
        />
        <TextFieldRF
          label={'Полное наименование эксплуатирующей организации'}
          name={'oper_fullname'}
          disabled={!editMode}
        />
        <PhoneFieldRF
          label={'Телефон эксплуатирующей организации'}
          name={'oper_phone'}
          disabled={!editMode}
        />
        <TextFieldRF
          label={'Сайт эксплуатирующей организации'}
          name={'oper_link'}
          disabled={!editMode}
        />
        <TextFieldRF
          label={'График работы'}
          name={'schedule'}
          disabled={!editMode}
        />
        <TextFieldRF
          label={'День недели'}
          name={'schedule_days'}
          disabled={!editMode}
        />
        <TextFieldRF
          label={'Часы работы'}
          name={'schedule_hours'}
          disabled={!editMode}
        />
        <TextFieldRF
          label={'Уточнение графика работы'}
          name={'schedule_comm'}
          disabled={!editMode}
        />
        <TextFieldRF
          label={'Способ геопривязки парковой территории'}
          name={'park_geo'}
          disabled={!editMode}
        />
        <TextFieldRF
          label={'Дополнительная информация о входе на территорию'}
          disabled={!editMode}
          name={'add_inform'}
        />
      </FieldsContainer>
    </div>
  );
};
