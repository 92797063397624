import * as env from 'config/env';
import qs from 'query-string';

import { getUrlWithParams } from './getUrlWithParams';
import { handleErrors } from './handleErrors';
import { toJson } from './middlewares';
import { turn_into_snake_case } from './turn_into_snake_case';

/**
 * Метод-запрос Post.
 *
 * @param {string} methodUrl - Метод запроса  он же url.
 * @param {object} queryParams - Параметры.
 * @param {object} [options] - Дополнительные параметры.
 * @param {string} [options.host] - Хост (начальный урл).
 * @param {string} [options.credentials] - Опция credentials.
 * @param {boolean} [options.parseResponse] - Флаг, что нужно перевести ответ Json.
 * @param {boolean} [options.isFile] - Флаг, что это файл.
 * @param {object} [options.headers] - Дополнительные параметры для хедера.
 * @param {boolean} [options.convertIntoSnakeCase] - Флаг нужно ли конвертировать в CamelCase.
 * @param {boolean} [options.jopa] - Флаг костыль для авторизации на арм - Его удалить по возможности используется в авторизации.
 * @returns {Promise<*|Response>}
 */
export const post = async (methodUrl, queryParams, options = {}) => {
  const {
    host = env.ODS_API_URL,
    credentials = 'include',
    parseResponse = true,
    headers,
    convertIntoSnakeCase = true,
    isFile = false,
    jopa,
    ...fetchOptions
  } = options;

  let fetchBody;
  let contentType;
  const bodyWithSnakeCase = convertIntoSnakeCase
    ? turn_into_snake_case(queryParams)
    : queryParams;

  if (jopa) {
    fetchBody = qs.stringify(bodyWithSnakeCase);
    contentType = 'application/x-www-form-urlencoded';
  } else if (!isFile && typeof queryParams === 'object') {
    fetchBody = JSON.stringify(bodyWithSnakeCase);
    contentType = 'application/json; charset=utf-8';
  } else {
    fetchBody = queryParams;
    contentType = null;
  }

  const queryOptions = {
    body: fetchBody,
    credentials,
    headers: {
      ...(contentType ? { 'Content-Type': contentType } : {}),
      ...headers,
    },
    method: 'POST',
    ...fetchOptions,
  };

  const url = getUrlWithParams(host, methodUrl);
  const resp = await fetch(url, queryOptions);

  await handleErrors(resp);

  return parseResponse ? toJson(resp) : resp;
};
