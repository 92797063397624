import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid/index';


/* eslint-disable */
export default function () {
  const cols = 2;
  const title = '';
  const elements = getElements();
  return (props) =>
    elementFormGrid(elements(props), cols, title, props.card.type_id);
}

function getElements() {
  return (props) => [
    {
      ...defaultNameParams({
        ...props,
      }),
    },
    {
      ...defaultNameDescriptionParams({
        ...props,
      }),
    },
  ];
}

function defaultNameParams() {
  return {
    id: 're_calc_name',
    name: 're_calc_name',
    label: 'Наименование',
    formValue: true,
    component: renderCustomTextField,
    addition: {
      type: 'text',
    },
  };
}

function defaultNameDescriptionParams({ editable, required }) {
  return {
    id: 'name_description',
    name: 'name_description',
    label: 'Уточнение наименования',
    formValue: true,
    component: renderCustomTextField,
    editable,
    addition: {
      type: 'text',
      required: editable && required,
    },
  };
}
