/* eslint-disable  */
import { shallow } from 'enzyme';

import Odh from './odh';

jest.mock('dateformat', () => ({
  DateFormat: jest.fn(),
}));

jest.mock('redux-form', () => ({
  ...jest.requireActual('redux-form'),
  reduxForm: jest.fn(() => (Component) => {
    return Component;
  }),
}));

jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  connect: jest.fn(() => (b) => {
    return b;
  }),
}));

describe('spec odh', () => {
  it('default', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const wrapper = shallow(<Odh />);

    // Assert
    expect(wrapper.text()).toBe('<OdhCard />');
  });

  it('should', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    // 🧹 clear mock

    // 🔥 Act
    const wrapper = shallow(<Odh />);

    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      onPropertyTab: expect.any(Function),
      propertyTab: 'characteristics',
    });
  });
});
