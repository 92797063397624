import CardAbstract from 'app/components/card/common/CardAbstract';
/* eslint-disable */
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import printOghCardHeader from 'app/components/card/ogh/header/oghHeader';
import Properties from 'app/components/card/ogh/OtherOksCard/components/Properties';
import submitCard from 'app/components/card/ogh/submit';
import VersionsTemplate from 'app/components/card/ogh/versions/VersionsTemplate';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import { getInitialBuildingsTypeId } from 'app/utils/card/getInitialBuildingsTypeId';
import { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { COATING_GROUP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingGroupId';
import { COATING_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingTypeId';
import { mapValues, pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

const types = ['other_oks'];
const Versions = VersionsTemplate(types);

const propTypes = {
  card: PropTypes.object.isRequired,
  createMode: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  mode: PropTypes.object.isRequired,
  required: PropTypes.bool.isRequired,
};

/**
 * Карточка __Иной объект капитального строительства__.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.card - Объект паспорта.
 * @param {boolean} props.mode - Ж 💆.
 * @param {*} props.createMode - Ж 💯.
 * @param {boolean} props.editMode - Флаг редактирования.
 * @param {boolean} props.required - Обязательность поля.
 * @param {*} props.isCurrentObjectIsExternalSystem - Ж 💯.
 * @returns {JSX.Element} - Реакт компонент.
 * @example ----------
 */
function OtherOksCard({
  card,
  mode,
  createMode,
  editMode,
  required,
  isCurrentObjectIsExternalSystem,
}) {
  const { type_id: typeId } = card;

  return (
    <CardAbstract>
      <div id="editor-card">
        {printOghCardHeader(card, mode)}
        {card && objectTabs(typeId)}
        <div id="props">
          <Properties
            editMode={editMode}
            required={required}
            typeId={typeId}
            isCurrentObjectIsExternalSystem={isCurrentObjectIsExternalSystem}
          />
        </div>
        <div className="d-none" id="versions">
          <Versions
            card={card}
            disabled={createMode}
            key={`versions-${card.root_id}`}
          />
        </div>
      </div>
    </CardAbstract>
  );
}

OtherOksCard.propTypes = propTypes;

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const { card } = props;
  const { address_list: addresses, start_date, end_date } = card;

  const parse = getParseAttribute(props);

  const address_list = addresses
    ? addresses.map(({ attributes, ...item }) => ({
        ...item,
        ...attributes,
      }))
    : null;

  return {
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),
    enableReinitialize: true,

    initialValues: {
      address_list,
      area: parse('area'),
      buildings_type_id: getInitialBuildingsTypeId(props, parse),
      buildings_type_spec_id: parse(
        'buildings_type_spec_id.buildings_type_spec',
      ),
      coating_face_type_id: parse('coating_face_type_id.coating_type'),
      coating_group_id: parse('coating_group_id.coating_group'),
      coating_type_id: parse('coating_type_id.coating_type'),
      endDate: parseDate(end_date),
      face_area: parse('face_area'),
      is_diff_height_mark: parse('is_diff_height_mark'),
      no_calc: parse('no_calc'),
      property: parse('property'),
      startDate: parseDate(start_date),
      totalAreaGeo: parse('total_area_geo'),
    },

    onSubmit: onSubmit.bind({ props }),
    required: isRequired(state, props),
    validate,
  };
};

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object} - Отвалидированные поля.
 * @example ----
 */
function validate(values) {
  return validateNumeric(values, numericFields);
}

const numericFields = [
  {
    name: 'area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  { name: 'face_area', positive: true, type: 'decimal', zero: true },
];

const dictFields = [
  'buildings_type_id',
  'buildings_type_spec_id',
  COATING_GROUP_ID_NAME,
  COATING_TYPE_ID_NAME,
];

/**
 * Функция сабмита.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {void} - Ж.
 * @example ----
 */
function onSubmit(formValues) {
  const { props } = this;
  const {
    card: { type_id: typeId },
  } = props;

  validateRequiredFieldsAsync(formValues, mandatoryFields[typeId]);

  submitCard(
    props,
    transformValues(formValues, {
      custom: {
        address_list: {
          /**
           *
           * @param value
           */
          transformer: (value) => {
            return value == null || value.length === 0
              ? null
              : value.map((item) => {
                  return mapValues(
                    pick(item, [
                      'okrug_id',
                      'address_id',
                      'street_id',
                      'district_id',
                      'description',
                    ]),
                    (id) => ({ bti_object: id }),
                  );
                });
          },
        },
      },
      dictFields,
      numericFields,
    }),
  );
}

export default connect(mapStateToProps)(
  reduxForm({ form: 'editorCard' })(OtherOksCard),
);
