import { FieldsContainer } from 'app/pages/cardsOgh/components/FieldsContainer';
import { OghCardHeaderFF } from 'app/pages/cardsOgh/components/headerFF/OghHeaderFF';
import { OtherDocumentsFf } from 'app/pages/cardsOgh/components/tables/Documents/FieldsFF/preparedFields/OtherDocuments.FF';
import Versions from 'app/pages/cardsOgh/components/tables/Versions/Versions';
import { TabsCard } from 'app/pages/cardsOgh/components/TabsCard';
import { TabsCardItem } from 'app/pages/cardsOgh/components/TabsCard/TabsCardItem';
import { ReagentIdFFTank } from 'app/pages/cardsOgh/Tank/components/ReagentIdFF.Tank';
import { ReagentKindIdFFTank } from 'app/pages/cardsOgh/Tank/components/ReagentKindIdFF.Tank';
import { TonnageFFTank } from 'app/pages/cardsOgh/Tank/components/TonnageFF.Tank';
import {
  NumberFieldFF,
  TankNumFF,
  TextFieldFF,
} from 'core/form/finalForm/fields';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import React from 'react';

import { useMode } from '../hooks/useMode';

const tabsTopHeader = [
  { label: 'Свойства', value: 'features' },
  { label: 'Версии', value: 'versions' },
];

const tabsEnd = [
  { label: 'Исходные данные', value: 'initialData' },
  { label: 'Документы', value: 'documents' },
];

/**
 * TankLeftPanel компонент.
 *
 * @returns {JSX.Element} - JSX.
 * @example <TankLeftPanel />
 */
export const TankLeftPanel = () => {
  const { editMode } = useMode();

  return (
    <>
      <OghCardHeaderFF />
      <TabsCard tabs={tabsTopHeader}>
        <TabsCardItem tabValue={'features'}>
          <FieldsContainer>
            <ReagentKindIdFFTank />
            <ReagentIdFFTank />
            <ParentInfo />
          </FieldsContainer>

          <FieldsContainer>
            <TextFieldFF
              label={'Описание емкости'}
              name={'descr'}
              disabled={!editMode}
            />
          </FieldsContainer>

          <TabsCard tabs={tabsEnd}>
            <TabsCardItem tabValue={'initialData'}>
              <FieldsContainer>
                <TankNumFF required={true} disabled={!editMode} />
                <NumberFieldFF
                  name={'quantity'}
                  label={'Объем, куб.м.'}
                  maxLength={10}
                  decimalScale={1}
                  required={true}
                  disabled={!editMode}
                />
                <NumberFieldFF
                  name={'minimumBalancePercent'}
                  label={'Неснижаемый остаток, %'}
                  maxLength={3}
                  defaultValue={5}
                  required={true}
                  disabled={!editMode}
                />
                <TonnageFFTank />
              </FieldsContainer>
            </TabsCardItem>
            <TabsCardItem tabValue={'documents'}>
              <OtherDocumentsFf />
            </TabsCardItem>
          </TabsCard>
        </TabsCardItem>

        <TabsCardItem tabValue={'versions'}>
          <FieldsContainer>
            <Versions />
          </FieldsContainer>
        </TabsCardItem>
      </TabsCard>
    </>
  );
};
