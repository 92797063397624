import './FieldsContainer.scss';

import React, { FC, ReactNode } from 'react';
interface FieldsContainerProps {
  className?: string;
  children: ReactNode;
}

/**
 * Контейнер для инпутов в два столбца.
 *
 * @param param0 - Params.
 * @param param0.className - Class css.
 * @param param0.children - Children.
 * @returns - Контейнер для инпутов.
 */
export const FieldsContainer: FC<FieldsContainerProps> = ({
  className,
  children,
}) => {
  return (
    <div className={'field-card-ogh-new__container ' + className}>
      {children}
    </div>
  );
};
