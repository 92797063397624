import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { SelectFF } from 'core/form/finalForm/fields/default/selects/SelectFF';
import { usePreparationMaterialId } from 'core/form/finalForm/fields/prepared/selects/simple/MaterialId.FF/usePreparationMaterialId.FF';
import React from 'react';

export const MATERIAL_ID_NAME = 'material_id';

/**
 * Select FF Материал.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const MaterialIdFF = (props) => {
  usePreparationMaterialId();
  const dict = 'material';

  const { data = [] } = useGetDictionary(dict);

  return (
    <SelectFF
      {...props}
      label={'Материал'}
      name={MATERIAL_ID_NAME}
      options={data}
    />
  );
};
