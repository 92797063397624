import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { FieldsContainer } from 'app/pages/cardsOgh/components/FieldsContainer';
import { getNameFile } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getNameFile';
import React from 'react';
import { Field } from 'redux-form';

import { DocumentWithReduxForm } from '../DocumentWithReduxForm';

const ID = 12;
const NAME = getNameFile(ID);
const TITLE = 'Планировочное решение объекта благоустройства';

 
/**
 * Таблица документов __Планировочное решение объекта благоустройства__.
 *
 * @returns {JSX.Element} - JSX.
 * @example
 * <SolutionObjectRf />
 */
export const SolutionObjectRF = () => {
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();

  return (
    <FieldsContainer>
      <Field
        title={TITLE}
        id={ID}
        name={NAME}
        disabled={isCurrentObjectIsExternalSystem}
        component={DocumentWithReduxForm}
      />
    </FieldsContainer>
  );
};
