import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { FieldsContainer } from 'app/pages/cardsOgh/components/FieldsContainer';
import { OghCardHeaderFF } from 'app/pages/cardsOgh/components/headerFF/OghHeaderFF';
import { OtherDocumentsFf } from 'app/pages/cardsOgh/components/tables/Documents/FieldsFF/preparedFields/OtherDocuments.FF';
import { Versions } from 'app/pages/cardsOgh/components/tables/Versions';
import { TabsCard } from 'app/pages/cardsOgh/components/TabsCard';
import { TabsCardItem } from 'app/pages/cardsOgh/components/TabsCard/TabsCardItem';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { IsDiffHeightMarkFf } from 'core/form/finalForm/fields/prepared/checkboxes/IsDiffHeightMark.FF';
import { NoCalcFF } from 'core/form/finalForm/fields/prepared/checkboxes/NoCalc.FF';
import { DistanceFF } from 'core/form/finalForm/fields/prepared/numberField/Distance.FF/Distance.FF';
import { AbutmentTypeNameFF } from 'core/form/finalForm/fields/prepared/selects/simple/AbutmentTypeName.FF';
import { MaterialIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/MaterialId.FF/MaterialId.FF';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import React from 'react';

import { ElementOwnershipTable } from './tables/ElementOwnership.Table/ElementOwnership.Table';

/**
 * Электронная форма паспорта __Элементы сопряжения поверхностей__.
 *
 * @returns {JSX.Element}
 */
export const LeftPanelAbutment = () => {
  const { rootId = 0 } = useGetCurrentCard();
  const { editMode } = useEditMode();

  const tabsTopHeader = [
    { label: 'Свойства', value: 'features' },
    { label: 'Версии', show: Boolean(rootId), value: 'versions' },
  ];

  const tabsEnd = [
    { label: 'Исходные данные', value: 'initialData' },
    { label: 'Принадлежность элемента', value: 'elementOwnership' },
    { label: 'Документы', value: 'documents' },
  ];

  return (
    <>
      <OghCardHeaderFF />
      <TabsCard tabs={tabsTopHeader}>
        <TabsCardItem tabValue={'features'}>
          <FieldsContainer>
            <AbutmentTypeNameFF disabled={!editMode} withAll={false} />
          </FieldsContainer>
          <FieldsContainer>
            <ParentInfo />
          </FieldsContainer>
          <TabsCard tabs={tabsEnd}>
            <TabsCardItem tabValue={'initialData'}>
              <FieldsContainer>
                <DistanceFF disabled={true} decimalScale={2} />
                <MaterialIdFF withAll={false} disabled={!editMode} />
                <NoCalcFF disabled={!editMode} />
                <IsDiffHeightMarkFf disabled={!editMode} />
              </FieldsContainer>
            </TabsCardItem>
            <TabsCardItem tabValue={'elementOwnership'}>
              <ElementOwnershipTable />
            </TabsCardItem>
            <TabsCardItem tabValue={'documents'}>
              <OtherDocumentsFf />
            </TabsCardItem>
          </TabsCard>
        </TabsCardItem>
        <TabsCardItem tabValue={'versions'}>
          <FieldsContainer>
            <Versions />
          </FieldsContainer>
        </TabsCardItem>
      </TabsCard>
    </>
  );
};
