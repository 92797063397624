import { useFetchOghDispatch } from 'app/actions/odsObjectActions/useFetchOgh.Dispatch';
/* eslint-disable */
import { toCurrentDateTime } from 'app/utils/date/toCurrentDateTime';
import { toast } from 'core/uiKit/components/Toast';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

import { signCloseElectronicSignature } from './signCloseElectronicSignature';

/**
 * Хук получения функции sendSign.
 *
 * @param {object} params - Параметры.
 * @param {number} params.objectId - Id Объекта.
 * @param {Function} params.onErrorCallback - Callback ошибки.
 * @param {Function} params.onSuccessCallback - Callback успеха.
 * @param {string} params.signType - Тип.
 * @param {object} params.objectCardFiles - Объект файлов.
 * @returns {{isLoadingSignOghSave: {boolean}, sendSign: {Function}}}
 */
export const useSignCloseElectronicSignature = ({
  objectCardFiles,
  objectId,
  onErrorCallback,
  onSuccessCallback,
  signType,
}) => {
  const fetchOgh = useFetchOghDispatch(objectId);

  const [sendSign, { isLoading: isLoadingSignOghSave }] = useMutationAdaptor(
    signCloseElectronicSignature({
      date: toCurrentDateTime(),
      objectCardFiles,
      objectId,
      signType,
    }),
    {
      /**
       *
       * @param value
       */
      onError: (value) => {
        toast.error(value?.message);
        onErrorCallback();
      },

      /**
       *
       */
      onSuccess: () => {
        toast.success('Подписано');
        fetchOgh();
        onSuccessCallback();
      },
    },
  );
  return { isLoadingSignOghSave, sendSign };
};
