import { useGetFilesForSignature } from 'app/api/hooks/signs/useGetFilesForSignature';
/* eslint-disable */
import { ContinueFFDialog } from 'core/form/finalForm/components/dialogs/ContinueFF.Dialog';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { shallow } from 'enzyme';

import { DialogSignatureFiles } from './Dialog.SignatureFiles';
import { useSignCloseElectronicSignature } from './useSignClose.ElectronicSignature';

jest.mock('core/utils/hooks/useGetObjectId');
jest.mock('app/api/hooks/signs/useGetFilesForSignature');
jest.mock('./useSignClose.ElectronicSignature');
jest.mock('./Form.Dialog.SignatureFiles', () => ({
  /**
   *
   */
  FormDialogSignatureFiles: () => <div>form</div>,
}));

describe('DialogSignatureFiles', () => {
  const mockOnClose = jest.fn();

  it('renders a ContinueFFDialog component', () => {
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({});
    useGetFilesForSignature.mockReturnValue({});
    useSignCloseElectronicSignature.mockReturnValue({});
    // Act
    const wrapper = shallow(
      <DialogSignatureFiles isOpen={false} onClose={mockOnClose} />,
    );

    // Assert

    expect(wrapper.find(ContinueFFDialog)).toHaveLength(1);
  });

  it('passes the correct props to the ContinueFFDialog component', () => {
    // Arrange (всякие моки)
    useGetObjectId.mockReturnValue({});
    useGetFilesForSignature.mockReturnValue({});
    useSignCloseElectronicSignature.mockReturnValue({});

    const mockOnClose = jest.fn();
    // Act
    const wrapper = shallow(
      <DialogSignatureFiles isOpen={false} onClose={mockOnClose} />,
    );

    const dialog = wrapper.find(ContinueFFDialog);
    expect(dialog.prop(' closeDialog')).toBe();
    expect(wrapper.prop('initialValues')).toEqual({
      date: expect.any(Date),
      sendReason: [],
    });
    expect(wrapper.prop('isLoading')).toBeUndefined();
    expect(wrapper.prop('isOpen')).toBeFalsy();
    expect(wrapper.prop('onSubmit')).toBeUndefined();
    expect(wrapper.prop('textHeader')).toEqual('Форма подписания файлов');
    expect(wrapper.prop('validate')).toEqual(expect.any(Function));
  });
});
