/* eslint-disable */
import React, { useContext } from 'react';
import toggleContent from 'app/components/card/common/MapEditPanel/content/toggle';
import ToggleButton from 'app/components/card/common/MapEditPanel/buttons/ToggleButton';
import { WithMapContext } from 'app/components/map/withMap';
import { REON } from 'app/constants/layers';
import { useGetReonIntersections } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/ReonButton/hooks/useGetReonIntersections';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { ContextMapEdit } from 'app/pages/cardsOgh/components/Map/EditMapContext';

 
export const ReonButton = () => {
  const { clearLayer, getGeometry, isMapLoaded } = useContext(WithMapContext);
  const { hasReonIntersections, setHasReonIntersections } =
    useContext(ContextMapEdit);
  const { recordId } = useGetCurrentCard();
  const { getReonIntersections } = useGetReonIntersections();

  const checkGeometryReonByButton = () => {
    if (hasReonIntersections) {
      clearLayer(REON);
      setHasReonIntersections(false);
    } else {
      const data = {
        geometry: getGeometry(recordId),
      };
      getReonIntersections(data);
    }
  };

  return (
    <ToggleButton
      disabled={!isMapLoaded}
      active={hasReonIntersections}
      content={toggleContent.reon}
      onClick={checkGeometryReonByButton}
    />
  );
};
