/* eslint-disable */
import React from 'react';

import { DEPARTMENT_NAME, DepartmentFF } from 'core/form/finalForm/fields';

import { filterInput } from '../filterInputsClasses';
import { NumberParam } from 'use-query-params';

 
export { DEPARTMENT_NAME };

 
/* eslint-disable */
/**
 * Отраслевой ОИВ
 * department
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
 
export const DepartmentFilter = (props) => {
  return <DepartmentFF {...props} className={filterInput} />;
};
 
export const departmentParams = {
  [DEPARTMENT_NAME]: NumberParam,
};
