import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { FieldsContainer } from 'app/pages/cardsOgh/components/FieldsContainer';
import { getNameFile } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getNameFile';
import React from 'react';
import { Field } from 'redux-form';

import { DocumentWithReduxForm } from './DocumentWithReduxForm';

const ID = 18;
const NAME = getNameFile(ID);
const TITLE = 'План-схема объекта М 1:500';

/**
 * Таблица документов __План-схема объекта М 1:500__.
 *
 * @returns {JSX.Element} - JSX.
 * @example
 * <ObjectDiagramRF />
 */
export const ObjectDiagramRF = () => {
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();

  return (
    <FieldsContainer>
      <Field
        title={TITLE}
        id={ID}
        name={NAME}
        disabled={isCurrentObjectIsExternalSystem}
        component={DocumentWithReduxForm}
      />
    </FieldsContainer>
  );
};
