import { FieldsContainer } from 'app/pages/cardsOgh/components/FieldsContainer';
import { getNameFile } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getNameFile';
import React from 'react';
import { Field } from 'redux-form';

import { DocumentWithReduxForm } from '../DocumentWithReduxForm';

const ID = 10;
const NAME = getNameFile(ID);
const TITLE = 'Эскиз цветника';

/**
 * Таблица документов __Эскиз цветника__.
 *
 * @returns {JSX.Element} - JSX.
 * @example
 * <SketchFlowerRf />
 */
export const SketchFlowerRF = () => {
  return (
    <FieldsContainer>
      <Field
        title={TITLE}
        id={ID}
        name={NAME}
        component={DocumentWithReduxForm}
      />
    </FieldsContainer>
  );
};
