// 5 Остановки общественного транспорта

/* eslint-disable  */
import CardAbstract from 'app/components/card/common/CardAbstract';
import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid/index';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
  validateValidateCleanArea,
} from 'app/components/card/common/validation';
import DocumentsTab, {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import { ODH_AXIS_TOOLTIP } from 'app/constants/messages';
import { column } from 'app/constants/tables';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import isMandatory, { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['stops'];
const Versions = VersionsTemplate(types);

const tabs = [
  {
    id: 'characteristics-tab',
    name: 'Исходные данные',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

const propTypes = {
  card: PropTypes.object,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  mode: PropTypes.object,
  required: PropTypes.bool,
};

/**
 * Классовый компонет StopsCard.
 */
class StopsCard extends React.Component {
  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   * @example ----
   */
  render() {
    const {
      mode,
      card,
      createMode,
      editMode,
      required,
      documents,
      formValues = {},
      isCurrentObjectIsExternalSystem,
    } = this.props;

    return (
      <CardAbstract>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(card.type_id)}

          <div id="props">
            {elementFormGrid(
              [
                {
                  addition: {
                    label: 'Тип',
                    options: card.conv_element_type,
                    required: isMandatory(
                      card.type_id,
                      'conv_element_type_id',
                      required,
                    ),
                  },
                  component: WithReduxFormSelect,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'conv_element_type_id',
                  name: 'conv_element_type_id',
                },
                <div
                  style={{
                    height: 50,
                    margin: '9px 24px',
                    minWidth: 256,
                    paddingTop: 0,
                  }}
                >
                  <IsDiffHeightMarkRF
                    disabled={!mode.editMode || isCurrentObjectIsExternalSystem}
                    justifyContent={'space-between'}
                    labelPlacement={'start'}
                  />
                </div>,
                <div>
                  <ParentInfo />
                </div>,
              ],
              column.TWO,
              '',
              card.type_id,
            )}

            <Tabs tabs={tabs} />

            <div id="characteristics">
              {elementFormGrid(
                [
                  {
                    addition: {
                      formatChars: {
                        9: '[а-яА-Я0-9a-zA-Z]',
                      },
                      mask: '99',
                      tooltipText: ODH_AXIS_TOOLTIP,
                      type: 'text',
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'odh_axis',
                    label: 'Ось',
                    name: 'odh_axis',
                  },
                  {
                    addition: {
                      label: 'Сторона',
                      options: card.odh_side,
                      required: isMandatory(
                        card.type_id,
                        'odh_side_id',
                        required,
                      ),
                    },
                    component: WithReduxFormSelect,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'odh_side_id',
                    name: 'odh_side_id',
                  },
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      required: isMandatory(
                        card.type_id,
                        'bord_begin',
                        required,
                      ),
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    // костыль что бы 0 отобразился
                    defaultValue: formValues.bord_begin,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'bord_begin',
                    label: 'Начало, м',
                    name: 'bord_begin',
                  },
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      required: isMandatory(card.type_id, 'bord_end', required),
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    // костыль что бы 0 отобразился
                    defaultValue: formValues.bord_end,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'bord_end',
                    label: 'Конец, м',
                    name: 'bord_end',
                  },
                ],
                column.TWO,
                'Местоположение',
                card.type_id,
              )}

              {elementFormGrid(
                [
                  {
                    addition: {
                      required: isMandatory(card.type_id, 'name', required),
                      type: 'text',
                    },
                    component: renderCustomTextField,
                    editable: editMode,
                    formValue: true,
                    id: 'name',
                    label: 'Название остановки (адресная привязка)',
                    name: 'name',
                  },
                  {
                    addition: {
                      digits: 0,
                      positive: true,
                      type: 'integer',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'quantity',
                    label: 'Количество павильонов, шт.',
                    name: 'quantity',
                  },
                  {
                    addition: {
                      type: 'text',
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'routes',
                    label: 'Наличие маршрутов',
                    name: 'routes',
                  },
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      required: isMandatory(card.type_id, 'area', required),
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'area',
                    label:
                      'Площадь посадочной площадки(включая павильон), кв.м',
                    name: 'area',
                  },
                  <div>
                    <TotalAreaGeoRF disabled={true} decimalScale={2} />
                  </div>,
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'pocket_area',
                    label: 'Площадь уширения(кармана), кв.м',
                    name: 'pocket_area',
                  },
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      type: 'decimal',
                      zero: true,
                    },
                    component: renderCustomTextField,
                    defaultValue: formValues.pavilion_area,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'pavilion_area',
                    label: 'Площадь павильонов, кв.м',
                    name: 'pavilion_area',
                  },
                ],
                column.TWO,
                'Характеристики',
                card.type_id,
              )}

              {elementFormGrid(
                [
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      required: true,
                      type: 'decimal',
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'station_clean_area',
                    label: 'Площадь уборки, кв.м',
                    name: 'station_clean_area',
                  },
                ],
                column.ONE,
                '',
                card.type_id,
              )}

              {elementFormGrid(
                [
                  {
                    addition: { type: 'text' },
                    component: renderCustomTextField,
                    editable: editMode,
                    formValue: true,
                    id: 'description',
                    label: 'Примечание',
                    name: 'description',
                  },
                ],
                column.ONE,
                '',
                card.type_id,
              )}
            </div>
            {DocumentsTab()({
              documents,
              editable: editMode,
              typeId: card.type_id,
            })}
          </div>
          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={createMode}
              key={`versions-${card.root_id}`}
            />
          </div>
        </div>
      </CardAbstract>
    );
  }
}

StopsCard.propTypes = propTypes;

const stopsCardDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

const dictFields = ['conv_element_type_id', 'odh_side_id'];

const numericFields = [
  { name: 'quantity', positive: true, type: 'integer' },
  { name: 'bord_begin', type: 'decimal', zero: true },
  { name: 'bord_end', type: 'decimal', zero: true },
  {
    name: 'pavilion_area',
    positive: true,
    type: 'decimal',
    zero: true,
  },
  {
    name: 'area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'station_clean_area',
    positive: true,
    type: 'decimal',
    zero: true,
  },
  {
    name: 'pocket_area',
    positive: true,
    type: 'decimal',
    zero: true,
  },
];

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object} - Отвалидированные поля.
 * @example ----
 */
const validate = (values) => ({
  ...validateNumeric(values, numericFields),
  ...validateValidateCleanArea(values),
});

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

/**
 * Функция сабмита.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {void} - Жопа.
 * @example ----
 */
function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );
  formValuesWithAttribute.is_diff_height_mark = Boolean(
    formValuesWithAttribute.is_diff_height_mark,
  );

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);

  const result = {
    createMode: isCreateMode(state, props),

    editMode: isEditMode(state, props),

    enableReinitialize: true,
    initialValues: {
      area: parse('area'),
      bord_begin: parse('bord_begin') || '0.00',
      bord_end: parse('bord_end') || '0.00',
      conv_element_type_id: parse('conv_element_type_id.conv_element_type'),
      customer_id: parse('customer_id'),
      description: parse('description'),
      endDate: parseDate(props.card.end_date),
      is_diff_height_mark: parse('is_diff_height_mark'),
      name: parse('name'),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      owner_id: parse('owner_id'),
      parent_name: props.card.parent_info,
      pavilion_area: parse('pavilion_area'),
      pocket_area: parse('pocket_area'),
      quantity: parse('quantity'),
      routes: parse('routes'),
      startDate: parseDate(props.card.start_date),
      station_clean_area: parse('station_clean_area'),
      totalAreaGeo: parse('total_area_geo'),
    },
    onSubmit: onSubmit.bind({ props }),

    required: isRequired(state, props),
    validate,
  };

  return stopsCardDocumentsReducer(result, state, props);
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'editorCard',
  })(StopsCard),
);
