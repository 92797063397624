import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { MaterialIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/MaterialId.FF/MaterialId.FF';
import React from 'react';

/**
 * Field FF Материал.
 *
 * `name: material_id`.
 *
 * @returns {JSX.Element}
 */
export const MaterialIdFFField = () => {
  const { editMode } = useEditMode();
  return <MaterialIdFF disabled={!editMode} required={true} />;
};
