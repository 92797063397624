import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { FieldsContainer } from 'app/pages/cardsOgh/components/FieldsContainer';
import { DocumentWithReduxForm } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/DocumentWithReduxForm';
import { getNameFile } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getNameFile';
import React from 'react';
import { Field } from 'redux-form';

const ID = 7;
const NAME = getNameFile(ID);
const TITLE = 'Инвентаризационный план';

/**
 * Таблица документов __Инвентаризационный план__.
 *
 * @returns {JSX.Element} - JSX.
 * @example
 * <InventoryPlanRF />
 */
export const InventoryPlanRF = () => {
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();

  return (
    <FieldsContainer>
      <Field
        title={TITLE}
        id={ID}
        name={NAME}
        disabled={isCurrentObjectIsExternalSystem}
        component={DocumentWithReduxForm}
      />
    </FieldsContainer>
  );
};
