import { getCreatedCurrentCard } from 'app/api/requests/getCreatedCurrentCard';
import { getOghCard } from 'app/api/requests/getOghCard';
import { turnIntoCamelCase } from 'app/utils/turnIntoCamelCase';
import { useGetCreatedObjectId } from 'core/utils/hooks/useGetCreatedObjectId';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

/**
 * @typedef {import("types/Card").CardOgh} CardOgh
 */

/**
 * Функция получения текущих методов, создания или редактирования.
 *
 * @param {number} objectId - ObjectId.
 * @param {number} typeId - Тип объекта.
 * @param {string | undefined} startDate - Текущая дата.
 * @returns {CardOgh}
 */
const getCreatedCard = (objectId, typeId, startDate) => {
  let getCurrentFn = startDate ? getCreatedCurrentCard : getOghCard;
  let queryKey = startDate
    ? ['getOghCard', { startDate, typeId }]
    : ['getOghCard', objectId];
  return { getCurrentFn, queryKey };
};

/**
 * Хук получения текущего паспорта ОГХ.
 *
 * @returns {{
 * typeId:number,
 * successCard: boolean,
 * refetch: void,
 * startDate: string,
 * rootId: string,
 * recordId: string,
 * isLoading: boolean,
 * stateId: string
 * }} - Возвращает информацию о текущей карточке флаг successCard и функцию сброса данных.
 * @example
 * const card = useGetCurrentCard();
 */
export const useGetCurrentCard = () => {
  const { objectId } = useGetObjectId();
  const { typeId, startDate } = useGetCreatedObjectId();
  const { queryKey, getCurrentFn } = getCreatedCard(
    objectId,
    typeId,
    startDate,
  );

  const { data, refetch, isSuccess, isLoading } = useQueryAdaptor(
    queryKey,
    getCurrentFn,
  );

  const dataCamel = turnIntoCamelCase(data);
  return {
    ...data,
    ...dataCamel,
    isLoading,
    refetch,
    successCard: isSuccess,
  };
};
