import { WithMapContext } from 'app/components/map/withMap';
import { FOREGROUND, PARENTS } from 'app/constants/layers';
import { useGeometryMapCurrent } from 'app/pages/cardsOgh/components/Map/MapEditPanel/hooks/useGeometryMapCurrent';
import { useGeometryMapParent } from 'app/pages/cardsOgh/components/Map/MapEditPanel/hooks/useGeometryMapParent';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';
import { useContext, useEffect } from 'react';

 
/**
 * Хук инициализации карты.
 *
 * @description - Вызывается при изменение url родительского и дочернего id.
 * @returns {void} - Жопа.
 * @example
 * const Comp = ()=> {
 *     useActionsMap();
 *     return <OghCard/>
 * }
 */
export const useActionsMap = () => {
  const {
    clearLayer,
    drawCurrentObjectsGeometry,
    isMapLoaded,
    drawParentGeometry,
  } = useContext(WithMapContext);

  const { actions } = useGetParamsUrl();
  const { editMode } = useEditMode();
  const { dataMap = [], isSuccess } = useGeometryMapParent();
  const { dataMapCurrent = [], currentSuccess } = useGeometryMapCurrent();

  useEffect(() => {
    if (editMode && !actions.haveParent) {
      clearLayer(PARENTS);
    }
  });

  useEffect(() => {
    if (isSuccess && isMapLoaded && actions.haveParent) {
      clearLayer(PARENTS);
      drawParentGeometry(dataMap);
    }
  }, [
    isSuccess,
    drawParentGeometry,
    dataMap,
    isMapLoaded,
    clearLayer,
    actions.haveParent,
  ]);

  useEffect(() => {
    if (currentSuccess && isMapLoaded) {
      clearLayer(FOREGROUND);
      drawCurrentObjectsGeometry(dataMapCurrent);
    }
  }, [
    currentSuccess,
    drawCurrentObjectsGeometry,
    dataMapCurrent,
    isMapLoaded,
    clearLayer,
  ]);
};
