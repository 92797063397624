import { usePreparation } from 'core/utils/hooks/usePreparation/preparation';

import { MATERIAL_ID_NAME } from './MaterialId.FF';

/**
 * Хук перевода значений.
 *
 * @returns {void}
 */
export const usePreparationMaterialId = () => {
  usePreparation(MATERIAL_ID_NAME, (value) => {
    if (value) {
      return { material: value };
    }
  });
};
