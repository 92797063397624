import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { shallow } from 'enzyme';
import React from 'react';

import { PlantationTypeIdRFTreesShrubs } from './PlantationTypeId.RF.TreesShrubs';

jest.mock('app/api/hooks/useIsCurrentObjectIsExternalSystem');

describe('spec PlantationTypeId.RF.TreesShrubs', function () {
  it('should pass correct props to PlantationTypeIdRF', () => {
    // Arrange
    (useIsCurrentObjectIsExternalSystem as jest.Mock).mockReturnValue(true);
    const changeFieldValueMock = jest.fn();
    const isCurrentObjectIsExternalSystem = true;
    const editMode = true;

    // Act
    const wrapper = shallow(
      <PlantationTypeIdRFTreesShrubs
        changeFieldValue={changeFieldValueMock}
        editMode={editMode}
      />,
    );

    // Assert
    const plantationTypeIdRFComponent = wrapper.find('PlantationTypeIdRF');
    expect(plantationTypeIdRFComponent.prop('disabled')).toBe(
      editMode && isCurrentObjectIsExternalSystem,
    );
    expect(plantationTypeIdRFComponent.prop('required')).toBe(true);
    expect(typeof plantationTypeIdRFComponent.prop('withChange')).toBe(
      'function',
    );
  });

  // Вызывает ли withChange колбэк функцию правильно?
  it('should invoke changeFieldValues correctly when withChange is called', () => {
    // Arrange
    const changeFieldValueMock = jest.fn();
    const editMode = true;
    const wrapper = shallow(
      <PlantationTypeIdRFTreesShrubs
        changeFieldValue={changeFieldValueMock}
        editMode={editMode}
      />,
    );
    const plantationTypeIdRFComponent = wrapper.find('PlantationTypeIdRF');

    // Act
    // @ts-ignore
    plantationTypeIdRFComponent.prop('withChange')();

    // Assert
    expect(changeFieldValueMock).toHaveBeenCalledTimes(5);
    expect(changeFieldValueMock).toHaveBeenCalledWith('plant_type_id', null);
    expect(changeFieldValueMock).toHaveBeenCalledWith(
      'life_form_type_id',
      null,
    );
    expect(changeFieldValueMock).toHaveBeenCalledWith('age', null);
    expect(changeFieldValueMock).toHaveBeenCalledWith('diameter', null);
    expect(changeFieldValueMock).toHaveBeenCalledWith('area', '');
  });

  // Рендерится ли дочерний элемент PlantationTypeIdRF:
  it('should render the PlantationTypeIdRF component', () => {
    // Arrange
    const changeFieldValueMock = jest.fn();
    const editMode = true;

    // Act
    const wrapper = shallow(
      <PlantationTypeIdRFTreesShrubs
        changeFieldValue={changeFieldValueMock}
        editMode={editMode}
      />,
    );

    // Assert
    expect(wrapper.find('PlantationTypeIdRF').exists()).toBe(true);
  });

  // Проверка, что поле PlantationTypeIdRF выключено при editMode === false:
  it('should disable PlantationTypeIdRF when editMode is false', () => {
    // Arrange
    (useIsCurrentObjectIsExternalSystem as jest.Mock).mockReturnValue(false);
    const changeFieldValueMock = jest.fn();
    const editMode = false;

    // Act
    const wrapper = shallow(
      <PlantationTypeIdRFTreesShrubs
        changeFieldValue={changeFieldValueMock}
        editMode={editMode}
      />,
    );

    // Assert
    expect(wrapper.find('PlantationTypeIdRF').prop('disabled')).toBe(true);
  });

  // Проверить поведение поля PlantationTypeIdRF, когда флаг isCurrentObjectIsExternalSystem установлен в true:
  it('should disable PlantationTypeIdRF when isCurrentObjectIsExternalSystem flag is true', () => {
    // Arrange
    (useIsCurrentObjectIsExternalSystem as jest.Mock).mockReturnValue(true);
    const changeFieldValueMock = jest.fn();
    const editMode = true;

    // Act
    const wrapper = shallow(
      <PlantationTypeIdRFTreesShrubs
        changeFieldValue={changeFieldValueMock}
        editMode={editMode}
      />,
    );

    // Assert
    expect(wrapper.find('PlantationTypeIdRF').prop('disabled')).toBe(true);
  });

  // Проверить поведение поля PlantationTypeIdRF, когда флаг isCurrentObjectIsExternalSystem установлен в true a editMode === false:
  it('should disable PlantationTypeIdRF when isCurrentObjectIsExternalSystem flag is true editMode is false', () => {
    // Arrange
    (useIsCurrentObjectIsExternalSystem as jest.Mock).mockReturnValue(true);
    const changeFieldValueMock = jest.fn();
    const editMode = false;

    // Act
    const wrapper = shallow(
      <PlantationTypeIdRFTreesShrubs
        changeFieldValue={changeFieldValueMock}
        editMode={editMode}
      />,
    );

    // Assert
    expect(wrapper.find('PlantationTypeIdRF').prop('disabled')).toBe(true);
  });
});
