import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { useEditCheckStatus } from 'app/pages/cardsOgh/hooks/useEditStatus';
import { useUser } from 'app/pages/providers';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';

import { checkPrivileges } from '../../helpers/checkPrivileges';
import { useCheckShowChangeBtn } from '../ChangeButton/useCheckShowChangeBtn';

interface useSetupAddButtonInterface {
  activeButton: boolean;
  typeCode: string | number;
}

/**
 * Setup кнопки Добавить дочерний элемент.
 *
 * @param param - Параметры.
 * @param param.activeButton - Параметры активности кнопки.
 * @param param.typeCode - Тайп код.
 * @returns {{isShowAdd: boolean}}
 */
export const useSetupAddButton = ({
  activeButton,
  typeCode,
}: useSetupAddButtonInterface) => {
  const editStatus = useEditCheckStatus();
  // @ts-ignore
  const { parentId } = useGetParamsUrl();
  const { user } = useUser();
  const showChangeBtn = useCheckShowChangeBtn();
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();
  const { data: { edit = false } = {} } = useGetVisibilityButtonsOgh(parentId);

  const isShowAdd =
    !isCurrentObjectIsExternalSystem &&
    !showChangeBtn &&
    activeButton &&
    edit &&
    !editStatus &&
    checkPrivileges(user.privileges ?? '', typeCode, 'createOgh');

  return { isShowAdd };
};
