import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import {
  CONTAINER,
  IMPROVEMENT_OBJECT,
  ODH,
  OOPT,
  OZN,
  SPA,
  YARD,
} from 'app/constants/oghTypes';
import { geometryLoad } from 'app/pages/cardsOgh/components/Map/api/geometryLoad';
import { useQueryAdaptor } from 'core/utils/hooks/useReactQuery';

const parentCards = [ODH, IMPROVEMENT_OBJECT, YARD, OZN, OOPT, CONTAINER, SPA];

/* eslint-disable */
/**
 * Хук получения родительской геометрии.
 *
 * @returns {{dataMap: object, initMapParent: Function, isSuccess: boolean}} - Возвращает данные карты родителя, флаг успешновыполненого запроса и функцию перезапроса.
 * @example - useGeometryMapParent();
 */
export const useGeometryMapParent = () => {
  const { typeId, recordId, isSuccessParent } = useGetParentCard();

  const { data, isSuccess, remove } = useQueryAdaptor(
    [
      'geometryLoadParent',
      {
        ids: [recordId],
      },
    ],
    geometryLoad,
    {
      enabled: isSuccessParent && parentCards.includes(typeId) && !!recordId,
    },
  );

  return { dataMap: data, isSuccess, initMapParent: remove };
};
