/* eslint-disable */
import React from 'react';
import { useGetAllowedGeometryTypes } from 'app/pages/cardsOgh/components/Map/MapEditPanel/hooks/useGetAllowedGeometryTypes';
import { PanelRightCard } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/Panel';

const MapEditPanelWrapper = (props) => {
  const allowedGeometryTypes = useGetAllowedGeometryTypes();

  return <PanelRightCard allowedGeometryTypes={allowedGeometryTypes} />;
};

 
export default MapEditPanelWrapper;
