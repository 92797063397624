import { shallow } from 'enzyme';
import React from 'react';

import { OznThreeTab } from './OznThree.Tab';

describe('OznThreeTab component', () => {
  it('renders all child components successfully', () => {
    const props = {
      editable: true,
      isCurrentObjectIsExternalSystem: false,
    };
    const wrapper = shallow(<OznThreeTab {...props} />);

    expect(wrapper.find('CardContainer').exists()).toBe(true);
    expect(wrapper.find('OwnerIdRF').exists()).toBe(true);
    expect(wrapper.find('CustomerOznRF').exists()).toBe(true);
    expect(wrapper.find('ImprovementCategoryIdRF').exists()).toBe(true);
    expect(wrapper.find('ImprovementObjectCategoryIdWrapper').exists()).toBe(
      true,
    );
    expect(wrapper.find('NameField').exists()).toBe(true);
    expect(wrapper.find('OkrugListField').exists()).toBe(true);
    expect(wrapper.find('DistrictListField').exists()).toBe(true);
    expect(wrapper.find('IsDiffHeightMarkRF').exists()).toBe(true);
  });
});
