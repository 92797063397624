import OwnerAndCustomerFields from 'app/components/card/ogh/ImprovementTerritoryCard/components/OwnerAndCustomerFields/OwnerAndCustomerFields';
import { CUSTOMER_HINT } from 'app/constants/messages';
import getHint from 'app/utils/getHint';
import { CustomerPlaceRF } from 'core/form/reduxForm/fields/prepared/selects/autocompleteAsync/CustomerPlace.RF';
import { OwnerIdPlaceRF } from 'core/form/reduxForm/fields/prepared/selects/autocompleteAsync/OwnerIdPlace.RF/OwnerIdPlace.RF';
import { RANDOM_BOOL } from 'core/forTesting/constants';
import { shallow } from 'enzyme';

describe('OwnerAndCustomerFieldsJestSpec', function () {
  it('test props CustomerPlaceRF', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const props = {
      card: { type_id: 40 },
      editable: true,
      isCurrentObjectIsExternalSystem: false,
      isOrphanObject: false,
      mode: { editMode: RANDOM_BOOL },
      required: true,
    };

    // Act
    const wrapper = shallow(<OwnerAndCustomerFields {...props} />);

    // Assert
    expect(wrapper.find(CustomerPlaceRF).props()).toStrictEqual({
      disabled: false,
      helpTooltip: CUSTOMER_HINT,
      place: 'ozn',
      required: true,
    });
  });

  it('test props OwnerIdRF', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const props = {
      card: { type_id: 40 },
      editable: true,
      isCurrentObjectIsExternalSystem: false,
      isOrphanObject: false,
      mode: { editMode: true },
      required: true,
    };

    // Act
    const wrapper = shallow(<OwnerAndCustomerFields {...props} />);

    // Assert
    expect(wrapper.find(OwnerIdPlaceRF).props()).toStrictEqual({
      disabled: false,
      helpTooltip: getHint('owner_id'),
      place: 'ozn',
      required: true,
    });
  });
});
