import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { FieldsContainer } from 'app/pages/cardsOgh/components/FieldsContainer';
import { OghCardHeaderFF } from 'app/pages/cardsOgh/components/headerFF/OghHeaderFF';
import Versions from 'app/pages/cardsOgh/components/tables/Versions/Versions';
import { CoatingFaceTypeIdFFField } from 'app/pages/cardsOgh/EngineerBuilding/fields/CoatingFaceTypeIdFF.Field';
import { IsDiffHeightMarkFf } from 'core/form/finalForm/fields/prepared/checkboxes/IsDiffHeightMark.FF';
import { NoCalcFF } from 'core/form/finalForm/fields/prepared/checkboxes/NoCalc.FF';
import { TotalAreaGeoFF } from 'core/form/finalForm/fields/prepared/numberField/TotalAreaGeo.FF';
import { TabsCard } from 'core/uiKit/components/TabsCard';
import { TabsCardItem } from 'core/uiKit/components/TabsCard/TabsCardItem';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import React from 'react';

import { useMode } from '../hooks/useMode';
import { CleaningTypeIdFFField } from './fields/CleaningTypeIdFF.Field';
import { CoatingGroupIdFFField } from './fields/coatingFields/CoatingGroupIdFF.Field';
import { CoatingTypeIdFFField } from './fields/coatingFields/CoatingTypeIdFF.Field';
import { EngineerBuildingTypeIdFfField } from './fields/EngineerBuildingTypeIdFF.Field';
import { FaceAreaFFField } from './fields/FaceAreaFF.Field';
import { MaterialIdFFField } from './fields/MaterialIdFF.Field';
import { QuantityFFField } from './fields/QuantityFf.Field';
import { UnitIdFFField } from './fields/UnitIdFF.Field';

/**
 * Левая панель Инженерных сооружений.
 *
 * @returns {JSX.Element}
 */
export const LeftPanelEngineerBuilding = () => {
  const { editMode } = useMode();
  const { rootId = 0 } = useGetCurrentCard();

  const tabsTopHeader = [
    { label: 'Свойства', value: 'features' },
    { label: 'Версии', show: Boolean(rootId), value: 'versions' },
  ];

  const tabsEnd = [{ label: 'Исходные данные', value: 'initialData' }];

  return (
    <>
      <OghCardHeaderFF />
      <TabsCard tabs={tabsTopHeader}>
        <TabsCardItem tabValue={'features'}>
          <FieldsContainer>
            <EngineerBuildingTypeIdFfField disabled={!editMode} />
          </FieldsContainer>
          <FieldsContainer>
            <ParentInfo />
          </FieldsContainer>
          <TabsCard tabs={tabsEnd}>
            <TabsCardItem tabValue={'initialData'}>
              <FieldsContainer>
                <QuantityFFField disabled={!editMode} required={true} />
                <TotalAreaGeoFF disabled={true} digits={2} />
                <UnitIdFFField />
                <MaterialIdFFField />
                <CoatingGroupIdFFField />
                <CoatingTypeIdFFField />
                <CoatingFaceTypeIdFFField />
                <FaceAreaFFField />
                <CleaningTypeIdFFField />
                <NoCalcFF disabled={!editMode} />
                <IsDiffHeightMarkFf disabled={!editMode} />
              </FieldsContainer>
            </TabsCardItem>
          </TabsCard>
        </TabsCardItem>
        <TabsCardItem tabValue={'versions'}>
          <FieldsContainer>
            <Versions />
          </FieldsContainer>
        </TabsCardItem>
      </TabsCard>
    </>
  );
};
