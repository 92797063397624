/* eslint-disable  */
import TreesAndShrubsCard, {
  onSubmit,
} from 'app/components/card/ogh/trees_shrubs/trees_shrubs';
import { shallow } from 'enzyme';
import { SubmissionError } from 'redux-form';

jest.mock('redux-form', () => ({
  ...jest.requireActual('redux-form'),
  reduxForm: jest.fn(() => (Component) => {
    return Component;
  }),
}));

jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  connect: jest.fn(() => (b) => {
    return b;
  }),
}));

describe('TreesShrubsJest', function () {
  it('Поле участка в режиме редактирования загружена не из внешней системы', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const props = {
      card: {},
      editMode: true,
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };

    const wrapper = shallow(<TreesAndShrubsCard foo={123} {...props} />);

    const { disabled } = wrapper.find('#section_num').props();

    // Assert
    expect(disabled).toBeFalsy();
  });
  it('Поле участка в режиме просмотра загружена не из внешней системы', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const props = {
      card: {},
      editMode: false,
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };

    const wrapper = shallow(<TreesAndShrubsCard foo={123} {...props} />);

    const { disabled } = wrapper.find('#section_num').props();

    // Assert
    expect(disabled).toBeTruthy();
  });
  it('Поле участка в режиме редактирования загружена из внешней системы', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const props = {
      card: {},
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      mode: {},
    };

    const wrapper = shallow(<TreesAndShrubsCard foo={123} {...props} />);

    const { disabled } = wrapper.find('#section_num').props();

    // Assert
    expect(disabled).toBeTruthy();
  });
  it('Поле участка в режиме просмотра загружена из внешней системы', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const props = {
      card: {},
      editMode: false,
      isCurrentObjectIsExternalSystem: true,
      mode: {},
    };

    const wrapper = shallow(<TreesAndShrubsCard foo={123} {...props} />);

    const { disabled } = wrapper.find('#section_num').props();

    // Assert
    expect(disabled).toBeTruthy();
  });
});

describe('TreesShrubs onsubmit', function () {
  it('test', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const alert = jest.fn();

    const props = {
      alert,
      mandatoryFields: [],
    };
    // Act

    /**
     * Обертка для вызова onSubmit.
     *
     * @returns {void}
     */
    const callback = () => {
      onSubmit({}, (_) => _, props);
    };

    // Assert
    expect(callback).toThrow(SubmissionError);
    expect(alert).toBeCalledTimes(1);
  });
});
