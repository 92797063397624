// 27  плоскостные сооружения
/* eslint-disable  */

import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import CardAbstract from 'app/components/card/common/CardAbstract';
import { getParseAttribute } from 'app/components/card/common/getParseAttribute';
import {
  elementFormGrid,
  renderAbutmentList,
} from 'app/components/card/common/grid/index';
import { parseDate } from 'app/components/card/common/parse';
import { objectTabs } from 'app/components/card/common/tabs';
import {
  validateCleanArea,
  validateNumeric,
  validatePlanarStructureTypeRef,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import { WithReduxFormCheckbox } from 'app/components/common/CheckboxField';
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import {
  AUTO_CLEANING_TYPE,
  MANUAL_CLEANING_TYPE,
} from 'app/constants/cleaningTypes';
import { PLANAR_STRUCTURE } from 'app/constants/oghTypes';
import getAccessToPlanarStructureTypeRefId from 'app/selectors/card/getAccessToPlanarStructureTypeRefId';
import getCoatingTypes from 'app/selectors/card/getCoatingTypes';
import getPlanarStructureTypeRefId from 'app/selectors/card/getPlanarStructureTypeRefId';
import hasCoverageLock from 'app/selectors/card/hasCoverageLock';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import getDict from 'app/selectors/getDict';
import { transformValues } from 'app/utils/form/transformation';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields';
import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import { CleaningTypeRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CleaningType.RF';
import { validateWithTypeOfCoating } from 'core/form/reduxForm/fields/prepared/selects/simple/CleaningType.RF/validateWithTypeOfCoating';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { TabsCard } from 'core/uiKit/components/TabsCard';
import { TabsCardItem } from 'core/uiKit/components/TabsCard/TabsCardItem';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import { GroupIdRFPlanarStructureCard } from './PlanarStructureCard/fields/GroupId.RF.PlanarStructureCard';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';
const types = ['planar_structure'];
const Versions = VersionsTemplate(types);

const propTypes = {
  abutmentTypes: PropTypes.array,
  card: PropTypes.object,
  changeFieldValue: PropTypes.func,
  coatingTypes: PropTypes.array,
  coverageLock: PropTypes.bool,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  formValues: PropTypes.object,
  isEditablePlanarStructureType: PropTypes.bool,
  mode: PropTypes.object,
  planarStructureTypeRef: PropTypes.array,
  required: PropTypes.bool,
};

const checkboxStyle = {
  height: 50,
  paddingTop: 0,
};

/**
 * Классовый компонет PlanarStructureCard.
 *
 * @returns {JSX.ElementClass} - Жопа.
 */
class PlanarStructureCard extends React.Component {
  /**
   * Конструктор.
   *
   * @param {object} props - Пропсы.
   * @example -----
   */
  constructor(props) {
    super(props);
    this.state = {
      typeIds: [],
    };
  }

  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   * @example ----
   */
  render() {
    const { props } = this;
    const {
      card,
      changeFieldValue,
      editMode,
      formValues,
      isCurrentObjectIsExternalSystem,
      isEditablePlanarStructureType,
      mode,
      parentTypeId,
      planarStructureTypeRef,
      required,
    } = props;
    const { type_id: typeId } = card;

    const tabsEnd = [
      {
        label: 'Исходные данные',
        value: 'characteristics',
      },
      {
        label: 'Элемент сопряжения',
        show: !editMode,
        value: 'abutments',
      },
      {
        label: 'Документы',
        value: 'documents',
      },
    ];

    return (
      <CardAbstract>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(typeId)}
          <div id="props">
            {elementFormGrid(
              [
                {
                  addition: {
                    /**
                     *
                     * @param value
                     */
                    insteadOfOnChange: (value) => {
                      changeFieldValue('planar_structure_type_id', value);
                      changeFieldValue('planar_structure_type_ref_id', null);
                    },
                    label: 'Назначение',
                    options: parentTypeId
                      ? card?.planar_structure_type?.filter(
                          ({ parent_ogh_object_type_list = [] }) =>
                            parent_ogh_object_type_list?.includes(parentTypeId),
                        )
                      : card?.planar_structure_type,

                    required,
                  },
                  component: WithReduxFormSelect,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'planar_structure_type_id',
                  name: 'planar_structure_type_id',
                },
                {
                  addition: {
                    label: 'Уточнение',
                    options: planarStructureTypeRef,
                    placeholder: '',
                  },
                  component: WithReduxFormSelect,
                  editable: isEditablePlanarStructureType,
                  formValue: true,
                  id: 'planar_structure_type_ref_id',
                  name: 'planar_structure_type_ref_id',
                },
              ],
              1,
              '',
              typeId,
            )}

            {elementFormGrid(
              [
                <div>
                  <ParentInfo />
                </div>,
                {
                  component: WithReduxFormCheckbox,
                  editable: editMode,
                  formValue: true,
                  id: 'no_calc',
                  label: 'Не учитывать',
                  name: 'no_calc',
                  style: checkboxStyle,
                },
                <div
                  style={{
                    height: 50,
                    margin: '9px 24px',
                    minWidth: 256,
                    paddingTop: 0,
                  }}
                >
                  <IsDiffHeightMarkRF
                    disabled={!mode.editMode || isCurrentObjectIsExternalSystem}
                    justifyContent={'space-between'}
                    labelPlacement={'start'}
                  />
                </div>,
                <div
                  style={{
                    height: 50,
                    margin: '9px 24px',
                    minWidth: 256,
                    paddingTop: 0,
                  }}
                >
                  <GroupIdRFPlanarStructureCard editMode={editMode} />
                </div>,
              ],
              2,
              '',
              typeId,
            )}
            <TabsCard tabs={tabsEnd}>
              <TabsCardItem tabValue={'characteristics'}>
                {elementFormGrid(
                  [
                    {
                      addition: {
                        label: 'Вид покрытия',
                        options: card.coating_group,
                        required,

                        /**
                         *
                         */
                        withChange: () => {
                          changeFieldValue('coating_type_id', null);
                        },
                      },
                      component: WithReduxFormSelect,
                      editable: editMode && !isCurrentObjectIsExternalSystem,
                      formValue: true,
                      id: 'coating_group_id',
                      name: 'coating_group_id',
                    },
                    {
                      addition: {
                        label: 'Вид покрытия (уточнение)',
                        options: this.props.coatingTypes,
                      },
                      component: WithReduxFormSelect,
                      editable:
                        editMode &&
                        get(formValues, 'coating_group_id') &&
                        !isCurrentObjectIsExternalSystem,
                      formValue: true,
                      id: 'coating_type_id',
                      name: 'coating_type_id',
                    },
                    <div>
                      <CleaningTypeRF disabled={!editMode} required />
                    </div>,
                    {
                      addition: {
                        digits: 2,
                        positive: true,
                        required,
                        type: 'decimal',
                        zero: false,
                      },
                      component: TextFieldRF,
                      editable: editMode && !isCurrentObjectIsExternalSystem,
                      formValue: true,
                      id: 'total_area',
                      label: 'Площадь, кв.м',
                      name: 'total_area',
                    },
                    <div>
                      <TotalAreaGeoRF disabled={true} decimalScale={2} />
                    </div>,
                    {
                      addition: { multiLine: true, type: 'text' },
                      component: TextFieldRF,
                      editable: editMode && !isCurrentObjectIsExternalSystem,
                      formValue: true,
                      id: 'property',
                      label: 'Характеристика',
                      name: 'property',
                      style: { height: 'auto' },
                    },
                  ],
                  2,
                  '',
                  typeId,
                )}
              </TabsCardItem>
              <TabsCardItem tabValue={'abutments'}>
                {elementFormGrid(
                  [
                    {
                      addition: { abutmentTypes: this.props.abutmentTypes },
                      component: renderAbutmentList,
                      editable: false,
                      formValue: true,
                      id: 'abutment_type_list',
                      name: 'abutment_type_list',
                      style: { overflow: 'auto' },
                    },
                  ],
                  1,
                  '',
                  typeId,
                )}
              </TabsCardItem>
              <TabsCardItem tabValue={'documents'}>
                <OtherDocumentsRF
                  disabled={!editMode && isCurrentObjectIsExternalSystem}
                />
              </TabsCardItem>
            </TabsCard>
          </div>
          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={props.createMode}
              key={`versions-${card.root_id}`}
            />
          </div>
        </div>
      </CardAbstract>
    );
  }
}

const getAbutmentTypes = getDict('abutmentTypes');

const planarStructureDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);
  const result = {
    abutmentTypes: getAbutmentTypes(state),
    coatingTypes: getCoatingTypes(state, props),
    coverageLock: hasCoverageLock(state),
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),
    enableReinitialize: true,
    formValues: getFormValues(state),
    initialValues: {
      abutment_type_list: {
        showDialog: false,
        table: parse('abutment_type_list'),
      },
      cleaningType: parse('cleaning_type'),
      coating_group_id: parse('coating_group_id.coating_group'),
      coating_type_id: parse('coating_type_id.coating_type'),
      endDate: parseDate(props.card.end_date),
      group_id: props?.card?.group_id || [],
      is_diff_height_mark: parse('is_diff_height_mark'),
      no_calc: parse('no_calc'),
      parent_name: props.card.parent_info,
      planar_structure_type_id: parse(
        'planar_structure_type_id.planar_structure_type',
      ),
      planar_structure_type_ref_id: parse(
        'planar_structure_type_ref_id.planar_structure_type_ref',
      ),
      property: parse('property'),
      startDate: parseDate(props.card.start_date),
      total_area: parse('total_area'),
      totalAreaGeo: parse('total_area_geo'),
    },

    isEditablePlanarStructureType: getAccessToPlanarStructureTypeRefId(state),

    onSubmit: onSubmit.bind({ props }),
    planarStructureTypeRef: getPlanarStructureTypeRefId(state, props),
    required: isRequired(state, props),

    validate,
  };

  return planarStructureDocumentsReducer(result, state, props);
};

PlanarStructureCard.propTypes = propTypes;

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object} - Отвалидированные поля.
 * @example ----
 */
function validate(values) {
  return {
    ...validateNumeric(values, numericFields),
    ...validateCleanArea(values),
  };
}

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

const numericFields = [
  {
    name: 'total_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'manual_clean_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'auto_clean_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
];

const dictFields = [
  'planar_structure_type_id',
  'coating_type_id',
  'coating_group_id',
  'planar_structure_type_ref_id',
];

const mandatoryFields = {
  [PLANAR_STRUCTURE]: [
    'planar_structure_type_id',
    'coating_type_id',
    'coating_group_id',
    'total_area',
  ],
};

/**
 * Функция сабмита.
 *
 * @returns {void} - Жопа.
 * @param {object} formValues - Значения из формы.
 * @example ----
 */
function onSubmit(formValues) {
  const data = { ...formValues };

  delete data.parent_name;

  const formValuesWithAttribute = {
    ...data,
    ...createAttribute(data),
  };
  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[this.props.card.type_id],
    (values) => {
      if (values.total_area && values.total_area > 0) {
        if (values.cleaningType === MANUAL_CLEANING_TYPE) {
          values.manual_clean_area = values.total_area;
        }
        if (values.cleaningType === AUTO_CLEANING_TYPE) {
          values.auto_clean_area = values.total_area;
        }
        delete values.cleaning_type;
      }
      return {
        ...validatePlanarStructureTypeRef(values),
        ...validateCleanArea(values),
      };
    },
    validateWithTypeOfCoating,
  );

  if (get(formValuesWithAttribute, 'abutment_type_list.table[0]')) {
    formValuesWithAttribute.abutment_type_list =
      formValuesWithAttribute.abutment_type_list.table;
  } else {
    delete formValuesWithAttribute.abutment_type_list;
  }

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

/**
 * Функция получения экшенов.
 *
 * @param {Function} dispatch - Функция диспатч.
 * @returns {{changeFieldValue: mapDispatchToProps.changeFieldValue}} - Экшен changeFieldValue.
 * @example ----
 */
function mapDispatchToProps(dispatch) {
  return {
    /**
     *
     * @param field
     * @param value
     */
    changeFieldValue: (field, value) => {
      dispatch(change('editorCard', field, value));
    },
  };
}

/**
 * Обертка для использования хуков.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - Реакт Компонент.
 * @example -----
 */
const PlanarStructureCardContainer = (props) => {
  const { typeId } = useGetParentCard();
  return <PlanarStructureCard parentTypeId={typeId} {...props} />;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(PlanarStructureCardContainer));
