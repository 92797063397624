import { get } from 'app/api/crud/get';
import { toast } from 'core/uiKit/components/Toast';
import { snake_case } from 'core/utils/snake_case';

/**
 * Метод получения словарей.
 *
 * @param dict - Название словаря.
 * @returns - Словарь.
 * @example ---
 */
export const fetchDictionary = async (
  dict: string,
): Promise<
  {
    id: number;
    name: string;
    code?: string;
    full_name?: string;
  }[]
> => {
  const code = snake_case(dict);

  let res;
  try {
    res = await get('/dict', { code });
  } catch (error) {
    res = error;
  }

  // @ts-ignore
  if (res?.error) {
    // @ts-ignore
    toast.error(res?.message);
    return [];
  }
  if (Array.isArray(res)) {
    return res;
  }
  toast.error(`${code} не тот тип`);
  return [];
};
