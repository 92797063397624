import {
  elementFormGrid,
  renderAddressList2,
} from 'app/components/card/common/grid/index';

/**
 * Таблица связанные адреса Бти.
 *
 * @param {object} params - Parameters.
 * @returns {object}
 */
export default function(params = {}) {
  const cols = 1;
  const title = 'Связанные адреса БТИ';
  const elements = getElements(params);
  return (props) =>
    elementFormGrid(elements(props), cols, title, props.card.type_id, {
      required: props.editable && params.required,
    });
}

/**
 * Таблица связанные адреса Бти.
 *
 * @param {object} params - Parameters.
 * @returns {object}
 */
function getElements(params) {
  const { addition, ...rest } = params;
  return ({ editable, required, isCurrentObjectIsExternalSystem }) => [
    {
      addition: {
        address_required: true,
        noWrapper: true,
        required: editable && required,
        ...addition,
      },
      component: renderAddressList2,
      editable: editable && !isCurrentObjectIsExternalSystem,
      formValue: true,
      id: 'address_list',
      label: null,
      name: 'address_list',
      ...rest,
    },
  ];
}
