import { mapStateToPropsReducer as documentsReducer } from 'app/components/card/ogh/DocumentsTab';
import { IsDiffHeightMarkReducer } from 'app/components/card/ogh/ImprovementTerritoryCard/components/IsDiffHeightMark';
import { OznThreeTab } from 'app/components/card/ogh/ImprovementTerritoryCard/ozn/OznThree.Tab';
import { OznTwoTab } from 'app/components/card/ogh/ImprovementTerritoryCard/ozn/OznTwo.Tab';
import OrdersTab, {
  mapDispatchToPropsReducer as ordersDispatchReducer,
  mapStateToPropsReducer as ordersReducer,
} from 'app/components/card/ogh/OrdersTab';
import { DISTRICT_LIST_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/DistrictList';
import { OKRUG_LIST_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/OkrugList';
import { OZN_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/OznTypeID';

import {
  additionalInformationReducer,
  AdditionalInformationTab,
} from './components/AdditionalInformation.Tab';
import AddressListField, {
  mapStateToPropsReducer as addressListFieldReducer,
} from './components/AddressListField';
import { mapStateToPropsReducer as aquaAreaReducer } from './components/AquaAreaTab';
import { mapStateToPropsReducer as departmentTypeFieldReducer } from './components/DepartmentTypeField';
import { districtListFieldReducer } from './components/DistrictListField';
import {
  mapStateToPropsReducer as improvementCategoriesReducer,
  metadata as improvementCategoriesMetadata,
} from './components/ImprovementCategories';
import MainParamsTab from './components/MainParamsTab';
import { mapStateToPropsReducer as nameFieldReducer } from './components/NameField';
import { okrugListFieldReducer } from './components/OkrugListField';
import { mapStateToPropsReducer as ownerAndCustomerReducer } from './components/OwnerAndCustomerFields';
import { oznTypeFieldReducer } from './components/OznTypeField';
import PlantationTab from './components/PlantationTab';
import PropsTabs from './components/PropsTabs';
import { mapStateToPropsReducer as snowAreaReducer } from './components/SnowAreaTab';
import ImprovementTerritoryCardTemplate from './ImprovementTerritoryCardTemplate';
import mapStateToPropsReducer from './mapStateToPropsReducer';
import { asyncValidate } from './ozn/asyncValidate';
import { createAttribute } from './ozn/createAttribute';
import { DocumentTab } from './ozn/DocumentTab';
import { OznOneTab } from './ozn/OznOne.Tab';
import { validate } from './ozn/validate';
import { oznCardTabs } from './tabs';

const mandatoryFieldNames = {
  [DISTRICT_LIST_NAME]: 'Район',
  [OKRUG_LIST_NAME]: 'Округ',
  [OZN_TYPE_ID_NAME]: 'Тип объекта',
  customer_id: 'Заказчик',
  department_id: 'Отраслевой ОИВ',
  name: 'Наименование',
  owner_id: 'Балансодержатель',
  snow_clean_area: 'Площадь вывоза снега',
  [improvementCategoriesMetadata.improvement.id]:
    improvementCategoriesMetadata.improvement.label,
  [improvementCategoriesMetadata.landscaping.id]:
    improvementCategoriesMetadata.landscaping.label,
};

const tabToFields = {
  'Дополнительная информация': [[OZN_TYPE_ID_NAME]],
  Свойства: [
    improvementCategoriesMetadata.improvement.id,
    improvementCategoriesMetadata.landscaping.id,
    'owner_id',
    'name',
    'customer_id',
    'department_id',
    'snow_clean_area',
    'okrug_id',
    [OKRUG_LIST_NAME],
    [DISTRICT_LIST_NAME],
  ],
};

export const OznCard = ImprovementTerritoryCardTemplate({
  mapDispatchToPropsReducers: [ordersDispatchReducer],
  mapStateToPropsReducers: [
    mapStateToPropsReducer({
      asyncValidate,
      createAttribute,
      mandatoryFieldNames,
      tabToFields,
      validate,
    }),
    improvementCategoriesReducer({
      improvement: {
        defaultOption: {
          id: -1,
          name: '',
        },
        defaultValue: -1,
        isAlwaysDefault: true,
      },
    }),
    ownerAndCustomerReducer(),
    departmentTypeFieldReducer({
      id: 'department_id',
      path: 'attribute.department_id.legal_person',
    }),
    IsDiffHeightMarkReducer(),
    nameFieldReducer(),
    addressListFieldReducer(),
    snowAreaReducer,
    aquaAreaReducer,
    okrugListFieldReducer,
    oznTypeFieldReducer,
    districtListFieldReducer,
    documentsReducer({
      oghGroupCode: 'ozn_object',
    }),
    ordersReducer,
    additionalInformationReducer,
  ],
  propsTabElementRenders: [
    OznOneTab,
    OznTwoTab,
    OznThreeTab,
    AddressListField({
      editable: false,
      required: false,
    }),
    PropsTabs(oznCardTabs),
    MainParamsTab(),
    <PlantationTab />,
    <AdditionalInformationTab />,
    // Таб документов
    DocumentTab,
    OrdersTab(),
  ],
});
