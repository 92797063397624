import { Button } from 'core/uiKit/components';
import { TableSimple } from 'core/uiKit/components/tables/TableSimple';
import React from 'react';

import { getColumnsRepairsPlanTab } from './columns.RepairsPlanTab';
import { DialogRepairsInfoListPlan } from './Dialog.RepairsInfoListPlan';
import { useSetupRepairsInfoListPlanWithReduxFrom } from './useSetup.RepairsInfoListPlanWithReduxFrom';

/**
 * RF Таблица с диалоговым окном для таблицы __Проекты__.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.input - Значения формы RF.
 * @returns {JSX.Element}
 */
export const RepairsInfoListPlanWithReduxFrom = ({ input }) => {
  const { value, onChange } = input;
  const {
    closeDialog,
    dataTableRepairsPlanTab,
    editMode,
    isOpen,
    openDialog,
    removeRow,
    selectRow,
    disabledDeleteButton,
  } = useSetupRepairsInfoListPlanWithReduxFrom({ onChange, value });

  return (
    <>
      <DialogRepairsInfoListPlan
        closeDialog={closeDialog}
        isOpen={isOpen}
        onChange={onChange}
        value={value}
      />
      <div className="d-flex justify-content-end gap-1 my-2">
        <Button
          disabled={disabledDeleteButton}
          color={'error'}
          variant={'contained'}
          onClick={removeRow}
        >
          Удалить
        </Button>
        <Button
          disabled={!editMode}
          color={'success'}
          variant={'contained'}
          onClick={openDialog}
        >
          Добавить сведения о проектах
        </Button>
      </div>
      <TableSimple
        columns={getColumnsRepairsPlanTab()}
        data={dataTableRepairsPlanTab}
        checkboxSelection={editMode}
        selectedRows={selectRow}
      />
    </>
  );
};
