import { PlanarStructureTypeRefIdWithFF } from 'core/form/finalForm/fields/prepared/selects/simple/PlanarStructureTypeRefId.FF/PlanarStructureTypeRefIdWithFF';
import { PLANAR_STRUCTURE_TYPE_REF_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/PlanarStructureTypeRefId';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

 
/**
 * @typedef {import('node_modules/react-final-form/typescript/index.d.ts').Field} Field
 * @typedef {JSX.Element} SelectFF - Select Final Form.
 */

 
/**
 * @typedef {object} Props
 * @property {boolean} clear - Очищаем инпут с сохранением значения.
 * @property {boolean} dark - Функция для фильтрации options.
 */

/* eslint-disable */
/**
 * ## Уточнение Select FF.
 *
 * @param {object} props - Пропсы.
 * @param {string} props.name - Имя.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {React.ReactElement} - Компонент.
 * @example ---
 */
export const PlanarStructureTypeRefIdFF = ({ name, ...otherProps }) => {
  return (
    <Field name={PLANAR_STRUCTURE_TYPE_REF_NAME} component={PlanarStructureTypeRefIdWithFF} {...otherProps} />
  );
};

PlanarStructureTypeRefIdFF.propTypes = {
   
/**
   * Очищаем инпут с сохранением значения.
   */
  clear: PropTypes.bool,
   
/**
   * Быбор темной темы.
   */
  dark: PropTypes.bool,
   
/**
   * Функция для фильтрации options.
   */
  filter: PropTypes.func,
  /* eslint-disable */
/**
   * Заблокировать на редактирование.
   */
  disabled: PropTypes.bool,
   
/**
   * Вывод ошибки.
   */
  errorText: PropTypes.string,
   
/**
   * Вывод вспомогательного текста.
   */
  helperText: PropTypes.string,
   
/**
   * Placeholder.
   */
  placeholder: PropTypes.string,
   
/**
   * Вывод информации об обязательном поле.
   */
  required: PropTypes.bool,
};
PlanarStructureTypeRefIdFF.defaultProps = {
  disabled: false,
};
