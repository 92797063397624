import * as geometryApi from 'app/api/requests/geometry';
import { fetchNeighboursGeometry as getFetchNeighboursGeometry } from 'app/api/requests/geometry/fetchNeighboursGeometry';

import * as types from '../constants/actionTypes';

/* eslint-disable */
export const fetchObjectsGeometry = (data) => async (dispatch) => {
  dispatch(requestGeometry());
  try {
    const response = await geometryApi.fetchObjectsGeometry(data);
    return response;
  } finally {
    dispatch(receiveGeometry());
  }
};

function requestGeometry() {
  return {
    type: types.REQUEST_GEOMETRY,
  };
}

function receiveGeometry() {
  return {
    type: types.RECEIVE_GEOMETRY,
  };
}

/* eslint-disable */
export function toggleGeometryOfChildren() {
  return {
    type: types.TOGGLE_GEOMETRY_OF_CHILDREN,
  };
}

/* eslint-disable */
export const fetchGroupGeometry =
  (element, group, rootId) => async (dispatch) => {
    const data = {
      id: element.root ? rootId : element.id,
      typeId: group.type_id,
      ...(element.path_object_id
        ? { pathId: element.path_object_id }
        : { pathTypeId: group.type_path }),
      rootId: rootId,
    };
    dispatch(requestGroupGeometry());

    try {
      const response = await geometryApi.fetchGroupGeometry(data);
      return response;
    } finally {
      dispatch(receiveGroupGeometry());
    }
  };

function requestGroupGeometry() {
  return {
    type: types.REQUEST_GROUP_GEOMETRY,
  };
}

function receiveGroupGeometry() {
  return {
    type: types.RECEIVE_GROUP_GEOMETRY,
  };
}

/* eslint-disable */
export const fetchNeighboursGeometry = (data) => async (dispatch) => {
  dispatch(requestNeighboursGeometry());
  const response = await getFetchNeighboursGeometry(data);
  dispatch(receiveNeighboursGeometry(response.data));
  return response;
};

function requestNeighboursGeometry() {
  return {
    type: types.REQUEST_NEIGHBOURS_GEOMETRY,
  };
}

function receiveNeighboursGeometry(object) {
  return {
    type: types.RECEIVE_NEIGHBOURS_GEOMETRY,
    object,
  };
}

/* eslint-disable */
export function resetNeighboursGeometry() {
  return {
    type: types.RESET_NEIGHBOURS_GEOMETRY,
  };
}

/* eslint-disable */
export const fetchReonIntersectionsGeometry = (data) => async (dispatch) => {
  dispatch(requestReonIntersectionsGeometry(data));
  const response = await geometryApi.fetchReonIntersectionsGeometry(data);
  dispatch(receiveReonIntersectionsGeometry(response));
  return response;
};

function requestReonIntersectionsGeometry(object) {
  return {
    type: types.REQUEST_REON_INTERSECTIONS_GEOMETRY,
    object,
  };
}

function receiveReonIntersectionsGeometry(object) {
  return {
    type: types.RECEIVE_REON_INTERSECTIONS_GEOMETRY,
    object,
  };
}

/* eslint-disable */
export function resetReonIntersections() {
  return {
    type: types.RESET_REON_INTERSECTIONS,
  };
}

/* eslint-disable */
export const fetchCheckGeomContains = (data) => async (dispatch) => {
  dispatch(requestCheckGeomContains(data));
  const response = await geometryApi.fetchCheckGeomContains(data);
  dispatch(receiveCheckGeomContains(response));
  return response;
};

function requestCheckGeomContains(object) {
  return {
    type: types.REQUEST_CHECK_GEOM_CONTAINS,
    object,
  };
}

function receiveCheckGeomContains(object) {
  return {
    type: types.RECEIVE_CHECK_GEOM_CONTAINS,
    object,
  };
}

 
export const fetchCheckGeometryIntersections = (data) => async (dispatch) => {
  dispatch(requestCheckGeometryIntersections(data));
  const response = await geometryApi.fetchIntersectionsGeometry(data);
  dispatch(receiveCheckGeometryIntersections(response));
  return response;
};

/* eslint-disable */
export function resetIntersectionsGeometry() {
  return {
    type: types.RESET_INTERSECTIONS_GEOMETRY,
  };
}

function requestCheckGeometryIntersections(id) {
  return {
    type: types.REQUEST_CHECK_INTERSECTIONS,
    id,
  };
}

function receiveCheckGeometryIntersections(object) {
  return {
    type: types.RECEIVE_CHECK_INTERSECTIONS,
    object,
  };
}

/* eslint-disable */
export const fetchBtiGeometry = (data) => async (dispatch) => {
  dispatch(requestBtiGeometry(data));

  const response = await geometryApi.fetchBtiGeometry(data);
  dispatch(receiveBtiGeometry(response));
  return response;
};

function requestBtiGeometry(data) {
  return {
    type: types.REQUEST_BTI_GEOMETRY,
    data,
  };
}

function receiveBtiGeometry(object) {
  return {
    type: types.RECEIVE_BTI_GEOMETRY,
    object,
  };
}

/* eslint-disable */
export function resetBtiGeometry() {
  return {
    type: types.RESET_BTI_GEOMETRY,
  };
}

 
export const fetchGeometryForMainPage = (data) => async (dispatch) => {
  dispatch(requestGeometryForMainPage(data));
  const response = await geometryApi.fetchFullOghGeometry(data);
  dispatch(receiveGeometryForMainPage(response));
  return response;
};

/* eslint-disable */
export const fetchGeometryGroupForMainPage = (data) => async (dispatch) => {
  dispatch(requestGeometryForMainPage(data));
  const response = await geometryApi.fetchGroupGeometry(data);
  dispatch(receiveGeometryForMainPage(response));
  return response;
};

function requestGeometryForMainPage(object) {
  return {
    type: types.REQUEST_GEOMETRY_FOR_MAIN_PAGE,
    object,
  };
}

function receiveGeometryForMainPage(object) {
  return {
    type: types.RECEIVE_GEOMETRY_FOR_MAIN_PAGE,
    object,
  };
}
