import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { FieldsContainer } from 'app/pages/cardsOgh/components/FieldsContainer';
import { OghCardHeaderFF } from 'app/pages/cardsOgh/components/headerFF/OghHeaderFF';
import Versions from 'app/pages/cardsOgh/components/tables/Versions/Versions';
import { TabsCard } from 'app/pages/cardsOgh/components/TabsCard';
import { TabsCardItem } from 'app/pages/cardsOgh/components/TabsCard/TabsCardItem';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { CheckboxFF, NumberFieldFF } from 'core/form/finalForm/fields';
import { IsDiffHeightMarkFf } from 'core/form/finalForm/fields/prepared/checkboxes/IsDiffHeightMark.FF';
import { TotalAreaGeoFF } from 'core/form/finalForm/fields/prepared/numberField/TotalAreaGeo.FF';
import { WaterTypeIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/WaterTypeId.FF';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import React from 'react';

export const tabsTopHeaderWaterBuilding = [
  { label: 'Свойства', value: 'features' },
  { label: 'Версии', value: 'versions' },
];

/**
 * Поля Водных сооружений.
 *
 * @returns {JSX.Element} - Компонент.
 * @example <WaterBuildingLeftPanel />
 */
export const LeftPanelWaterBuilding = () => {
  const { editMode } = useEditMode();
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();
  return (
    <>
      <OghCardHeaderFF />
      <TabsCard tabs={tabsTopHeaderWaterBuilding}>
        <TabsCardItem tabValue={'features'}>
          <FieldsContainer>
            <WaterTypeIdFF
              withAll={false}
              disabled={!editMode || isCurrentObjectIsExternalSystem}
              required={true}
            />
            <NumberFieldFF
              name={'area'}
              label={'Площадь, кв.м.'}
              maxLength={10}
              required={true}
              disabled={!editMode || isCurrentObjectIsExternalSystem}
              digits={2}
            />
            <TotalAreaGeoFF decimalScale={2} disabled={true} />
            <ParentInfo />
          </FieldsContainer>
          <FieldsContainer>
            <CheckboxFF
              label={'Не учитывать'}
              name={'noCalc'}
              disabled={!editMode || isCurrentObjectIsExternalSystem}
            />
            <IsDiffHeightMarkFf
              disabled={!editMode || isCurrentObjectIsExternalSystem}
            />
          </FieldsContainer>
        </TabsCardItem>
        <TabsCardItem tabValue={'versions'}>
          <FieldsContainer>
            <Versions />
          </FieldsContainer>
        </TabsCardItem>
      </TabsCard>
    </>
  );
};
