import { get, isEmpty } from 'lodash';
/* eslint-disable  */
import { createSelector } from 'reselect';

/**
 *
 * @param state
 */
const getNeighbours = (state) => get(state, 'card.neighbours', []);

export default createSelector(
  [getNeighbours],
  (neighbours) => !isEmpty(neighbours),
);
