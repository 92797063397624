/* eslint-disable */
import { shallow } from 'enzyme';

import { RANDOM_BOOL } from 'core/forTesting/constants';
import { useFetchIntersectionsGeometry } from 'app/api/hooks/geometry/useFetchIntersectionsGeometry';

import { DialogIntersectionsApproveElectronicSignature } from './Dialog.Intersections.ApproveElectronicSignature';

jest.mock('app/api/hooks/geometry/useFetchIntersectionsGeometry');

/* eslint-disable */
describe('file Dialog.Intersections.ApproveElectronicSignature', () => {
  it('[snapshot]', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const onCloseDialogIntersectionsSpy = jest.fn();
    const onContinueDialogIntersectionsSpy = jest.fn();
    const isOpen = RANDOM_BOOL;
    useFetchIntersectionsGeometry.mockReturnValue({});

    // Act
    const wrapper = shallow(
      <DialogIntersectionsApproveElectronicSignature
        {...{
          onCloseDialogIntersections: onCloseDialogIntersectionsSpy,
          onContinueDialogIntersections: onContinueDialogIntersectionsSpy,
          isOpen,
        }}
      />,
    );
    // Assert
    expect(wrapper).toMatchSnapshot();
  });

  it('пропсы без никого', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const onCloseDialogIntersectionsSpy = jest.fn();
    const onContinueDialogIntersectionsSpy = jest.fn();
    const isOpen = RANDOM_BOOL;
    useFetchIntersectionsGeometry.mockReturnValue({});

    // Act
    const wrapper = shallow(
      <DialogIntersectionsApproveElectronicSignature
        {...{
          onCloseDialogIntersections: onCloseDialogIntersectionsSpy,
          onContinueDialogIntersections: onContinueDialogIntersectionsSpy,
          isOpen,
        }}
      />,
    );
    // Assert
    expect(wrapper.props()).toMatchObject({
      isOpen,
      closeDialog: onCloseDialogIntersectionsSpy,
      textHeader: 'Ошибка пересечения геометрий. Скорректируйте границы ОГХ.',
    });
    expect(wrapper.prop('Body')).toMatchSnapshot();
    expect(wrapper.prop('ButtonsActions')).toMatchSnapshot();
  });

  it('isLoading true', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const onCloseDialogIntersectionsSpy = jest.fn();
    const onContinueDialogIntersectionsSpy = jest.fn();
    const isOpen = RANDOM_BOOL;
    useFetchIntersectionsGeometry.mockReturnValue({
      isLoading: true,
    });

    // Act
    const wrapper = shallow(
      <DialogIntersectionsApproveElectronicSignature
        {...{
          onCloseDialogIntersections: onCloseDialogIntersectionsSpy,
          onContinueDialogIntersections: onContinueDialogIntersectionsSpy,
          isOpen,
        }}
      />,
    );
    // Assert
    expect(wrapper.props()).toMatchObject({
      isOpen,
      textHeader: 'Проверка на пересечение геометрии',
    });
    expect(wrapper.prop('Body')).toMatchSnapshot();
  });
  it('isLoading false', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const onCloseDialogIntersectionsSpy = jest.fn();
    const onContinueDialogIntersectionsSpy = jest.fn();
    const isOpen = RANDOM_BOOL;
    useFetchIntersectionsGeometry.mockReturnValue({
      isLoading: false,
    });

    // Act
    const wrapper = shallow(
      <DialogIntersectionsApproveElectronicSignature
        {...{
          onCloseDialogIntersections: onCloseDialogIntersectionsSpy,
          onContinueDialogIntersections: onContinueDialogIntersectionsSpy,
          isOpen,
        }}
      />,
    );
    // Assert
    expect(wrapper.props()).toMatchObject({
      textHeader: 'Ошибка пересечения геометрий. Скорректируйте границы ОГХ.',
    });
    expect(wrapper.prop('Body')).toMatchSnapshot();
  });

  it('textHeader | isLoading false & isallow: true', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const onCloseDialogIntersectionsSpy = jest.fn();
    const onContinueDialogIntersectionsSpy = jest.fn();
    const isOpen = RANDOM_BOOL;
    useFetchIntersectionsGeometry.mockReturnValue({
      isLoading: false,
      data: { isallow: true },
    });

    // Act
    const wrapper = shallow(
      <DialogIntersectionsApproveElectronicSignature
        {...{
          onCloseDialogIntersections: onCloseDialogIntersectionsSpy,
          onContinueDialogIntersections: onContinueDialogIntersectionsSpy,
          isOpen,
        }}
      />,
    );
    // Assert
    expect(wrapper.props()).toMatchObject({
      textHeader: 'Обнаружены пересечения геометрий. Продолжить?',
    });
  });
  it('textHeader | isLoading false & isallow: false', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const onCloseDialogIntersectionsSpy = jest.fn();
    const onContinueDialogIntersectionsSpy = jest.fn();
    const isOpen = RANDOM_BOOL;
    useFetchIntersectionsGeometry.mockReturnValue({
      isLoading: false,
      data: { isallow: false },
    });

    // Act
    const wrapper = shallow(
      <DialogIntersectionsApproveElectronicSignature
        {...{
          onCloseDialogIntersections: onCloseDialogIntersectionsSpy,
          onContinueDialogIntersections: onContinueDialogIntersectionsSpy,
          isOpen,
        }}
      />,
    );
    // Assert
    expect(wrapper.props()).toMatchObject({
      textHeader: 'Ошибка пересечения геометрий. Скорректируйте границы ОГХ.',
    });
  });

  it('intersected true', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const onCloseDialogIntersectionsSpy = jest.fn();
    const onContinueDialogIntersectionsSpy = jest.fn();
    const isOpen = RANDOM_BOOL;
    useFetchIntersectionsGeometry.mockReturnValue({
      data: { intersected: true },
    });

    // Act
    shallow(
      <DialogIntersectionsApproveElectronicSignature
        {...{
          onCloseDialogIntersections: onCloseDialogIntersectionsSpy,
          onContinueDialogIntersections: onContinueDialogIntersectionsSpy,
          isOpen,
        }}
      />,
    );

    // Assert
    expect(onContinueDialogIntersectionsSpy).not.toHaveBeenCalled();
  });

  it('intersected вобще нет data', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const onCloseDialogIntersectionsSpy = jest.fn();
    const onContinueDialogIntersectionsSpy = jest.fn();
    const isOpen = RANDOM_BOOL;
    useFetchIntersectionsGeometry.mockReturnValue({});

    // Act
    shallow(
      <DialogIntersectionsApproveElectronicSignature
        {...{
          onCloseDialogIntersections: onCloseDialogIntersectionsSpy,
          onContinueDialogIntersections: onContinueDialogIntersectionsSpy,
          isOpen,
        }}
      />,
    );

    // Assert
    expect(onContinueDialogIntersectionsSpy).not.toHaveBeenCalled();
  });

  it('intersected 0', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const onCloseDialogIntersectionsSpy = jest.fn();
    const onContinueDialogIntersectionsSpy = jest.fn();
    const isOpen = RANDOM_BOOL;
    useFetchIntersectionsGeometry.mockReturnValue({
      data: { intersected: 0 },
    });

    // Act
    shallow(
      <DialogIntersectionsApproveElectronicSignature
        {...{
          onCloseDialogIntersections: onCloseDialogIntersectionsSpy,
          onContinueDialogIntersections: onContinueDialogIntersectionsSpy,
          isOpen,
        }}
      />,
    );

    // Assert
    expect(onContinueDialogIntersectionsSpy).not.toHaveBeenCalled();
  });
  it('intersected false', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const onCloseDialogIntersectionsSpy = jest.fn();
    const onContinueDialogIntersectionsSpy = jest.fn();
    const isOpen = RANDOM_BOOL;
    useFetchIntersectionsGeometry.mockReturnValue({
      data: { intersected: false },
    });

    // Act
    shallow(
      <DialogIntersectionsApproveElectronicSignature
        {...{
          onCloseDialogIntersections: onCloseDialogIntersectionsSpy,
          onContinueDialogIntersections: onContinueDialogIntersectionsSpy,
          isOpen,
        }}
      />,
    );

    // Assert
    expect(onContinueDialogIntersectionsSpy).toHaveBeenCalled();
  });
  it('ButtonsActions | isallow true', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const onCloseDialogIntersectionsSpy = jest.fn();
    const onContinueDialogIntersectionsSpy = jest.fn();
    const isOpen = RANDOM_BOOL;
    useFetchIntersectionsGeometry.mockReturnValue({
      data: { isallow: true },
    });

    // Act
    const wrapper = shallow(
      <DialogIntersectionsApproveElectronicSignature
        {...{
          onCloseDialogIntersections: onCloseDialogIntersectionsSpy,
          onContinueDialogIntersections: onContinueDialogIntersectionsSpy,
          isOpen,
        }}
      />,
    );
    // Assert
    expect(wrapper.prop('ButtonsActions')).toMatchSnapshot();
  });

  it('ButtonsActions | isallow false', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const onCloseDialogIntersectionsSpy = jest.fn();
    const onContinueDialogIntersectionsSpy = jest.fn();
    const isOpen = RANDOM_BOOL;
    useFetchIntersectionsGeometry.mockReturnValue({
      data: { isallow: false },
    });

    // Act
    const wrapper = shallow(
      <DialogIntersectionsApproveElectronicSignature
        {...{
          onCloseDialogIntersections: onCloseDialogIntersectionsSpy,
          onContinueDialogIntersections: onContinueDialogIntersectionsSpy,
          isOpen,
        }}
      />,
    );
    // Assert

    expect(wrapper.prop('ButtonsActions')).toMatchSnapshot();
  });
});
