import { elementFormGrid } from 'app/components/card/common/grid/index';
import { WithReduxFormSelect } from 'app/components/common/SelectField';


/* eslint-disable */
export default function (params = {}) {
  const cols = 1;
  const title = '';
  return (props) =>
    elementFormGrid(
      getElements(params, props),
      cols,
      title,
      props.card.type_id,
    );
}

function getElements(params = {}, props) {
  const { editable } = params || null;
  const elementEditable = editable && props.editable;
  return [
    {
      id: 'department_id',
      name: 'department_id',
      editable: elementEditable,
      formValue: true,
      component: WithReduxFormSelect,
      addition: {
        label: 'Отраслевой ОИВ',
        options: props.departmentTypes,
        required: props.required,
      },
    },
  ];
}
