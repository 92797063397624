/* eslint-disable  */

import { validate as validateAquaArea } from 'app/components/card/ogh/ImprovementTerritoryCard/components/AquaAreaTab';
import { validate as validateSnowArea } from 'app/components/card/ogh/ImprovementTerritoryCard/components/SnowAreaTab';

import { validate } from './validate';

jest.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/components/AquaAreaTab',
);
jest.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/components/SnowAreaTab',
);
describe('spec validate', function () {
  it('should', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    (validateAquaArea as jest.Mock).mockReturnValue({ c: 3 });
    (validateSnowArea as jest.Mock).mockReturnValue({ b: 3 });
    // Act
    const res = validate({ a: 4 });
    // Assert
    expect(validateAquaArea).toBeCalledWith({ a: 4 });
    expect(validateSnowArea).toBeCalledWith({ a: 4 });

    expect(res).toStrictEqual({ b: 3, c: 3 });
  });
});
