import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { LAMPPOSTS } from 'app/constants/oghTypes';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';

export const MATERIAL_ID_NAME = 'material_id';

const codes = ['metal', 'tree', 'reinf_concret'];

/**
 * Материал.
 *
 * @param {*} props - Пропсы.
 * @returns {JSX.Element}
 */
export const MaterialId = (props) => {
  const { material = [], typeId } = useGetCurrentCard();
  const { editMode } = useMode();

  const options =
    typeId === LAMPPOSTS
      ? material.filter((item) => codes.includes(item.code))
      : material;

  return (
    <Select
      name={MATERIAL_ID_NAME}
      label={'Материал опоры'}
      {...props}
      disabled={!editMode || props.disabled}
      options={options}
    />
  );
};
