import { getRequiredDocumentCloseField } from 'app/components/card/ogh/index/card/components/CloseElectronicSignature/fields/DocumentCloseFileField/getRequiredDocumentCloseField';
import { DOCUMENT_CLOSE_FILE_NAME } from 'app/components/card/ogh/index/card/components/CloseElectronicSignature/fields/DocumentCloseFileField/index';
import { getDateMinusDays } from 'app/utils/date/getDateMinusDays';
import { SIGNATURES_NAME } from 'core/form/finalForm/fields/prepared/FileSignatureCryptoProFF/index';
import { Validator } from 'core/form/Validator';
import { SEND_REASON_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/SendReason/index';

import { DATE_CLOSE_FIELD_NAME } from './fields/DateCloseField/DateCloseField';

/**
 * Function validate submit form.
 *
 * @param values - Значения формы.
 * @param values.sendReason - Поле sendReason.
 * @param values.date - Поле Дата закрытия.
 * @returns {object | null}
 */
export const formValidationCloseElectronicSignature = (values: {
  sendReason: number[];
  date: Date;
}): Record<string, string> | null => {
  const validator = new Validator(values);

  if (+getDateMinusDays(2) > +values.date) {
    validator.addError(
      DATE_CLOSE_FIELD_NAME,
      'Дата закрытия не может быть раньше вчера. Укажите корректное значение',
    );
  }

  if (getRequiredDocumentCloseField(values[SEND_REASON_NAME])) {
    validator.setRequired(
      DOCUMENT_CLOSE_FILE_NAME,
      'Загрузите документ-основание закрытия ОГХ. Допустимые форматы - .JPEG, .PNG, .PDF, .JPG',
    );
  }

  validator.setFilesValidate(DOCUMENT_CLOSE_FILE_NAME, {
    byExtensions: ['jpeg', 'png', 'pdf', 'jpg'],
    maxFiles: 100,
  });
  validator.setRequired(SEND_REASON_NAME);
  validator.setRequired(
    SIGNATURES_NAME,
    'Выберите сертификат для подписи и нажмите подписать',
  );

  if ((values.sendReason || []).includes(46)) {
    validator.setRequired('id_ogh_uni');
  }

  return validator.getErrors();
};
