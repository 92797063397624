import { IMPROVEMENT_OBJECT, ODH, OZN, YARD } from 'app/constants/oghTypes';
/* eslint-disable */
const TYPE_ID_CODE = {
  [YARD]: 'yard',
  [IMPROVEMENT_OBJECT]: 'improvement_object',
  [OZN]: 'ozn',
  [ODH]: 'odh',
};


 
export default (typeId) => (TYPE_ID_CODE[typeId] ? TYPE_ID_CODE[typeId] : '');
