import { createAttribute as createDocumentsAttribute } from 'app/components/card/ogh/DocumentsTab';
import { createAttribute as createAddressListFieldAttribute } from 'app/components/card/ogh/ImprovementTerritoryCard/components/AddressListField';
import { createAttribute as createAquaAreaAttribute } from 'app/components/card/ogh/ImprovementTerritoryCard/components/AquaAreaTab';
import { createAttribute as improvementCategoriesCreateAttribute } from 'app/components/card/ogh/ImprovementTerritoryCard/components/ImprovementCategories';
import { createAttribute as createOwnerAndCustomerFieldsAttribute } from 'app/components/card/ogh/ImprovementTerritoryCard/components/OwnerAndCustomerFields';
import { createAttribute as createRepairsPlanAttribute } from 'app/components/card/ogh/ImprovementTerritoryCard/components/RepairsPlanTab';
import { createAttribute as createRepairsAttribute } from 'app/components/card/ogh/ImprovementTerritoryCard/components/RepairsTab';

const ids = {
  address: 're_calc_address_list',
  name: 're_calc_name',
};

/**
 * CreateAttribute.
 *
 * @param formValues - The form values.
 * @returns Возвращает атрибуты.
 */
export function createAttribute(formValues: object) {
  return {
    // @ts-ignore
    ...createAddressListFieldAttribute(formValues),
    // @ts-ignore
    ...createRepairsAttribute(formValues),
    // @ts-ignore
    ...createRepairsPlanAttribute(formValues),
    // @ts-ignore
    ...improvementCategoriesCreateAttribute(formValues),
    // @ts-ignore
    ...createOwnerAndCustomerFieldsAttribute(formValues),
    // @ts-ignore
    ...createDocumentsAttribute(formValues),
    // @ts-ignore
    ...createAquaAreaAttribute(formValues),
    // @ts-ignore
    [ids.name]: null,
    [ids.address]: null,
  };
}
