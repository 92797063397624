/* eslint-disable  */
import {
  CUSTOMER_OZN_NAME,
  CustomerOzn,
} from 'core/uiKit/preparedInputs/selects/autocompleteAsync/CustomerOzn';
import { Field } from 'redux-form';

/**
 *
 * @param root0
 * @param root0.input
 */
const CustomerOznRFWithReduxForm = ({ input, ...props }) => {
  return <CustomerOzn {...input} {...props} />;
};

/* eslint-disable */
export const CustomerOznRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={CUSTOMER_OZN_NAME}
      component={CustomerOznRFWithReduxForm}
    />
  );
};
