// 10 Знаки, указатели и информационные щиты
/* eslint-disable  */

import CardAbstract from 'app/components/card/common/CardAbstract';
import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid/index';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { parseAsyncAutocomplete } from 'app/components/card/common/parseAsyncAutocomplete';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import { PlacementIdRFWrapper } from 'app/components/card/ogh/components/selects/PlacementIdRFWrapper';
import DocumentsTab, {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import { ODH_AXIS_TOOLTIP } from 'app/constants/messages';
import { column } from 'app/constants/tables';
import getDefaultFieldValues from 'app/selectors/card/getDefaultFieldValues';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import filterDictBySprNumber from 'app/utils/card/filterDictBySprNumber';
import isMandatory, { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { TrafficSignsIdRF } from 'core/form/reduxForm/fields/prepared/selects/autocompleteAsync/TrafficSignsId.RF';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['road_signs'];
const Versions = VersionsTemplate(types);

const tabs = [
  { id: 'characteristics-tab', name: 'Исходные данные' },
  { id: 'docs-tab', name: 'Документы' },
];

const labelStyles = {
  height: 33,
};

const heightLabelStyles = {
  height: 24,
};

const fieldFullWidthStyles = {
  width: 'calc(100% - 48px)',
};

const propTypes = {
  card: PropTypes.object,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  mode: PropTypes.object,
  required: PropTypes.bool,
};

/**
 * 10 Дорожные знаки, указатели и информационные щиты.
 */
class RoadSignsCard extends React.Component {
  /**
   * GetMountingModeOptions.
   *
   * @returns {Array<string>}
   */
  getMountingModeOptions() {
    const { type_id, mounting_mode, viewing } = this.props.card;
    if (viewing) {
      return mounting_mode;
    }

    return filterDictBySprNumber(mounting_mode, type_id, 'mounting_mode');
  }

  /**
   * GetEquipmentTypeOptions.
   *
   * @returns {Array<string>}
   */
  getEquipmentTypeOptions() {
    const { type_id, equipment_type } = this.props.card;

    if (!this.props.editMode) {
      return equipment_type;
    }

    return filterDictBySprNumber(equipment_type, type_id, 'equipment_type');
  }

  /**
   * Render.
   *
   * @returns {JSX.Element}
   */
  render() {
    const {
      mode,
      card,
      createMode,
      editMode,
      required,
      documents,
      isCurrentObjectIsExternalSystem,
    } = this.props;

    return (
      <CardAbstract>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(card.type_id)}

          <div id="props">
            {elementFormGrid(
              [
                {
                  addition: {
                    label: 'Тип',
                    options: this.getEquipmentTypeOptions(),
                    required: isMandatory(
                      card.type_id,
                      'equipment_type_id',
                      required,
                    ),
                  },
                  component: WithReduxFormSelect,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'equipment_type_id',
                  name: 'equipment_type_id',
                },
                <div>
                  <ParentInfo />
                </div>,
              ],
              column.TWO,
              '',
              card.type_id,
            )}

            <Tabs tabs={tabs} />

            <div id="characteristics">
              {elementFormGrid(
                [
                  <div>
                    <PlacementIdRFWrapper disabled={true} withAll={false} />
                  </div>,
                  {
                    addition: {
                      formatChars: {
                        9: '[а-яА-Я0-9a-zA-Z]',
                      },
                      mask: '99',
                      tooltipText: ODH_AXIS_TOOLTIP,
                      type: 'text',
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'odh_axis',
                    label: 'Ось',
                    name: 'odh_axis',
                  },
                  {
                    addition: {
                      label: 'Сторона',
                      options: card.odh_side,
                      required: isMandatory(
                        card.type_id,
                        'odh_side_id',
                        required,
                      ),
                    },
                    component: WithReduxFormSelect,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'odh_side_id',
                    name: 'odh_side_id',
                  },
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      required: isMandatory(card.type_id, 'endwise', required),
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'endwise',
                    label: 'По оси, м',
                    name: 'endwise',
                  },
                ],
                column.TWO,
                'Местоположение',
                card.type_id,
              )}

              {elementFormGrid(
                [
                  <div>
                    <TrafficSignsIdRF
                      disabled={!(editMode && !isCurrentObjectIsExternalSystem)}
                    />
                  </div>,

                  {
                    addition: {
                      digits: 2,
                      labelStyle: labelStyles,
                      positive: true,
                      required: isMandatory(card.type_id, 'area', required),
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'area',
                    label: 'Площадь знака, указателя, кв.м',
                    name: 'area',
                  },
                  {
                    addition: {
                      label: 'Тип установки',
                      options: this.getMountingModeOptions(),
                      required: isMandatory(
                        card.type_id,
                        'mounting_mode_id',
                        required,
                      ),
                    },
                    component: WithReduxFormSelect,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'mounting_mode_id',
                    name: 'mounting_mode_id',
                  },
                  {
                    addition: {
                      digits: 2,
                      labelStyle: heightLabelStyles,
                      positive: true,
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    editable: false,
                    formValue: true,
                    id: 'height',
                    label:
                      'Высота расположения по низу, м (исключено с 04.04.2023)',
                    name: 'height',
                  },
                  {
                    addition: { type: 'text' },
                    component: renderCustomTextField,
                    editable: editMode,
                    formValue: true,
                    id: 'description',
                    label: 'Примечание',
                    name: 'description',
                    style: fieldFullWidthStyles,
                  },
                ],
                column.TWO,
                'Характеристики',
                card.type_id,
              )}
            </div>

            {DocumentsTab()({
              documents,
              editable: editMode,
              typeId: card.type_id,
            })}
          </div>

          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={createMode}
              key={`versions-${card.root_id}`}
            />
          </div>
        </div>
      </CardAbstract>
    );
  }
}

RoadSignsCard.propTypes = propTypes;

const ajaxFields = ['traffic_signs_id'];

const dictFields = [
  'odh_side_id',
  'equipment_type_id',
  'mounting_mode_id',
  'placement_id',
];

const numericFields = [
  { name: 'endwise', type: 'decimal', zero: true },
  { name: 'height', positive: true, type: 'decimal' },
  { name: 'area', positive: true, type: 'decimal', zero: false },
];

/**
 * CreateAttribute.
 *
 * @param {{}} values - The form values.
 * @returns {{}}
 */
const validate = (values) => validateNumeric(values, numericFields);

const roadSignsCardDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * CreateAttribute.
 *
 * @param {{}} formValues - The form values.
 * @returns {{}}
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

/**
 * OnSubmit.
 *
 * @param {{}} formValues - The form values.
 * @returns {void}
 */
function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };
  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );
  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      ajaxFields,
      dictFields,
      numericFields,
    }),
  );
}

/**
 * MapStateToProps.
 *
 * @param {{}} state - The state values.
 * @param {{}} props - The props values.
 * @returns {{}}
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);
  const defaultFieldValues = getDefaultFieldValues(state, props);
  const result = {
    createMode: isCreateMode(state, props),

    editMode: isEditMode(state, props),

    enableReinitialize: true,
    initialValues: {
      area: parse('area'),
      customer_id: parse('customer_id'),
      description: parse('description'),
      endDate: parseDate(props.card.end_date),
      endwise: parse('endwise'),
      equipment_type_id: parse('equipment_type_id.equipment_type'),
      height: parse('height'),
      mounting_mode_id:
        defaultFieldValues.mounting_mode_id ||
        parse('mounting_mode_id.mounting_mode'),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      owner_id: parse('owner_id'),
      parent_name: props.card.parent_info,
      placement_id: parse('placement_id.placement'),
      startDate: parseDate(props.card.start_date),
      traffic_signs_id: parseAsyncAutocomplete(
        props.card.attribute.traffic_signs_id,
      ),
    },
    onSubmit: onSubmit.bind({ props }),

    required: isRequired(state, props),
    validate,
  };
  return roadSignsCardDocumentsReducer(result, state, props);
};

/**
 * MapDispatchToProps.
 *
 * @param {Function} dispatch - The dispatch function.
 * @returns {{changeFieldValue: mapDispatchToProps.changeFieldValue}}
 */
const mapDispatchToProps = (dispatch) => ({
  /**
   *
   * @param field
   * @param value
   */
  changeFieldValue: (field, value) => {
    dispatch(change('editorCard', field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(RoadSignsCard));
