/* eslint-disable */
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import { CarCustomers, CAR_CUSTOMERS_NAME } from 'core/uiKit/preparedInputs';

const CarCustomersWithReduxForm = ({ input, ...props }) => {
  return <CarCustomers {...input} {...props} />;
};

 
export { CAR_CUSTOMERS_NAME };

 
 
/**
 * ## Заказчик
 *
 * ### Redux Form Field
 *
 * * `type = AutocompleteMultiAsync`
 * * `name = customers`
 */
/* eslint-disable */
export const CarCustomersRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={CAR_CUSTOMERS_NAME}
      component={CarCustomersWithReduxForm}
    />
  );
};

CarCustomersRF.propTypes = {
   
   
/**
   * быбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * placeholder
   */
  placeholder: PropTypes.string,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
};
CarCustomersRF.defaultProps = {
  disabled: false,
};
