import { FieldsContainer } from 'app/pages/cardsOgh/components/FieldsContainer';
import { getNameFile } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getNameFile';
import { Field } from 'redux-form';

import { DocumentWithReduxForm } from '../DocumentWithReduxForm';

const ID = 20;
const NAME = getNameFile(ID);
const TITLE =
  'Акты сдачи-приемки выполненных работ по изготовлению цифровой геоподосновы';

/**
 * Таблица документов __Акты сдачи-приемки выполненных работ по изготовлению цифровой геоподосновы__.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - JSX.
 * @example
 * <ActOfCompletedWorkRF />
 */
export const ActOfCompletedWorkRF = (props) => {
  return (
    <FieldsContainer>
      <Field
        {...props}
        title={TITLE}
        id={ID}
        name={NAME}
        filesFormat={['tiff', 'jpeg', 'pdf', 'png', 'jpg']}
        component={DocumentWithReduxForm}
      />
    </FieldsContainer>
  );
};
