import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';

/**
 * Поле Наименование.
 *
 * @param {*} root0 - A.
 * @param {*} root0.editable - A.
 * @param {*} root0.isCurrentObjectIsExternalSystem - A.
 * @returns {JSX.Element} - Возвращает поле Наименование.
 */
export const NameField = ({ editable, isCurrentObjectIsExternalSystem }) => {
  return (
    <TextFieldRF
      name={'name'}
      label={'Наименование'}
      disabled={!editable || isCurrentObjectIsExternalSystem}
      required={true}
    />
  );
};
