import { render, screen } from '@testing-library/react';
import React, { FC } from 'react';

/* eslint-disable */
import { Characteristics } from './Characteristics';
jest.mock('redux-form', () => {
  /**
   * Mock Field от redux form.
   *
   * @param {*} p - Prop.
   * @param {*} p.component - Component.
   * @param {*} p.props - Properties.
   * @returns {JSX}
   */
  const Field = ({
    component,
    ...props
  }: {
    component: FC<unknown>;
    props: object;
  }) => {
    const Component = component;
    // @ts-ignore
    return <Component {...props} />;
  };
  return {
    ...jest.requireActual('redux-form'),
    Field,
  };
});
jest.mock(
  'core/uiKit/preparedInputs/selects/simpleSelect/CoatingTypeId/CoatingTypeId',
);

test('Characteristics.jestSpec', async () => {
  expect.hasAssertions();
  // Arrange (всякие моки)
  // Act
  const { rerender } = render(
    <Characteristics
      {...{
        card: { coating_group: [1, 2] },

        /**
         *
         * @param _
         */
        changeFieldValue: (_: unknown): unknown => _,
        editMode: false,
        isCurrentObjectIsExternalSystem: false,
        isPointGeometry: true,
        required: true,
        typeId: 5,
      }}
    />,
  );

  // Assert
  expect(screen.getByTestId('coating_type_id')).toHaveAttribute(
    'aria-disabled',
  );

  rerender(
    <Characteristics
      {...{
        card: { coating_group: [1, 2] },

        /**
         *
         * @param _
         */
        changeFieldValue: (_: unknown): unknown => _,
        editMode: true,
        isCurrentObjectIsExternalSystem: false,
        isPointGeometry: true,
        required: true,
        typeId: 5,
      }}
    />,
  );

  // Assert
  expect(screen.getByTestId('coating_type_id')).not.toHaveAttribute(
    'aria-disabled',
  );

  rerender(
    <Characteristics
      {...{
        card: { coating_group: [1, 2] },

        /**
         *
         * @param _
         */
        changeFieldValue: (_: unknown): unknown => _,
        editMode: true,
        isCurrentObjectIsExternalSystem: true,
        isPointGeometry: true,
        required: true,
        typeId: 5,
      }}
    />,
  );

  // Assert
  expect(screen.getByTestId('coating_type_id')).toHaveAttribute(
    'aria-disabled',
  );
});
