import { useSetupRepairsInfoListPlanWithReduxFrom } from 'app/components/card/ogh/ImprovementTerritoryCard/components/RepairsPlanTab/useSetup.RepairsInfoListPlanWithReduxFrom';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { RANDOM_ID, RANDOM_WORD } from 'core/forTesting/constants';
import { useMemo, useState } from 'react';
import { vi } from 'vitest';

vi.mock('react', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    useMemo: vi.fn(),
    useState: vi.fn(),
  };
});

vi.mock('app/pages/cardsOgh/hooks/useMode');

/**
 * Дефолтные моки.
 *
 * @returns {object}
 */
const defaultMock = () => {
  const setState = vi.fn();
  useState.mockImplementation((init) => [init, setState]);
  useMode.mockReturnValue({ editMode: true });
  useMemo.mockReturnValue([]);

  return { setState };
};

describe('UseSetupRepairsInfoListPlanWithReduxFromSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    defaultMock();
    const onChange = vi.fn();
    const params = {
      onChange,
      value: { table: null },
    };
    // 🔥 Act

    // ❓ Assert
    expect(useSetupRepairsInfoListPlanWithReduxFrom(params)).toStrictEqual({
      closeDialog: expect.any(Function),
      dataTableRepairsPlanTab: [],
      disabledDeleteButton: true,
      editMode: true,
      isOpen: false,
      openDialog: expect.any(Function),
      removeRow: expect.any(Function),
      selectRow: expect.any(Function),
    });
  });
  it('open closeDialog', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const { setState } = defaultMock();
    const onChange = vi.fn();
    const params = {
      onChange,
      value: { table: null },
    };
    // 🔥 Act
    const { openDialog, closeDialog } =
      useSetupRepairsInfoListPlanWithReduxFrom(params);
    openDialog();
    closeDialog();
    // ❓ Assert
    expect(setState).toHaveBeenCalledWith(false);
    expect(setState).toHaveBeenCalledWith(true);
  });
  it('selectRow', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const { setState } = defaultMock();
    const onChange = vi.fn();
    const params = {
      onChange,
      value: { table: null },
    };
    // 🔥 Act
    const { selectRow } = useSetupRepairsInfoListPlanWithReduxFrom(params);
    selectRow({ selectedFlatRows: [{ original: { id: RANDOM_ID } }] });
    // ❓ Assert
    expect(setState).toHaveBeenCalledWith([RANDOM_ID]);
  });
  it('removeRow', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const { setState } = defaultMock();
    useState.mockImplementation(() => [[1], setState]);
    const onChange = vi.fn();
    const params = {
      onChange,
      value: { table: [{ id: 1 }, { id: 2 }] },
    };
    // 🔥 Act
    const { removeRow } = useSetupRepairsInfoListPlanWithReduxFrom(params);
    removeRow();
    // ❓ Assert
    expect(onChange).toHaveBeenCalledWith({
      table: [{ id: 2 }],
    });
  });
  it('dataTableRepairsPlanTab', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    defaultMock();
    useMemo.mockImplementation((fn) => fn());
    const onChange = vi.fn();
    const params = {
      onChange,
      value: { table: [{ file_list: [{ files: RANDOM_WORD }], id: 1 }] },
    };
    // 🔥 Act
    const { dataTableRepairsPlanTab } =
      useSetupRepairsInfoListPlanWithReduxFrom(params);
    // ❓ Assert
    expect(dataTableRepairsPlanTab).toStrictEqual([
      {
        file_list: [RANDOM_WORD],
        id: 1,
      },
    ]);
  });
});
