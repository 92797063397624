import { snake_case } from 'core/utils/snake_case';

import { arrange_element_type } from './arrange_element_type';
import { bid_years } from './bid_years';
import { category_sp } from './category_sp';
import { clean_category } from './clean_category';
import { clean_type } from './clean_type';
import { coating_group } from './coating_group';
import { districts } from './districts';
import { dts_type } from './dtsType';
import { engineer_building_type } from './engineer_building_type';
import { geometry_type } from './geometry_type';
import { grbs } from './grbs';
import { odh_registry_type } from './odh_registry_type';
import { ogh_types } from './ogh_types';
import { okrugs } from './okrugs';
import { owners } from './owners';
import { passportization_type } from './passportization_type';
import { redBookPlant } from './redBookPlant';
import { schedule_status } from './schedule_status';
import { tl_types } from './tl_types';

const dicts = {
  arrange_element_type,
  bid_create_type: [
    {
      id: 1,
      name: 'Пользователь',
    },
    {
      id: 2,
      name: 'Автоматически',
    },
  ],
  bid_reason: [
    {
      id: 1,
      name: 'Новый объект',
    },
    {
      id: 2,
      name: 'Разделение объектов',
    },
    {
      id: 3,
      name: 'Объединение объектов',
    },
    {
      id: 4,
      name: 'Изменение характеристик объекта/элементов объекта',
    },
  ],
  bid_status: [
    {
      id: 1,
      name: 'Проект',
    },
    {
      id: 2,
      name: 'Отправлена на согласование',
    },
    {
      id: 3,
      name: 'Отклонена',
    },
    {
      id: 4,
      name: 'Согласована ОИВ',
    },
    {
      id: 5,
      name: 'Согласована МКА',
    },
    {
      id: 6,
      name: 'Включена в график паспортизации',
    },
    {
      id: 7,
      name: 'Аннулирована',
    },
    {
      id: 8,
      name: 'Исполнена',
    },
  ],
  bid_years,
  category_sp,
  clean_category,
  clean_type,
  coating_group,
  districts,
  dts_type,
  engineer_building_type,
  geometry_type,
  grbs,
  odh_registry_type,
  ogh_types,
  okrugs,
  owners,
  passportization_type,
  redBookPlant,
  schedule_status,
  tl_types,
};

/**
 * Mock for useGetDictionary.
 *
 * @param dict - The dictionary.
 * @returns - The dictionary.
 */
const useGetDictionary = (dict: string) => {
  // @ts-ignore
  return { data: dicts[snake_case(dict)] || [] };
};

export { useGetDictionary };
