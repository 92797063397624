import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { HOLE, LINESTRING, POINT, POLYGON } from 'app/constants/geometryTypes';
import IconButton from 'core/newComponents/IconButton';
import PropTypes from 'prop-types';
import React from 'react';

import content from '../content/draw';

/**
 * Кнопка рисовать геометрию.
 *
 * @param {object} props - Пропсы.
 * @param {boolean} props.active - Флаг активности.
 * @param {string} props.geometryType - Тип геометрии.
 * @param {boolean} props.disabled - Флаг disabled.
 * @param {Function} props.onClick - Функция клика.
 * @returns {JSX.Element}
 */
const DrawButton = ({ active, geometryType, disabled, onClick }) => {
  const className = active ? 'active boxArea' : 'icon boxArea';
  const { drawFunction, ...rest } = content[geometryType];

  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();

  return (
    <Button
      content={{ ...rest, className }}
      disabled={disabled || isCurrentObjectIsExternalSystem}
      onClick={() => onClick(active, geometryType, drawFunction)}
    ></Button>
  );
};

DrawButton.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  geometryType: PropTypes.oneOf([LINESTRING, POINT, POLYGON, HOLE]),
  onClick: PropTypes.func.isRequired,
};

/**
 * Кнопка.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.content - Контент.
 * @param {Function} props.onClick - Функция клика.
 * @param {boolean} props.disabled - Флаг disabled.
 * @returns {JSX.Element}
 */
const Button = ({ content, onClick, disabled }) => {
  const { title, className, icon } = content;
  return (
    <IconButton
      className={`${className} button-toggle`}
      disabled={disabled}
      title={title}
      onClick={onClick}
    >
      <i className="material-icons">{icon}</i>
    </IconButton>
  );
};

Button.propTypes = {
  content: PropTypes.shape({
    className: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DrawButton;
