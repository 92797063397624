import { createMessage } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/NeighboursButton/helpers/createMessage';
import { createObjectForMap } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/NeighboursButton/helpers/createObjectForMap';
import toArray from 'app/utils/toArray';

import messages from './messages';

/* eslint-disable */
/**
 * Обработка нажатия кнопки Показать смежные объекты.
 *
 * @param {object} params - Параметры.
 * @returns {Promise<{headerData: null, bodyData: null}|{headerData: JSX.Element, bodyData: JSX.Element}|{headerData: string, bodyData: string}>} - Жопа.
 * @example
 * const { bodyData, headerData } = await onGetNeighboursButtonPress({
 *   data,
 *   hasNeighbours,
 *   resetNeighboursGeometry,
 *   fetchNeighboursGeometry,
 *   showAlert,
 *   draw: this.context.drawNeighboursGeometry,
 * });
 */
export async function onGetNeighboursButtonPress(params) {
  const { hasNeighbours, resetNeighboursGeometry } = params;

  if (hasNeighbours) {
    resetNeighboursGeometry();
    return { headerData: null, bodyData: null };
  } else {
    return await getNeighboursGeometries(params);
  }
}

/* eslint-disable */
/**
 * Получение Смежных объектов.
 *
 * @param {object} params - Параметры.
 * @returns {Promise<{headerData: JSX.Element, bodyData: JSX.Element}|{headerData: string, bodyData: string}>} - Жопа.
 * @example
 * return await getNeighboursGeometries(params);
 */
async function getNeighboursGeometries(params) {
  const { data, fetchNeighboursGeometry, draw } = params;
  const object = await fetchNeighboursGeometry(data);
  const neighbours = toArray(object.data);
  const total = object.count;

  if (neighbours && neighbours.length > 0) {
    const items = neighbours.map((item) => createObjectForMap(item));
    draw(items);
    const { headerData, bodyData } = createMessage(
      neighbours,
      total,
      messages.neighbours,
      data,
    );
    return { headerData: headerData, bodyData: bodyData };
  } else {
    return {
      headerData: '',
      bodyData: `Смежные объекты на расстоянии до ${object.radius} м. отсутствуют`,
    };
  }
}
