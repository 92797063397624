/* eslint-disable */
import './Panel.scss';

import { WithMapContext } from 'app/components/map/withMap';
import { HOLE, LINESTRING, POINT, POLYGON } from 'app/constants/geometryTypes';
import { Dialogs } from 'app/pages/cardsOgh/components/DIalogs/Dialogs';
import { NeighboursButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/NeighboursButton';
import { ShowChildrenButton } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/ShowChildrenButton';
import { ShowDistrictBoundaries } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/ShowDistrictBoundaries/ShowDistrictBoundaries';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import * as B from './buttons';

const propTypes = {
  allowedGeometryTypes: PropTypes.array.isRequired,
  card: PropTypes.object,
  currentCard: PropTypes.object,
  editable: PropTypes.bool.isRequired,
  mode: PropTypes.object,
  showAlert: PropTypes.func.isRequired,
};

/**
 * Класс MapEditPanel.
 *
 * @class MapEditPanel
 * @returns {JSX.ElementClass} - Жопа.
 */
export default class MapEditPanel extends React.Component {
  /**
   * Конструктор.
   *
   * @param {object} props - Хопа.
   * @example -
   */
  constructor(props) {
    super(props);

    /**
     *
     * @type {{activeGeometry: null}}
     */
    this.state = {
      activeGeometry: null,
    };
  }

  static contextType = WithMapContext;

  /**
   * UNSAFE_componentWillReceiveProps.
   *
   * @param {object} nextProps - Следующие пропсы.
   * @returns {void} - Nothing.
   * @example - Жопа.
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { editable } = this.props;
    const { activeGeometry } = this.state;

    const activeGeometryNotAllowed =
      activeGeometry &&
      !isEmpty(nextProps.allowedGeometryTypes) &&
      !nextProps.allowedGeometryTypes.includes(activeGeometry);

    const exitEditMode = editable && !nextProps.editable;

    if (activeGeometryNotAllowed || exitEditMode) {
      this.stopDrawing();
    }
  }

  /**
   * Метод onDrawClick.
   *
   * @param {boolean} active - Флаг.
   * @param {*} geometryType - Тип геометрии.
   * @param {Function} drawFunction - Функция отрисовки.
   * @returns {void} - Nothing.
   * @example ---
   */
  onDrawClick = (active, geometryType, drawFunction) => {
    if (active) {
      this.stopDrawing();
    } else {
      this.context.callMapFunction(drawFunction, this.props.currentCard);
      this.changeActiveGeometry(geometryType);
    }
  };

  /**
   * Функция changeActiveGeometry.
   *
   * @param {object} activeGeometry - Активная геометрия.
   * @returns {void}
   */
  changeActiveGeometry = (activeGeometry) => {
    this.setState({ activeGeometry });
  };

  stopDrawing = () => {
    this.context.callMapFunction('stopDrawing', this.props.currentCard);
    this.changeActiveGeometry(null);
  };

  getGeometry = () => {
    const { currentCard } = this.props;
    return this.context.getGeometry(currentCard.record_id);
  };

  /**
   * Метод render.
   *
   * @returns {JSX.Element} - JSX.
   * @example -
   */
  render() {
    const { editable, allowedGeometryTypes, currentCard } = this.props;
    const { activeGeometry } = this.state;

    const polygonAllowed = allowedGeometryTypes.includes(POLYGON);
    const pointAllowed = allowedGeometryTypes.includes(POINT);
    const lineAllowed = allowedGeometryTypes.includes(LINESTRING);

    return (
      <div className="map-edit">
        <ShowDistrictBoundaries />
        <B.DownloadGeometryButton
          currentCard={currentCard}
          getGeometry={this.getGeometry}
          isMapLoaded={this.context.isMapLoaded}
        />
        <NeighboursButton />
        <B.ReonButton
          currentCard={currentCard}
          showAlert={this.props.showAlert}
        />
        <Dialogs>
          <ShowChildrenButton />
        </Dialogs>

        <B.CheckGeometryButton showAlert={this.props.showAlert} />
        <B.DrawButton
          active={activeGeometry === POINT}
          disabled={!editable || !pointAllowed}
          geometryType={POINT}
          onClick={this.onDrawClick}
        />
        <B.DrawButton
          active={activeGeometry === LINESTRING}
          disabled={!editable || !lineAllowed}
          geometryType={LINESTRING}
          onClick={this.onDrawClick}
        />
        <B.DrawButton
          active={activeGeometry === POLYGON}
          disabled={!editable || !polygonAllowed}
          geometryType={POLYGON}
          onClick={this.onDrawClick}
        />
        <B.DrawButton
          active={activeGeometry === HOLE}
          disabled={!editable || !polygonAllowed}
          geometryType={HOLE}
          onClick={this.onDrawClick}
        />
        <B.DeleteSelectedButton
          callMapFunction={this.context.callMapFunction}
          currentCard={currentCard}
          disabled={!(editable && this.context.isMapLoaded)}
        />
      </div>
    );
  }
}

MapEditPanel.propTypes = propTypes;
