
/* eslint-disable */
export default function (formValues) {
  const REQUIRED_FIELD_MESSAGE =
    'Обязательно при отсутствии объектов капитального строительства';
  const { re_calc_address_list, name_description } = formValues;
  let result;
  if (!re_calc_address_list && !name_description) {
    result = {
      name_description: REQUIRED_FIELD_MESSAGE,
    };
  } else {
    result = null;
  }
  return result;
}
