import { uploadFileReturnObj } from 'app/api/requests/signs/signOghSave/uploadFileReturnObj';
import { RANDOM_ID, RANDOM_WORD } from 'core/forTesting/constants';

import { signCloseElectronicSignature } from './signCloseElectronicSignature';

jest.mock('app/api/crud');
jest.mock('app/api/requests/signs/signOghSave/uploadFileReturnObj');

describe('SignCloseElectronicSignatureJestSpec', function () {
  it('test called prepareDataForSaveSign', async () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const certificate = {};
    const documentCloseFile = { file: RANDOM_WORD };
    const files = ['files'];
    const objectCardFiles = [];
    const signatures = ['signatures'];
    const signType = RANDOM_WORD;
    const date = RANDOM_WORD;

    // Act
    const sign = signCloseElectronicSignature({
      certificate,
      date,
      documentCloseFile,
      files,
      objectCardFiles,
      signatures,
    });
    uploadFileReturnObj.mockReturnValue([{ storageId: RANDOM_ID }]);

    await sign({ signType });

    // Assert
    expect(uploadFileReturnObj).toBeCalledWith(
      { file: RANDOM_WORD },
      { create_date: RANDOM_WORD, type_id: 19 },
    );
    expect(uploadFileReturnObj).toBeCalledWith(['files']);
    expect(uploadFileReturnObj).toBeCalledWith(['signatures']);
    expect(uploadFileReturnObj).toBeCalledTimes(3);
  });
});
