import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import * as geometryTypes from 'app/constants/geometryTypes';
import { useMemo } from 'react';

/**
 * UseGetAllowedGeometryTypes.
 *
 * @returns {*}
 */
export const useGetAllowedGeometryTypes = () => {
  const { typeId } = useGetCurrentCard();

  const dict = 'geometry_type';
  const { data } = useGetDictionary(dict);

  return useMemo(() => {
    const geometryTypesArray = (data || []).reduce((arr, item) => {
      if (item.ogh_object_type_id === typeId) {
        return item.attribute.map(({ geometry_type }) => {
          return geometryTypes[geometry_type];
        });
      }
      return arr;
    }, []);

    return [...new Set(geometryTypesArray)];
  }, [data, typeId]);
};
