import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { FieldsContainer } from 'app/pages/cardsOgh/components/FieldsContainer';
import { getNameFile } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getNameFile';
import { Field } from 'redux-form';

import { DocumentWithReduxForm } from './DocumentWithReduxForm';

const ID = 22;
const NAME = getNameFile(ID);
const TITLE = 'Условные обозначения к плану-схеме';

/**
 * Таблица документов __Условные обозначения к плану-схеме__.
 *
 * @param {object} props - Пропсы.
 * @param {string} [props.title] - Заголовок.
 * @returns {JSX.Element} - JSX.
 * @example
 * <OtherDocumentsRf />
 */
export const SymbolsForTheLayoutPlanRF = ({ title = TITLE }) => {
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();
  return (
    <FieldsContainer>
      <Field
        title={title ?? TITLE}
        id={ID}
        name={NAME}
        component={DocumentWithReduxForm}
        disabled={isCurrentObjectIsExternalSystem}
      />
    </FieldsContainer>
  );
};
