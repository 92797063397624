/* eslint-disable  */
import CreateOgh from 'app/components/card/ogh/index/create';
import CreateCardOgh from 'app/pages/cardsOgh/CreateCardOgh';
import { checkIsNewCard } from 'app/routes/components/bridgeMap';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';

/**
 *
 * @param props
 */
export const RoutingBridgeCreateOgh = (props) => {
  const { typeId } = useGetParamsUrl();

  const isNewCardCreate = checkIsNewCard(+typeId);

  const Component = isNewCardCreate ? CreateCardOgh : CreateOgh;

  return <Component {...props} />;
};
