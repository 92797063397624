import CardAbstract from 'app/components/card/common/CardAbstract';
import { elementFormGrid } from 'app/components/card/common/grid';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import { QuantityField } from 'app/components/card/ogh/components/selects/Quantity.Field';
import DocumentsTab from 'app/components/card/ogh/DocumentsTab';
import VersionsTemplate from 'app/components/card/ogh/versions/VersionsTemplate';
import { WithReduxFormCheckbox } from 'app/components/common/CheckboxField';
import { SPA } from 'app/constants/oghTypes';
import { column } from 'app/constants/tables';
import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import isMandatory from 'app/utils/card/isMandatory';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields/prepared/checkboxes/IsDiffHeightMark.RF';
import { CoatingFaceTypeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CoatingFaceTypeId.RF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { TextField } from 'core/uiKit/inputs/TextField';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';

import { ArrangeElementTypeIdField } from '../components/selects/ArrangeElementTypeId.Field';
import { UnitIdField } from '../components/selects/UnitId.Field';
import printOghCardHeader from '../header/oghHeader';

const types = ['functionality'];
const Versions = VersionsTemplate(types);

const tabs = [
  {
    id: 'characteristics-tab',
    name: 'Исходные данные',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

const checkboxStyle = {
  height: 50,
  paddingTop: 0,
};

/**
 * Компонент FunctionalityCard.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
export const FunctionalityCard = (props) => {

  /**
   * Метод render.
   *
   * @returns {JSX.Element} Компонент с полями.
   * @example -----------
   */

  const {
    card,
    mode,
    createMode,
    editMode,
    required,
    quantityLock,
    parentTypeId,
    quantityDigits,
    documents,
    isCurrentObjectIsExternalSystem,
  } = props;

  const parentSPA = parentTypeId === SPA;

  return (
    <CardAbstract>
      <div id="editor-card">
        {printOghCardHeader(card, mode)}
        {card && objectTabs(card.type_id)}

        <div id="props">
          <CardContainer>
            <ArrangeElementTypeIdField />
            <ParentInfo />
          </CardContainer>

          {elementFormGrid(
            [
              {
                component: WithReduxFormCheckbox,
                editable: editMode,
                formValue: true,
                id: 'no_calc',
                label: 'Не учитывать',
                name: 'no_calc',
                style: checkboxStyle,
              },
              <div
                style={{
                  height: 50,
                  margin: '9px 24px',
                  minWidth: 256,
                  paddingTop: 0,
                }}
              >
                <IsDiffHeightMarkRF
                  disabled={!mode.editMode || isCurrentObjectIsExternalSystem}
                  justifyContent={'space-between'}
                  labelPlacement={'start'}
                />
              </div>,
            ],
            2,
            '',
            card.typeId,
          )}

          <Tabs tabs={tabs} />

          <div id="characteristics">
            {elementFormGrid(
              [
                <div>
                  <QuantityField
                    digits={quantityDigits}
                    disabled={
                      !(parentSPA
                        ? editMode
                        : editMode &&
                          !quantityLock &&
                          !isCurrentObjectIsExternalSystem)
                    }
                    required={isMandatory(card.type_id, 'quantity', required)}
                  />
                </div>,
                <div>
                  <TotalAreaGeoRF disabled={true} decimalScale={2} />
                </div>,

                <div>
                  <UnitIdField />
                </div>,
              ],
              column.TWO,
              '',
              card.type_id,
            )}
            <CardContainer>
              <SelectRF
                label={'Материал'}
                name={'material_id'}
                options={card.material}
                required={false}
                disabled={!editMode}
              />
              <TextField
                label={'Принадлежность элемента к зоне территории'}
                disabled={true}
                value={card.zone_name || ''}
                name={'zone_name'}
              />
              <CoatingFaceTypeIdRF disabled={!editMode} />
              <NumberFieldRF
                disabled={!editMode}
                name={'face_area'}
                decimalScale={2}
                label={'Площадь облицовки, кв.м.'}
              />
            </CardContainer>
          </div>

          {DocumentsTab()({
            documents: documents,
            editable: editMode,
            typeId: card.type_id,
          })}
        </div>
        <div hidden={true} id="versions">
          <Versions
            card={card}
            disabled={createMode}
            key={`versions-${card.root_id}`}
          />
        </div>
      </div>
    </CardAbstract>
  );
};
