import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { elementFormGrid } from 'app/components/card/common/grid';
import { ODH } from 'app/constants/oghTypes';
import { column } from 'app/constants/tables';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { useEditorCardSelector } from 'app/selectors/useSelectors/useEditorCard.Selector';
import { useGetCardCalcAttributeSelector } from 'app/selectors/useSelectors/useGetCardCalcAttribute.Selector';
import { createNumberedList } from 'app/utils/createNumberedList';
import getHint from 'app/utils/getHint';
import { NumberFieldRF } from 'core/form/reduxForm/fields';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { TabPanel } from 'core/uiKit/components/TabPanel';
import React from 'react';

/**
 * Tab Основные параметры.
 *
 * @param {object} params - Parameters.
 * @param {string} params.propertyTab - Активная вкладка.
 * @returns {JSX}
 */
export const BasicParameters = ({ propertyTab }: { propertyTab: string }) => {
  const { editMode } = useEditMode();
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();
  const { is_orphan_object: isOrphanObject } = useEditorCardSelector();
  const calcAttributes = useGetCardCalcAttributeSelector();

  /**
   * Fn.
   *
   * @param {object} obj - Жопа.
   * @returns {null|*} - Жопа.
   * @example ----
   */
  const showIfIsNotOrphanObject = (
    obj: Record<string, unknown> | JSX.Element,
  ) => (isOrphanObject ? null : obj);

  /**
   * Fn.
   *
   * @param hint - Хинт.
   * @param hintIsOrphanObject - Ф.
   * @returns - Хинт.
   */
  const hintIfIsNotOrphanObject = (
    hint: string,
    hintIsOrphanObject:
      | string
      | Element
      | undefined = 'Заполняется пользователем вручную',
  ) => (isOrphanObject ? hintIsOrphanObject : getHint(hint));

  return (
    <TabPanel show={propertyTab === 'options'}>
      {elementFormGrid(
        [
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('inbound_area'),
              required: Boolean(isOrphanObject),
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.inbound_area,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'inbound_area',
            label: 'Общая площадь в границах ОДХ, кв.м',
            name: 'inbound_area',
          },
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject(
                'total_area_ts',
                // @ts-ignore
                createNumberedList({
                  header: 'Сумма значений полей:',
                  list: [
                    'Проезжая часть всего, кв.м',
                    'Обочины, кв.м',
                    'Тротуары, кв.м',
                  ],
                }),
              ),
              required: Boolean(isOrphanObject),
              zero: false,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.total_area,
            editable: false,
            formValue: true,
            id: 'total_area',
            label: 'Общая площадь в ТС, кв.м',
            name: 'total_area',
          },
          showIfIsNotOrphanObject(
            <div>
              <NumberFieldRF
                {...{
                  defaultValue: calcAttributes.axis_length || '',
                  digits: 20,
                  disabled: !editMode,
                  formValue: true,
                  helpTitle: hintIfIsNotOrphanObject('axis_length'),
                  id: 'axis_length',
                  label: 'Протяженность осевой разделительной линии, п.м',
                  name: 'axis_length',
                  required: true,
                }}
              />
            </div>,
          ),
          <div>
            {/*// @ts-ignore*/}
            <TotalAreaGeoRF disabled={true} digits={20} />
          </div>,

          <div>
            <NumberFieldRF
              {...{
                digits: 20,
                disabled: !(editMode && !isCurrentObjectIsExternalSystem),
                formValue: true,
                helpTitle: hintIfIsNotOrphanObject('distance'),
                id: 'distance',
                label: 'Протяженность по оси, п.м',
                name: 'distance',
                required: true,
              }}
            />
          </div>,
          <div>
            <NumberFieldRF
              {...{
                digits: 20,
                disabled: !(editMode && !isCurrentObjectIsExternalSystem),
                formValue: true,
                helpTitle: hintIfIsNotOrphanObject('manual_edit'),
                id: 'layout_length',
                label: 'Протяженность объекта по всем осям, п.м',
                name: 'layout_length',
                required: true,
              }}
            />
          </div>,

          showIfIsNotOrphanObject({
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('bound_stone_length'),
              zero: false,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.bound_stone_length,
            editable: false,
            formValue: true,
            id: 'bound_stone_length',
            label: 'Бортовой камень, п.м',
            name: 'bound_stone_length',
          }),
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('gutters_length'),
              required: Boolean(isOrphanObject),
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.gutters_length,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'gutters_length',
            label: 'Протяженность лотков, п.м',
            name: 'gutters_length',
          },
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('roadway_area'),
              required: Boolean(isOrphanObject),
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.roadway_area,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'roadway_area',
            label: 'Проезжая часть всего, кв.м',
            name: 'roadway_area',
          },
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('margin_area'),
              required: Boolean(isOrphanObject),
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.margin_area,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'margin_area',
            label: 'Обочины, кв.м',
            name: 'margin_area',
          },
          {
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('footway_area'),
              required: Boolean(isOrphanObject),
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.footway_area,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'footway_area',
            label: 'Тротуары, кв.м',
            name: 'footway_area',
          },
          showIfIsNotOrphanObject({
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('other_area'),
              zero: false,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.other_area,
            editable: false,
            formValue: true,
            id: 'other_area',
            label: 'Прочие территории, кв.м',
            name: 'other_area',
          }),
          showIfIsNotOrphanObject({
            addition: {
              digits: 20,
              helpTitle: createNumberedList({
                header: 'Сумма значения полей:',
                list: [
                  '«Трамвайные пути», «Площадь, кв.м», для карточек, у которых установлен признак «Обособленные»',
                ],
              }),
              zero: false,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.tram_rails_detached_area,
            editable: false,
            formValue: true,
            id: 'tram_rails_detached_area',
            label: 'Трамвайные пути (обособленные), кв.м ',
            name: 'tram_rails_detached_area',
          }),
          showIfIsNotOrphanObject({
            addition: {
              digits: 20,
              helpTitle: createNumberedList({
                header: 'Сумма значения полей',
                list: [
                  '«Малые архитектурные формы (ОДХ)», «Площадь, кв.м», для карточек, у которых установлен признак «Включать в общую площадь ОДХ»',
                ],
              }),
              zero: false,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.maf_own_territory_area,
            editable: false,
            formValue: true,
            id: 'maf_own_territory_area',
            label: 'Малые архитектурные формы (на своей территории), кв.м ',
            name: 'maf_own_territory_area',
          }),
          showIfIsNotOrphanObject({
            addition: {
              digits: 20,
              helpTitle: createNumberedList({
                header: 'Сумма значения полей',
                list: [
                  '«Инженерные сооружения», «Площадь (в плане), кв.м», для карточек, у которых установлен признак «Входит в общую площадь ОДХ»',
                ],
              }),
              zero: false,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.engin_own_territory_area,
            editable: false,
            formValue: true,
            id: 'engin_own_territory_area',
            label: 'Инженерные сооружения (на своей территории), кв.м ',
            name: 'engin_own_territory_area',
          }),
          showIfIsNotOrphanObject({
            addition: {
              helpTitle: hintIfIsNotOrphanObject('engin_qty'),
              zero: false,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.engin_qty,
            editable: false,
            formValue: true,
            id: 'engin_qty',
            label: 'Инженерные сооружения, шт',
            name: 'engin_qty',
          }),
          showIfIsNotOrphanObject({
            addition: {
              digits: 20,
              helpTitle: createNumberedList({
                header: 'Сумма значения полей',
                list: [
                  '«Ограждения», «Площадь, кв.м», для карточек, у которых «Место размещения (наименование плоскостного элемента)» - «На цоколе (на своей территории)».',
                ],
              }),
              zero: false,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.fencing_own_territory_area,
            editable: false,
            formValue: true,
            id: 'fencing_own_territory_area',
            label: 'Ограждения (на своей территории – на цоколе), кв.м ',
            name: 'fencing_own_territory_area',
          }),
          showIfIsNotOrphanObject({
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('guiding_area'),
              zero: false,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.fencing_area,
            editable: false,
            formValue: true,
            id: 'fencing_area',
            label: 'Ограждения, кв.м',
            name: 'fencing_area',
          }),
          showIfIsNotOrphanObject({
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('guiding_length'),
              zero: false,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.guiding_length,
            editable: false,
            formValue: true,
            id: 'guiding_length',
            label: 'Ограждения, п.м',
            name: 'guiding_length',
          }),
          showIfIsNotOrphanObject({
            addition: {
              helpTitle: hintIfIsNotOrphanObject('guiding_qty'),
              zero: false,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.guiding_qty,
            editable: false,
            formValue: true,
            id: 'guiding_qty',
            label: 'Ограждения, шт.',
            name: 'guiding_qty',
          }),
          showIfIsNotOrphanObject({
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('traffic_signs_qty'),
              zero: false,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.traffic_signs_qty,
            editable: false,
            formValue: true,
            id: 'traffic_signs_qty',
            label: 'Дорожные знаки, указатели и информационные щиты, шт.',
            name: 'traffic_signs_qty',
          }),
          {
            addition: {
              helpTitle: hintIfIsNotOrphanObject('sign'),
              required: Boolean(isOrphanObject),
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.sign,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'sign',
            label: 'Знаки, ед.',
            name: 'sign',
          },
          {
            addition: {
              helpTitle: hintIfIsNotOrphanObject('pointer'),
              required: Boolean(isOrphanObject),
              zero: isOrphanObject,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.pointer,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'pointer',
            label: 'Дорожные указатели, ед.',
            name: 'pointer',
          },
          {
            addition: {
              helpTitle: hintIfIsNotOrphanObject('info'),
              required: Boolean(isOrphanObject),
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.info,
            editable:
              isOrphanObject && editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'info',
            label: 'Информационные щиты, ед.',
            name: 'info',
          },
          showIfIsNotOrphanObject({
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('bicycle_area'),
              zero: false,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.bicycle_area,
            editable: false,
            formValue: true,
            id: 'bicycle_area',
            label: 'Общая площадь велодорожек, кв.м',
            name: 'bicycle_area',
          }),
          showIfIsNotOrphanObject({
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('bicycle_length'),
              zero: false,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.bicycle_length,
            editable: false,
            formValue: true,
            id: 'bicycle_length',
            label: 'Протяженность по пикетажным осям велодорожек, п.м',
            name: 'bicycle_length',
          }),
          showIfIsNotOrphanObject({
            addition: {
              digits: 20,
              helpTitle: hintIfIsNotOrphanObject('tpu_area'),
              zero: false,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.tpu_area,
            editable: false,
            formValue: true,
            id: 'tpu_area',
            label: 'Общая площадь ТПУ, кв.м',
            name: 'tpu_area',
          }),
          showIfIsNotOrphanObject({
            addition: {
              helpTitle: hintIfIsNotOrphanObject('station_qty'),
              zero: false,
            },
            component: NumberFieldRF,
            defaultValue: calcAttributes.station_qty,
            editable: false,
            formValue: true,
            id: 'station_qty',
            label: 'Остановки общественного транспорта, шт.',
            name: 'station_qty',
          }),
        ],
        column.TWO,
        '',
        ODH,
        {},
      )}
    </TabPanel>
  );
};
