import { OWNER_ID_NAME } from 'core/uiKit/preparedInputs/selects/autocompleteAsync/OwnerIdOdh';
import { Field } from 'redux-form';

import { OwnerIdPlaceWithReduxForm } from './OwnerIdPlaceWithReduxForm';

/**
 * Балансодержатель для ОДХ.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 * @class
 */
export const OwnerIdPlaceRF = ({ ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={OWNER_ID_NAME}
      component={OwnerIdPlaceWithReduxForm}
    />
  );
};
