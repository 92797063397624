import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { CheckboxGreeningComponent } from 'app/components/card/ogh/header/inputs/CheckboxGreeningComponent';
import { OZN, YARD } from 'app/constants/oghTypes';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { CheckboxRF } from 'core/form/reduxForm/fields';
import { RANDOM_BOOL } from 'core/forTesting/constants';
import { shallow } from 'enzyme';

jest.mock('app/api/hooks/useGetCurrentCard');
jest.mock('app/pages/cardsOgh/hooks/useEditMode');
jest.mock('app/api/hooks/useIsCurrentObjectIsExternalSystem');

describe('title CheckboxGreeningComponent', () => {
  it.each([OZN, YARD])('тип %i карточки есть в массиве', (type) => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    useEditMode.mockReturnValue({ editMode: true });
    useIsCurrentObjectIsExternalSystem.mockReturnValue(false);
    useGetCurrentCard.mockReturnValue({
      typeId: type,
    });
    // Act
    const wrapper = shallow(<CheckboxGreeningComponent />);

    // Assert
    expect(wrapper.text()).toBe('<CheckboxRF />');
  });

  it('типа карточки нет в массиве', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    useEditMode.mockReturnValue({ editMode: true });
    useGetCurrentCard.mockReturnValue({
      typeId: 306,
    });
    // Act
    const wrapper = shallow(<CheckboxGreeningComponent />);

    // Assert
    expect(wrapper.html()).toBeNull();
  });

  it('проверка пропсов', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useEditMode.mockReturnValue({ editMode: RANDOM_BOOL });
    useGetCurrentCard.mockReturnValue({
      typeId: OZN,
    });
    // Act
    const wrapper = shallow(<CheckboxGreeningComponent />);
    // Assert
    expect(
      wrapper.contains(
        <CheckboxRF
          label={'Требует дополнения по зелёным насаждениям'}
          name={'greeningAddition'}
          disabled={!RANDOM_BOOL}
        />,
      ),
    ).toBe(true);
  });

  describe('проверка disabled', () => {
    it('не редактирование и внешняя система', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      useEditMode.mockReturnValue({ editMode: false });
      useIsCurrentObjectIsExternalSystem.mockReturnValue(true);
      useGetCurrentCard.mockReturnValue({
        typeId: OZN,
      });
      // Act
      const wrapper = shallow(<CheckboxGreeningComponent />);
      // Assert
      expect(wrapper.prop('disabled')).toBe(true);
    });

    it('не редактирование и не внешняя система', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      useEditMode.mockReturnValue({ editMode: false });
      useIsCurrentObjectIsExternalSystem.mockReturnValue(false);
      useGetCurrentCard.mockReturnValue({
        typeId: OZN,
      });
      // Act
      const wrapper = shallow(<CheckboxGreeningComponent />);
      // Assert
      expect(wrapper.prop('disabled')).toBe(true);
    });

    it('редактирование и внешняя система', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      useEditMode.mockReturnValue({ editMode: true });
      useIsCurrentObjectIsExternalSystem.mockReturnValue(true);
      useGetCurrentCard.mockReturnValue({
        typeId: OZN,
      });
      // Act
      const wrapper = shallow(<CheckboxGreeningComponent />);
      // Assert
      expect(wrapper.prop('disabled')).toBe(true);
    });

    it('редактирование и не внешняя система', () => {
      expect.hasAssertions();
      // Arrange (всякие моки)
      useEditMode.mockReturnValue({ editMode: true });
      useIsCurrentObjectIsExternalSystem.mockReturnValue(false);
      useGetCurrentCard.mockReturnValue({
        typeId: OZN,
      });
      // Act
      const wrapper = shallow(<CheckboxGreeningComponent />);
      // Assert
      expect(wrapper.prop('disabled')).toBe(false);
    });
  });
});
