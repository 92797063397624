import { render } from '@testing-library/react';

import CharacteristicsTab from './CharacteristicsTab';

jest.setTimeout(60000);

jest.mock('redux-form', () => {

  /**
   * Mock Field от redux form.
   *
   * @param {*} p - Prop.
   * @param {*} p.component - Component.
   * @param {*} p.props - Properties.
   * @returns {JSX}
   */
  const Field = ({ component, ...props }) => {
    const Component = component;
    return <Component {...props} />;
  };

  return {
    ...jest.requireActual('redux-form'),

    Field,
  };
});

describe('CharacteristicsTabJestSpec', function () {
  test('Поле № участка на доступность редактирования', () => {
    expect.hasAssertions();
    // 1. Поле № участка в режиме редактирования и относится к внешней системе
    // Arrange (всякие моки)

    // Act

    const { queryByLabelText, rerender } = render(
      <CharacteristicsTab
        editable={true}
        isCurrentObjectIsExternalSystem={true}
      />,
    );

    // Assert
    expect(queryByLabelText(/№ участка/i)).toBeDisabled();

    // 2. Поле № участка при просмотре и создан внешней системой
    rerender(
      <CharacteristicsTab
        editable={false}
        isCurrentObjectIsExternalSystem={true}
      />,
    );
    // Assert
    expect(queryByLabelText(/№ участка/i)).toBeDisabled();

    // 3. Поле № участка при редактировании и не создан от внешней системы
    rerender(
      <CharacteristicsTab
        editable={true}
        isCurrentObjectIsExternalSystem={false}
      />,
    );
    // Assert
    expect(queryByLabelText(/№ участка/i)).toBeEnabled();

    // 4. Поле № участка в режиме просмотра и не создан от внешней системы
    rerender(
      <CharacteristicsTab
        editable={false}
        isCurrentObjectIsExternalSystem={false}
      />,
    );
    // Assert
    expect(queryByLabelText(/№ участка/i)).toBeDisabled();
  });
});
