import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';

/**
 * Хук для управления значением поля формы в Redux.
 *
 * @param {string} fieldName - Имя поля формы.
 * @returns {{value: any, onChange: Function}} Объект, содержащий текущее значение поля формы и функцию для его обновления.
 */
export const useFieldRF = (fieldName) => {
  // Получаем текущее значение поля формы из Redux
  const fieldValue = useSelector((state) => {
    return state.form?.editorCard?.values?.[fieldName];
  });
  const dispatch = useDispatch();

  /**
   * Функция для обновления значения поля формы.
   *
   * @param {any} newValue - Новое значение поля формы.
   * @returns {void}
   */
  const onChange = (newValue) => {
    dispatch(change('editorCard', fieldName, newValue));
    // dispatch({
    //   payload: { fieldName, value: newValue },
    //   type: 'UPDATE_FORM_FIELD',
    // });
  };

  return {
    onChange,
    value: fieldValue,
  };
};
