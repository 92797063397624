import { CONTAINER_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/ContainerTypeId';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import { ContainerTypeIdRFWithReduxForm } from './ContainerTypeIdRFWithReduxForm';

/* eslint-disable */
/**
 * ContainerTypeIdRF.
 *
 * @param {object} props - Пропсы.
 * @param {string} [props.name] - Имя.
 * @param {object} props.otherProps - Остальные пропсы.
 * @returns {JSX.Element} - JSX.
 * @example ----
 */
export const ContainerTypeIdRF = ({
  name,
  ...otherProps
}) => {
  return (
    <Field
      {...otherProps}
      name={CONTAINER_TYPE_ID_NAME}
      component={ContainerTypeIdRFWithReduxForm}
    />
  );
};

ContainerTypeIdRF.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
};
ContainerTypeIdRF.defaultProps = {
  disabled: false,
};
