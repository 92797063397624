import { get } from 'lodash';
/* eslint-disable  */
import { createSelector } from 'reselect';

/**
 *
 * @param state
 */
const getCard = (state) => {
  return get(state, 'card.object', {});
};

export default createSelector(
  [getCard],
  (card) => (card && (card.siblings || card.children)) || [],
);
