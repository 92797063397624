import {
  fetchObjectsGeometry,
  toggleGeometryOfChildren,
} from 'app/actions/geometryActions';
import { WithMapContext } from 'app/components/map/withMap';
import { CHILDREN } from 'app/constants/layers';
import getChildren from 'app/selectors/card/getChildren';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import { onShowChildrenButtonPress } from 'app/utils/card/checkGeometry';
import PropTypes from 'prop-types';
/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';

import toggleContent from '../content/toggle';
import ToggleButton from './ToggleButton';

class ShowChildrenButton extends React.Component {
  static propTypes = {
    showAlert: PropTypes.func.isRequired,

    childrenOnMap: PropTypes.bool.isRequired,
    toggleGeometryOfChildren: PropTypes.func.isRequired,
    fetchObjectsGeometry: PropTypes.func.isRequired,
    children: PropTypes.array.isRequired,
    createMode: PropTypes.bool.isRequired,
    editMode: PropTypes.bool.isRequired,
  };
  static contextType = WithMapContext;

  componentDidUpdate(prevProps) {
    const { children, childrenOnMap, editMode } = this.props;

    const childrenNumChanged = prevProps.children.length !== children.length;
    const exitEditMode =
      !prevProps.createMode && prevProps.editMode && !editMode;

    if (childrenOnMap && (childrenNumChanged || exitEditMode)) {
      if (children.length === 0) {
        this.props.toggleGeometryOfChildren();
      }
      this.context.clearLayer(CHILDREN);
      this.loadAndDrawChildren();
    }
  }

  componentWillUnmount() {
    if (this.props.childrenOnMap) {
      this.props.toggleGeometryOfChildren();
    }
  }

  loadAndDrawChildren = () => {
    const ids = this.props.children.map((item) => item.recordId);
    this.props
      .fetchObjectsGeometry({ ids })
      .then(this.context.drawChildrenGeometry);
  };

  showChildrenByButton = () => {
    const { childrenOnMap } = this.props;

    this.context.clearLayer(CHILDREN);
    onShowChildrenButtonPress({
      childrenOnMap,
      toggleGeometryOfChildren: this.props.toggleGeometryOfChildren,
      children: this.props.children,
      showAlert: this.props.showAlert,
      loadAndDrawChildren: this.loadAndDrawChildren,
    });
  };

  render() {
    return (
      <ToggleButton
        active={this.props.childrenOnMap}
        content={toggleContent.children}
        onClick={this.showChildrenByButton}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    childrenOnMap: state.card.childrenOnMap,
    children: getChildren(state),
    createMode: isCreateMode(state, ownProps),
    editMode: isEditMode(state, ownProps),
  };
};

const mapDispatchToProps = {
  toggleGeometryOfChildren,
  fetchObjectsGeometry,
};

 
export default connect(mapStateToProps, mapDispatchToProps)(ShowChildrenButton);
