import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid/index';
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import { NumberFieldRF } from 'core/form/reduxForm/fields/index';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Класс CharacteristicsTab.
 */
export default class CharacteristicsTab extends React.Component {
  static defaultProps = {
    required: false,
  };

  static propTypes = {
    editable: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    lawnTypes: PropTypes.array.isRequired,
    required: PropTypes.bool,
    stateGardening: PropTypes.array.isRequired,
    typeId: PropTypes.number.isRequired,
    values: PropTypes.object.isRequired,
  };

  /**
   * Метод получения списка полей.
   *
   * @returns {Array}
   */
  getCharacteristicsElements() {
    const {
      editable,
      stateGardening,
      lawnTypes,
      required,
      isCurrentObjectIsExternalSystem,
    } = this.props;
    return [
      {
        addition: {
          digits: 0,
          required,
          type: 'integer',
        },
        component: NumberFieldRF,
        editable: editable && !isCurrentObjectIsExternalSystem,
        formValue: true,
        id: 'section_num',
        label: '№ участка',
        name: 'section_num',
      },
      {
        addition: {
          label: 'Тип газона',
          options: lawnTypes,
          required,
        },
        component: WithReduxFormSelect,
        editable: editable && !isCurrentObjectIsExternalSystem,
        formValue: true,
        id: 'lawn_type_id',
        name: 'lawn_type_id',
      },
      {
        addition: {
          label: 'Состояние',
          options: stateGardening,
          required,
        },
        component: WithReduxFormSelect,
        editable: editable && !isCurrentObjectIsExternalSystem,
        formValue: true,
        id: 'state_gardening_id',
        name: 'state_gardening_id',
      },
      {
        addition: {
          digits: 2,
          positive: true,
          required,
          type: 'decimal',
          zero: false,
        },
        component: renderCustomTextField,
        editable: editable && !isCurrentObjectIsExternalSystem,
        formValue: true,
        id: 'total_lawn_area',
        label: 'Площадь, кв.м',
        name: 'total_lawn_area',
      },
      <div>
        <TotalAreaGeoRF disabled={true} decimalScale={2} />
      </div>,
      {
        component: renderCustomTextField,
        editable: false,
        formValue: true,
        id: 'lawn_area_without',
        label: 'Площадь за вычетом насаждений, кв.м',
        name: 'lawn_area_without',
      },
    ];
  }

  /**
   * Метод рендер.
   *
   * @returns {JSX.Element}
   */
  render() {
    const { id, typeId } = this.props;
    const elements = this.getCharacteristicsElements();
    return <div id={id}>{elementFormGrid(elements, 2, '', typeId)}</div>;
  }
}
