/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import './CardHeader.scss';

const CardHeader = ({ subtitle, title, children }) => (
  <div className="header-card m-4">
    {subtitle && <div className="header-card__subtitle">{subtitle}</div>}
    <h1 className="header-card__title">{title}</h1>
    <div className="header-card__inputs">{children}</div>
  </div>
);

CardHeader.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

 
export default CardHeader;
