/* eslint-disable  */
// @ts-ignore
import { createAttribute as createDocumentsAttribute } from 'app/components/card/ogh/DocumentsTab';
// @ts-ignore
import { createAttribute as createAddressListFieldAttribute } from 'app/components/card/ogh/ImprovementTerritoryCard/components/AddressListField';
// @ts-ignore
import { createAttribute as createAquaAreaAttribute } from 'app/components/card/ogh/ImprovementTerritoryCard/components/AquaAreaTab';
// @ts-ignore
import { createAttribute as improvementCategoriesCreateAttribute } from 'app/components/card/ogh/ImprovementTerritoryCard/components/ImprovementCategories';
// @ts-ignore
import { createAttribute as createOwnerAndCustomerFieldsAttribute } from 'app/components/card/ogh/ImprovementTerritoryCard/components/OwnerAndCustomerFields';
// @ts-ignore
import { createAttribute as createRepairsPlanAttribute } from 'app/components/card/ogh/ImprovementTerritoryCard/components/RepairsPlanTab';
// @ts-ignore
import { createAttribute as createRepairsAttribute } from 'app/components/card/ogh/ImprovementTerritoryCard/components/RepairsTab';

// @ts-ignore
import { createAttribute } from './createAttribute';

// @ts-ignore
const ids = {
  address: 're_calc_address_list',
  name: 're_calc_name',
};

jest.mock('app/components/card/ogh/DocumentsTab');
jest.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/components/AddressListField',
);
jest.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/components/AquaAreaTab',
);
jest.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/components/ImprovementCategories',
);
jest.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/components/OwnerAndCustomerFields',
);
jest.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/components/RepairsPlanTab',
);
jest.mock(
  'app/components/card/ogh/ImprovementTerritoryCard/components/RepairsTab',
);

describe('createAttribute.jestSpec', () => {
  it('should', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    (createDocumentsAttribute as jest.Mock).mockReturnValue({ b: 1 });
    (createAddressListFieldAttribute as jest.Mock).mockReturnValue({ c: 2 });
    (createAquaAreaAttribute as jest.Mock).mockReturnValue({ d: 3 });
    (improvementCategoriesCreateAttribute as jest.Mock).mockReturnValue({
      e: 4,
    });
    (createOwnerAndCustomerFieldsAttribute as jest.Mock).mockReturnValue({
      f: 5,
    });
    (createRepairsPlanAttribute as jest.Mock).mockReturnValue({ g: 6 });
    (createRepairsAttribute as jest.Mock).mockReturnValue({ i: 7 });
    // Act
    const res = createAttribute({ a: 23 });

    // Assert
    expect(res).toStrictEqual({
      b: 1,
      c: 2,
      d: 3,
      e: 4,
      f: 5,
      g: 6,
      i: 7,
      [ids.name]: null,
      [ids.address]: null,
    });

    expect(createDocumentsAttribute).toHaveBeenCalledWith({ a: 23 });
    expect(createAddressListFieldAttribute).toHaveBeenCalledWith({ a: 23 });
    expect(createAquaAreaAttribute).toHaveBeenCalledWith({ a: 23 });
    expect(improvementCategoriesCreateAttribute).toHaveBeenCalledWith({
      a: 23,
    });
    expect(createOwnerAndCustomerFieldsAttribute).toHaveBeenCalledWith({
      a: 23,
    });
    expect(createRepairsPlanAttribute).toHaveBeenCalledWith({ a: 23 });
    expect(createRepairsAttribute).toHaveBeenCalledWith({ a: 23 });
  });
});

// @ts-ignore
