import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import { CreateType } from 'core/uiKit/preparedInputs';
import { GrbsId } from 'core/uiKit/preparedInputs/selects/simpleSelect/GrbsId';
import React from 'react';

/**
 * One tab.
 *
 * @param {*} props - Properties.
 * @returns {JSX.Element}
 */
export const OznOneTab = (props) => (
  <CardContainer>
    <CreateType disabled={true} value={props?.card?.attribute?.create_type} />
    <GrbsId
      disabled={true}
      value={props?.card?.attribute?.grbs_id?.legal_person}
      label={'Учредитель/ГРБС'}
    />
  </CardContainer>
);
