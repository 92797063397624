import { RepairsInfoListPlanWithReduxFrom } from 'app/components/card/ogh/ImprovementTerritoryCard/components/RepairsPlanTab/RepairsInfoListPlanWithReduxFrom';
import { shallow } from 'enzyme';
import React from 'react';
import { Field } from 'redux-form';

import { REPAIRS_INFO_LIST_PLAN_NAME, RepairsPlanTab } from './RepairsPlanTab';

describe('RepairsPlanTabSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const wrapper = shallow(<RepairsPlanTab />);
    // ❓ Assert
    expect(wrapper.props()).toStrictEqual({
      children: (
        <Field
          name={REPAIRS_INFO_LIST_PLAN_NAME}
          component={RepairsInfoListPlanWithReduxFrom}
        />
      ),
      title: 'Проекты',
    });
  });
});
