import { columnsTableVerticalLandscapingList } from './columns.Table.VerticalLandscapingList';

describe('ColumnsTableVerticalLandscapingListSpec', () => {
  it('columns', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act
    const expected = [
      {
        Header: 'ID связанного Цветника',
        accessor: 'related_elements_flowers_garden_id',
        rowSpan: 4,
      },
      {
        Header: 'ID связанного Дерева/Кустарника',
        accessor: 'related_elements_trees_shrubs_id',
        rowSpan: 4,
      },
      {
        Header: 'Общее количество посадочных мест ЭЦО, шт.',
        columns: [
          {
            Header: 'Всего:',
            accessor: 'floral_design_element_qty',
            rowSpan: 1,
          },
        ],
        rowSpan: 3,
      },
      {
        Header: 'Общая площадь посадочных мест, кв.м',
        columns: [
          {
            Header: 'Всего:',
            accessor: 'floral_design_element_area',
            rowSpan: 1,
          },
        ],
        rowSpan: 3,
      },
      {
        Header: 'Элементы цветочного оформления',
        columns: [
          {
            Header: 'Кадки',
            columns: [
              {
                Header: 'шт.',
                accessor: 'tub_floral_design_element_qty',
              },
              {
                Header: 'кв.м.',
                accessor: 'tub_floral_design_element_area',
              },
            ],
            rowSpan: 2,
          },
          {
            Header: 'Вазоны',
            columns: [
              {
                Header: 'Посадочные места',
                accessor: 'Посадочные места',
                columns: [
                  {
                    Header: 'шт.',
                    accessor: 'flowerpot_floral_design_element_qty',
                  },
                  {
                    Header: 'кв.м.',
                    accessor: 'flowerpot_floral_design_element_area',
                  },
                ],
              },
            ],
          },
          {
            Header: 'Вертикальные конструкции напольные',
            columns: [
              {
                Header: 'шт.',
                accessor: 'vertical_structures_floor_floral_design_element_qty',
              },
              {
                Header: 'кв.м.',
                accessor:
                  'vertical_structures_floor_floral_design_element_area',
              },
            ],
            rowSpan: 2,
          },
          {
            Header: 'Вертикальные конструкции подвесные',
            columns: [
              {
                Header: 'шт.',
                accessor:
                  'vertical_structures_suspended_floral_design_element_qty',
              },
              {
                Header: 'кв.м.',
                accessor:
                  'vertical_structures_suspended_floral_design_element_area',
              },
            ],
            rowSpan: 2,
          },
          {
            Header: 'Вертикальные конструкции (иные элементы)',
            columns: [
              {
                Header: 'шт.',
                accessor:
                  'vertical_structures_other_elements_floral_design_element_qty',
              },
              {
                Header: 'кв.м.',
                accessor:
                  'vertical_structures_other_elements_floral_design_element_area',
              },
            ],
            rowSpan: 2,
          },
        ],
      },

      {
        Header: 'Из них',
        columns: [
          {
            Header: 'Стационарные',
            accessor: 'stationary_floral_design_element',
            rowSpan: 3,
          },
          {
            Header: 'Мобильные',
            accessor: 'mobile_floral_design_element',
            rowSpan: 3,
          },
        ],
      },
      {
        Header: 'Общее количество посадочных мест ЭО, шт.',
        columns: [
          {
            Header: 'Всего:',
            accessor: 'greening_element_qty',
            rowSpan: 1,
          },
        ],
        rowSpan: 3,
      },
      {
        Header: 'Общее количество посадочных мест, кв.м.',
        columns: [
          {
            Header: 'Всего:',
            accessor: 'greening_element_area',
          },
        ],
        rowSpan: 3,
      },
      {
        Header: 'Элементы озеленения',
        columns: [
          {
            Header: 'Кадки',
            columns: [
              {
                Header: 'шт.',
                accessor: 'tub_greening_element_qty',
              },
              {
                Header: 'кв.м.',
                accessor: 'tub_greening_element_area',
              },
            ],
          },
          {
            Header: 'Вазоны',
            columns: [
              {
                Header: 'шт.',
                accessor: 'flowerpot_greening_element_qty',
              },
              {
                Header: 'кв.м.',
                accessor: 'flowerpot_greening_element_area',
              },
            ],
          },
        ],
        rowSpan: 2,
      },
      {
        Header: 'Из них',
        columns: [
          {
            Header: 'Стационарные',
            accessor: 'stationary_greening_element',
            rowSpan: 3,
          },
          {
            Header: 'Мобильные',
            accessor: 'mobile_greening_element',
            rowSpan: 3,
          },
        ],
      },
    ];

    // ❓ Assert
    expect(columnsTableVerticalLandscapingList).toStrictEqual(expected);
  });
});
