/* eslint-disable */
import React from 'react';
import { getNameFile } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getNameFile';
import { Field } from 'react-final-form';
import { Document } from 'app/pages/cardsOgh/components/tables/Documents/Document';
import { FieldsContainer } from 'app/pages/cardsOgh/components/FieldsContainer';

const ID = 14;
const NAME = getNameFile(14);
const TITLE = 'Иные документы';

 
export const OtherDocumentsFf = () => {
  return (
    <FieldsContainer>
      <Field name={NAME}>
        {({ input }) => {
          const { value, onChange } = input;
          return (
            <Document
              titleDialog={`Добавление файла (${TITLE})`}
              title={TITLE}
              dataTable={value || []}
              typeId={ID}
              onChange={onChange}
            />
          );
        }}
      </Field>
    </FieldsContainer>
  );
};
