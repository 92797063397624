import { RepairsInfoListPlanWithReduxFrom } from 'app/components/card/ogh/ImprovementTerritoryCard/components/RepairsPlanTab/RepairsInfoListPlanWithReduxFrom';
import { RANDOM_BOOL } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { getColumnsRepairsPlanTab } from './columns.RepairsPlanTab';
import { useSetupRepairsInfoListPlanWithReduxFrom } from './useSetup.RepairsInfoListPlanWithReduxFrom';

vi.mock('./useSetup.RepairsInfoListPlanWithReduxFrom');
vi.mock('./columns.RepairsPlanTab');

describe('RepairsInfoListPlanWithReduxFromSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const closeDialog = vi.fn();
    const openDialog = vi.fn();
    const removeRow = vi.fn();
    const selectRow = vi.fn();
    const onChange = vi.fn();
    useSetupRepairsInfoListPlanWithReduxFrom.mockReturnValue({
      closeDialog,
      dataTableRepairsPlanTab: [],
      disabledDeleteButton: RANDOM_BOOL,
      editMode: RANDOM_BOOL,
      isOpen: RANDOM_BOOL,
      openDialog,
      removeRow,
      selectRow,
    });
    getColumnsRepairsPlanTab.mockReturnValue([]);
    const props = {
      input: {
        onChange,
        value: {},
      },
    };
    // 🔥 Act
    const wrapper = shallow(<RepairsInfoListPlanWithReduxFrom {...props} />);
    // ❓ Assert
    expect(wrapper.text()).toBe(
      '<DialogRepairsInfoListPlan /><Button /><Button /><TableSimple />',
    );
    expect(useSetupRepairsInfoListPlanWithReduxFrom).toHaveBeenCalledWith({
      onChange,
      value: {},
    });
  });
});
