import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { FieldsContainer } from 'app/pages/cardsOgh/components/FieldsContainer';
import { getNameFile } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getNameFile';
import { Field } from 'redux-form';

import { DocumentWithReduxForm } from '../DocumentWithReduxForm';

const ID = 13;
const NAME = getNameFile(ID);
const TITLE = 'Сведения о согласовывающих организациях';

/**
 * Таблица документов __Сведения о согласовывающих организациях__.
 *
 * @returns {JSX.Element} - JSX.
 * @example
 * <DataCoordOrganizationRF />
 */
export const DataCoordOrganizationRF = () => {
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();

  return (
    <FieldsContainer>
      <Field
        title={TITLE}
        id={ID}
        name={NAME}
        component={DocumentWithReduxForm}
        disabled={isCurrentObjectIsExternalSystem}
      />
    </FieldsContainer>
  );
};
