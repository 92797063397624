/* eslint-disable  */
import {
  IMPROVEMENT_CATEGORY_ID,
  ImprovementCategoryId,
} from 'core/uiKit/preparedInputs/selects/simpleSelect/ImprovementCategoryId';
import { Field } from 'redux-form';

/**
 *
 * @param root0
 * @param root0.input
 */
const ImprovementCategoryIdRFWithReduxForm = ({ input, ...props }) => {
  return <ImprovementCategoryId {...input} {...props} />;
};

/* eslint-disable */
export const ImprovementCategoryIdRF = ({ name, ...otherProps }) => {
  return (
    <Field
      {...otherProps}
      name={IMPROVEMENT_CATEGORY_ID}
      component={ImprovementCategoryIdRFWithReduxForm}
    />
  );
};
