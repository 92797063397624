import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useState } from 'react';

import { useStartEditMutation } from './useStartEditMutation';

/**
 * Сетап Кнопки "Редактировать" Огх.
 *
 * @param {object} props - Properties.
 * @param {Function} props.onClick - Функция обратного вызова кнопки.
 * @returns {*}
 */
export const useSetupEditOghButton = ({ onClick }) => {
  const [open, setOpen] = useState(false);

  const { objectId } = useGetObjectId();
  const { data: { edit } = {} } = useGetVisibilityButtonsOgh(objectId);
  const { editMode } = useMode();
  const { recordId, rootId, typeId } = useGetCurrentCard();

  /**
   * Функция закрыть диалог.
   *
   * @returns {void}
   */
  const closeDialog = () => {
    // onClick();
    setOpen(false);
  };

  /**
   * Функция открыть диалог.
   *
   * @returns {void}
   */
  const openDialog = () => setOpen(true);

  const [startEdit, { data }] = useStartEditMutation({
    continueEditFn: onClick,
    openDialog,
  });

  // const a

  return {
    closeDialog,
    data,
    isShow: edit && !editMode,
    open,
    recordId,
    rootId,
    startEdit,
    typeId,
  };
};
