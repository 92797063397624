/* eslint-disable  */
import FlowersGardenCard from 'app/components/card/ogh/flowers_garden';
import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});

vi.mock('react-redux', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    connect: vi.fn(() => (b) => {
      return b;
    }),
  };
});

describe('FlowersGardenSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      card: { bord_begin: 1, type_id: RANDOM_NUMBER },
      card_type: 'FLOWERS_GARDEN',
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };
    // 🔥 Act
    const wrapper = shallow(<FlowersGardenCard {...props} />);
    // ❓ Assert
    expect(wrapper.text()).toBe('<CardAbstract />');
  });
});
