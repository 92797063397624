import 'react-hint/css/index.css';
/* eslint-disable */
import 'assets/styles/card.nestedTable.scss';

import TableErrorText from 'app/components/common/TableErrorText';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import classnames from 'classnames';
import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { ButtonGreen } from 'core/newComponents/Button';
import { Button } from 'core/uiKit/components/buttons/Button';
import { TableSimple } from 'core/uiKit/components/tables/TableSimple';
import { Icon } from 'core/uiKit/material-ui';
import React from 'react';

const errorTextStyle = {
  marginLeft: '24px',
};

const defaultColumns = [
  {
    /**
     *
     * @param root0
     * @param root0.row
     */
    Cell: ({ row }) => {
      const { editMode } = useEditMode();
      const { onChange, value: valueAL } = useFieldRF('address_list');

      return editMode ? (
        <Button
          color={'error'}
          onClick={() => {
            const newValue =
              valueAL?.filter((_, idx) => +idx !== +row.id) || [];
            onChange(newValue);
          }}
        >
          <Icon>delete</Icon>
        </Button>
      ) : null;
    },
    Header: '',
    accessor: 'id',
  },
  {
    Header: 'Округ',
    accessor: 'okrug_name',
  },
  {
    Header: 'Район',
    accessor: 'district_name',
  },
  {
    Header: 'Улица',
    accessor: 'street_name',
  },
  {
    Header: 'Дом, корпус, строение',
    accessor: 'address_name',
  },
  {
    Header: 'UNOM',
    accessor: 'unom',
  },
  {
    Header: 'UNAD',
    accessor: 'unad',
  },
  {
    Header: 'Статус',
    accessor: 'is_actual',
  },
  {
    Header: 'Класс строения',
    accessor: 'kl',
  },
];

/**
 * Преобразование массива записей таблицы в понятный компоненту таблицы формат.
 *
 * @param {*} source - Массив записей таблицы.
 * @returns {*}
 */
const makeValues = (source = []) =>
  source
    .filter((row) => !row.editable)
    .map((row) => ({
      ...row,
      is_actual: row.is_actual ? 'актуален' : 'не актуален',
    }));

/**
 * Форма добавления адреса.
 *
 * @param {*} root0 - A.
 * @param {*} root0.renderAddressForm - A.
 * @param {*} root0.value - A.
 * @param {*} root0.row - A.
 * @returns {*}
 */
const AddAddressForm = ({ renderAddressForm, value, row }) => (
  <div>
    <div className={'edit_form_title'}>Добавить адрес БТИ</div>
    {renderAddressForm(value, row)}
  </div>
);

/**
 * Отображение ошибок формы добавления адреса.
 *
 * @param {*} root0 - A.
 * @param {*} root0.disabled - A.
 * @param {*} root0.errorText - A.
 * @returns {*}
 */
const AddressTableErrorText = ({ disabled, errorText }) => (
  <div style={errorTextStyle}>
    <TableErrorText errorText={errorText} visible={!disabled} />
  </div>
);

/**
 * Кнопка добавления адреса.
 *
 * @param {*} root0 - A.
 * @param {*} root0.input - A.
 * @param {*} root0.onlyOne - A.
 * @param {*} root0.value - A.
 * @returns {*}
 */
const AddAddressButton = ({ input, onlyOne, value }) => {
  const onlyOneRow = onlyOne ? value.length >= 1 : false;

  return (
    <div className={'add_button'}>
      <ButtonGreen
        disabled={onlyOneRow}
        id="add"
        name="add"
        onClick={() => {
          input.onChange([
            ...value,
            {
              editable: true,
            },
          ]);
        }}
      >
        Добавить адрес БТИ
      </ButtonGreen>
    </div>
  );
};

/**
 * UI Блок добавления адреса в карточку ОГХ.
 *
 * @param {*} root0 - A.
 * @param {*} root0.disabled - A.
 * @param {*} root0.meta - A.
 * @param {*} root0.input - A.
 * @param {*} root0.onlyOne - A.
 * @param {*} root0.onDelete - A.
 * @param {*} root0.hintText - A.
 * @param {*} root0.renderAddressForm - A.
 * @returns {*}
 */
const AddressListTemplate = ({
  disabled,
  meta,
  input,
  onlyOne,
  hintText,
  renderAddressForm,
}) => {
  const errorText = meta ? meta.error : null;
  const value = (input ? input.value : []) || [];
  const editRow = value.find((row) => row.editable);

  return (
    <div className="mt-3 w-100">
      {!disabled && editRow && (
        <div data-rh={hintText}>
          <AddAddressForm
            renderAddressForm={renderAddressForm}
            row={editRow}
            value={value}
          />
        </div>
      )}

      {!disabled && !editRow && (
        <div className={classnames('clearfix', 'ops_buttons')}>
          <AddAddressButton input={input} onlyOne={onlyOne} value={value} />
        </div>
      )}

      {!!errorText && (
        <AddressTableErrorText disabled={disabled} errorText={errorText} />
      )}

      <TableSimple columns={defaultColumns} data={makeValues(value)} />
    </div>
  );
};

export default AddressListTemplate;
