// 15 Бортовой камень
/* eslint-disable  */

import CardAbstract from 'app/components/card/common/CardAbstract';
import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import validateMaterial from 'app/components/card/common/validation/validateMaterial';
import DocumentsTab, {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import { WithReduxFormCheckbox } from 'app/components/common/CheckboxField';
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import { ODH_AXIS_TOOLTIP } from 'app/constants/messages';
import { column } from 'app/constants/tables';
import getBoundStoneMaterial from 'app/selectors/card/getBoundStoneMaterial/getBoundStoneMaterial';
import getDefaultFieldValues from 'app/selectors/card/getDefaultFieldValues';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import filterDictBySprNumber from 'app/utils/card/filterDictBySprNumber';
import isMandatory, { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { NumberFieldRF } from 'core/form/reduxForm/fields/default/NumberField.RF';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import { find } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const flatElementTypeFieldStyles = {
  width: 'calc(100% - 48px)',
};

const BOUND_STONE_MARK_NO_GOST = 10163; // не по ГОСТу

const types = ['board_stone'];
const Versions = VersionsTemplate(types);

const tabs = [
  { id: 'characteristics-tab', name: 'Исходные данные' },
  { id: 'docs-tab', name: 'Документы' },
];

/**
 * Компонент __Бортовые камни__.
 *
 * @returns {JSX.Element}
 */
class BoardStoneCard extends React.Component {
  /**
   * Метод жизненного цикла componentDidMount.
   *
   * @returns {void}
   */
  componentDidMount() {
    const { editMode } = this.props;
    if (editMode) {
      this.handleMaterialFieldChange();
    }
  }

  /**
   * Метод жизненного цикла componentDidUpdate.
   *
   * @param {object} prevProps - Предыдущие пропсы.
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const {
      editMode,
      formValues: { bound_stone_mark_id },
    } = this.props;

    if (
      editMode &&
      bound_stone_mark_id !== prevProps.formValues.bound_stone_mark_id
    ) {
      this.handleMaterialFieldChange();
    }
  }

  /**
   * Метод getBoundStoneMarkOptions.
   *
   * @returns {Array<string>}
   */
  getBoundStoneMarkOptions() {
    const { type_id, bound_stone_mark, viewing } = this.props.card;

    if (viewing) {
      return bound_stone_mark;
    }
    return filterDictBySprNumber(bound_stone_mark, type_id);
  }

  /**
   * Метод getBoundStoneMarkMaterial.
   *
   * @returns {Array<string>}
   */
  getBoundStoneMarkMaterial() {
    const {
      card,
      formValues: { bound_stone_mark_id },
    } = this.props;
    const { bound_stone_mark, material } = card;

    const currentBoundStoneMark = find(bound_stone_mark, {
      id: bound_stone_mark_id,
    });
    const currentBoundStoneMarkMaterial = find(material, {
      id: currentBoundStoneMark.material_id,
    });

    return currentBoundStoneMarkMaterial.name || null;
  }

  /**
   * Метод handleMaterialFieldChange.
   *
   * @returns {void}
   */
  handleMaterialFieldChange() {
    const {
      formValues: { bound_stone_mark_id },
      changeFieldValue,
    } = this.props;

    if (
      !bound_stone_mark_id ||
      bound_stone_mark_id === BOUND_STONE_MARK_NO_GOST
    ) {
      changeFieldValue('material_id', null);
    } else {
      const value = this.getBoundStoneMarkMaterial();
      changeFieldValue('material_id', value);
    }
  }

  /**
   * Метод isBoundStoneMarkNoGost.
   *
   * @returns {boolean}
   */
  isBoundStoneMarkNoGost() {
    const {
      formValues: { bound_stone_mark_id },
    } = this.props;

    if (
      !bound_stone_mark_id ||
      bound_stone_mark_id === BOUND_STONE_MARK_NO_GOST
    ) {
      return true;
    }
    return false;
  }

  /**
   * Метод renderMaterialInput.
   *
   * @returns {void}
   */
  renderMaterialInput() {
    const { card, editMode, viewing } = this.props;

    const { material } = card;

    if (this.isBoundStoneMarkNoGost() && !viewing) {
      // https://jira.elocont.ru/browse/ODS-7132
      //  id=10163, code=185,
      // 7	granite2	Гранит
      // 1	concrete	Бетонный
      const granite = material.find(({ id }) => +id === 100900) || {};
      const concrete = material.find(({ id }) => +id === 82) || {};
      const options = [granite, concrete].map((item) => ({
        id: item.name,
        name: item.name,
      }));

      return {
        addition: {
          label: 'Материал',
          options,
        },
        component: WithReduxFormSelect,
        editable: editMode,
        formValue: true,
        id: 'material_id',
        name: 'material_id',
      };
    }
    return {
      addition: { type: 'text' },
      component: renderCustomTextField,
      editable: false,
      formValue: true,
      id: 'material_id',
      label: 'Материал',
      name: 'material_id',
    };
  }

  /**
   * Метод render.
   *
   * @returns {JSX.Element}
   */
  render() {
    const {
      card,
      mode,
      createMode,
      editMode,
      required,
      documents,
      formValues,
      isCurrentObjectIsExternalSystem,
    } = this.props;

    const materialInput = this.renderMaterialInput();

    return (
      <CardAbstract>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(card.type_id)}

          <div id="props">
            {elementFormGrid(
              [
                {
                  addition: {
                    label: 'Тип',
                    options: card.flat_element_type,
                    required: isMandatory(
                      card.type_id,
                      'flat_element_type_id',
                      required,
                    ),
                  },
                  component: WithReduxFormSelect,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'flat_element_type_id',
                  name: 'flat_element_type_id',
                  style: flatElementTypeFieldStyles,
                },
                <div>
                  <ParentInfo />
                </div>,
              ],
              column.TWO,
              '',
              card.type_id,
            )}

            <Tabs tabs={tabs} />

            <div id="characteristics">
              {elementFormGrid(
                [
                  {
                    addition: {
                      formatChars: {
                        9: '[а-яА-Я0-9a-zA-Z]',
                      },
                      mask: '99',
                      tooltipText: ODH_AXIS_TOOLTIP,
                      type: 'text',
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'odh_axis',
                    label: 'Ось',
                    name: 'odh_axis',
                  },
                  {
                    addition: {
                      label: 'Сторона',
                      options: card.odh_side,
                      required: isMandatory(
                        card.type_id,
                        'odh_side_id',
                        required,
                      ),
                    },
                    component: WithReduxFormSelect,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'odh_side_id',
                    name: 'odh_side_id',
                  },
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      required: isMandatory(
                        card.type_id,
                        'bord_begin',
                        required,
                      ),
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    // костыль что бы 0 отобразился
                    defaultValue: formValues.bord_begin,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'bord_begin',
                    label: 'Начало, м',
                    name: 'bord_begin',
                  },
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      required: isMandatory(card.type_id, 'bord_end', required),
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    // костыль что бы 0 отобразился
                    defaultValue: formValues.bord_end,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'bord_end',
                    label: 'Конец, м',
                    name: 'bord_end',
                  },
                ],
                column.TWO,
                'Местоположение',
                card.type_id,
              )}
              {elementFormGrid(
                [
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      required: isMandatory(card.type_id, 'distance', required),
                      type: 'decimal',
                      zero: false,
                    },
                    component: NumberFieldRF,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'distance',
                    label: 'Длина, п.м',
                    name: 'distance',
                  },
                ],
                column.TWO,
                'Характеристики',
                card.type_id,
              )}
              {elementFormGrid(
                [
                  {
                    addition: {
                      label: 'Марка бортового камня',
                      options: this.getBoundStoneMarkOptions(),
                      required: isMandatory(
                        card.type_id,
                        'bound_stone_mark_id',
                        required,
                      ),
                    },
                    component: WithReduxFormSelect,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'bound_stone_mark_id',
                    name: 'bound_stone_mark_id',
                  },
                  materialInput,
                  {
                    addition: {
                      label: 'Лотковая зона',
                    },
                    component: WithReduxFormCheckbox,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'is_gutter_zone',
                    name: 'is_gutter_zone',
                  },
                  {
                    addition: {
                      label: 'Прилегает к проезжей части',
                    },
                    component: WithReduxFormCheckbox,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'near_roadway',
                    name: 'near_roadway',
                  },
                ],
                column.TWO,
                '',
                card.type_id,
              )}

              {elementFormGrid(
                [
                  {
                    addition: { type: 'text' },
                    component: renderCustomTextField,
                    editable: editMode,
                    formValue: true,
                    id: 'description',
                    label: 'Примечание',
                    name: 'description',
                  },
                ],
                column.ONE,
                '',
                card.type_id,
              )}
            </div>
            {DocumentsTab()({
              documents,
              editable: editMode,
              typeId: card.type_id,
            })}
          </div>
          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={createMode}
              key={`versions-${card.root_id}`}
            />
          </div>
        </div>
      </CardAbstract>
    );
  }
}

const boardStoneDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция mapStateToProps.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object}
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);
  const defaultFieldValues = getDefaultFieldValues(state, props);

  const result = {
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),

    enableReinitialize: true,

    formValues: getFormValues(state),
    initialValues: {
      bord_begin: parse('bord_begin'),
      bord_end: parse('bord_end'),
      bound_stone_mark_id:
        defaultFieldValues.bound_stone_mark_id ||
        parse('bound_stone_mark_id.bound_stone_mark'),
      customer_id: parse('customer_id'),
      description: parse('description'),
      distance: parse('distance'),
      endDate: parseDate(props.card.end_date),
      flat_element_type_id: parse('flat_element_type_id.flat_element_type'),
      is_gutter_zone: parse('is_gutter_zone') || 0,
      material_id: getBoundStoneMaterial(state, props),
      near_roadway: parse('near_roadway'),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      owner_id: parse('owner_id'),
      startDate: parseDate(props.card.start_date),
    },
    onSubmit: onSubmit.bind({ props }),
    required: isRequired(state, props),
    validate,
    viewing: [isEditMode(state, props), isCreateMode(state, props)].every(
      (item) => !item,
    ),
  };

  return boardStoneDocumentsReducer(result, state, props);
};

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения формы.
 * @returns {{file_list: *}}
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

const numericFields = [
  { name: 'bord_begin', type: 'decimal', zero: true },
  { name: 'bord_end', type: 'decimal', zero: true },
  { name: 'distance', positive: true, type: 'decimal', zero: false },
];

const dictFields = [
  'odh_side_id',
  'flat_element_type_id',
  'bound_stone_mark_id',
];

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object}
 */
const validate = (values) => ({
  ...validateMaterial(values),
  ...validateNumeric(values, numericFields),
});

/**
 * Функция сабмита.
 *
 * @param {object} formValues - Значения формы.
 * @returns {void}
 */
function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  validateRequiredFieldsAsync(values, mandatoryFields[card.type_id]);
  values.near_roadway = Boolean(values.near_roadway);

  const material = find(card.material, { name: values.material_id });
  const formattedValuesTmp = { ...values, material_id: material?.id };

  const formattedValues = {
    ...formattedValuesTmp,
    ...createAttribute(formattedValuesTmp),
  };

  submitCard(
    this.props,
    transformValues(formattedValues, {
      dictFields,
      numericFields,
    }),
  );
}

/**
 * Функция mapDispatchToProps.
 *
 * @param {Function} dispatch - Функция dispatch.
 * @returns {{changeFieldValue: mapDispatchToProps.changeFieldValue}}
 */
const mapDispatchToProps = (dispatch) => ({
  /**
   *
   * @param field
   * @param value
   */
  changeFieldValue: (field, value) => {
    dispatch(change('editorCard', field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(BoardStoneCard));
