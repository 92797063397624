import { DistrictListRF } from 'core/form/reduxForm/fields/prepared/selects/multiSelect/DistrictList.RF';
import { RANDOM_BOOL } from 'core/forTesting/constants';
import { OKRUG_LIST_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/OkrugList';
import { shallow } from 'enzyme';

import { DistrictListField } from './DistrictListField';

const dict = [
  { id: 62, name: 'Арбат', okrug: [5] },
  { id: 10, name: 'Алексеевский', okrug: [2] },
  { id: 11, name: 'Аэропорт', okrug: [10] },
];

/* eslint-disable */
describe('title DistrictListField.jestSpec', () => {
  it('test props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const props = {
      editable: RANDOM_BOOL,
    };

    // Act
    const wrapper = shallow(<DistrictListField {...props} />);
    const { filter } = wrapper.props();

    // Assert
    expect(
      wrapper.contains(
        <DistrictListRF disabled={!RANDOM_BOOL} required filter={filter} />,
      ),
    ).toBeTruthy();
  });

  it.each`
    okrug_list | expected
    ${[5]}     | ${[{ id: 62, name: 'Арбат', okrug: [5] }]}
    ${['']}    | ${dict}
    ${[]}      | ${dict}
  `('test filter positive case', ({ okrug_list, expected }) => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const props = {
      editable: RANDOM_BOOL,
      currentValues: {
        [OKRUG_LIST_NAME]: okrug_list,
      },
    };

    // Act
    const wrapper = shallow(<DistrictListField {...props} />);
    const { filter } = wrapper.props();
    filter(dict);
    // Assert
    expect(filter(dict)).toStrictEqual(expected);
  });

  it.each`
    currentValues            | expected
    ${{ currentValues: {} }} | ${dict}
    ${{}}                    | ${dict}
  `('test filter negative case', ({ currentValues, expected }) => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    const props = {
      editable: RANDOM_BOOL,
      ...currentValues,
    };

    // Act
    const wrapper = shallow(<DistrictListField {...props} />);
    const { filter } = wrapper.props();
    filter(dict);
    // Assert
    expect(filter(dict)).toStrictEqual(expected);
  });
});
