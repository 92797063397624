// 20 Трамвайные пути
/* eslint-disable  */

import CardAbstract from 'app/components/card/common/CardAbstract';
import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import DocumentsTab, {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import printOghCardHeader from 'app/components/card/ogh/header/oghHeader';
import submitCard from 'app/components/card/ogh/submit';
import { CoatingTypeRfTramRails } from 'app/components/card/ogh/tram_rails/fields/CoatingTypeRf.TramRails';
import VersionsTemplate from 'app/components/card/ogh/versions/VersionsTemplate';
import { WithReduxFormCheckbox } from 'app/components/common/CheckboxField';
import { ODH_AXIS_TOOLTIP } from 'app/constants/messages';
import { column } from 'app/constants/tables';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import isMandatory, { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields';
import { CoatingGroupIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CoatingGroupId.RF';
import { NetElementTypeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/NetElementTypeId.RF';
import { OdhSideIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/OdhSideId.RF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

const types = ['tram_rails'];
const Versions = VersionsTemplate(types);

const tabs = [
  { id: 'characteristics-tab', name: 'Исходные данные' },
  { id: 'docs-tab', name: 'Документы' },
];

const propTypes = {
  card: PropTypes.object,
  changeFieldValue: PropTypes.func,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  formValues: PropTypes.array,
  mode: PropTypes.object,
  required: PropTypes.bool,
};

/**
 * Классовый компонет TramRailsCard.
 */
class TramRailsCard extends React.Component {
  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   * @example ----
   */
  render() {
    const {
      mode,
      card,
      createMode,
      editMode,
      required,
      formValues,
      isCurrentObjectIsExternalSystem,
      changeFieldValue,
      documents,
    } = this.props;

    return (
      <CardAbstract>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(card.type_id)}

          <div id="props">
            {elementFormGrid(
              [
                <div>
                  <NetElementTypeIdRF
                    disabled={!editMode || isCurrentObjectIsExternalSystem}
                    required={true}
                  />
                </div>,
                <div
                  style={{
                    height: 50,
                    margin: '9px 24px',
                    minWidth: 256,
                    paddingTop: 0,
                  }}
                >
                  <IsDiffHeightMarkRF
                    disabled={!editMode || isCurrentObjectIsExternalSystem}
                    justifyContent={'space-between'}
                    labelPlacement={'start'}
                  />
                </div>,
                <div>
                  <ParentInfo />
                </div>,
              ],
              column.TWO,
              '',
              card.type_id,
            )}

            <Tabs tabs={tabs} />

            <div id="characteristics">
              {elementFormGrid(
                [
                  {
                    addition: {
                      formatChars: {
                        9: '[а-яА-Я0-9a-zA-Z]',
                      },
                      mask: '99',
                      tooltipText: ODH_AXIS_TOOLTIP,
                      type: 'text',
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'odh_axis',
                    label: 'Ось',
                    name: 'odh_axis',
                  },
                  <div>
                    <OdhSideIdRF
                      disabled={!editMode || isCurrentObjectIsExternalSystem}
                      required={true}
                    />
                  </div>,

                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      required: isMandatory(
                        card.type_id,
                        'bord_begin',
                        required,
                      ),
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    defaultValue: formValues.bord_begin,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'bord_begin',
                    label: 'Начало, м',
                    name: 'bord_begin',
                  },
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      required: isMandatory(card.type_id, 'bord_end', required),
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    // костыль что бы 0 отобразился
                    defaultValue: formValues.bord_end,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'bord_end',
                    label: 'Конец, м',
                    name: 'bord_end',
                  },
                ],
                column.TWO,
                'Местоположение',
                card.type_id,
              )}

              {elementFormGrid(
                [
                  {
                    addition: {
                      label: 'Обособленные',

                      /**
                       *
                       * @param value
                       */
                      withChange: (value) => {
                        if (value) {
                          changeFieldValue('is_isolated', null);
                        }
                      },
                    },
                    component: WithReduxFormCheckbox,
                    editable: false,
                    formValue: true,
                    id: 'detached',
                    name: 'detached',
                  },
                  {
                    addition: {
                      label: 'В одном уровне',

                      /**
                       *
                       * @param value
                       */
                      withChange: (value) => {
                        if (value) {
                          changeFieldValue('detached', null);
                        }
                      },
                    },
                    component: WithReduxFormCheckbox,
                    editable: false,
                    formValue: true,
                    id: 'is_isolated',
                    name: 'is_isolated',
                  },
                  // {
                  //   addition: {
                  //     digits: 0,
                  //     positive: true,
                  //     required: isMandatory(card.type_id, 'quantity', required),
                  //     type: 'integer',
                  //     zero: false,
                  //   },
                  //   component: renderCustomTextField,
                  //   editable: editMode && !isCurrentObjectIsExternalSystem,
                  //   formValue: true,
                  //   id: 'quantity',
                  //   label: 'Количество путей',
                  //   name: 'quantity',
                  // },
                  {
                    addition: {
                      digits: 1,
                      positive: true,
                      required: isMandatory(card.type_id, 'distance', required),
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'distance',
                    label: 'Длина путей, п.м',
                    name: 'distance',
                  },
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      required: isMandatory(card.type_id, 'area', required),
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'area',
                    label: 'Площадь, кв.м',
                    name: 'area',
                  },
                  <div>
                    <TotalAreaGeoRF disabled={true} decimalScale={2} />
                  </div>,
                  // {
                  //   addition: {
                  //     digits: 2,
                  //     positive: true,
                  //     required: isMandatory(
                  //       card.type_id,
                  //       'susp_height',
                  //       required,
                  //     ),
                  //     type: 'decimal',
                  //     zero: false,
                  //   },
                  //   component: renderCustomTextField,
                  //   editable: editMode && !isCurrentObjectIsExternalSystem,
                  //   formValue: true,
                  //   id: 'susp_height',
                  //   label: 'Высота подвески над проезжей частью, м',
                  //   name: 'susp_height',
                  // },
                  <div>
                    <CoatingGroupIdRF
                      disabled={!editMode || isCurrentObjectIsExternalSystem}
                      withAll={false}
                      required={true}
                      withChange={() => {
                        changeFieldValue('coating_type_id', undefined);
                      }}
                    />
                  </div>,
                  <div>
                    <CoatingTypeRfTramRails />
                  </div>,

                  // <div>
                  //   <CoatingGroupRoadIdRF
                  //     disabled={!editMode || isCurrentObjectIsExternalSystem}
                  //     withAll={false}
                  //     required={true}
                  //     withChange={() => {
                  //       changeFieldValue('coating_type_road_id', null);
                  //     }}
                  //   />
                  // </div>,
                  // <div>
                  //   <CoatingTypeRoadIdRFTramRails />
                  // </div>,
                  // {
                  //   addition: {
                  //     digits: 2,
                  //     positive: true,
                  //     type: 'decimal',
                  //     zero: false,
                  //   },
                  //   component: renderCustomTextField,
                  //   editable: editMode && !isCurrentObjectIsExternalSystem,
                  //   formValue: true,
                  //   id: 'coating_tracks_area',
                  //   label: 'Площадь по типу покрытия на путях, кв.м',
                  //   name: 'coating_tracks_area',
                  // },
                  // {
                  //   addition: {
                  //     digits: 2,
                  //     positive: true,
                  //     type: 'decimal',
                  //     zero: false,
                  //   },
                  //   component: renderCustomTextField,
                  //   editable: editMode && !isCurrentObjectIsExternalSystem,
                  //   formValue: true,
                  //   id: 'coating_between_road_area',
                  //   label: 'Площадь по типу покрытия на сопряжении с ПЧ, кв.м',
                  //   name: 'coating_between_road_area',
                  // },
                ],
                column.TWO,
                'Характеристики',
                card.type_id,
              )}

              {elementFormGrid(
                [
                  {
                    addition: { type: 'text' },
                    component: renderCustomTextField,
                    editable: editMode,
                    formValue: true,
                    id: 'description',
                    label: 'Примечание',
                    name: 'description',
                  },
                ],
                column.ONE,
                '',
                card.type_id,
              )}
            </div>
            {DocumentsTab()({
              documents,
              editable: editMode,
              typeId: card.type_id,
            })}
          </div>
          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={createMode}
              key={`versions-${card.root_id}`}
            />
          </div>
        </div>
      </CardAbstract>
    );
  }
}

TramRailsCard.propTypes = propTypes;

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object} - Отвалидированные поля.
 * @example ----
 */
const validate = (values) => validateNumeric(values, numericFields);

const dictFields = [
  'odh_side_id',
  'net_element_type_id',
  'coating_group_id',
  'coating_type_road_id',
  'coating_type_id',
  'coating_group_road_id',
  'coating_type_road_id',
];

const numericFields = [
  {
    name: 'quantity',
    positive: true,
    type: 'integer',
    zero: false,
  },
  { name: 'bord_begin', type: 'decimal', zero: true },
  { name: 'bord_end', type: 'decimal', zero: true },
  {
    name: 'distance',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'susp_height',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'snow_area',
    positive: true,
    type: 'decimal',
  },
  {
    name: 'coating_tracks_area',
    positive: true,
    type: 'decimal',
  },
  {
    name: 'coating_between_road_area',
    positive: true,
    type: 'decimal',
  },
  {
    name: 'rotor_area',
    positive: true,
    type: 'decimal',
  },
  {
    name: 'reagent_area',
    positive: true,
    type: 'decimal',
  },
];

const tramRailsCardDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

/**
 * Функция сабмита.
 *
 * @returns {void} - Жопа.
 * @param {object} formValues - Значения из формы.
 * @example ----
 */
function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  formValuesWithAttribute.is_diff_height_mark = Boolean(
    formValuesWithAttribute.is_diff_height_mark,
  );
  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);

  const result = {
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),
    enableReinitialize: true,
    formValues: getFormValues(state),
    initialValues: {
      area: parse('area'),
      bord_begin: parse('bord_begin'),
      bord_end: parse('bord_end'),
      coating_between_road_area: parse('coating_between_road_area'),
      coating_group_id: parse('coating_group_id.coating_group'),
      coating_group_road_id: parse('coating_group_road_id.coating_group'),
      coating_tracks_area: parse('coating_tracks_area'),
      coating_type_id: parse('coating_type_id.coating_type'),
      coating_type_road_id: parse('coating_type_road_id.coating_type'),
      customer_id: parse('customer_id'),
      description: parse('description'),
      detached: parse('detached') ?? true,
      distance: parse('distance'),
      endDate: parseDate(props.card.end_date),
      is_diff_height_mark: parse('is_diff_height_mark'),
      is_isolated: parse('is_isolated'),
      net_element_type_id: parse('net_element_type_id.net_element_type'),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      owner_id: parse('owner_id'),
      parent_name: props.card.parent_info,
      quantity: parse('quantity'),
      reagent_area: parse('reagent_area'),
      rotor_area: parse('rotor_area'),
      snow_area: parse('snow_area'),
      startDate: parseDate(props.card.start_date),
      susp_height: parse('susp_height'),
      totalAreaGeo: parse('total_area_geo'),
    },
    onSubmit: onSubmit.bind({ props }),
    required: isRequired(state, props),
    validate,
  };

  return tramRailsCardDocumentsReducer(result, state, props);
};

/**
 * Функция получения экшенов.
 *
 * @param {Function} dispatch - Функция диспатч.
 * @returns {{changeFieldValue: mapDispatchToProps.changeFieldValue}} - Экшен changeFieldValue.
 * @example ----
 */
const mapDispatchToProps = (dispatch) => ({
  /**
   *
   * @param field
   * @param value
   */
  changeFieldValue: (field, value) => {
    dispatch(change('editorCard', field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(TramRailsCard));
