import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { fetchStartEdit } from 'app/api/ogh';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { parseDateToMoment } from 'app/utils/date/parseDateToMoment';
import { toast } from 'core/uiKit/components';
import { EditOghButton } from 'core/uiKit/components/buttons/actionsButtons/EditOgh.Button';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import React from 'react';
import { useField } from 'react-final-form';

/**
 * Кнопка редактировать.
 *
 * @returns {JSX}
 */
export const EditButton = () => {
  const { rootId, recordId, typeId } = useGetCurrentCard();
  const { editMode, startEditMode } = useEditMode();
  const { objectId } = useGetObjectId();

  const {
    input: { onChange: onChangeStartDate },
  } = useField('startDate');

  const { startDate: parentStartDate } = useGetParentCard();

  const [startEdit] = useMutationAdaptor((value) => fetchStartEdit(value), {

    /**
     * OnError.
     *
     * @param {*} error - Error.
     * @returns {void}
     */
    onError(error) {
      toast.errorDying(error?.message);
    },

    /**
     * OnSuccess.
     *
     * @returns {void}
     */
    onSuccess() {
      startEditMode(true);
    },
  });

  /**
   * Функция нажадитя на кнопку редактировать.
   *
   * @returns {void}
   */
  const onClickEditOghButton = () => {
    onChangeStartDate(parseDateToMoment(parentStartDate));
    startEdit({ recordId, rootId, typeId });
  };

  return (
    <>
      <EditOghButton
        objectId={+objectId}
        hidden={editMode}
        onClick={onClickEditOghButton}
      />
    </>
  );
};
