import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { useFieldRF } from 'core/form/reduxForm/hooks/useField.RF';
import { useEffect, useRef } from 'react';

/**
 * Setup для  Единица измерения Select RF.
 *
 * @returns {object}
 */
export const useSetupUnitIdField = () => {
  const { data: arrangeElementTypes = [] } =
    useGetDictionary('arrangeElementType');
  const { data = [] } = useGetDictionary('unit');

  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();
  const { editMode } = useMode();
  const { onChange } = useFieldRF('unit_id');
  const { value: arrange_element_type_id } = useFieldRF(
    'arrange_element_type_id',
  );

  const unitIdArrangeElementType =
    arrangeElementTypes.find(({ id }) => id === arrange_element_type_id)
      ?.attribute?.unit_id?.unit || null;

  const onChangeRef = useRef(onChange);

  useEffect(() => {
    if (!editMode) {
      return;
    }

    if (unitIdArrangeElementType && !Array.isArray(unitIdArrangeElementType)) {
      onChangeRef.current(unitIdArrangeElementType);
    } else {
      onChangeRef.current(null);
    }
    // eslint-disable-next-line
  }, [unitIdArrangeElementType]);

  const units = data.filter(({ id }) => {
    if (Array.isArray(unitIdArrangeElementType)) {
      return unitIdArrangeElementType.includes(id);
    }
    return +id === +unitIdArrangeElementType;
  });

  const disabled = !(
    !isCurrentObjectIsExternalSystem &&
    arrange_element_type_id &&
    units.length > 1 &&
    editMode
  );

  return { disabled, units };
};
