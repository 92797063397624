import { REQUIRED_FIELD_MESSAGE } from 'app/constants/messages';
import { DISTRICT_LIST_NAME } from 'core/uiKit/preparedInputs/selects/multiSelect/DistrictList';

/**
 * Валидация поля DistrictListField.
 *
 * @param {object} formValues - Значения формы.
 * @returns {{OKRUG_NAME: string}} - Возвращает объект с текстом ошибки или null.
 * @example
 * function asyncValidate(formValues) {
 *   return {
 *     ...districtListAsyncValidate(formValues),
 *   };
 * }
 */
export function districtListAsyncValidate(formValues) {
  const value = formValues[DISTRICT_LIST_NAME];

  let result;
  if (value.length === 0 || value[0] === '') {
    result = {
      [DISTRICT_LIST_NAME]: REQUIRED_FIELD_MESSAGE,
    };
  } else {
    result = null;
  }
  return result;
}
