import { FieldsContainer } from 'app/pages/cardsOgh/components/FieldsContainer';
import { getNameFile } from 'app/pages/cardsOgh/components/tables/Documents/helpers/getNameFile';
import React from 'react';
import { Field } from 'redux-form';

import { DocumentWithReduxForm } from '../DocumentWithReduxForm';

const ID = 9;
const NAME = getNameFile(ID);
const TITLE = 'Схема расположения цветника на объекте';

/**
 * Таблица документов __Схема расположения цветника на объекте__.
 *
 * @returns {JSX.Element}
 * @example
 * <PlanFlowerRF />
 */
export const PlanFlowerRF = () => {
  return (
    <FieldsContainer>
      <Field
        title={TITLE}
        id={ID}
        name={NAME}
        component={DocumentWithReduxForm}
      />
    </FieldsContainer>
  );
};
