import PropsTabTemplate from '../PropsTabTemplate';

export default PropsTabTemplate({
  className: 'arst',
  elements: [
    {
      getElements,
      title: 'Проекты',
    },
  ],
  id: 'repairs_plan',
});

/**
 * Fn.
 *
 * @param {object} props - Properties.
 * @param {boolean} props.editable - Editable.
 * @param {*} props.component - Component.
 * @returns {*}
 */
function getElements({ editable, component }) {
  return [
    {
      component,
      editable,
      formValue: true,
      id: 'repairs_info_list_plan',
      label: '',
      name: 'repairs_info_list_plan',
      style: {
        overflow: 'auto',
      },
    },
  ];
}
