import { elementFormGrid } from 'app/components/card/common/grid';
import DocumentsTab, {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import NameField, {
  asyncValidate as nameFieldAsyncValidate,
  mapStateToPropsReducer as nameFieldReducer,
} from 'app/components/card/ogh/ImprovementObjectCard/components/NameField';
import { IsDiffHeightMarkReducer } from 'app/components/card/ogh/ImprovementTerritoryCard/components/IsDiffHeightMark';
import { CardContainer } from 'app/components/card/ogh/ImprovementTerritoryCard/components/UI/CardContainer';
import OrdersTab, {
  mapDispatchToPropsReducer as ordersDispatchReducer,
  mapStateToPropsReducer as ordersReducer,
} from 'app/components/card/ogh/OrdersTab';
import { UNTENANTABLE } from 'app/constants/addressKlKods';
import { CreateTypeRF, IsDiffHeightMarkRF } from 'core/form/reduxForm/fields';
import { ReCalcNameRF } from 'core/form/reduxForm/fields/prepared/textField/ReCalcName.RF';
import { get } from 'lodash';
import React from 'react';

import AddressListField, {
  createAttribute as createAddressListFieldAttribute,
  mapStateToPropsReducer as addressListFieldReducer,
} from './components/AddressListField';
import {
  createAttribute as createAquaAreaAttribute,
  mapStateToPropsReducer as aquaAreaReducer,
  validate as validateAquaArea,
} from './components/AquaAreaTab';
import DepartmentField, {
  mapStateToPropsReducer as DepartmentFieldReducer,
} from './components/DepartmentTypeField';
import {
  EquipmentIndex,
  mapStateToPropsReducer as equipmentIndexReducer,
} from './components/EquipmentIndex';
import ImprovementCategories, {
  asyncValidate as improvementCategoriesAsyncValidate,
  createAttribute as improvementCategoriesCreateAttribute,
  mapStateToPropsReducer as improvementCategoriesReducer,
  metadata as improvementCategoriesMetadata,
} from './components/ImprovementCategories';
import MainParamsTab from './components/MainParamsTab';
import OwnerAndCustomerFields, {
  asyncValidate as ownerAndCustomerFieldsAsyncValidate,
  createAttribute as createOwnerAndCustomerFieldsAttribute,
  mapStateToPropsReducer as ownerAndCustomerReducer,
} from './components/OwnerAndCustomerFields';
import PlantationTab from './components/PlantationTab';
import PropsTabs from './components/PropsTabs';
import RepairsPlanTab, {
  createAttribute as createRepairsPlanAttribute,
  mapStateToPropsReducer as repairsPlanReducer,
} from './components/RepairsPlanTab';
import RepairsPlanTable from './components/RepairsPlanTable';
import RepairsTab, {
  createAttribute as createRepairsAttribute,
  mapStateToPropsReducer as repairsReducer,
} from './components/RepairsTab';
import {
  asyncValidate as snowAreaAsyncValidate,
  mapStateToPropsReducer as snowAreaReducer,
  validate as validateSnowArea,
} from './components/SnowAreaTab';
import TypeOtherTerritory, {
  asyncValidate as typeOtherTerritoryAsyncValidate,
  createAttribute as createTypeOtherTerritoryAttribute,
  mapStateToPropsReducer as typeOtherTerritoryReducer,
  metadata as typeOtherTerritoryMetadata,
} from './components/TypeOtherTerritory';
import ImprovementTerritoryCardTemplate from './ImprovementTerritoryCardTemplate';
import mapStateToPropsReducer from './mapStateToPropsReducer';
import { improvementObjectCardTabs } from './tabs';

const mandatoryFieldNames = {
  customer_id: 'Заказчик',
  name: 'Наименование',
  name_description: 'Уточнение наименования',
  owner_id: 'Балансодержатель',
  snow_clean_area: 'Площадь вывоза снега',
  [improvementCategoriesMetadata.improvement.id]:
    improvementCategoriesMetadata.improvement.label,
  [improvementCategoriesMetadata.landscaping.id]:
    improvementCategoriesMetadata.landscaping.label,
  [typeOtherTerritoryMetadata.key]: typeOtherTerritoryMetadata.label,
};

const tabToFields = {
  Свойства: [
    improvementCategoriesMetadata.improvement.id,
    improvementCategoriesMetadata.landscaping.id,
    typeOtherTerritoryMetadata.key,
    'owner_id',
    'name_description',
    'customer_id',
    'snow_clean_area',
  ],
};

const idForRecalcField = {
  address: 're_calc_address_list',
  name: 're_calc_name',
};

/**
 * CreateTypeRFFunc.
 *
 * @returns {function(*): *}
 */
function CreateTypeRFFunc() {
  const cols = 1;
  const title = '';
  return (props) =>
    elementFormGrid(
      [
        <div>
          <CreateTypeRF disabled={true} />
        </div>,
      ],
      cols,
      title,
      props.card.type_id,
    );
}

/**
 * Получение initialValues Тип создания объекта.
 *
 * @returns {function(*, *, *): {initialValues: {re_calc_name: *, name_description: *}}}
 */
function initialValuesCreateType() {
  return (result, state, props) => {
    const { card } = props;
    return {
      ...result,
      initialValues: {
        ...result.initialValues,
        createType: get(card, 'attribute.create_type', null),
      },
    };
  };
}

/**
 * ImprovementObjectCard.
 */
export const ImprovementObjectCard = ImprovementTerritoryCardTemplate({
  mapDispatchToPropsReducers: [ordersDispatchReducer],
  mapStateToPropsReducers: [
    mapStateToPropsReducer({
      asyncValidate,
      createAttribute,
      mandatoryFieldNames,
      tabToFields,
      validate,
    }),
    improvementCategoriesReducer({
      improvement: {
        defaultOption: {
          id: -2,
          name: 'Не определена (уборочная площадь = 0)',
        },
        defaultValue: -2,
      },
    }),
    IsDiffHeightMarkReducer(),
    ownerAndCustomerReducer(),
    equipmentIndexReducer(),
    initialValuesCreateType(),
    nameFieldReducer(),
    addressListFieldReducer({
      id: idForRecalcField.address,
    }),
    DepartmentFieldReducer({
      department_id: 10216105,
    }),
    snowAreaReducer,
    aquaAreaReducer,
    repairsReducer,
    repairsPlanReducer,
    typeOtherTerritoryReducer(),
    documentsReducer({
      oghGroupCode: 'yard_object',
    }),
    ordersReducer,
  ],
  propsTabElementRenders: [
    CreateTypeRFFunc(), // todo: очень странно но это не работает берется из YardCard
    DepartmentField({ editable: false }),
    OwnerAndCustomerFields,
    ImprovementCategories({
      improvement: {
        editable: false,
      },
    }),
    TypeOtherTerritory,
    NameField({ required: true }),
    (props) => (
      <CardContainer>
        <ReCalcNameRF />
        <IsDiffHeightMarkRF
          label={'Разновысотный ОГХ'}
          disabled={!props.editable}
        />
        <EquipmentIndex />
      </CardContainer>
    ),
    // ImprovementObjectCardAttributes(),
    AddressListField({
      addition: {
        data: {
          address: {
            kl_kod: UNTENANTABLE,
          },
        },
      },
      editable: false,
      id: idForRecalcField.address,
      name: idForRecalcField.address,
      required: false,
    }),
    PropsTabs(improvementObjectCardTabs),
    MainParamsTab(),
    <PlantationTab />,
    RepairsTab(),
    RepairsPlanTab({
      component: RepairsPlanTable,
    }),
    DocumentsTab(),
    OrdersTab(),
  ],
});

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object} - Отвалидированные поля.
 * @example ----
 */
function validate(values) {
  return {
    ...validateAquaArea(values),
    ...validateSnowArea(values),
  };
}

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
function createAttribute(formValues) {
  return {
    ...createAddressListFieldAttribute(formValues),
    ...createOwnerAndCustomerFieldsAttribute(formValues),
    ...createRepairsAttribute(formValues),
    ...createRepairsPlanAttribute(formValues),
    ...createTypeOtherTerritoryAttribute(formValues),
    ...improvementCategoriesCreateAttribute(formValues),
    ...createDocumentsAttribute(formValues),
    ...createAquaAreaAttribute(formValues),
    [idForRecalcField.address]: null,
    [idForRecalcField.name]: null,
  };
}

/**
 * Асинхронная валидация формы.
 *
 * @param {{}} formValues - Значения формы.
 * @returns {*}
 */
function asyncValidate(formValues) {
  return {
    ...ownerAndCustomerFieldsAsyncValidate(formValues),
    ...nameFieldAsyncValidate(formValues),
    ...typeOtherTerritoryAsyncValidate(formValues),
    ...snowAreaAsyncValidate(formValues),
    ...improvementCategoriesAsyncValidate(formValues),
  };
}
