// 25 Объекты капитального строительства
/* eslint-disable  */

import {
  fetchBtiGeometry,
  resetBtiGeometry,
} from 'app/actions/geometryActions';
import CardAbstract from 'app/components/card/common/CardAbstract';
import {
  elementFormGrid,
  renderAddressList2,
} from 'app/components/card/common/grid';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import DocumentsTab, {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import { WithMapContext } from 'app/components/map/withMap';
import { UNTENANTABLE } from 'app/constants/addressKlKods';
import {
  FLOOR_QTY_MESSAGE,
  REQUIRED_FIELD_MESSAGE,
} from 'app/constants/messages';
import { BUILDING } from 'app/constants/oghTypes';
import getBtiAddressId from 'app/selectors/card/getBtiAddressId';
import getBuildingsTypeSpecByTypeId from 'app/selectors/card/getBuildingsTypeSpecByTypeId';
import getParentTypeId from 'app/selectors/card/getParentTypeId';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import { isImprovementObject } from 'app/utils/checkOghTypeId';
import { transformValues } from 'app/utils/form/transformation';
// import { FOREGROUND } from 'app/constants/layers';
// import { WithReduxFormCheckbox } from 'app/components/common/CheckboxField';
import { IsDiffHeightMarkRF, NumberFieldRF } from 'core/form/reduxForm/fields';
import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import { get, mapValues, pick } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['building'];
const Versions = VersionsTemplate(types);
const tabs = [
  {
    id: 'characteristics-tab',
    name: 'Исходные данные',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

/**
 * Классовый компонет BuildingCard.
 */
class BuildingCard extends React.Component {
  /**
   * Метод жизненого цикла componentDidMount.
   *
   * @returns {void} - Nothing.
   * @example ----
   */
  componentDidMount() {
    this.props.resetBtiGeometry();
  }
  static contextType = WithMapContext;

  /**
   * Компонент жизненого цикла componentDidUpdate.
   *
   * @param {object} prevProps - Предыдущие пропсы.
   * @returns {void} - Nothing.
   * @example --------
   */
  componentDidUpdate(prevProps) {
    const {
      editMode,
      btiAddressId,
      btiGeometry,
      changeFieldValue,
      currentValues,
    } = this.props;

    if (editMode) {
      if (prevProps.btiAddressId !== btiAddressId && btiAddressId === null) {
        // this.context.clearLayer(FOREGROUND);
      }

      if (prevProps.btiGeometry !== btiGeometry && btiGeometry) {
        // this.context.clearLayer(FOREGROUND);
        // this.context.drawCurrentObjectsGeometry([btiGeometry]);
        /* Вывод модального окна - понадобится позже
        const isEmpty = Object.keys(btiGeometry).length === 0;

        if (isEmpty) {
          this.props.alert('Отсутствует геометрия объекта по данным каталога «Адресный реестр объектов недвижимости города Москвы (ID каталога 60562) ЕГАС ОДОПМ». Укажите другой адрес объекта.');
        } else {
          this.context.drawCurrentObjectsGeometry([btiGeometry]);
        }*/
      }

      if (
        currentValues?.address_list &&
        prevProps?.currentValues?.address_list &&
        prevProps?.currentValues?.address_list[0]?.kl !==
          currentValues?.address_list[0]?.kl
      )
        changeFieldValue(
          'buildings_type_id',
          currentValues?.address_list[0]?.kl?.toLowerCase()?.trim() === 'жилые'
            ? 84
            : 91,
        );
    }
  }

  /**
   * Функция getAdditionForAddressList.
   *
   * @returns {{onDelete: Function, data: {address: {kl_kod: number}}, address_required: boolean, withChange: Function, onlyOne: boolean}} - Жопа.
   * @example ----
   */
  getAdditionForAddressList() {
    const {
      changeFieldValue,
      // btiAddressId,
      // fetchBtiGeometry,
      resetBtiGeometry,
    } = this.props;
    const data = this.isNeedFilterAddress() ? this.getFilterData() : null;
    return {
      address_required: true,
      data,
      noWrapper: true,

      /**
       *
       */
      onDelete: () => {
        changeFieldValue('buildings_type_id', null);
        changeFieldValue('buildings_type_spec_id', null);
        resetBtiGeometry();
      },
      onlyOne: true,

      /**
       *
       * @param value
       */
      withChange: (value) => {
        changeFieldValue(
          'buildings_type_id',
          value?.toLowerCase()?.trim() === 'жилые' ? 84 : 91,
        );
        // fetchBtiGeometry(btiAddressId);
      },
    };
  }

  /**
   * Функция getFilterData.
   *
   * @returns {{address: {kl_kod: number}}} - Жопа.
   * @example ----
   */
  getFilterData() {
    return {
      address: {
        kl_kod: UNTENANTABLE,
      },
    };
  }

  /**
   * Флаг isNeedFilterAddress.
   *
   * @returns {boolean} - Жопа.
   * @example ----
   */
  isNeedFilterAddress() {
    const { parentTypeId } = this.props;
    return isImprovementObject(parentTypeId);
  }

  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   * @example ----
   */
  render() {
    const { props } = this;
    const { card, required, editMode, mode, isCurrentObjectIsExternalSystem } =
      props;
    const { type_id: typeId } = card;

    return (
      <CardAbstract>
        <div>
          <div id="editor-card">
            {printOghCardHeader(card, props.mode)}
            {card && objectTabs(typeId)}
            <div id="props">
              {elementFormGrid(
                [
                  {
                    addition: {
                      /**
                       *
                       * @param value
                       */
                      insteadOfOnChange: (value) => {
                        props.changeFieldValue('buildings_type_id', value);
                        props.changeFieldValue('buildings_type_spec_id', null);
                      },
                      label: 'Назначение',
                      options: card.buildings_type,
                      placeholder: '',
                    },
                    component: WithReduxFormSelect,
                    editable: false,
                    formValue: true,
                    id: 'buildings_type_id',
                    name: 'buildings_type_id',
                  },
                  {
                    addition: {
                      label: 'Уточнение',
                      options: props.buildingsTypeSpec,
                      placeholder: '',
                    },
                    component: WithReduxFormSelect,
                    editable:
                      props.currentValues.buildings_type_id === 84 && editMode,
                    formValue: true,
                    id: 'buildings_type_spec_id',
                    name: 'buildings_type_spec_id',
                  },
                ],
                2,
                '',
                typeId,
              )}

              {elementFormGrid(
                [
                  // Закоментировано в рамках ODS-9626
                  // {
                  //   id: 'no_calc',
                  //   name: 'no_calc',
                  //   label: 'Не учитывать',
                  //   editable: editMode,
                  //   formValue: true,
                  //   component: WithReduxFormCheckbox,
                  //   style: checkboxStyle,
                  // },
                  <div
                    style={{
                      height: 50,
                      margin: '9px 24px',
                      minWidth: 256,
                      paddingTop: 0,
                    }}
                  >
                    <IsDiffHeightMarkRF
                      disabled={
                        !mode.editMode || isCurrentObjectIsExternalSystem
                      }
                      justifyContent={'space-between'}
                      labelPlacement={'start'}
                    />
                  </div>,

                  <div>
                    <ParentInfo />
                  </div>,
                ],
                2,
                '',
                typeId,
              )}

              {elementFormGrid(
                [
                  {
                    addition: this.getAdditionForAddressList(),
                    component: renderAddressList2,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'address_list',
                    label: null,
                    name: 'address_list',
                  },
                ],
                1,
                'Адрес БТИ',
                typeId,
                {
                  required,
                },
              )}

              <Tabs tabs={tabs} />

              <div id="characteristics">
                {elementFormGrid(
                  [
                    {
                      addition: {
                        digits: 2,
                        positive: true,
                        required,
                        type: 'decimal',
                        zero: false,
                      },
                      component: NumberFieldRF,
                      editable: editMode && !isCurrentObjectIsExternalSystem,
                      formValue: true,
                      id: 'build_area',
                      label: 'Площадь застройки, кв.м',
                      name: 'build_area',
                    },
                    <div>
                      <TotalAreaGeoRF disabled={true} decimalScale={2} />
                    </div>,
                    {
                      addition: {
                        digits: 0,
                        positive: true,
                        required,
                        type: 'integer',
                        zero: false,
                      },
                      component: TextFieldRF,
                      editable: editMode && !isCurrentObjectIsExternalSystem,
                      formValue: true,
                      id: 'floor_qty',
                      label: 'Этажность',
                      name: 'floor_qty',
                    },
                    {
                      addition: {
                        multiLine: true,
                        type: 'text',
                      },
                      component: TextFieldRF,
                      editable: editMode,
                      formValue: true,
                      id: 'property',
                      label: 'Характеристика',
                      name: 'property',
                      style: { height: 'auto' },
                    },
                  ],
                  2,
                  '',
                  typeId,
                )}
              </div>

              {DocumentsTab()({
                documents: props.documents,
                editable: editMode,
                typeId,
              })}
            </div>
            <div hidden={true} id="versions">
              <Versions
                card={card}
                disabled={props.createMode}
                key={`versions-${card.root_id}`}
              />
            </div>
          </div>
        </div>
      </CardAbstract>
    );
  }
}

const buildingsDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
function mapStateToProps(state, props) {
  const parse = getParseAttribute(props);
  const getBuildingsTypeSpec = getBuildingsTypeSpecByTypeId(props.card.type_id);
  const result = {
    btiAddressId: getBtiAddressId(state),
    btiGeometry: state.card.btiGeometry,
    buildingsTypeSpec: getBuildingsTypeSpec(state),
    createMode: isCreateMode(state, props),
    currentValues: getFormValues(state),
    editMode: isEditMode(state, props),
    enableReinitialize: true,
    initialValues: {
      address_list:
        (props.card.address_list &&
          props.card.address_list.map((item) => {
            return {
              ...item,
              ...item.attributes,
              attributes: null,
            };
          })) ||
        null,
      architecture_color: parse('architecture_color'),
      architecture_lighting: parse('architecture_lighting'),
      build_area: parse('build_area'),
      buildings_type_id: parse('buildings_type_id.buildings_type'),
      buildings_type_spec_id: parse(
        'buildings_type_spec_id.buildings_type_spec',
      ),
      endDate: parseDate(props.card.end_date),
      floor_qty: parse('floor_qty'),
      is_diff_height_mark: parse('is_diff_height_mark'),
      property: parse('property'),
      startDate: parseDate(props.card.start_date),
      totalAreaGeo: parse('total_area_geo'),
      // Закоментировано в рамках ODS-9626
      // no_calc: parse('no_calc'),
    },

    onSubmit: onSubmit.bind({ props }),
    parentTypeId: getParentTypeId(state),
    required: isRequired(state, props),
    validate,
  };

  return buildingsDocumentsReducer(result, state, props);
}

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object} - Отвалидированные поля.
 * @example ----
 */
function validate(values) {
  return validateNumeric(values, numericFields);
}

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

const numericFields = [
  {
    name: 'build_area',
    positive: true,
    type: 'decimal',
    zero: false,
  },
  {
    name: 'floor_qty',
    positive: true,
    type: 'decimal',
  },
];

const dictFields = ['buildings_type_id', 'buildings_type_spec_id'];

const mandatoryFields = {
  [BUILDING]: ['buildings_type_id', 'build_area', 'floor_qty', 'address_list'],
};

/**
 * Функция сабмита.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {void} - Жопа.
 * @example ----
 */
function onSubmit(formValues) {
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[this.props.card.type_id],
    () => {
      const errors = {};
      if (
        get(formValuesWithAttribute, 'buildings_type_id') === 84 &&
        !get(formValuesWithAttribute, 'buildings_type_spec_id')
      ) {
        errors.buildings_type_spec_id = REQUIRED_FIELD_MESSAGE;
      }
      if (get(formValuesWithAttribute, 'floor_qty') === 0) {
        errors.floor_qty = FLOOR_QTY_MESSAGE;
      }
      return errors;
    },
  );

  let submitValues = transformValues(formValuesWithAttribute, {
    custom: {
      address_list: {
        /**
         *
         * @param value
         */
        transformer: (value) => {
          return value == null || value.length === 0
            ? null
            : value.map((item) => {
                return mapValues(
                  pick(item, [
                    'okrug_id',
                    'address_id',
                    'street_id',
                    'district_id',
                    'description',
                  ]),
                  (id) => {
                    return { bti_object: id };
                  },
                );
              });
        },
      },
    },
    dictFields,
    numericFields,
  });

  submitCard(this.props, submitValues);
}

/**
 * Функция получения экшенов.
 *
 * @param {Function} dispatch - Функция диспатч.
 * @returns {object} - Экшен changeFieldValue.
 * @example ----
 */
function mapDispatchToProps(dispatch) {
  return {
    /**
     * Функция changeFieldValue.
     *
     * @param {*} field - Жопа.
     * @param {*} value - Жопа.
     * @returns {void} - Nothing.
     * @example ----
     */
    changeFieldValue: function (field, value) {
      dispatch(change('editorCard', field, value));
    },

    /**
     * Функция fetchBtiGeometry.
     *
     * @param {number} id - ID.
     * @returns {void} - Nothing.
     * @example ----
     */
    fetchBtiGeometry: function (id) {
      dispatch(fetchBtiGeometry({ addressId: id }));
    },

    /**
     * Функция resetBtiGeometry.
     *
     * @returns {void} - Nothing.
     * @example ----
     */
    resetBtiGeometry: function () {
      dispatch(resetBtiGeometry());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(BuildingCard));
