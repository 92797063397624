import {
  fetchCheckGeomContains,
  fetchCheckGeometryIntersections,
  resetIntersectionsGeometry,
} from 'app/actions/geometryActions';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { WithMapContext } from 'app/components/map/withMap';
import { INTERSECTIONS } from 'app/constants/layers';
import getTreeJournal from 'app/selectors/card/getTreeJournal';
import hasIntersections from 'app/selectors/card/hasIntersections';
import getFormValues from 'app/selectors/form/getFormValues';
import {
  getCheckGeometryIntersectionAttributes,
  onCheckGeometryButtonPress,
} from 'app/utils/card/checkGeometry';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import toggleContent from '../content/toggle';
import ToggleButton from './ToggleButton';

/* eslint-disable */
/**
 * Компонент кнопки CheckGeometryButton.
 *
 * @returns {JSX.ElementClass} - Жопа.
 */
class CheckGeometryButton extends React.Component {
  static propTypes = {
    hasIntersections: PropTypes.bool.isRequired,
    resetIntersectionsGeometry: PropTypes.func.isRequired,
    fetchCheckGeomContains: PropTypes.func.isRequired,
    fetchCheckGeometryIntersections:
      PropTypes.func.isRequired,
    showAlert: PropTypes.func.isRequired,

    treeJournal: PropTypes.object,
    selected: PropTypes.object,
    card: PropTypes.object,
    currentFormValues: PropTypes.object,
  };

  static contextType = WithMapContext;

  /* eslint-disable */
/**
   * Метод жизненого цикла componentWillUnmount.
   *
   * @returns {void} - Nothing.
   * @example .
   */
  componentWillUnmount() {
    this.props.resetIntersectionsGeometry();
  }

  checkGeometryByButton = () => {
    const {
      treeJournal,
      card,
      selected,
      currentFormValues,
      parentInfo,
    } = this.props;
    const currentCard = selected || card;
    const geometry = this.context.getGeometry(
      currentCard.record_id,
    );

    const data = {
      id: currentCard.root_id,
      parentId: currentCard.parent_id,
      typeId: currentCard.type_id,
      geometry,
      journal:
        treeJournal !== null
          ? JSON.stringify(treeJournal.actions)
          : null,
      cnt:
        treeJournal !== null ? treeJournal.cnt_action : 0,
      attribute: getCheckGeometryIntersectionAttributes(
        currentCard.type_id,
        currentFormValues,
        card.attribute,
      ),
      treeParentId: parentInfo ? parentInfo.recordId : null,
      startDate: currentCard.start_date,
    };

    this.context.clearLayer(INTERSECTIONS);
    onCheckGeometryButtonPress({
      data,
      hasIntersections: this.props.hasIntersections,
      resetIntersectionsGeometry:
        this.props.resetIntersectionsGeometry,
      fetchCheckGeomContains:
        this.props.fetchCheckGeomContains,
      fetchCheckGeometryIntersections:
        this.props.fetchCheckGeometryIntersections,
      showAlert: this.props.showAlert,
      draw: this.context.drawIntersectionsGeometry,
    });
  };

   
/**
   * Метод жизненого цикла render.
   *
   * @returns {React.ReactComponentElement} - React.
   * @example .
   */
  render() {
    return (
      <ToggleButton
        active={this.props.hasIntersections}
        content={toggleContent.intersections}
        onClick={this.checkGeometryByButton}
      />
    );
  }
}

/* eslint-disable */
/**
 * Функция получения значений из стейта.
 *
 * @param {object} state - Стейт.
 * @returns {{treeJournal: object, currentFormValues: *, hasIntersections: boolean, card: object, selected: object}} - Данные которые нужны в props.
 * @example
 * export default connect(
 *   mapStateToProps,
 *   mapDispatchToProps,
 * )(CheckGeometryButtonHOC);
 */
const mapStateToProps = (state) => {
  return {
    hasIntersections: hasIntersections(state),
    treeJournal: getTreeJournal(state),
    card: state.card.object,
    selected: state.tree.object,
    currentFormValues: getFormValues(state),
  };
};

const mapDispatchToProps = {
  resetIntersectionsGeometry,
  fetchCheckGeomContains,
  fetchCheckGeometryIntersections,
};

 
/**
 * Компонент обертка для получения информации из хуков.
 *
 * @param {object} props - Пропрсы.
 * @returns {JSX.Element} - Возвращает компонет CheckGeometryButton прокидывая в него информацию о родителе.
 * @example
 * export default connect(
 *   mapStateToProps,
 *   mapDispatchToProps,
 * )(CheckGeometryButtonHOC);
 */
const CheckGeometryButtonHOC = (props) => {
  const parent = useGetParentCard();
  const { actions } = useGetParamsUrl();

  const parentInfo = actions?.haveParent ? parent : null;
  return (
    <CheckGeometryButton
      {...props}
      parentInfo={parentInfo}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckGeometryButtonHOC);
