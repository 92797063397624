import { TabBody } from 'app/components/card/ogh/Odh/components/tabs/TabBody';
import { DataCoordOrganizationRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/DataCoordOrganization.RF';
import { FellingTicketRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/FellingTicket.RF';
import { MarkingDrawingRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/MarkingDrawing.RF';
import { PlanFlowerRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/PlanFlower.RF';
import { SituationPlanRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/SituationPlan.RF';
import { SketchFlowerRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/SketchFlower.RF';
import { SymbolsInventoryPlanRF } from 'app/pages/cardsOgh/components/tables/Documents/FieldsRF/preparedFields/SymbolsInventoryPlan.RF';
import { InventoryPlanRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/InventoryPlan.RF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';

/**
 * Document Tab Spa.
 *
 * @returns {JSX}
 */
export const DocumentsTabSpa = () => {
  return (
    <TabBody id="docs">
      <SituationPlanRF />
      <InventoryPlanRF />
      <SymbolsInventoryPlanRF />
      <PlanFlowerRF />
      <SketchFlowerRF />
      <MarkingDrawingRF />
      <DataCoordOrganizationRF />
      <OtherDocumentsRF />
      <FellingTicketRF />
    </TabBody>
  );
};
