import { MATERIAL_ID_NAME } from 'core/form/finalForm/fields/prepared/selects/simple/MaterialId.FF/MaterialId.FF';
import { Validator } from 'core/form/Validator';
import { UNIT_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/UnitId';
import { VERTICAL_LANDSCAPING_REF_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/VerticalLandscapingRefTypeId';
import { VERTICAL_LANDSCAPING_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/VerticalLandscapingTypeId';
import { PLACES_AREA_NAME } from 'core/uiKit/preparedInputs/textField/PlacesArea';
import { PLACES_QUANTITY_NAME } from 'core/uiKit/preparedInputs/textField/PlacesQuantity';
import { QUANTITY_NAME } from 'core/uiKit/preparedInputs/textField/Quantity';

/**
 * Валидация формы __Вертикальные объекты озеленения__.
 *
 * @param {object} values - Значения формы.
 * @returns {object}
 */
export const validateVerticalLandscaping = (values) => {
  const validator = new Validator(values);

  validator.setRequired(VERTICAL_LANDSCAPING_TYPE_ID_NAME);
  validator.setRequired(VERTICAL_LANDSCAPING_REF_TYPE_ID_NAME);
  validator.setRequired(PLACES_QUANTITY_NAME);
  validator.setRequired(QUANTITY_NAME);
  validator.setRequired(UNIT_ID_NAME);
  validator.setRequired(MATERIAL_ID_NAME);
  validator.setRequired(PLACES_AREA_NAME);

  if (values[PLACES_AREA_NAME] <= 0) {
    validator.addError(PLACES_AREA_NAME, 'значение должно быть больше 0');
  }

  return validator.getErrors();
};
