import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import React from 'react';
import { Field } from 'redux-form';

import { RepairsInfoListPlanWithReduxFrom } from './RepairsInfoListPlanWithReduxFrom';

export const REPAIRS_INFO_LIST_PLAN_NAME = 'repairs_info_list_plan';

/**
 * Таб таблицы __Проекты__.
 *
 * @returns {Element}
 */
export const RepairsPlanTab = () => {
  return (
    <CardContainer title={'Проекты'}>
      <Field
        name={REPAIRS_INFO_LIST_PLAN_NAME}
        component={RepairsInfoListPlanWithReduxFrom}
      />
    </CardContainer>
  );
};
