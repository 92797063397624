/* eslint-disable */

import { useGetFilesForSignature } from 'app/api/hooks/signs/useGetFilesForSignature';
import { SEND_REASON_NAME } from 'core/form/filterForm';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useState } from 'react';
import { useField } from 'react-final-form';

import useSetup from './useSetup.Form.Dialog.SignatureFiles';

jest.mock('app/api/hooks/signs/useGetFilesForSignature');
jest.mock('react-final-form');
jest.mock('core/utils/hooks/useGetObjectId');

jest.mock('react', () => ({
  ...jest.requireActual('react'),
  useEffect: jest.fn(),
  useState: jest.fn(),
}));

describe('useSetup', () => {
  it('should return initial values when form fields are empty', () => {
    // Arrange (всякие моки)
    useField.mockImplementation(() => ({ input: { value: undefined } }));
    useState.mockImplementation(() => ['ssdf', (e) => e]);
    useGetObjectId.mockReturnValue({});
    useGetFilesForSignature.mockReturnValue({});

    // Act
    const result = useSetup();

    // Assert
    expect(result).toStrictEqual({
      callbackError: expect.any(Function),
      certificate: undefined,
      error: 'ssdf',
      files: [],
      filesForSignature: [],
      isUnion: undefined,
      onSignaturesChange: undefined,
      signatures: undefined,
    });
  });

  it('callbackError', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useField.mockImplementation(() => ({ input: { value: undefined } }));
    useGetObjectId.mockReturnValue({});
    useGetFilesForSignature.mockReturnValue({});

    const setErrorSpy = jest.fn();
    useState.mockReturnValue(['error', setErrorSpy]);
    // Act
    const { callbackError } = useSetup();
    callbackError(new Error('вот такая ошибка'));

    // Assert
    expect(setErrorSpy).toHaveBeenCalledWith('вот такая ошибка');
  });

  it('isUnion false default', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useField.mockImplementation(() => ({ input: { value: undefined } }));
    useState.mockImplementation(() => ['ssdf', (e) => e]);
    useGetObjectId.mockReturnValue({});
    useGetFilesForSignature.mockReturnValue({});

    // Act
    const { isUnion } = useSetup();

    // Assert
    expect(isUnion).toBeFalsy();
  });

  it('isUnion false  Если пользователь не выбрал причину закрытия паспорта ОГХ "Объединение ОГХ"', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useState.mockImplementation(() => ['ssdf', (e) => e]);
    useField.mockImplementation((name) => {
      if (name === SEND_REASON_NAME) {
        return { input: { value: [3, 4, 6, 7] } };
      }
      return { input: { value: undefined } };
    });
    useGetObjectId.mockReturnValue({});
    useGetFilesForSignature.mockReturnValue({});

    // Act
    const { isUnion } = useSetup();

    // Assert
    expect(isUnion).toBeFalsy();
  });

  it('isUnion true Если пользователь выбрал причину закрытия паспорта ОГХ "Объединение ОГХ" (46)', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useState.mockImplementation(() => ['ssdf', (e) => e]);
    useField.mockImplementation((name) => {
      if (name === SEND_REASON_NAME) {
        return { input: { value: [3, 46, 7] } };
      }
      return { input: { value: undefined } };
    });
    useGetObjectId.mockReturnValue({});
    useGetFilesForSignature.mockReturnValue({});

    // Act
    const { isUnion } = useSetup();

    // Assert
    expect(isUnion).toBeTruthy();
  });
});
