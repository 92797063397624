/* eslint-disable */
import React from 'react';

import { useDeleteOghButton } from './useDeleteOgh.Button';
import { DeleteButton } from 'core/uiKit/components/buttons/actionsButtons/Delete.Button';

 
export const DeleteOghButton = () => {
  const { onDelete, isShow } = useDeleteOghButton();
  return isShow ? <DeleteButton onClick={onDelete} /> : null;
};
