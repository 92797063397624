import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import Ogh from 'app/components/card/ogh/index/view';
import { useRedirectToParent } from 'app/hooks/useRedirectToParent';
import CardOgh from 'app/pages/cardsOgh/CardOgh';
import { checkIsNewCard } from 'app/routes/components/bridgeMap';
import { useGetOghType } from 'core/utils/hooks/useGetOghType';
import { log } from 'core/utils/log';

/**
 * Мост связующий два типа карточек. Новые и старые.
 *
 * @param {object} props - Properties.
 * @returns {JSX.Element} - JSX.
 */
export const RoutingBridge = (props) => {
  const { typeId } = useGetCurrentCard();
  const isNewCardCreate = checkIsNewCard(+typeId);

  useRedirectToParent();

  log.info(
    `${isNewCardCreate ? 'Новая' : 'Старая'} карточка типа`,
    useGetOghType(+typeId),
  );

  const Component = isNewCardCreate ? CardOgh : Ogh;

  return <Component {...props} />;
};
