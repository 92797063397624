import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { parseDate } from 'app/components/card/common/parse';
import { OZN } from 'app/constants/oghTypes';
import { CardContainer } from 'app/pages/cardsOgh/components/CardContainer';
import { RANDOM_ID, RANDOM_WORD } from 'core/forTesting/constants';
import { DatePicker } from 'core/uiKit/inputs/datePickers/DatePicker';
import { TextField } from 'core/uiKit/inputs/index';
import { shallow } from 'enzyme';
import React from 'react';

import { DataPassport } from './DataPassport';

jest.mock('app/api/hooks/useGetCurrentCard');

describe('DataPassportJestSpec', function () {
  it('test render props', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({
      attribute: {
        date_survey: '16.01.2024',
        date_survey_green_zones: '16.01.2024',
      },
      passportDate: '16.01.2024',
      passportDraftOrg: RANDOM_WORD,
      typeId: OZN,
    });
    // Act
    const wrapper = shallow(<DataPassport />);

    // Assert
    expect(wrapper.props()).toStrictEqual({
      children: (
        <CardContainer title={'Данные паспорта'}>
          <TextField
            label={
              'Исполнитель/Исполнители работ (по разработке, актуализации паспорта)'
            }
            disabled={true}
            value={RANDOM_WORD}
          />
          <DatePicker
            label={'Дата составления'}
            value={parseDate('16.01.2024')}
            disabled={true}
          />
          <DatePicker
            label={'Дата обследования территории'}
            value={parseDate('16.01.2024')}
            disabled={true}
          />
          <DatePicker
            label={'Дата обследования территории (дополнение сведений по ЗН)'}
            value={parseDate('16.01.2024')}
            disabled={true}
          />
        </CardContainer>
      ),
    });
  });
  it('test render typeId not OZN and YARD', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    useGetCurrentCard.mockReturnValue({
      dateSurvey: '16.01.2024',
      dateSurveyGreenZones: '16.01.2024',
      passportDate: '16.01.2024',
      passportDraftOrg: '16.01.2024',
      typeId: RANDOM_ID,
    });
    // Act
    const wrapper = shallow(<DataPassport />);
    // Assert
    expect(wrapper.text()).toBe('');
  });
});
