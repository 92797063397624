/* eslint-disable  */

import { validateRequiredFieldsAsync } from 'app/components/card/common/validation';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields/prepared/checkboxes/IsDiffHeightMark.RF';
import { shallow } from 'enzyme';

import FencingCard, { mapStateToProps } from './fencing';
import submitCard from './submit';

jest.mock('app/components/card/common/validation');
jest.mock('./submit');

jest.mock('redux-form', () => ({
  ...jest.requireActual('redux-form'),
  reduxForm: jest.fn(() => (Component) => {
    return Component;
  }),
}));

jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  connect: jest.fn(() => (b) => {
    return b;
  }),
}));

describe('FencingJestSpec', function () {
  it('test IsDiffHeightMarkRF render card', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const props = {
      card: { bord_begin: 1 },
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };

    // Act
    const wrapper = shallow(<FencingCard {...props} />);
    // Assert
    expect(wrapper.find(IsDiffHeightMarkRF).props()).toStrictEqual({
      disabled: false,
    });
  });
  it('test quantity_sqr render card', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const props = {
      card: { bord_begin: 1 },
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };

    // Act
    const wrapper = shallow(<FencingCard {...props} />);

    // Assert
    expect(wrapper.find('#quantity_sqr').props()).toMatchObject({
      value: 0,
    });
  });
  it('atest IsDiffHeightMarkRF render card', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const props = {
      card: { bord_begin: 1 },
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };

    // Act
    const wrapper = shallow(<FencingCard {...props} />);
    // Assert
    expect(wrapper.find(IsDiffHeightMarkRF).props()).toStrictEqual({
      disabled: false,
    });
  });
  it('test', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)

    // Act
    const { onSubmit } = mapStateToProps({}, { card: { attribute: {} } });
    onSubmit({});
    // Assert
    expect(submitCard).toBeCalledTimes(1);
    expect(validateRequiredFieldsAsync).toBeCalledTimes(1);
  });
});
