import { elementFormGrid } from 'app/components/card/common/grid';
import { WithReduxFormSelect } from 'app/components/common/SelectField';

import metadata from './metadata';


/* eslint-disable */
export default function (params = {}) {
  const { cols = 2, title = '' } = params;
  const element = getElements(params);
  return (props) =>
    elementFormGrid(element(props), cols, title, props.card.type_id);
}

function getElements(params) {
  return (props) =>
    Object.entries(metadata).map(([key, item]) => {
      const { editable = true, formValue = true } = params[key] || {};
      const elementEditable = editable && props.editable;
      return {
        id: item.id,
        name: item.id,
        editable: elementEditable,
        formValue,
        component: WithReduxFormSelect,
        addition: {
          defaultValue: props.initialValues[item.id],
          label: item.label,
          options: props.options[item.id],
          required: elementEditable && props.required,
        },
      };
    });
}
