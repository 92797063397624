import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
import { Button } from 'core/uiKit/components/buttons/Button';
import PropTypes from 'prop-types';

/**
 * Закрыть (ЭП).
 *
 * @param {*} root0 - Параметры.
 * @param {number} root0.objectId - Id версии ОГХ.
 * @returns {JSX.Element}
 */
export const ButtonCloseElectronicSignature = ({ objectId, ...props }) => {
  const {
    data: { close_eds: closeEds } = {}, // todo: сделать по дефолту false
  } = useGetVisibilityButtonsOgh(objectId);

  return closeEds ? (
    <Button
      {...props}
      color={'error'}
      variant={'contained'}
      startIcon={<FontAwesomeIcon icon={faFileSignature} />}
    >
      Закрыть (ЭП)
    </Button>
  ) : null;
};

ButtonCloseElectronicSignature.propTypes = {

  /**
   * Id версии ОГХ.
   */
  objectId: PropTypes.number.isRequired,
};
