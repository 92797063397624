import {
  elementFormGrid,
  renderAddressList2,
} from 'app/components/card/common/grid';
import { WithReduxFormCheckbox } from 'app/components/common/CheckboxField';
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import { UNTENANTABLE } from 'app/constants/addressKlKods';
import { column } from 'app/constants/tables';
import getBuildingsTypeSpecByTypeId from 'app/selectors/card/getBuildingsTypeSpecByTypeId';
import getDict from 'app/selectors/getDict';
import isMandatory from 'app/utils/card/isMandatory';
import getHint from 'app/utils/getAddressListHints';
import { NumberFieldRF } from 'core/form/reduxForm/fields/default/NumberField.RF';
import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields/prepared/checkboxes/IsDiffHeightMark.RF';
import { CoatingGroupIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CoatingGroupId.RF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import React from 'react';
import { connect } from 'react-redux';

import { CoatingFaceTypeIdField } from '../../components/selects/CoatingFaceTypeId.Field';
import { CoatingTypeIdFiled } from '../../components/selects/CoatingTypeId.Field';

const checkboxStyle = {
  height: 50,
  paddingTop: 0,
};

/**
 * Компонет Properties.
 *
 * @param {object} props - Пропсы.
 * @param {number} props.typeId - Тайп айди.
 * @param {boolean} props.editMode - Флаг редактирования.
 * @param {boolean} props.required - Обязательность поля.
 * @param {*} props.buildingsTypes - Жопа.
 * @param {*} props.buildingsTypeSpec - Жопа.
 * @param {*} props.isCurrentObjectIsExternalSystem - Жопа.
 * @returns {JSX.Element} - Реакт компонент.
 * @example ------.
 */
function Properties({
  typeId,
  editMode,
  required,
  buildingsTypes,
  buildingsTypeSpec,
  isCurrentObjectIsExternalSystem,
}) {
  return (
    <div>
      {elementFormGrid(
        [
          {
            addition: {
              label: 'Назначение',
              options: buildingsTypes,
              required: isMandatory(typeId, 'buildings_type', required),
            },
            component: WithReduxFormSelect,
            editable: false,
            formValue: true,
            id: 'buildings_type_id',
            name: 'buildings_type_id',
          },
          {
            addition: {
              label: 'Уточнение назначения',
              options: buildingsTypeSpec,
              required: isMandatory(typeId, 'buildings_type_spec_id', required),
            },
            component: WithReduxFormSelect,
            editable: editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'buildings_type_spec_id',
            name: 'buildings_type_spec_id',
          },
        ],
        column.TWO,
        '',
        typeId,
      )}

      {elementFormGrid(
        [
          {
            component: WithReduxFormCheckbox,
            editable: editMode,
            formValue: true,
            id: 'no_calc',
            label: 'Не учитывать',
            name: 'no_calc',
            style: checkboxStyle,
          },
          <div
            style={{
              height: 50,
              margin: '9px 24px',
              minWidth: 256,
              paddingTop: 0,
            }}
          >
            <IsDiffHeightMarkRF
              disabled={!editMode || isCurrentObjectIsExternalSystem}
              justifyContent={'space-between'}
              labelPlacement={'start'}
            />
          </div>,
        ],
        2,
        '',
        typeId,
      )}

      {elementFormGrid(
        [
          {
            addition: {
              address_required: true,
              data: {
                address: {
                  kl_kod: UNTENANTABLE,
                },
              },
              hintText: editMode ? getHint('near_address') : null,
              noWrapper: true,
              onlyOne: true,
            },
            component: renderAddressList2,
            editable: editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'address_list',
            label: null,
            name: 'address_list',
          },
        ],
        column.ONE,
        'Адрес БТИ',
        typeId,
      )}
      {elementFormGrid(
        [
          {
            addition: {
              digits: 2,
              positive: true,
              required: isMandatory(typeId, 'area', required),
              type: 'decimal',
              zero: false,
            },
            component: NumberFieldRF,
            editable: editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'area',
            label: 'Площадь, кв.м',
            name: 'area',
          },
          <div>
            <TotalAreaGeoRF disabled={true} decimalScale={2} />
          </div>,
          {
            addition: {
              multiLine: true,
              required: isMandatory(typeId, 'property', required),
              type: 'text',
            },
            component: TextFieldRF,
            editable: editMode && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'property',
            label: 'Характеристика',
            name: 'property',
          },

          <div>
            <CoatingGroupIdRF disabled={!editMode} />
          </div>,
          <div>
            <CoatingTypeIdFiled />
          </div>,
          <div>
            <CoatingFaceTypeIdField />
          </div>,
          <div>
            <NumberFieldRF
              disabled={!editMode}
              label="Площадь облицовки, кв.м"
              name="face_area"
              decimalScale={2}
            />
          </div>,
        ],
        column.TWO,
        '',
        typeId,
      )}
    </div>
  );
}

const getBuildingsTypes = getDict('buildingsType');

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const { typeId } = props;
  const getBuildingsTypeSpec = getBuildingsTypeSpecByTypeId(typeId);
  return {
    buildingsTypeSpec: getBuildingsTypeSpec(state),
    buildingsTypes: getBuildingsTypes(state),
  };
};

export default connect(mapStateToProps)(Properties);
