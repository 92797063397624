/* eslint-disable */
import React, { useState } from 'react';

import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';

import { ButtonCloseElectronicSignature } from './Button.CloseElectronicSignature';
import { DialogCloseElectronicSignature } from './Dialog.CloseElectronicSignature';

 
export const CloseElectronicSignature = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { objectId } = useGetObjectId();
  return (
    <>
      <ButtonCloseElectronicSignature
        objectId={objectId}
        onClick={() => setIsOpen(true)}
      />
      {isOpen ? (
        <DialogCloseElectronicSignature
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      ) : null}
    </>
  );
};
