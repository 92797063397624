import { DownLoadFilesListCell } from 'core/uiKit/components/cells/DownLoadFilesList.Cell/DownLoadFilesList.Cell';

/**
 * Колонки таблицы проектов.
 *
 * @returns {Array}
 */
export const getColumnsRepairsPlanTab = () => [
  {
    Header: 'Наименование',
    accessor: 'name',
  },
  {
    Header: 'Дата начала работ',
    accessor: 'date_start',
  },
  {
    Header: 'Дата окончания работ',
    accessor: 'date_end',
  },
  {
    Header: 'Техническое задание (характеристика объемы)',
    accessor: 'property',
  },
  {
    Cell: DownLoadFilesListCell,
    Header: 'Техническое задание / проект благоустройства',
    accessor: 'file_list',
  },
];
