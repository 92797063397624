import { Button } from 'core/uiKit/components/buttons/Button';
import { ContinueDialog } from 'core/uiKit/components/dialogs/Continue.Dialog';
import { Icon } from 'core/uiKit/components/Icon';
import React from 'react';

import { useSetupCancelButton } from './useSetup.CanselButton';

/**
 * Кнопка Отмена.
 *
 * @param {object} props - Пропсы.
 * @param {Function} props.resetForm - Функция сброса формы.
 * @returns {JSX}
 */
export const CancelButton = ({ resetForm }) => {
  const {
    closeDialog,
    dialog,
    editMode,
    handleCancelButtonClick,
    isLoading,
    sendDialog,
  } = useSetupCancelButton(resetForm);

  return (
    <>
      <ContinueDialog
        closeDialog={closeDialog}
        isLoading={isLoading}
        send={sendDialog}
        isOpen={dialog}
        textHeader={'Подтвердите действие'}
        Body={<div>Все несохраненные изменения будут потеряны</div>}
      />

      <Button
        loading={isLoading}
        color={'error'}
        variant={'contained'}
        startIcon={<Icon>reply</Icon>}
        hidden={!editMode}
        onClick={handleCancelButtonClick}
      >
        Отмена
      </Button>
    </>
  );
};
