import { Button } from 'core/uiKit/components';
import { StepContent } from 'core/uiKit/material-ui';
import { renderA } from 'core/utils/renders/renderA';
import React, { FC, MouseEventHandler } from 'react';

interface StepContentNewsProps {
  children: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  hidden: boolean;
}

/**
 * Компонент текстовой Степа.
 *
 * @param props - Properties.
 * @param props.children - Текст.
 * @param props.onClick - Функция клика.
 * @param props.hidden - Флаг скрытия блока.
 * @returns {JSX.Element}
 */
export const StepContentNews: FC<StepContentNewsProps> = ({
  children,
  onClick,
  hidden,
}) => {
  return hidden ? null : (
    <StepContent>
      {renderA(children)}
      {/*// @ts-ignore*/}

      {/* <TextField multiline={true} value={children} /> */}
      <Button
        className={'ms-2 ms-auto d-block'}
        variant="contained"
        color={'warning'}
        data-testid={'next_step'}
        onClick={onClick}
      >
        Прочитано
      </Button>
    </StepContent>
  );
};
