import { useFetchOghDispatch } from 'app/actions/odsObjectActions/useFetchOgh.Dispatch';
import { RANDOM_BOOL, RANDOM_ID, RANDOM_WORD } from 'core/forTesting/constants';
import { toast } from 'core/uiKit/components/Toast';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';

import { signCloseElectronicSignature } from './signCloseElectronicSignature';
import { useSignCloseElectronicSignature } from './useSignClose.ElectronicSignature';

jest.mock('app/actions/odsObjectActions/useFetchOgh.Dispatch');
jest.mock('core/utils/hooks/useReactQuery');
jest.mock('./signCloseElectronicSignature');
jest.mock('core/uiKit/components/Toast');

describe('UseSignCloseElectronicSignatureJestSpec', function () {
  it('Успешный кейс', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const objectId = RANDOM_ID;
    const signType = RANDOM_WORD;
    const objectCardFiles = {};
    const onSuccessCallback = jest.fn();
    const onErrorCallback = jest.fn();
    const fetchOgh = jest.fn();
    const sign = jest.fn();

    const spyToastSuccess = jest.spyOn(toast, 'success');
    useFetchOghDispatch.mockReturnValue(fetchOgh);
    signCloseElectronicSignature.mockReturnValue(sign);
    useMutationAdaptor.mockImplementation((fn, { onSuccess }) => {
      fn();
      onSuccess();
      return [jest.fn(), { isLoading: RANDOM_BOOL }];
    });

    // Act
    useSignCloseElectronicSignature({
      objectCardFiles,
      objectId,
      onErrorCallback,
      onSuccessCallback,
      signType,
    });
    // Assert
    expect(sign).toBeCalledTimes(1);
    expect(onSuccessCallback).toBeCalledTimes(1);
    expect(fetchOgh).toBeCalledTimes(1);
    expect(spyToastSuccess).toBeCalledWith('Подписано');
  });
  it('Негативный кейс', () => {
    expect.hasAssertions();
    // Arrange (всякие моки)
    const objectId = RANDOM_ID;
    const signType = RANDOM_WORD;
    const objectCardFiles = {};
    const onSuccessCallback = jest.fn();
    const onErrorCallback = jest.fn();
    const fetchOgh = jest.fn();
    const sign = jest.fn();

    const spyToastError = jest.spyOn(toast, 'error');
    useFetchOghDispatch.mockReturnValue(fetchOgh);
    signCloseElectronicSignature.mockReturnValue(sign);
    useMutationAdaptor.mockImplementation((fn, { onError }) => {
      fn();
      onError({ message: RANDOM_WORD });
      return [jest.fn(), { isLoading: RANDOM_BOOL }];
    });

    // Act
    useSignCloseElectronicSignature({
      objectCardFiles,
      objectId,
      onErrorCallback,
      onSuccessCallback,
      signType,
    });
    // Assert
    expect(spyToastError).toBeCalledWith(RANDOM_WORD);
    expect(onErrorCallback).toBeCalledTimes(1);
  });
});
