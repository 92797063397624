import { editCardClearDialog } from 'app/actions/odsObjectActions';
/* eslint-disable */
import { useIsCurrentObjectIsExternalSystem } from 'app/api/hooks/useIsCurrentObjectIsExternalSystem';
import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid';
import { parseDate } from 'app/components/card/common/parse';
import Table from 'app/components/common/table/Table';
import DialogWithTableControls from 'app/components/dialogs/common/DialogWithTableControls';
import { toDate } from 'app/utils/date/toDate';
import KeyboardDatePickerRF from 'core/form/reduxForm/fields/default/datePickers/KeyboardDatePickerRF';
import Checkbox from 'core/newComponents/Checkbox';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const dialogFields = [
  {
    addition: {
      type: 'text',
    },
    component: renderCustomTextField,
    editable: true,
    id: 'name',
    label: 'Наименование',
    name: 'name',
  },
  {
    addition: {
      label: 'Дата',
    },
    component: KeyboardDatePickerRF,
    editable: true,
    id: 'date',
    name: 'date',
  },
  {
    addition: {
      type: 'text',
    },
    component: renderCustomTextField,
    editable: true,
    id: 'property',
    label: 'Характеристика (объемы)',
    name: 'property',
  },
  {
    addition: {
      label: 'Планируемое время проведения работ',
    },
    component: KeyboardDatePickerRF,
    editable: true,
    id: 'plan_time',
    name: 'plan_time',
  },
];

/**
 * Repairs plane table Class.
 *
 */
class RepairsPlanTable extends React.Component {
  static propTypes = {
    input: PropTypes.object,
  };

  /**
   * Function.
   *
   * @returns {void}
   */
  onAdd = () => {
    const table = this.getTable();
    this.props.input.onChange({
      showDialog: true,
      table,
    });
  };

  /**
   * Function.
   *
   * @returns {void}
   */
  onCancel = () => {
    const table = this.getTable();
    this.props.input.onChange({
      showDialog: false,
      table,
    });
    this.props.dispatch(editCardClearDialog());
  };

  /**
   * Function.
   *
   * @param {event} event - Event.
   * @param {*} value - Value.
   * @param {*} index - Index.
   * @returns {void}
   */
  onCheckCheckbox(event, value, index) {
    const table = this.getTable();
    const character = {
      ...table[index],
      checked: value,
    };

    const newTable = table.slice();
    newTable.splice(index, 1, character);

    this.props.input.onChange({
      showDialog: false,
      table: newTable,
    });
  }

  /**
   * Function.
   *
   * @returns {void}
   */
  onRemove = () => {
    const table = this.getTable();
    const newTable = table.filter((item) => !item.checked);
    this.props.input.onChange({
      showDialog: false,
      table: newTable,
    });
  };

  /**
   * Function.
   *
   * @param {object} formValues - Form values.
   * @returns {void}
   */
  onSubmit = (formValues) => {
    const table = this.getTable();
    const value = table.slice();
    if (Object.keys(formValues).length) {
      if (formValues.date) {
        formValues.date = toDate(formValues.date);
      }
      if (formValues.plan_time) {
        formValues.plan_time = toDate(formValues.plan_time);
      }
      value.push(formValues);
    }
    this.props.input.onChange({
      showDialog: false,
      table: value,
    });
    this.props.dispatch(editCardClearDialog());
  };

  /**
   * Function.
   *
   * @returns {*}
   */
  getContainerStyle() {
    return {
      marginBottom: '15px',
      ...this.props.style,
    };
  }

  /**
   * Function.
   *
   * @returns {Array}
   */
  getTable() {
    const { input } = this.props;
    return (input.value && input.value.table) || [];
  }

  /**
   * Function Render.
   *
   * @returns {JSX.Element}
   */
  render() {
    const { props } = this;

    const resultTable = {
      columns: [
        {
          cells: [],
          databaseName: null,
          key: 'name',
          screenName: 'Наименование',
          sortable: false,
        },
        {
          cells: [],
          databaseName: null,
          key: 'date',
          screenName: 'Дата',
          sortable: false,
        },
        {
          cells: [],
          databaseName: null,
          key: 'property',
          screenName: 'Характеристика (объемы)',
          sortable: false,
        },
        {
          cells: [],
          databaseName: null,
          key: 'plan_time',
          screenName: 'Планируемое время проведения работ',
          sortable: false,
        },
      ],
    };

    const table = this.getTable();
    const showDialog =
      (props.input.value && props.input.value.showDialog) || false;
    const dateFormatter = new Intl.DateTimeFormat('ru');

    table.forEach((item) => {
      resultTable.columns.forEach((column) => {
        if (column.key === 'date' || column.key === 'plan_time') {
          column.cells.push(
            column.key in item &&
              item[column.key] !== null &&
              item[column.key] !== ''
              ? dateFormatter.format(parseDate(item[column.key]))
              : '',
          );
        } else {
          column.cells.push(item[column.key]);
        }
      });
    });

    if (!props.disabled) {
      resultTable.columns.unshift({
        cells: [],
        databaseName: null,
        key: 'delete',
        screenName: '',
        sortable: false,
      });
      table.forEach((item, index) => {
        resultTable.columns[0].cells.push(
          <Checkbox
            checked={item.checked || false}
            onChange={(event, value) =>
              this.onCheckCheckbox(event, value, index)
            }
          />,
        );
      });
    }

    return (
      <div style={this.getContainerStyle()}>
        <DialogWithTableControls
          arrayFields={dialogFields}
          className="Dialog-Add-Repairs-Plan-Info_Improvement-Territory"
          enableRemove={table.some((item) => item.checked)}
          inEdit={!props.disabled}
          nameDialog="add_repairs_plan_info"
          renderFunction={elementFormGrid}
          showCondition={showDialog}
          title="Добавить сведения о проектах"
          onAdd={this.onAdd}
          onCancel={this.onCancel}
          onRemove={this.onRemove}
          onSubmit={this.onSubmit}
        />
        <Table
          height={table.length * 50 + 50}
          table={resultTable}
          width={1200 + (props.disabled ? 0 : 50)}
          widths={(name) => (name === '' ? 50 : 300)}
        />
      </div>
    );
  }
}
const Comp = connect()(RepairsPlanTable);

/**
 * Represents.
 *
 * @param {object} pr - Properties.
 * @param {boolean} pr.disabled - Disabled.
 * @param {object} pr.props - Properties.
 * @returns {JSX.Element}
 */

/**
 *
 * @param root0
 * @param root0.disabled
 */
export default ({ disabled, ...props }) => {
  const isCurrentObjectIsExternalSystem = useIsCurrentObjectIsExternalSystem();
  return (
    <Comp {...props} disabled={disabled || isCurrentObjectIsExternalSystem} />
  );
};
