import getAllowedGeometryTypes from 'app/selectors/card/getAllowedGeometryTypes';
import { connect } from 'react-redux';

import Panel from './Panel';
/* eslint-disable */
function mapStateToProps(state, props) {
  const { mode, selected, card, isMapLoaded, isCurrentObjectIsExternalSystem } =
    props;

  return {
    allowedGeometryTypes: getAllowedGeometryTypes(state),
    editable:
      !!(mode && mode.editMode && isMapLoaded) &&
      !isCurrentObjectIsExternalSystem,
    currentCard: selected || card,
  };
}

 
export default connect(mapStateToProps)(Panel);
