// 6 Опоры освещения и контактных сетей
/* eslint-disable  */
//

import CardAbstract from 'app/components/card/common/CardAbstract';
import {
  elementFormGrid,
  renderCustomTextField,
} from 'app/components/card/common/grid/index';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import { PlacementIdRFWrapper } from 'app/components/card/ogh/components/selects/PlacementIdRFWrapper';
import DocumentsTab, {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import { ODH_AXIS_TOOLTIP } from 'app/constants/messages';
import { column } from 'app/constants/tables';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import isMandatory, { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { ConvElementTypeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/ConvElementTypeId.RF';
import { MaterialIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/MaterialId.RF';
import { OdhSideIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/OdhSideId.RF';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['lampposts'];
const Versions = VersionsTemplate(types);

const tabs = [
  {
    id: 'characteristics-tab',
    name: 'Исходные данные',
  },
  {
    id: 'docs-tab',
    name: 'Документы',
  },
];

const propTypes = {
  card: PropTypes.object,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  mode: PropTypes.object,
  required: PropTypes.bool,
};

/**
 *
 */
class LamppostsCard extends React.Component {
  /**
   *
   */
  render() {
    const {
      mode,
      card,
      createMode,
      editMode,
      required,
      documents,
      isCurrentObjectIsExternalSystem,
    } = this.props;

    return (
      <CardAbstract>
        <div id="editor-card">
          {printOghCardHeader(card, mode)}
          {card && objectTabs(card.type_id)}

          <div id="props">
            {elementFormGrid(
              [
                <div>
                  <ConvElementTypeIdRF
                    withAll={false}
                    disabled={isCurrentObjectIsExternalSystem}
                  />
                </div>,
                <div>
                  <ParentInfo />
                </div>,
              ],
              column.TWO,
              '',
              card.type_id,
            )}

            <Tabs tabs={tabs} />

            <div id="characteristics">
              {elementFormGrid(
                [
                  <div>
                    <PlacementIdRFWrapper
                      disabled={!editMode || isCurrentObjectIsExternalSystem}
                      required={true}
                      withAll={false}
                    />
                  </div>,
                  {
                    addition: {
                      formatChars: {
                        9: '[а-яА-Я0-9a-zA-Z]',
                      },
                      mask: '99',
                      tooltipText: ODH_AXIS_TOOLTIP,
                      type: 'text',
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'odh_axis',
                    label: 'Ось',
                    name: 'odh_axis',
                  },
                  <div>
                    <OdhSideIdRF
                      disabled={!editMode || isCurrentObjectIsExternalSystem}
                    />
                  </div>,
                  {
                    addition: {
                      digits: 2,
                      positive: true,
                      required: isMandatory(card.type_id, 'endwise', required),
                      type: 'decimal',
                      zero: false,
                    },
                    component: renderCustomTextField,
                    editable: editMode && !isCurrentObjectIsExternalSystem,
                    formValue: true,
                    id: 'endwise',
                    label: 'По оси, м',
                    name: 'endwise',
                  },
                ],
                column.TWO,
                'Местоположение',
                card.type_id,
              )}

              {elementFormGrid(
                [
                  <div>
                    <MaterialIdRF
                      disabled={!editMode || isCurrentObjectIsExternalSystem}
                    />
                  </div>,
                ],
                column.TWO,
                'Характеристики',
                card.type_id,
              )}
              {elementFormGrid(
                [
                  {
                    addition: { type: 'text' },
                    component: renderCustomTextField,
                    editable: editMode,
                    formValue: true,
                    id: 'description',
                    label: 'Примечание',
                    name: 'description',
                  },
                ],
                column.ONE,
                '',
                card.type_id,
              )}
            </div>
            {DocumentsTab()({
              documents,
              editable: editMode,
              typeId: card.type_id,
            })}
          </div>
          <div hidden={true} id="versions">
            <Versions
              card={card}
              disabled={createMode}
              key={`versions-${card.root_id}`}
            />
          </div>
        </div>
      </CardAbstract>
    );
  }
}

LamppostsCard.propTypes = propTypes;

const dictFields = [
  'conv_element_type_id',
  'odh_side_id',
  'material_id',
  'placement_id',
];

const numericFields = [{ name: 'endwise', type: 'decimal', zero: true }];

/**
 *
 * @param values
 */
const validate = (values) => validateNumeric(values, numericFields);

const lamppostsCardDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Create attribute from form values.
 *
 * @param {*} formValues - Form values.
 * @returns {*}
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

/**
 * On submit handler.
 *
 * @param {*} formValues - Form values.
 * @returns {void}
 */
function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };
  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[card.type_id],
  );
  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      dictFields,
      numericFields,
    }),
  );
}

/**
 *
 * @param state
 * @param props
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);

  const result = {
    createMode: isCreateMode(state, props),

    editMode: isEditMode(state, props),

    enableReinitialize: true,
    initialValues: {
      conv_element_type_id: parse('conv_element_type_id.conv_element_type'),
      customer_id: parse('customer_id'),
      description: parse('description'),
      endDate: parseDate(props.card.end_date),
      endwise: parse('endwise'),
      material_id: parse('material_id.material'),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      owner_id: parse('owner_id'),
      parent_name: props.card.parent_info,
      placement_id: parse('placement_id.placement'),
      startDate: parseDate(props.card.start_date),
    },
    onSubmit: onSubmit.bind({ props }),

    required: isRequired(state, props),
    validate,
  };
  return lamppostsCardDocumentsReducer(result, state, props);
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'editorCard',
  })(LamppostsCard),
);
