import { elementFormGrid } from 'app/components/card/common/grid';
/* eslint-disable */
import AreaContainer from 'app/components/card/ogh/Container/components/AreaContainer';
import { WithReduxFormSelect } from 'app/components/common/SelectField';
import { CoatingTypeIdRF } from 'core/form/reduxForm/fields/prepared/selects/simple/CoatingTypeId.RF';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import React from 'react';

/**
 * Component Characteristics.
 *
 * @param params - Parameters.
 * @param params.isCurrentObjectIsExternalSystem - True if current object is external system.
 * @param params.card - Card object.
 * @param params.isPointGeometry - True if current object is point geometry.
 * @param params.editMode - True if current object is edit mode.
 * @param params.required - True if current object required.
 * @param params.typeId - Type id.
 * @param params.changeFieldValue - S.
 * @param params.card.coating_group - W.
 * @returns {JSX}
 */
export const Characteristics = ({
  isCurrentObjectIsExternalSystem,
  card,
  isPointGeometry,
  editMode,
  required,
  typeId,
  changeFieldValue,
}: {
  isCurrentObjectIsExternalSystem?: boolean;
  card: { coating_group: number[] };
  isPointGeometry?: unknown;
  editMode?: boolean;
  required?: boolean;
  changeFieldValue?: (_: unknown) => unknown;
  typeId?: number;
}) => {
  return (
    <div id="characteristics">
      {elementFormGrid(
        [
          {
            addition: {
              label: 'Вид покрытия',
              options: card.coating_group,
            },
            component: WithReduxFormSelect,
            editable: false,
            formValue: true,
            id: 'coating_group_id',
            name: 'coating_group_id',
          },
          <div>
            <CoatingTypeIdRF
              {...{
                disabled: !(editMode && !isCurrentObjectIsExternalSystem),

                /**
                 *
                 * @param dict
                 */
                filter: (dict: { code: string }[]) => {
                  return dict.filter(({ code }) =>
                    [
                      'asphalt_concrete',
                      'paving_stones',
                      'tiled',
                      'cement_concrete',
                    ].includes(code),
                  );
                },
                label: 'Вид покрытия (уточнение)',
                required: true,
                withAll: false,
              }}
            />
          </div>,
          {
            addition: {
              FormHelperTextProps: {
                error: true,
              },
              digits: 2,
              positive: true,
              required,
              type: 'decimal',
              zero: false,
            },
            component: AreaContainer,
            editable:
              editMode && !isPointGeometry && !isCurrentObjectIsExternalSystem,
            formValue: true,
            id: 'area',
            label: 'Площадь, кв.м',
            name: 'area',
            props: {
              changeFieldValue,
              editMode,
              isPointGeometry,
            },
          },
          <div>
            <TotalAreaGeoRF
              {...{
                decimalScale: 2,
                disabled: true,
              }}
            />
          </div>,
        ],
        2,
        '',
        typeId,
        {},
      )}
    </div>
  );
};
