import { ODH, OZN, YARD } from 'app/constants/oghTypes';
import { shallow } from 'enzyme';

import { MainParamsTab } from './MainParamsTab';

describe('MainParamsTabSpec', () => {
  it.each`
    typeId  | name
    ${YARD} | ${'YARD'}
    ${OZN}  | ${'OZN'}
  `('TotalAreaGeoRF show typeId $name', ({ typeId }) => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      typeId,
    };
    // 🔥 Act
    const wrapper = shallow(<MainParamsTab {...props} />);

    // ❓ Assert
    expect(wrapper.find('TotalAreaGeoRF').props()).toStrictEqual({
      decimalScale: 2,
      disabled: true,
      fixedDecimalScale: true,
    });
  });
  it('TotalAreaGeoRF show not', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      typeId: ODH,
    };
    // 🔥 Act
    const wrapper = shallow(<MainParamsTab {...props} />);

    // ❓ Assert
    expect(wrapper.find('TotalAreaGeoRF').length).toBe(0);
  });
});
