import { cleaningTypesWithNoCleaning } from 'app/constants/cleaningTypes';
/* eslint-disable  */
import { ODH_AXIS_TOOLTIP } from 'app/constants/messages';
import { column } from 'app/constants/tables';
import { IsDiffHeightMarkRF } from 'core/form/reduxForm/fields';
import { TotalAreaGeoRF } from 'core/form/reduxForm/fields/prepared/textField/TotalAreaGeo.RF';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import { get } from 'lodash';
import React from 'react';

import isMandatory from '../../../../utils/card/isMandatory';
import { WithReduxFormSelect } from '../../../common/SelectField';
import CardAbstract from '../../common/CardAbstract';
import { elementFormGrid, renderCustomTextField } from '../../common/grid';
import { objectTabs, Tabs } from '../../common/tabs';
import DocumentsTab from '../DocumentsTab/DocumentsTab';
import printOghCardHeader from '../header/oghHeader';
import VersionsTemplate from '../versions/VersionsTemplate';

const tabs = [
  { id: 'characteristics-tab', name: 'Исходные данные' },
  { id: 'docs-tab', name: 'Документы' },
];

const types = ['sidewalks'];
const Versions = VersionsTemplate(types);

/**
 * SidewalksComponent.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element} - JSX.
 * @example ----
 */
const SidewalksComponent = (props) => {
  const {
    card,
    mode,
    createMode,
    editMode,
    required,
    formValues,
    coatingTypes,
    changeFieldValue,
    documents,
    isCurrentObjectIsExternalSystem,
  } = props;

  return (
    <CardAbstract>
      <div id="editor-card">
        {printOghCardHeader(card, mode)}
        {card && objectTabs(card.type_id)}

        <div id="props">
          {elementFormGrid(
            [
              {
                addition: {
                  label: 'Тип',
                  options: card.flat_element_type,
                  required: isMandatory(
                    card.type_id,
                    'flat_element_type_id',
                    required,
                  ),
                },
                component: WithReduxFormSelect,
                editable: editMode && !isCurrentObjectIsExternalSystem,
                formValue: true,
                id: 'flat_element_type_id',
                name: 'flat_element_type_id',
              },
              <div
                style={{
                  height: 50,
                  margin: '9px 24px',
                  minWidth: 256,
                  paddingTop: 0,
                }}
              >
                <IsDiffHeightMarkRF
                  disabled={!mode.editMode || isCurrentObjectIsExternalSystem}
                  justifyContent={'space-between'}
                  labelPlacement={'start'}
                />
              </div>,
              <div>
                <ParentInfo />
              </div>,
            ],
            column.TWO,
            '',
            card.type_id,
          )}

          <Tabs tabs={tabs} />

          <div id="characteristics">
            {elementFormGrid(
              [
                {
                  addition: {
                    formatChars: {
                      9: '[а-яА-Я0-9a-zA-Z]',
                    },
                    mask: '99',
                    tooltipText: ODH_AXIS_TOOLTIP,
                    type: 'text',
                  },
                  component: renderCustomTextField,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'odh_axis',
                  label: 'Ось',
                  name: 'odh_axis',
                },
                {
                  addition: {
                    label: 'Сторона',
                    options: card.odh_side,
                    required: isMandatory(
                      card.type_id,
                      'odh_side_id',
                      required,
                    ),
                  },
                  component: WithReduxFormSelect,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'odh_side_id',
                  name: 'odh_side_id',
                },
                {
                  addition: {
                    digits: 2,
                    positive: true,
                    required: isMandatory(card.type_id, 'bord_begin', required),
                    type: 'integer',
                    zero: false,
                  },
                  component: renderCustomTextField,
                  // костыль что бы 0 отобразился
                  defaultValue: formValues.bord_begin,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'bord_begin',
                  label: 'Начало, м',
                  name: 'bord_begin',
                },
                {
                  addition: {
                    digits: 2,
                    positive: true,
                    required: isMandatory(card.type_id, 'bord_end', required),
                    type: 'integer',
                    zero: false,
                  },
                  component: renderCustomTextField,
                  // костыль что бы 0 отобразился
                  defaultValue: formValues.bord_end,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'bord_end',
                  label: 'Конец, м',
                  name: 'bord_end',
                },
              ],
              column.TWO,
              'Местоположение',
              card.type_id,
            )}

            {elementFormGrid(
              [
                {
                  addition: {
                    label: 'Вид покрытия',
                    options: card.coating_group,
                    required: isMandatory(
                      card.type_id,
                      'coating_group_id',
                      required,
                    ),

                    /**
                     *
                     */
                    withChange: () => {
                      changeFieldValue('coating_type_id', null);
                    },
                  },
                  component: WithReduxFormSelect,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'coating_group_id',
                  name: 'coating_group_id',
                },
                {
                  addition: {
                    label: 'Вид покрытия (уточнение)',
                    options: coatingTypes,
                    required: true,
                  },
                  component: WithReduxFormSelect,
                  editable:
                    editMode &&
                    get(formValues, 'coating_group_id') &&
                    !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'coating_type_id',
                  name: 'coating_type_id',
                },
                {
                  addition: {
                    digits: 1,
                    positive: true,
                    required: isMandatory(card.type_id, 'distance', required),
                    type: 'integer',
                    zero: false,
                  },
                  component: renderCustomTextField,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'distance',
                  label: 'Длина, п.м',
                  name: 'distance',
                },
                {
                  addition: {
                    digits: 2,
                    positive: true,
                    required: isMandatory(card.type_id, 'area', required),
                    type: 'integer',
                    zero: false,
                  },
                  component: renderCustomTextField,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'area',
                  label: 'Площадь, кв.м',
                  name: 'area',
                },
                <div>
                  <TotalAreaGeoRF disabled={true} decimalScale={2} />
                </div>,
                {
                  addition: {
                    digits: 0,
                    positive: true,
                    type: 'integer',
                    zero: false,
                  },
                  component: renderCustomTextField,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'width_begin',
                  label: 'Ширина в начале, м',
                  name: 'width_begin',
                },
                {
                  addition: {
                    digits: 0,
                    positive: true,
                    type: 'integer',
                    zero: false,
                  },
                  component: renderCustomTextField,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'width_end',
                  label: 'Ширина в конце, м',
                  name: 'width_end',
                },
              ],
              column.TWO,
              'Характеристики',
              card.type_id,
            )}

            {elementFormGrid(
              [
                {
                  addition: {
                    digits: 2,
                    positive: true,
                    type: 'decimal',
                  },
                  component: renderCustomTextField,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'utn_area',
                  label: 'УТН, кв.м',
                  name: 'utn_area',
                },
              ],
              column.TWO,
              '',
              card.type_id,
            )}

            {elementFormGrid(
              [
                {
                  addition: {
                    label: 'Способ уборки',
                    options: cleaningTypesWithNoCleaning,
                    required: isMandatory(
                      card.type_id,
                      'cleaning_type',
                      required,
                    ),
                  },
                  component: WithReduxFormSelect,
                  editable: editMode && !isCurrentObjectIsExternalSystem,
                  formValue: true,
                  id: 'cleaning_type',
                  name: 'cleaning_type',
                },
              ],
              column.TWO,
              'УБОРОЧНЫЕ ДАННЫЕ',
              card.type_id,
            )}

            {elementFormGrid(
              [
                {
                  addition: { type: 'text' },
                  component: renderCustomTextField,
                  editable: editMode,
                  formValue: true,
                  id: 'description',
                  label:
                    'Примечание (с указанием пятиметровых зон без уборки, УТН  и проч.)',
                  name: 'description',
                },
              ],
              column.ONE,
              '',
              card.type_id,
            )}
          </div>
          {DocumentsTab()({
            documents,
            editable: editMode,
            typeId: card.type_id,
          })}
        </div>
        <div hidden={true} id="versions">
          <Versions
            card={card}
            disabled={createMode}
            key={`versions-${card.root_id}`}
          />
        </div>
      </div>
    </CardAbstract>
  );
};

export default SidewalksComponent;
