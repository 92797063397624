import { HOLE, LINESTRING, POINT, POLYGON } from 'app/constants/geometryTypes';


/* eslint-disable */
export default {
  [LINESTRING]: {
    title: 'Нарисовать линию',
    drawFunction: 'line',
    icon: 'remove',
  },
  [POINT]: {
    title: 'Нарисовать точку',
    drawFunction: 'point',
    icon: 'add_location',
  },
  [POLYGON]: {
    title: 'Нарисовать полигон',
    drawFunction: 'polygon',
    icon: 'crop_landscape',
  },
  [HOLE]: {
    title: 'Вырезать полигон',
    drawFunction: 'hole',
    icon: 'content_cut',
  },
};
