import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { FieldsContainer } from 'app/pages/cardsOgh/components/FieldsContainer';
import { OghCardHeaderFF } from 'app/pages/cardsOgh/components/headerFF/OghHeaderFF';
import Versions from 'app/pages/cardsOgh/components/tables/Versions/Versions';
import { TabsCard } from 'app/pages/cardsOgh/components/TabsCard';
import { TabsCardItem } from 'app/pages/cardsOgh/components/TabsCard/TabsCardItem';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { NoCalcFF } from 'core/form/finalForm/fields/prepared/checkboxes/NoCalc.FF';
import { GreenNumFF } from 'core/form/finalForm/fields/prepared/numberField/GreenNum.FF';
import { SectionNumFF } from 'core/form/finalForm/fields/prepared/numberField/SectionNum.FF/SectionNum.FF';
import { RedBookPlantIdFF } from 'core/form/finalForm/fields/prepared/selects/simple/RedBookPlantId.FF';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import React from 'react';

/**
 * Левая панель паспорта __Растения Красной книги__.
 *
 * @returns {JSX.Element}
 */
export const RedBookPlantLeftPanel = () => {
  const { editMode } = useEditMode();
  const { rootId = 0 } = useGetCurrentCard();

  const tabsTopHeader = [
    { label: 'Свойства', value: 'features' },
    { label: 'Версии', show: Boolean(rootId), value: 'versions' },
  ];

  return (
    <>
      <OghCardHeaderFF />
      <TabsCard tabs={tabsTopHeader}>
        <TabsCardItem tabValue={'features'}>
          <FieldsContainer>
            <RedBookPlantIdFF disabled={!editMode} required={true} />
            <SectionNumFF required={true} disabled={!editMode} />
            <GreenNumFF required={true} disabled={!editMode} />
          </FieldsContainer>
          <FieldsContainer>
            <ParentInfo />
          </FieldsContainer>
          <FieldsContainer>
            <NoCalcFF disabled={!editMode} />
          </FieldsContainer>
        </TabsCardItem>
        <TabsCardItem tabValue={'versions'}>
          <FieldsContainer>
            <Versions />
          </FieldsContainer>
        </TabsCardItem>
      </TabsCard>
    </>
  );
};
