import { useGetVisibilityButtonsOgh } from 'app/api/hooks/useGetVisibilityButtonsOgh';
import { useGetCardModeSelector } from 'app/selectors/useSelectors';
/* eslint-disable */
import { shallow } from 'enzyme';
import React from 'react';

import { ApproveElectronicSignatureButton } from './ApproveElectronicSignature.Button';

jest.mock('app/api/hooks/useGetVisibilityButtonsOgh');
jest.mock('app/selectors/useSelectors');

const createMock = ({ createMode = false } = {}) => {
  useGetVisibilityButtonsOgh.mockImplementation((objectId) => ({
    data: { approve_eds: objectId === 42 },
  }));
  useGetCardModeSelector.mockReturnValue({ createMode });
};

function wrapperComponent(objectId, obj) {
  createMock(obj);
  return shallow(<ApproveElectronicSignatureButton objectId={objectId} />);
}

 
describe('component EditOghButton', () => {
  it('useGetVisibilityButtonsOgh был вызван с 1', () => {
    expect.hasAssertions();
    wrapperComponent(1);
    expect(useGetVisibilityButtonsOgh).toHaveBeenCalledWith(1);
  });

  it('useGetVisibilityButtonsOgh был вызван с 2', () => {
    expect.hasAssertions();
    wrapperComponent(2);
    expect(useGetVisibilityButtonsOgh).toHaveBeenCalledWith(2);
  });

  it('есть кнопка', () => {
    expect.hasAssertions();
    const button = wrapperComponent(42);
    expect(button.text()).toBe('<Button />');
  });

  it('есть кнопка с пропсами', () => {
    expect.hasAssertions();
    const button = wrapperComponent(42);
    expect(button.props()).toMatchObject({
      children: 'Утвердить (ЭП)',
      color: 'success',
      variant: 'contained',
    });
  });

  it('нет кнопки createMode', () => {
    expect.hasAssertions();
    const button = wrapperComponent(2, {
      createMode: true,
    });
    expect(button.text()).toBe('');
  });
  it('42, нет кнопки createMode', () => {
    expect.hasAssertions();
    const button = wrapperComponent(42, {
      createMode: true,
    });
    expect(button.text()).toBe('');
  });

  it('нет кнопки createMode false', () => {
    expect.hasAssertions();
    const button = wrapperComponent(2, {
      createMode: false,
    });
    expect(button.text()).toBe('');
  });
});
