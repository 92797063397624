import { post } from 'app/api';
/* eslint-disable  */
import { getLengthGeometry } from 'app/pages/cardsOgh/api/helpers/getLengthGeometry';
import { getAttributeForm } from 'app/pages/cardsOgh/helpers/getAttributeForm';

/**
 *
 * @param data
 */
export const postGeometryContains = async (data) =>
  await post('/ogh/geometry/check_geom_contains', data);

/* eslint-disable */
export const postCheckGeometryContains = async (
  geometry,
  card,
  values,
  parentId,
) => {
  const data = {
    id: card?.rootId,
    parentId: card.parentId,
    typeId: Number(card.typeId),
    geometry,
    journal: null,
    cnt: null,
    attribute: getAttributeForm(card.typeId, values),
    treeParentId: card.recordId ? card.recordId : Number(parentId),
    startDate: card.startDate,
  };

  const res = await postGeometryContains(data);
  const length = getLengthGeometry(res);

  if (length > 10) {
    throw Object({ errorKey: 'errorMore', res });
  } else if (length) {
    throw Object({ errorKey: 'errorOther', res });
  }
  return 'Success';
};
