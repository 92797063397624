import { pathExaminationOGHApplication } from 'app/pages/certificationOfOgh/examinationOGH.Application/path.ExaminationOGH.Application';
import { pathCertificationOfOgh } from 'app/pages/certificationOfOgh/path.CertificationOfOgh';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import { Icon } from 'core/uiKit/material-ui';
import React from 'react';
import { Link } from 'react-router-dom';

import { useSetupEditOghButton } from './useState.EditOgh.Button';

/**
 * Кнопка "Редактировать" Огх.
 *
 * @param {object} props - Properties.
 * @returns {JSX.Element|null}
 */
export const EditOghButton = (props) => {
  const { startEdit, data, isShow, closeDialog, open } =
    useSetupEditOghButton(props);
  return isShow ? (
    <>
      <Dialog
        maxWidth={'sm'}
        isOpen={open}
        textHeader={'Внимание!'}
        Body={
          <p>
            Объект связан с действующей заявкой на актуализацию ОГХ №&nbsp;
            <Link
              target={'_blank'}
              to={`/${pathCertificationOfOgh.path}/${pathExaminationOGHApplication.path}/${data?.versionInfo.bid_id}`}
            >
              {data?.versionInfo.bid_id}
            </Link>
          </p>
        }
        ButtonsActions={
          <>
            <Button variant={'outlined'} onClick={closeDialog}>
              Закрыть
            </Button>
            <Button color="info" variant={'contained'} onClick={closeDialog}>
              Продолжить
            </Button>
          </>
        }
      />
      <Button
        {...props}
        onClick={startEdit}
        color="info"
        variant={'contained'}
        startIcon={<Icon>mode_edit</Icon>}
      >
        Редактировать
      </Button>
    </>
  ) : null;
};
