import { post } from 'app/api';
/* eslint-disable  */
import { getLengthGeometry } from 'app/pages/cardsOgh/api/helpers/getLengthGeometry';
import { getAttributeForm } from 'app/pages/cardsOgh/helpers/getAttributeForm';
import GeometryCSVLink from 'app/utils/card/checkGeometry/GeometryCSVLink';
import IntersectionsMessage from 'app/utils/card/checkGeometry/IntersectionsMessage';

/**
 *
 * @param data
 */
export const postIntersection = async (data) =>
  await post('/ogh/geometry/intersection', data);

/* eslint-disable */
export const postCheckIntersection = async (
  geometry,
  card,
  values,
  parentId,
) => {
  const data = {
    id: card?.rootId,
    parentId: card.parentId,
    typeId: Number(card.typeId),
    geometry,
    journal: null,
    cnt: null,
    attribute: getAttributeForm(card.typeId, values),
    treeParentId: card.recordId ? card.recordId : Number(parentId),
    startDate: card.startDate,
  };

  const res = await postIntersection(data);
  const length = getLengthGeometry(res);

  if (length) {
    throw Object({ errorKey: 'errorIntersections', res });
  }
  return 'Success';
};

/* eslint-disable */
export function createIntersectionsMessage(geometries, message) {
  const intersections = getIntersections(geometries);
  const objGeoData = geometries.map(createObjGeoData);

  const GeometryCSV = () => (
    <GeometryCSVLink
      fileName={'IntersectionsGeometry.csv'}
      objects={objGeoData}
    />
  );

  return (
    <IntersectionsMessage
      GeometryCSVLink={GeometryCSV()}
      intersections={intersections}
      message={message}
      orderedList={false}
    />
  );
}

function getIntersections(geometries) {
  return geometries.slice(0, 10000000000).map((item) => {
    const { object_attribute_hint } = item.hint;
    const title = getHintValue(object_attribute_hint, 'title');
    const id_ogh = getHintValue(object_attribute_hint, 'id_ogh');
    const owner_name = getHintValue(object_attribute_hint, 'owner_name');

    if (owner_name) {
      return `${id_ogh}, ${title}, ${owner_name}`;
    }

    return `${id_ogh}, ${title}`;
  });
}

function getHintValue(hint, key) {
  const result = hint.find((item) => item.key === key);
  return result ? result.value : null;
}

function createObjGeoData({
  points,
  lines,
  polygons,
  hint: { object_attribute_hint },
}) {
  const newValue = {
    geometry: {
      points,
      lines,
      polygons,
    },
  };

  object_attribute_hint.forEach(function (item) {
    newValue[item.key] = item.value;
  });
  return newValue;
}
