import { useResetPassword } from 'app/pages/auth/ResetPassword/useResetPassword';
import { useParams } from 'react-router-dom';

import { validateFormResetPassword } from './validate.FormResetPassword';

/**
 * Форма Сброса пароля из почты.
 *
 * @returns {JSX.Element}
 */
export const useSetupFormResetPassword = () => {
  const params = useParams();
  const { resetPassword, isLoading } = useResetPassword();

  /**
   * Функция сабмит.
   *
   * @param {{password: string, confirmPassword: string}} values - Значения формы.
   * @returns {*}
   */
  const onSubmit = (values) => {
    const errors = validateFormResetPassword(values);
    if (errors) {
      return errors;
    }

    resetPassword({
      data: { newPassword: values.password },
      tokenNumber: params.token,
    });
  };

  return { isLoading, onSubmit };
};
