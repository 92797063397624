
/* eslint-disable */
export default function ({ repairs_info_list }) {
  let result;
  if (
    repairs_info_list &&
    repairs_info_list.table &&
    repairs_info_list.table.length
  ) {
    result = repairs_info_list.table.map(({ checked, ...item }) => item);
  } else {
    result = null;
  }
  return {
    repairs_info_list: result,
  };
}
